import { Reducer } from 'redux';
import { defaultLocale } from '../../configureI18n';

import { ApiError } from '../../utils/api';
import { GlobalNotification, GlobalNotificationType, LayoutActionTypes, LayoutState } from './types';

const emptyNotification: GlobalNotification = {
  type: GlobalNotificationType.basic,
  title: '',
  message: ''
};

const initialState: LayoutState = {
  currentLocale: defaultLocale,
  currentPageTitle: '',
  currentPageSubtitle: '',
  isGlobalNotificationVisible: false,
  globalNotification: emptyNotification,
  isNavigationMenuVisible: false,
  isSettingsDrawerVisible: false,
  isHistoryDrawerVisible: false,
  isSearchModalVisible: false,
};
const reducer: Reducer<LayoutState> = (state = initialState, action) => {
  if (action.payload && (action.payload.error instanceof ApiError || action.payload instanceof ApiError)) {
    const error = (action.payload.error || action.payload) as ApiError;
    if (error.statusCode >= 500 && error.statusCode < 600) {
      return {
        ...state,
        globalNotification: { title: 'Server error', message: error.message, type: GlobalNotificationType.error },
        isGlobalNotificationVisible: true
      };
    }
  }

  switch (action.type) {
  case LayoutActionTypes.SET_LOCALE:
    return { ...state, currentLocale: action.payload };

  case LayoutActionTypes.SET_PAGE_TITLE: {
    return {
      ...state,
      currentPageTitle: action.payload.title,
      currentPageSubtitle: action.payload.subtitle
    };
  }

  case LayoutActionTypes.SHOW_GLOBAL_NOTIFICATION:
    return {
      ...state,
      globalNotification: action.payload,
      isGlobalNotificationVisible: true
    };

  case LayoutActionTypes.HIDE_GLOBAL_NOTIFICATION:
    return { ...state, isGlobalNotificationVisible: false };

  case LayoutActionTypes.SHOW_NAVIGATION_MENU: {
    return {
      ...state,
      isNavigationMenuVisible: true
    };
  }

  case LayoutActionTypes.HIDE_NAVIGATION_MENU: {
    return {
      ...state,
      isNavigationMenuVisible: false
    };
  }

  case LayoutActionTypes.SHOW_SETTINGS_DRAWER: {
    return {
      ...state,
      isSettingsDrawerVisible: true
    };
  }

  case LayoutActionTypes.HIDE_SETTINGS_DRAWER: {
    return {
      ...state,
      isSettingsDrawerVisible: false
    };
  }

  case LayoutActionTypes.SHOW_HISTORY_DRAWER: {
    return {
      ...state,
      isHistoryDrawerVisible: true
    };
  }

  case LayoutActionTypes.HIDE_HISTORY_DRAWER: {
    return {
      ...state,
      isHistoryDrawerVisible: false
    };
  }

  case LayoutActionTypes.SHOW_SEARCH_MODAL: {
    return {
      ...state,
      isSearchModalVisible: true
    };
  }

  case LayoutActionTypes.HIDE_SEARCH_MODAL: {
    return {
      ...state,
      isSearchModalVisible: false
    };
  }

  default:
    return state;
  }
};

export { reducer as layoutReducer };

