import React from 'react';
import { WorkHistory } from '../types';
import { Box, Stack, Typography } from '@mui/material';
import { Button, ButtonProps } from 'semantic-ui-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { defineMessages, useIntl } from 'react-intl';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
dayjs.extend(utc);

interface Props {
  workHistory: WorkHistory;
}

export type HistoryButtonProps = & Props & ButtonProps;

const m = defineMessages({
  lotTitle: { id: 'HistoryButton.lotTitle', defaultMessage: 'Lot: {title}' },
});

export const WorkHistoryButton: React.FC<HistoryButtonProps> = ({ workHistory, ...buttonProps }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {workHistory.productionItem?.id == null && workHistory.lot?.id == null
        ? <></>
        : <Button
          icon
          labelPosition="right"
          primary
          fluid
          style={{ padding: '12px' }}
          {...buttonProps}
        >
          <Stack direction='row' width='100%' justifyContent='space-between'>
            <Typography fontWeight='light' variant='h6'>{workHistory.operation?.code}</Typography>

            {workHistory.lot &&
              <Typography fontWeight='regular' variant='h6'>{formatMessage(m.lotTitle, { title: workHistory.lot.name || '' })}</Typography>
            }

            {workHistory.productionItem &&
              <Typography fontWeight='regular' variant='h6'>{workHistory.productionItem?.item.i18n[0].description}</Typography>
            }

            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography fontWeight='light' variant='h6'>{dayjs.utc(workHistory.eventDate).local().format('H:mm')}</Typography>
              <KeyboardArrowRightIcon />
            </Box>
          </Stack>
        </Button>
      }
    </>
  );
};