import * as React from 'react';
import { Card, SemanticICONS } from 'semantic-ui-react';
import { TileContent } from './TileContent';
import { TileEditHeader } from './TileEditHeader';
import { ValidationResult } from '../../models';

interface OwnProps {
    name: string;
    nameFieldId?: string;
    namePlaceholder?: string;
    description?: string | React.ReactElement;
    canEditDescription?: boolean;
    tileIcon?: SemanticICONS;
    closeIcon?: React.ReactElement | null;
    validationResult?: ValidationResult;

    children?: React.ReactChild;
    className?: string;
    style?: React.CSSProperties;

    onClose?: () => void;
    onUpdate: (fieldId: string, fieldValue: any) => void;
}

export type TileEditTemplateProps =
    & OwnProps;

class TileEditTemplate extends React.Component<TileEditTemplateProps> {
  public render (): JSX.Element {
    return (
      <Card
        raised
        className={this.props.className}
        style={this.props.style}
      >
        <TileEditHeader
          name={this.props.name}
          nameFieldId={this.props.nameFieldId}
          namePlaceholder={this.props.namePlaceholder}
          description={this.props.description}
          canEditDescription={this.props.canEditDescription}
          tileIcon={this.props.tileIcon}
          validationResult={this.props.validationResult}
          closeIcon={this.props.closeIcon}
          onClose={this.props.onClose}
          onUpdate={this.props.onUpdate}
        />

        <TileContent>
          {this.props.children}
        </TileContent>
      </Card>
    );
  }
}

export { TileEditTemplate };
