import { Operation } from 'modules';
import { Lot } from 'modules/production/lots/types';

export interface WorkHistory {
  id: number;
  eventDate: string;
  workstationId?: number;
  operation?: Operation;
  lot?: Lot;
  productionItem?: WorkHistoryProductionItem;
}

export interface WorkHistoryProductionItem {
  id: number;
  itemId: number;
  item: workHistoryItem;
}

export interface workHistoryItem {
  id: number;
  height: number;
  width: number;
  itemCode: string;
  itemType: number;
  i18n: workHistoryItemI18n[];
}

export interface workHistoryItemI18n {
  description: string;
  entityId: number;
  languageId: number;
  language?: string;
}

export enum WorkHistoryFilter {
  LastDay,
  LastWeek,
  LastMonth
}