import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import HelpIcon from '@mui/icons-material/Help';

import { DeviceConfigurationStatus } from 'modules/administration/devices/types';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { commonMessages } from 'constants/messages';
import { Stack, Typography } from '@mui/material';

const m = defineMessages({
  pending: { id: 'ConfigurationStatus.pending', defaultMessage: 'Pending' },
  configured: { id: 'ConfigurationStatus.configured', defaultMessage: 'Configured' },
});

interface StatusDescriptor {
  label: MessageDescriptor,
  icon: React.ReactElement;
}

const statusMap = new Map<DeviceConfigurationStatus, StatusDescriptor>([
  [DeviceConfigurationStatus.pending, { label: m.pending, icon: <PendingIcon color="warning" /> }],
  [DeviceConfigurationStatus.configured, { label: m.configured, icon: <CheckCircleIcon color="success" /> }],
]);

const unknownStatus: StatusDescriptor = { label: commonMessages.undefined, icon: <HelpIcon color="error" /> };


export const ConfigurationStatusLabel: React.FC<{
  status: DeviceConfigurationStatus
}> = (props) => {
  const { formatMessage } = useIntl();
  const statusDescriptor = statusMap.get(props.status) || unknownStatus;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {statusDescriptor.icon}
      <Typography>{formatMessage(statusDescriptor.label)}</Typography>
    </Stack>
  );
};