import React from 'react';
import { Form, Input, Checkbox, Accordion } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Roadmap } from '../../../../../production/roadmaps/types';
import { InformationBubble } from '../../../../../../components/common/InformationBubble';
import { RoadmapColumnsSelection } from './RoadmapColumnsSelection';
import { RoadmapFieldsSort } from '../RoadmapFieldsSort';
import { RoadmapFormSection } from './RoadmapFormSection';

interface OwnProps {
    loading?: boolean;
}

export type RoadmapFormControlsProps =
    & OwnProps;

const m = defineMessages({
  nameLabel: { id: 'CreateOrUpdateRoadmapModal.nameLabel', defaultMessage: 'Name' },
  clearSearchLink: { id: 'CreateOrUpdateRoadmapModel.clearSearchLink', defaultMessage: 'Clear search' },
  defaultRoadmapLabel: { id: 'CreateOrUpdateRoadmapModel.defaultRoadmapLabel', defaultMessage: 'Set as the default roadmap?' },
  defaultRoadmapTooltip: { id: 'CreateOrUpdateRoadmapModel.defaultRoadmapTooltip', defaultMessage: 'The default roadmap is used for all operations that have not yet been assigned any roadmaps.' },
  displaySectionTitle: { id: 'CreateOrUpdateRoadmapModel.displaySectionTitle', defaultMessage: 'Display settings' },
  displaySectionSubtitle: { id: 'CreateOrUpdateRoadmapModel.displaySectionSubtitle', defaultMessage: '{count, plural, =0 {No columns displayed on the roadmap} one {{count} column displayed on the roadmap} other {{count} columns displayed on the roadmap}}' },
  sortSectionTitle: { id: 'CreateOrUpdateRoadmapModel.sortSectionTitle', defaultMessage: 'Sort settings' },
  sortSectionSubtitleForDefaultSort: { id: 'CreateOrUpdateRoadmapModel.sortSectionSubtitleForDefaultSort', defaultMessage: 'Default sorting options will be used' },
  sortSectionSubtitleForCustomSort: { id: 'CreateOrUpdateRoadmapModel.sortSectionSubtitleForCustomSort', defaultMessage: 'Custom sorting options will be used' },
  nameRequired: { id: 'RoadmapEdit.nameRequired', defaultMessage: 'A roadmap name is required.' }
});

export const RoadmapFormControls: React.FC<RoadmapFormControlsProps> = (props) => {
  const { formatMessage } = useIntl();
  const { control, formState: { errors }, setValue, getValues } = useFormContext<Roadmap>();

  return (
    <>
      <Controller
        name="name"
        control={control}
        rules={{ required: formatMessage(m.nameRequired) }}
        render={() => (
          <Form.Field required error={errors.name != null}>
            <label>{formatMessage(m.nameLabel)}</label>
            <Input
              autoFocus={true}
              onChange={(e, data) => setValue('name', data.value)}
              value={getValues('name') || ''}
            />
          </Form.Field>
        )}
      />

      <Accordion>
        <Controller
          name="columns"
          control={control}
          render={() => (
            <RoadmapFormSection
              isOpenByDefault
              title={formatMessage(m.displaySectionTitle)}
              subtitle={formatMessage(m.displaySectionSubtitle, { count: getValues('columns')?.length || 0 })}
            >
              <RoadmapColumnsSelection
                roadmapId={getValues('id')}
                selectedColumns={getValues('columns') || []}
                onChange={(columns) => setValue('columns', columns)}
              />
            </RoadmapFormSection>
          )}
        />

        <Controller
          name="sortings"
          control={control}
          render={() => (
            <RoadmapFormSection title={formatMessage(m.sortSectionTitle)} subtitle={getValues('sortings')?.length > 0 ? formatMessage(m.sortSectionSubtitleForCustomSort) : formatMessage(m.sortSectionSubtitleForDefaultSort)}>
              <RoadmapFieldsSort
                selectedSortings={getValues('sortings') || []}
                onChange={(sortings) => setValue('sortings', sortings)}
              />
            </RoadmapFormSection>
          )}
        />
      </Accordion>

      <div style={{ marginTop: '20px' }}>
        <Controller
          name="isDefault"
          control={control}
          render={() => (
            <>
              <Checkbox
                toggle
                label={formatMessage(m.defaultRoadmapLabel)}
                checked={getValues('isDefault')}
                onChange={(e, data) => setValue('isDefault', data.checked || false)}
                style={{ marginRight: '5px' }}
              />
              <InformationBubble
                content={formatMessage(m.defaultRoadmapTooltip)}
              />
            </>
          )}
        />
      </div>
    </>
  );
};
