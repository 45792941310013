import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export const EmptySalesOrdersTableContent: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Stack alignItems='center' justifyContent='center'>
          <Typography variant="body1" justifyContent='center'>{formatMessage({ id: 'No sales orders' })}</Typography>
          <Typography variant="body2" color="textSecondary" justifyContent="center">{formatMessage({ id: 'No sales orders were received on this date.' })}</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};