import { LotsState, Lot, LOAD_LOTS_SUCCESS, LOAD_PRIORITIZED_LOTS_SUCCESS, LotsActionTypes, LOAD_PRIORITIZED_LOTS_FAILURE } from './types';
import { ProductionActionTypes, PrioritizationActionTypes } from '..';
import { RoadmapActionTypes, LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST, LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS, LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS, LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE } from '../roadmaps/types';
import { ProductionScheduleActionTypes, LOAD_PRODUCTION_SCHEDULE_SUCCESS } from '../../schedule/types';
import { Reducer } from 'redux';
import _ from 'lodash';
import { LOT_UPDATED, WorkstationActionTypes } from '../workstation/types';

const initialLotsState: LotsState = {
  byId: {},
  byUnitId: {},
  allIds: [],
  isRoadmapLoading: false
};

export const lotsReducer: Reducer<LotsState, LotsActionTypes | ProductionActionTypes | PrioritizationActionTypes | WorkstationActionTypes | RoadmapActionTypes | ProductionScheduleActionTypes> = (state = initialLotsState, action): LotsState => {
  switch (action.type) {
  case LOAD_LOTS_SUCCESS: {
    const lots = Object.assign({}, ...action.payload.lots.map((x: Lot) => ({ [x.id]: x })));
    const lotsIds = action.payload.lots.map((x: Lot) => x.id);
    const byProductionUnitId =
      Object.assign({}, ..._.chain(action.payload.lots)
        .groupBy(x => x.productionUnitId)
        .map((lots, productionUnitId) => ({ [productionUnitId]: lots.map(x => x.id) }))
        .value());

    return {
      ...state,
      byId: _.merge({}, state.byId, lots),
      allIds: _.union(state.allIds, lotsIds),
      byUnitId: _.merge({}, state.byUnitId, byProductionUnitId)
    };
  }

  case LOT_UPDATED: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: {
          ...state.byId[action.payload.id],
          ...action.payload
        }
      }
    };
  }


  case LOAD_PRIORITIZED_LOTS_SUCCESS: {
    const lots = Object.assign({}, ...action.payload.lots.map(x => ({ [x.id]: x })));
    const lotsIds = action.payload.lots.map(x => x.id);

    return {
      ...state,
      byId: _.merge({}, state.byId, lots),
      allIds: _.union(state.allIds, lotsIds)
    };
  }

  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    const lots = action.payload.entities.lots;
    const lotsIds = _.map(action.payload.entities.lots, x => x.id);
    const byProductionUnitId =
      Object.assign({}, ..._.chain(action.payload.entities.lots)
        .groupBy((x: Lot) => x.productionUnitId)
        .map((lots, productionUnitId) => ({ [productionUnitId]: lots.map(x => x.id) }))
        .value());

    return {
      ...state,
      byId: _.merge({}, state.byId, lots),
      allIds: _.union(state.allIds, lotsIds),
      byUnitId: _.merge({}, state.byUnitId, byProductionUnitId),
      isRoadmapLoading: false
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST: {
    return {
      ...state,
      isRoadmapLoading: true
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    const lots = action.payload.entities.lots;
    const lotsIds = _.map(action.payload.entities.lots, x => x.id);
    const byProductionUnitId =
      Object.assign({}, ..._.chain(action.payload.entities.lots)
        .groupBy(x => x.productionUnitId)
        .map((lots, productionUnitId) => ({ [productionUnitId]: lots.map(x => x.id) }))
        .value());

    return {
      ...state,
      byId: _.merge({}, state.byId, lots),
      allIds: _.union(state.allIds, lotsIds),
      byUnitId: _.merge({}, state.byUnitId, byProductionUnitId),
      isRoadmapLoading: false
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE: {
    return {
      ...state,
      isRoadmapLoading: false
    };
  }

  default:
    return state;
  }
};