import _ from 'lodash';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Search, SearchProps, SearchResultData, SearchResultProps } from 'semantic-ui-react';

import { ProductionItem } from '../../production/types';
import * as SearchApi from '../../search/api';

const m = defineMessages({
  noResults: { id: 'ProductionOrdersSearchControl.noResults', defaultMessage: 'No production orders found.' },
  lot: { id: 'ProductionOrdersSearchControl.lot', defaultMessage: 'Lot {number}' }
});

interface OwnProps {
    onProductionOrderSelected: (productionOrder: ProductionItem) => void;
    onProductionOrderCleared: () => void;
}

export type ProductionOrdersSearchControlProps =
    & OwnProps
    & SearchProps;

export const ProductionOrdersSearchControl: React.FC<ProductionOrdersSearchControlProps> = (props) => {
  const { formatMessage } = useIntl();
  const { onProductionOrderSelected, onProductionOrderCleared, ...searchProps } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<ProductionItem[]>([]);

  const handleSearchChange = (_event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
    if (data.value != null && data.value !== '') {
      const productionOrderId = Number(data.value);
      if (!isNaN(productionOrderId)) {
        setIsSearching(true);

        SearchApi.searchForProductionOrders(productionOrderId)
          .then(productionItems => {
            setSearchResults(productionItems);
            setIsSearching(false);
          });
      }
    } else {
      onProductionOrderCleared();
    }
  };

  const handleResultSelected = (_event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
    onProductionOrderSelected(data.result.productionOrder);
  };

  const renderSearchResult = (props: SearchResultProps): React.ReactElement<any> => {
    const productionOrder = props.productionOrder as ProductionItem;
    if (productionOrder == null) {
      return <div />;
    }

    return (
      <div>
        <strong>{productionOrder.id}</strong>
      </div>
    );
  };

  const formattedResults: SearchResultProps[] = searchResults.map(x => ({
    title: x.id.toString(),
    productionOrder: x
  }));

  return (
    <Search
      fluid={true}
      loading={isSearching}
      onSearchChange={_.debounce(handleSearchChange, 500, { leading: true })}
      onResultSelect={handleResultSelected}
      selectFirstResult={true}
      results={formattedResults}
      resultRenderer={renderSearchResult}
      noResultsMessage={formatMessage(m.noResults)}
      {...searchProps}
    />
  );
};
