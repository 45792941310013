import { Box, Button, ButtonProps, CircularProgress, Divider, Grid, IconButton, Input, Stack, styled, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { RoadmapItem } from '../../../../workstation/types';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import useApiErrorHandler from '../../../../../../utils/ApiErrorHandler';

const hugeButtonWidth = '100%';
const hugeButtonHeight = '51px';
const HugeButton = styled(Button)<ButtonProps>(() => ({
  width: hugeButtonWidth,
  height: hugeButtonHeight,
  fontSize: '1.3em'
}));

const m = defineMessages({
  title: { id: 'ExecutionProgressInputForm.title', defaultMessage: 'Set Quantity' },
  remaining: { id: 'ExecutionProgressInputForm.remaining', defaultMessage: '{count} remaining' },
  all: { id: 'ExecutionProgressInputForm.all', defaultMessage: 'all' },
  subtract: { id: 'ExecutionProgressInputForm.subtract', defaultMessage: 'Subtract' },
  add: { id: 'ExecutionProgressInputForm.add', defaultMessage: 'Add' }
});

export interface ExecutionProgressInputFormProps {
  roadmapItem: RoadmapItem;
  onUpdateSuccess: () => void;
}

export const ExecutionProgressInputForm = (props: ExecutionProgressInputFormProps) => {
  const [manufacturedCount, setManufacturedCount] = React.useState(0);
  const { formatMessage } = useIntl();

  const inputValue = useCallback((digit: number) => {
    if (manufacturedCount === 0) {
      setManufacturedCount(digit);
    }
    else {
      setManufacturedCount(Number(manufacturedCount.toString() + digit.toString()));
    }
  }, [manufacturedCount]);

  const removeDigit = useCallback(() => {
    setManufacturedCount(Number(manufacturedCount.toString().slice(0, manufacturedCount.toString().length - 1)));

  }, [manufacturedCount]);

  const { handleSubmit, control, setValue, formState } = useForm<{ quantity: number }>();
  const { handleError } = useApiErrorHandler();

  React.useEffect(() => setValue('quantity', manufacturedCount), [manufacturedCount]);
  const updateCountMutation = useMutation((data: { manufacturedItemOperationId: number, quantity: number}) =>
    axios.post('/api/progress/AddOrRemoveCompletedOperationQuantity', data),
  {
    onError: (error) => handleError(error),
    onSuccess: props.onUpdateSuccess
  });

  const updateQuantity = useCallback((form: { quantity: number}) => {
    if (form.quantity !== 0) {
      updateCountMutation.mutate({ manufacturedItemOperationId: props.roadmapItem.manufacturedItemOperations[0], quantity: form.quantity});
    }
  }, []);

  const remainingCount = props.roadmapItem.quantity - props.roadmapItem.quantityManufactured;

  const renderDigitButton = (digit: number) =>
    <Grid item xs={4}><HugeButton onClick={() => inputValue(digit)} variant='outlined'>{digit}</HugeButton></Grid>;

  return <Stack sx={{ width: '320px'}}>
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">{formatMessage(m.title)}</Typography>
    </Box>
    <Divider/>
    <form onSubmit={handleSubmit(updateQuantity)}>
      <Box sx={{ padding: 2 }}>

        <Controller
          name="quantity"
          control={control}
          rules={{
            required: true,
            min: -props.roadmapItem.quantityManufactured,
            max: remainingCount
          }}
          render={({ field, fieldState }) =>
            <TextField
              id="quantity"
              {...field}
              sx={{width: '100%'}}
              type='number'
              variant='standard'
              inputProps={{
                readOnly: true
              }}
              value={manufacturedCount}
              error={fieldState.error != null}
              label={fieldState.error?.message}
              helperText={formatMessage(m.remaining, { count: remainingCount})}
            />
          }/>

      </Box>
      <Grid container spacing={2} padding={2}>
        {_.range(1, 10).map(renderDigitButton)}
        <Grid item xs={4}><HugeButton variant='contained' onClick={() => setManufacturedCount(remainingCount)}>{formatMessage(m.all)}</HugeButton></Grid>
        {renderDigitButton(0)}

        <Grid item xs={4}>
          <Stack
            onClick={removeDigit}
            sx={{ cursor: 'pointer'}}
            alignItems='center'
            justifyContent='center'
            width={hugeButtonWidth}
            height={hugeButtonHeight}>
            <IconButton ><BackspaceIcon/></IconButton>
          </Stack>
        </Grid>

        <Grid item xs={5} mt={8}>
          <HugeButton
            variant='contained'
            color='error'
            disabled={updateCountMutation.isLoading}
            onClick={() => { setValue('quantity', Math.abs(manufacturedCount) * -1, { shouldValidate: true }); handleSubmit(updateQuantity)(); }}>
            {formatMessage(m.subtract)}
          </HugeButton>
        </Grid>
        <Grid item xs={2} mt={8}>
        </Grid>
        <Grid item xs={5} mt={8}>
          <HugeButton
            variant='contained'
            color='primary'
            disabled={updateCountMutation.isLoading}
            onClick={() => { setValue('quantity', Math.abs(manufacturedCount), { shouldValidate: true }); handleSubmit(updateQuantity)(); }}>
            {formatMessage(m.add)}
          </HugeButton>
        </Grid>
      </Grid>
    </form>
    {updateCountMutation.isLoading &&
      <Stack alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    }
  </Stack>;
};