import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { commonMessages } from 'constants/messages';
import { useIntl } from 'react-intl';

interface DeleteLotConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirmed: () => void;
}

export const DeleteLotConfirmationDialog = ({
  open,
  onClose,
  onConfirmed,
}: DeleteLotConfirmationDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {formatMessage({
          id: 'DeleteLotConfirmationDialog.Title',
          defaultMessage: 'Delete lot',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formatMessage({
            id: 'DeleteLotConfirmationDialog.Message',
            defaultMessage: 'Are you sure you want to delete this lot ?',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {formatMessage(commonMessages.cancel)}
        </Button>
        <Button
          onClick={() => {
            onConfirmed();
          }}
          color="warning"
          autoFocus
        >
          {formatMessage({
            id: 'DeleteLotConfirmationDialog.ConfirmButton',
            defaultMessage: 'Yes, delete this lot',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
