import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { Saga } from 'redux-saga';

import { initializeApplication } from './modules/system/sagas';
import { ApplicationState, createRootReducer, rootSaga } from './store';

export default function configureStore (history: History, initialState: ApplicationState): Store<ApplicationState> {
  const composeEnhancer = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware
      )
    )
  );

  sagaMiddleware.run(rootSaga);
  sagaMiddleware.run(initializeApplication as Saga<any[]>);

  return store;
}
