import { Stack, ToggleButton, ToggleButtonProps, Typography } from '@mui/material';
import moment, { MomentInput } from 'moment';
import React from 'react';

interface OwnProps {
  hasError?: boolean;
}

export type ProductionDateButtonProps =
  & OwnProps
  & ToggleButtonProps;

export const ProductionDateButton: React.FC<ProductionDateButtonProps> = (props) => {
  const { hasError, ...toggleButtonProps } = props;
  const date = moment(props.value as MomentInput).local();

  return (
    <ToggleButton
      sx={{ backgroundColor: (theme) => hasError ? theme.palette.error.light : theme.palette.common.white }}
      {...toggleButtonProps}
    >
      <Stack direction="column">
        <Typography>{date.format('dddd')}</Typography>
        <Typography>{date.format('YYYY/MM/DD')}</Typography>
      </Stack>
    </ToggleButton>
  );
};