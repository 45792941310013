import { get } from '../../utils/api';
import {
  CostCenter,
  Unit
} from './types';

const productionUnitsEndpoint = '/api/production-units';
const costCentersEndpoint = '/api/cost-centers';

export async function loadProductionUnits (): Promise<Unit[]> {
  return get<Unit[]>(`${productionUnitsEndpoint}`);
}

export async function loadCostCenters (): Promise<CostCenter[]> {
  return get<CostCenter[]>(`${costCentersEndpoint}`);
}
