import { Box, ListItemText, Typography } from '@mui/material';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { LotManagementItem, LotManagementProductionItem, LotManagementSalesOrder } from '../../types';

const m = defineMessages({
  orderNumber: { id: 'ProductionItemListItemText.orderNumber', defaultMessage: 'Order #: {value}' },
  item: { id: 'ProductionItemListItemText.item', defaultMessage: 'Item: {value}' },
  quantity: { id: 'ProductionItemListItemText.quantity', defaultMessage: 'Quantity: {value}' },
  orderDate: { id: 'ProductionItemListItemText.orderDate', defaultMessage: 'Order date: {value}' },
});

export interface ProductionItemListItemTextProps {
  salesOrder?: LotManagementSalesOrder,
  productionItem: LotManagementProductionItem,
  item?: LotManagementItem,
}

export const ProductionItemListItemText = (props: ProductionItemListItemTextProps) => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <ListItemText
        primary={formatMessage(m.orderNumber, { value: props.salesOrder?.salesOrderNumber })}
        secondary={
          <>
            {props.item &&
              <Typography
                variant="body2"
                color="text.primary"
              >
                {formatMessage(m.item, { value: props.item.itemCode })}
              </Typography>
            }
            <Typography
              variant="body2"
              color="text.primary"
            >
              {formatMessage(m.quantity, { value: props.productionItem.quantity })}
            </Typography>
            <Typography
              variant="body2"
              color="text.primary"
            >
              {formatMessage(m.orderDate, { value: moment(props.salesOrder?.orderedOn).format('L') })}
            </Typography>
          </>
        }
      />
    </Box>
  );
};