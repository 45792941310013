import { Drawer, TableRow, Table, TableBody, TableHead, TableCell, TableProps, Stack, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { RoadmapItem } from '../../../../workstation/types';
import { RoadmapField } from '../../../types';
import { ExecutionProgressInputForm } from '../ExecutionProgressInputForm/ExecutionProgressInputForm';
import { RoadmapExecutionTableRow } from './RoadmapExecutionTableRow';
import { RoadmapFieldTableHeader } from './RoadmapFieldTableHeader';
interface OwnProps {
  operationId: number;
  roadmapItems: RoadmapItem[];
  roadmapFields: RoadmapField[];
  productionOrderId?: number;
}

export type RoadmapExecutionTableProps =
  & TableProps
  & OwnProps;

export const RoadmapExecutionTable: React.FC<RoadmapExecutionTableProps> = (props) => {
  const {
    operationId,
    roadmapItems,
    roadmapFields,
    productionOrderId,
    ...tableProps
  } = props;

  const [selectedRoadmapItem, setSelectedRoadmapItem] = useState<RoadmapItem>();
  return (
    <Table {...tableProps}>
      <TableHead>
        <TableRow>

          {roadmapFields.map(x => (
            <RoadmapFieldTableHeader key={x.id} roadmapField={x} />
          ))}

          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {props.roadmapItems.map((x, index) => (
          <RoadmapExecutionTableRow
            key={index}
            operationId={operationId}
            roadmapItem={x}
            roadmapFields={roadmapFields}
            productionOrderId={productionOrderId}
            onUpdateQuantityClicked={() => setSelectedRoadmapItem(x)} isSelected={false} />
        ))}

        <Drawer open={selectedRoadmapItem != null} anchor='right' onClose={() => setSelectedRoadmapItem(undefined)} >
          <Stack direction='row' justifyContent='right'>
            <IconButton></IconButton>
          </Stack>
          {selectedRoadmapItem && <ExecutionProgressInputForm roadmapItem={selectedRoadmapItem} onUpdateSuccess={() => setSelectedRoadmapItem(undefined)} />}
        </Drawer>
      </TableBody>
    </Table>
  );
};
