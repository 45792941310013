import * as React from 'react';
import { Icon, IconProps } from 'semantic-ui-react';

export type DragDropIconProps = IconProps

class DragDropIcon extends React.Component<DragDropIconProps> {
  public render () {
    const { ...iconProps } = this.props;

    return (
      <Icon name="bars" {...iconProps} />
    );
  }
}

export { DragDropIcon };
