import React from 'react';
import { Modal, ModalProps } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { UpdateOperationForm } from './UpdateOperationForm';
import { Operation } from '../../../../production';

interface OwnProps {
    operation: Operation | undefined;
    onCancel: () => void;
    onConfirm: (operation: Operation) => void;
}

export type UpdateOperationModalProps =
    & ModalProps
    & OwnProps;

const m = defineMessages({
  title: { id: 'UpdateOperationModal.title', defaultMessage: 'Modify an operation' }
});

export const UpdateOperationModal: React.FC<UpdateOperationModalProps> = (props) => {
  const { formatMessage } = useIntl();
  const { onCancel, onConfirm, ...modalProps } = props;

  return (
    <Modal {...modalProps}>
      <Modal.Header>{formatMessage(m.title)}</Modal.Header>
      <Modal.Content>
        <UpdateOperationForm
          operation={props.operation}
          onCancel={props.onCancel}
          onConfirm={props.onConfirm}
        />
      </Modal.Content>
    </Modal>
  );
};
