import _ from 'lodash';
import { combineReducers, Reducer } from 'redux';

import { costCentersReducer } from '../administration/cost-centers/reducer';
import { operationsReducer } from '../administration/operations';
import { LOAD_PRODUCTION_SCHEDULE_SUCCESS, ProductionScheduleActionTypes } from '../schedule/types';
import { lotsReducer } from './lots/reducer';
import { LOAD_LOTS, LOAD_LOTS_FAILURE, LOAD_LOTS_SUCCESS, LotsActionTypes } from './lots/types';
import { PrioritizationActionTypes, SAVE_PRIORITIES_SUCCESS } from './prioritization';
import { roadmapsReducer } from './roadmaps/reducer';
import { LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS, LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS, RoadmapActionTypes } from './roadmaps/types';
import {
  ItemsState,
  LOAD_PRODUCTION_UNITS,
  LOAD_PRODUCTION_UNITS_FAILURE,
  LOAD_PRODUCTION_UNITS_SUCCESS,
  ManufacturedItemOperationsState,
  ManufacturedItemsState,
  ProductCollectionsState,
  ProductionActionTypes,
  ProductionItemsState,
  ProductionNotesState,
  ProductionState,
  ProductTypesState,
  SalesOrdersState,
  UnitsState,
  WorkInstructionsState
} from './types';
import { workstationReducer } from './workstation/reducer';
import {
  MANUFACTURED_ITEM_OPERATION_UPDATED,
  MANUFACTURED_ITEM_UPDATED,
  PRODUCTION_ITEM_UPDATED,
  WorkstationActionTypes
} from './workstation/types';

const initialUnitsState: UnitsState = {
  byId: {},
  allIds: [],
  loadingIds: [],
  isLoading: false
};

const initialItemsState: ItemsState = {
  byId: {},
  allIds: []
};

const initialProductionItemsState: ProductionItemsState = {
  byId: {},
  byLotId: {},
  byOrderId: {},
  allIds: []
};

const initialProductionNotesState: ProductionNotesState = {
  byId: {},
  byProductionItem: {},
  allIds: []
};

const initialManufacturedItemsState: ManufacturedItemsState = {
  byId: {},
  byProductionItemId: {}
};

const initialManufacturedItemOperationsState: ManufacturedItemOperationsState = {
  byId: {},
  byManufacturedItemId: {}
};

const initialWorkInstructionsState: WorkInstructionsState = {
  byId: {},
  allIds: [],
  isLoading: false
};

const initialProductCollectionsState: ProductCollectionsState = {
  byId: {},
  allIds: []
};

const initialProductTypesState: ProductTypesState = {
  byId: {},
  allIds: []
};

const initialSalesOrdersState: SalesOrdersState = {
  byId: {},
  allIds: []
};

const salesOrdersReducer: Reducer<SalesOrdersState, PrioritizationActionTypes | ProductionScheduleActionTypes | RoadmapActionTypes> = (state = initialSalesOrdersState, action) => {
  switch (action.type) {
  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    const salesOrders = action.payload.entities.salesOrders;
    const salesOrderIds = Object.keys(salesOrders).map(x => Number(x));
    return {
      ...state,
      byId: _.merge({}, state.byId, salesOrders),
      allIds: _.union(state.allIds, salesOrderIds)
    };
  }

  case SAVE_PRIORITIES_SUCCESS: {
    const orderedSalesOrdersIds = action.payload.orderedSalesOrdersIds;
    const salesOrders = Object.assign({}, ...Object.values(state.byId).map(salesOrder => {
      const newOrderIndex = orderedSalesOrdersIds.indexOf(salesOrder.id);
      return ({[salesOrder.id]: {...salesOrder, orderIndex: newOrderIndex == -1 ? null : newOrderIndex }});
    }));

    return {
      ...state,
      byId: salesOrders,
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.salesOrders),
      allIds: _.union(state.allIds, _.map(action.payload.entities.salesOrders, x => x.id))
    };
  }

  default:
    return state;
  }
};

const unitsReducer: Reducer<UnitsState, ProductionActionTypes | LotsActionTypes> = (state = initialUnitsState, action) => {
  switch (action.type) {
  case LOAD_LOTS: {
    return {
      ...state,
      loadingIds: _.union(state.loadingIds, [action.payload.productionUnitId])
    };
  }

  case LOAD_LOTS_SUCCESS:
  case LOAD_LOTS_FAILURE: {
    return {
      ...state,
      loadingIds: state.loadingIds.filter(x => x !== action.payload.productionUnitId)
    };
  }

  case LOAD_PRODUCTION_UNITS: {
    return {
      ...state,
      isLoading: true
    };
  }

  case LOAD_PRODUCTION_UNITS_SUCCESS: {
    const units = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
    const unitsIds = action.payload.map(x => x.id);

    return {
      ...state,
      byId: _.merge({}, state.byId, units),
      allIds: _.union(state.allIds, unitsIds),
      isLoading: false
    };
  }

  case LOAD_PRODUCTION_UNITS_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  default:
    return state;
  }
};

const productCollectionsReducer: Reducer<ProductCollectionsState, ProductionScheduleActionTypes> = (state = initialProductCollectionsState, action) => {
  switch (action.type) {
  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    const productCollections = action.payload.entities.productCollections;
    const productCollectionIds = Object.keys(productCollections).map(x => Number(x));
    return {
      ...state,
      byId: _.merge({}, state.byId, productCollections),
      allIds: _.union(state.allIds, productCollectionIds)
    };
  }

  default:
    return state;
  }
};

const productTypesReducer: Reducer<ProductTypesState, ProductionScheduleActionTypes> = (state = initialProductTypesState, action) => {
  switch (action.type) {
  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    const productTypes = action.payload.entities.productTypes;
    const productTypeIds = Object.keys(productTypes).map(x => Number(x));

    return {
      ...state,
      byId: _.merge({}, state.byId, productTypes),
      allIds: _.union(state.allIds, productTypeIds)
    };
  }

  default:
    return state;
  }
};

const itemsReducer: Reducer<ItemsState, RoadmapActionTypes | ProductionScheduleActionTypes> = (state = initialItemsState, action): ItemsState => {
  switch (action.type) {
  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.items),
      allIds: _.union(state.allIds, _.map(action.payload.entities.items, x => x.id))
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.items),
      allIds: _.union(state.allIds, _.map(action.payload.entities.items, x => x.id))
    };
  }

  default:
    return state;
  }
};

const productionItemsReducer: Reducer<ProductionItemsState, WorkstationActionTypes | RoadmapActionTypes | ProductionScheduleActionTypes> = (state = initialProductionItemsState, action): ProductionItemsState => {
  switch (action.type) {
  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    const productionOrderIds = Object.keys(action.payload.entities.productionItems).map(x => Number(x));

    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.productionItems),
      allIds: _.union(state.allIds, productionOrderIds)
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    const productionOrderIds = Object.keys(action.payload.entities.productionItems).map(x => Number(x));
    const byLotId = _.chain(action.payload.entities.productionItems)
      .groupBy(x => x.lotId)
      .map((productionItems, lotId) => ({ [Number(lotId)]: productionItems.map(x => x.id) }))
      .reduce((accumulator, value) => _.merge(accumulator, value), {})
      .value();

    const byOrderId = _.chain(action.payload.entities.productionItems)
      .groupBy(x => x.salesOrderId)
      .map((productionItems, salesOrderId) => ({ [Number(salesOrderId)]: productionItems.map(x => x.id) }))
      .reduce((accumulator, value) => _.merge(accumulator, value), {})
      .value();

    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.productionItems),
      byLotId: _.merge({}, state.byLotId, byLotId),
      byOrderId: _.merge({}, state.byOrderId, byOrderId),
      allIds: _.union(state.allIds, productionOrderIds)
    };
  }

  case PRODUCTION_ITEM_UPDATED: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: {
          ...state.byId[action.payload.id],
          ...action.payload
        }
      }
    };
  }

  default:
    return state;
  }
};

const productionNotesReducer: Reducer<ProductionNotesState, ProductionActionTypes | RoadmapActionTypes> = (state = initialProductionNotesState, action): ProductionNotesState => {
  switch (action.type) {
  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    const productionNoteIds = Object.keys(action.payload.entities.productionNotes).map(x => Number(x));
    const productionNotes = Object.values(action.payload.entities.productionNotes);
    const byProductionItem = _.chain(productionNotes)
      .groupBy(x => x.forProductionItemId)
      .map((notes, forProductionItemId) => ({ [Number(forProductionItemId)]: notes.map(x => x.id) }))
      .reduce((accumulator, value) => _.merge(accumulator, value), {})
      .value();

    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.productionNotes),
      byProductionItem: _.merge({}, state.byProductionItem, byProductionItem),
      allIds: _.union(state.allIds, productionNoteIds)
    };
  }

  default:
    return state;
  }
};

const manufacturedItemsReducer: Reducer<ManufacturedItemsState, ProductionActionTypes | RoadmapActionTypes | WorkstationActionTypes> = (state = initialManufacturedItemsState, action): ManufacturedItemsState => {
  switch (action.type) {
  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    const byProductionItemId = _.chain(action.payload.entities.manufacturedItems)
      .groupBy(x => x.productionItemId)
      .map((manufacturedItems, productionItemId) => ({ [Number(productionItemId)]: manufacturedItems.map(x => x.id) }))
      .reduce((accumulator, value) => _.merge(accumulator, value), {})
      .value();

    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.manufacturedItems),
      byProductionItemId: _.merge({}, state.byProductionItemId, byProductionItemId)
    };
  }

  case MANUFACTURED_ITEM_UPDATED: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: {
          ...state.byId[action.payload.id],
          ...action.payload
        }
      }
    };
  }

  default:
    return state;
  }
};

const manufacturedItemOperationsReducer: Reducer<ManufacturedItemOperationsState, WorkstationActionTypes | ProductionActionTypes | RoadmapActionTypes> = (state = initialManufacturedItemOperationsState, action): ManufacturedItemOperationsState => {
  switch (action.type) {
  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    const byManufacturedItemId = _.chain(action.payload.entities.manufacturedItemOperations)
      .groupBy(x => x.manufacturedItemId)
      .map((manufacturedItemOperations, manufacturedItemId) => ({ [Number(manufacturedItemId)]: manufacturedItemOperations.map(x => x.id) }))
      .reduce((accumulator, value) => _.merge(accumulator, value), {})
      .value();

    return {
      ...state,
      byId: _.merge({}, state.byId, action.payload.entities.manufacturedItemOperations),
      byManufacturedItemId: _.merge({}, state.byManufacturedItemId, byManufacturedItemId)
    };
  }

  case MANUFACTURED_ITEM_OPERATION_UPDATED: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: {
          ...state.byId[action.payload.id],
          ...action.payload
        }
      }
    };
  }

  default:
    return state;
  }
};

const workInstructionsReducer: Reducer<WorkInstructionsState, ProductionActionTypes | RoadmapActionTypes> = (state = initialWorkInstructionsState, action): WorkInstructionsState => {
  switch (action.type) {
  case LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS:
  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    return {
      ...state,
      byId: action.payload.entities.workInstructions,
      allIds: Object.keys(action.payload.entities.workInstructions).map(x => Number(x))
    };
  }

  default:
    return state;
  }
};

const reducers = combineReducers<ProductionState>({
  lots: lotsReducer,
  items: itemsReducer,
  productionItems: productionItemsReducer,
  productionNotes: productionNotesReducer,
  manufacturedItems: manufacturedItemsReducer,
  manufacturedItemOperations: manufacturedItemOperationsReducer,
  operations: operationsReducer,
  productCollections: productCollectionsReducer,
  productTypes: productTypesReducer,
  roadmaps: roadmapsReducer,
  costCenters: costCentersReducer,
  salesOrders: salesOrdersReducer,
  units: unitsReducer,
  workInstructions: workInstructionsReducer,
  workstation: workstationReducer
});

export { reducers as productionReducer };

