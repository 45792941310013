import { LoadRoadmapItemsForDeviceRequestAction, LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST, LoadRoadmapItemsForDeviceSuccessAction, LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS, LoadRoadmapItemsForDeviceFailureAction, LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE, LoadAllRoadmapFieldsRequestAction, LOAD_ALL_ROADMAP_FIELDS_REQUEST, LoadAllRoadmapFieldsSuccessAction, LOAD_ALL_ROADMAP_FIELDS_SUCCESS, LoadAllRoadmapFieldsFailureAction, LOAD_ALL_ROADMAP_FIELDS_FAILURE, LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST, LoadRoadmapItemsForOperationRequestAction, LoadRoadmapItemsForOperationSuccessAction, LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS, LoadRoadmapItemsForOperationFailureAction, LOAD_ROADMAP_ITEMS_FOR_OPERATION_FAILURE, RoadmapField, LoadAllRoadmapTemplatesRequestAction, LOAD_ALL_ROADMAP_TEMPLATES_REQUEST, Roadmap, LoadAllRoadmapTemplatesSuccessAction, LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS, LoadAllRoadmapTemplatesFailureAction, LOAD_ALL_ROADMAP_TEMPLATES_FAILURE } from './types';
import { ProductionEntities } from '../types';

export function loadRoadmapItemsForOperation (operationId: number, lotId?: number, productionItemId?: number): LoadRoadmapItemsForOperationRequestAction {
  return {
    type: LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST,
    payload: { operationId, lotId, productionItemId }
  };
}

export function loadRoadmapItemsForOperationSuccess (entities: ProductionEntities): LoadRoadmapItemsForOperationSuccessAction {
  return {
    type: LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS,
    payload: { entities }
  };
}

export function loadRoadmapItemsForOperationFailure (error: Error): LoadRoadmapItemsForOperationFailureAction {
  return {
    type: LOAD_ROADMAP_ITEMS_FOR_OPERATION_FAILURE,
    payload: { error }
  };
}

export function loadRoadmapItemsForDevice (deviceId: number, salesOrderId?: number, lotId?: number): LoadRoadmapItemsForDeviceRequestAction {
  return {
    type: LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST,
    payload: { deviceId, salesOrderId, lotId }
  };
}

export function loadRoadmapItemsForDeviceSuccess (entities: ProductionEntities): LoadRoadmapItemsForDeviceSuccessAction {
  return {
    type: LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS,
    payload: { entities }
  };
}

export function loadRoadmapItemsForDeviceFailure (error: Error): LoadRoadmapItemsForDeviceFailureAction {
  return {
    type: LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE,
    payload: { error }
  };
}

export function loadRoadmapTemplates (): LoadAllRoadmapTemplatesRequestAction {
  return { type: LOAD_ALL_ROADMAP_TEMPLATES_REQUEST };
}

export function loadRoadmapTemplatesSuccess (templates: Roadmap[]): LoadAllRoadmapTemplatesSuccessAction {
  return {
    type: LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS,
    payload: { templates }
  };
}

export function loadRoadmapTemplatesFailure (error: Error): LoadAllRoadmapTemplatesFailureAction {
  return {
    type: LOAD_ALL_ROADMAP_TEMPLATES_FAILURE,
    payload: { error }
  };
}

export function loadRoadmapFields (): LoadAllRoadmapFieldsRequestAction {
  return { type: LOAD_ALL_ROADMAP_FIELDS_REQUEST };
}

export function loadRoadmapFieldsSuccess (fields: RoadmapField[]): LoadAllRoadmapFieldsSuccessAction {
  return {
    type: LOAD_ALL_ROADMAP_FIELDS_SUCCESS,
    payload: { fields }
  };
}

export function loadRoadmapFieldsError (error: Error): LoadAllRoadmapFieldsFailureAction {
  return {
    type: LOAD_ALL_ROADMAP_FIELDS_FAILURE,
    payload: { error }
  };
}
