import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CostCenter } from '../..';

import * as actions from './actions';
import * as api from './api';
import { COST_CENTERS_CREATE, COST_CENTERS_LOAD_ALL, COST_CENTERS_UPDATE, CreateAction, UpdateAction, LoadOverviewAction, CostCenterOverview, COST_CENTERS_LOAD_OVERVIEW } from './types';

function * handleLoadAll () {
  try {
    const result: CostCenter[] = yield call(api.loadAll);
    yield put(actions.loadAllSuccess(result));
  } catch (e) {
    yield put(actions.loadAllFailure(e as Error));
  }
}

function * handleLoadOverview (action: LoadOverviewAction) {
  try {
    const overviews = (yield call(api.loadOverview, action.payload.costCenterId)) as CostCenterOverview[];
    yield put(actions.loadOverviewSuccess(action.payload.costCenterId, overviews));
  } catch (e) {
    yield put(actions.loadOverviewFailure(action.payload.costCenterId, e as Error));
  }
}

function * handleCreate (action: CreateAction) {
  try {
    const result: CostCenter = yield call(api.create, action.payload);
    yield put(actions.createSuccess(result));
  } catch (e) {
    yield put(actions.createFailure(e as Error));
  }
}

function * handleUpdate (action: UpdateAction) {
  try {
    const costCenter: CostCenter = yield call(api.update, action.payload);
    yield put(actions.updateSuccess(costCenter));
  } catch (e) {
    yield put(actions.updateFailure(e as Error));
  }
}

function * watchLoadAll () { yield takeLatest(COST_CENTERS_LOAD_ALL, handleLoadAll); }
function * watchLoadOverview () { yield takeLatest(COST_CENTERS_LOAD_OVERVIEW, handleLoadOverview); }
function * watchCreate () { yield takeEvery(COST_CENTERS_CREATE, handleCreate); }
function * watchUpdate () { yield takeEvery(COST_CENTERS_UPDATE, handleUpdate); }

function * costCentersSagas () {
  yield all([
    fork(watchLoadAll),
    fork(watchLoadOverview),
    fork(watchCreate),
    fork(watchUpdate)
  ]);
}

export default costCentersSagas;
