import { Roadmap } from '../../production/roadmaps/types';

export function searchRoadmaps (roadmaps: Roadmap[], searchTerm: string) {
  const escapedSearchTerm = searchTerm.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  const searchExpression = () => new RegExp(`(${escapedSearchTerm.trim()})`, 'gi');

  if (searchTerm == null || searchTerm === '') {
    return roadmaps;
  }

  const roadmapsWithPropertiesMatchingSearch = roadmaps.filter(x => searchExpression().test(x.name));

  return roadmapsWithPropertiesMatchingSearch;
}
