import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import NoResultsIcon from '../../assets/icons/undraw_no_results.svg';

interface OwnProps {
  title: string;
  subtitle?: string;
  colSpan?: number;
}

export type NoResultsTableRowProps =
    & OwnProps;

export const NoResultsTableRow: React.FC<NoResultsTableRowProps> = (props) => {
  return (
    <TableRow>
      <TableCell colSpan={props.colSpan}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1.5}>
          <img src={NoResultsIcon} style={{maxWidth:'250px'}} />
          <Typography variant="h5">{props.title}</Typography>
          {props.subtitle && <Typography variant="subtitle1">{props.subtitle}</Typography>}
        </Stack>
      </TableCell>
    </TableRow>
  );
};