import { RoadmapField } from '../../../../../production/roadmaps/types';

export * from './RoadmapFieldsSort';

export enum SortDirection {
    ascending,
    descending,
}

export interface RoadmapSortEntry {
    field: RoadmapField;
    fieldId: number;
    orderIndex: number;
    sortDirection: SortDirection;
}
