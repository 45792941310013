import { WorkstationEdit } from './models/WorkstationEdit';

export const ADD_WORKSTATION = 'WORKSTATIONS_ADD';
export const ADD_WORKSTATION_SUCCESS = 'WORKSTATIONS_ADD_SUCCESS';
export const ADD_WORKSTATION_FAILURE = 'WORKSTATIONS_ADD_FAILURE';

export const DELETE_WORKSTATION = 'WORKSTATIONS_DELETE';
export const DELETE_WORKSTATION_SUCCESS = 'WORKSTATIONS_DELETE_SUCCESS';
export const DELETE_WORKSTATION_FAILURE = 'WORKSTATIONS_DELETE_FAILURE';

export const LOAD_ALL_WORKSTATIONS = 'WORKSTATIONS_LOAD_ALL';
export const LOAD_ALL_WORKSTATIONS_SUCCESS = 'WORKSTATIONS_LOAD_ALL_SUCCESS';
export const LOAD_ALL_WORKSTATIONS_FAILURE = 'WORKSTATIONS_LOAD_ALL_FAILURE';

export const UPDATE_WORKSTATION = 'WORKSTATIONS_UPDATE';
export const UPDATE_WORKSTATION_SUCCESS = 'WORKSTATIONS_UPDATE_SUCCESS';
export const UPDATE_WORKSTATION_FAILURE = 'WORKSTATIONS_UPDATE_FAILURE';

export interface AddWorkstationAction { type: typeof ADD_WORKSTATION; payload: { workstation: WorkstationEdit }; }
export interface AddWorkstationSuccessAction { type: typeof ADD_WORKSTATION_SUCCESS; payload: { workstation: Workstation }; }
export interface AddWorkstationFailureAction { type: typeof ADD_WORKSTATION_FAILURE; payload: Error; }

export interface DeleteWorkstationAction { type: typeof DELETE_WORKSTATION; payload: { workstationId: number }; }
export interface DeleteWorkstationSuccessAction { type: typeof DELETE_WORKSTATION_SUCCESS; payload: { workstationId: number }; }
export interface DeleteWorkstationFailureAction { type: typeof DELETE_WORKSTATION_FAILURE; payload: { workstationId: number, error: Error }; }

export interface LoadWorkstationsAction { type: typeof LOAD_ALL_WORKSTATIONS; }
export interface LoadWorkstationsSuccessAction { type: typeof LOAD_ALL_WORKSTATIONS_SUCCESS; payload: Workstation[]; }
export interface LoadWorkstationsFailureAction { type: typeof LOAD_ALL_WORKSTATIONS_FAILURE; payload: Error; }

export interface UpdateWorkstationAction { type: typeof UPDATE_WORKSTATION; payload: { workstationId: number, workstation: WorkstationEdit }; }
export interface UpdateWorkstationSuccessAction { type: typeof UPDATE_WORKSTATION_SUCCESS; payload: { workstation: Workstation }; }
export interface UpdateWorkstationFailureAction { type: typeof UPDATE_WORKSTATION_FAILURE; payload: { workstationId: number, error: Error }; }

export type WorkstationsActionTypes =
    | AddWorkstationAction | AddWorkstationSuccessAction | AddWorkstationFailureAction
    | DeleteWorkstationAction | DeleteWorkstationSuccessAction | DeleteWorkstationFailureAction
    | LoadWorkstationsAction | LoadWorkstationsSuccessAction | LoadWorkstationsFailureAction
    | UpdateWorkstationAction | UpdateWorkstationSuccessAction | UpdateWorkstationFailureAction;

export enum WorkstationStatus {
    unregistered,
    online,
    offline,
}

export enum WorkstationConfigurationStatus {
    notConfigured,
    signingIn,
    awaitingAdminApproval,
    configured
}

export interface Workstation {
    id: number;
    name: string;
    description: string;
    productionUnitId: number | undefined;
    costCenterId: number;
    operationIds: number[];
}

export interface WorkstationConfiguration {
    costCenterId: number;
    operationsId: number[];
}

export interface WorkstationOperation {
    id: number;
    workstationId: number;
    operationId: number;
}

export interface WorkstationsState {
    readonly byId: { [id: number]: Workstation };
    readonly byCostCenterId: { [costCenterId: number]: number[] };
    readonly allIds: number[];
}

export interface WorkstationsAdministrationState {
    readonly workstations: WorkstationsState;
}
