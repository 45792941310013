import { defineMessages } from '@formatjs/intl';
import { Container } from '@mui/material';
import { matchSorter } from 'match-sorter';
import { SearchControls } from 'components/common/SearchControls';
import React, { useEffect, useState } from 'react';
import { Device } from '../types';
import { DevicesTable } from './components/DevicesTable';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as DevicesActions from '../actions';
import { areDevicesLoading, getAllDevices, getLoadingDeviceIds } from '../selectors';

const m = defineMessages({
  totalDevicesCount: { id: 'ManageDevicesPage.totalDevicesCount', defaultMessage: '{count, plural, =0 {No devices} one {{count} device} other {{count} devices}}' },
});

export const ManageDevicesPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [displayedDevices, setDisplayedDevices] = useState<Device[] | undefined>(undefined);

  const allDevices = useSelector(getAllDevices);
  const isLoading = useSelector(areDevicesLoading);
  const loadingIds = useSelector(getLoadingDeviceIds);

  const searchForDevices = (searchTerm: string) => {
    const searchResults = matchSorter<Device>(allDevices || [], searchTerm, { keys: ['name'] });
    setDisplayedDevices(searchResults);
  };

  const clearSearchResults = () => setDisplayedDevices(allDevices);
  const navigateToDeviceManagementPage = (device: Device) => history.push(`/administration/devices/${device.id}`);

  useEffect(() => {
    dispatch(DevicesActions.loadDevices());
  }, []);

  return (
    <Container>
      <SearchControls
        totalResultsCount={allDevices?.length || 0}
        totalResultsMessage={m.totalDevicesCount}
        searchResultsCount={displayedDevices?.length || 0}
        onChange={searchForDevices}
        onClear={clearSearchResults}
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
      </SearchControls>

      <DevicesTable
        devices={displayedDevices || allDevices || []}
        isLoading={isLoading}
        loadingIds={loadingIds}
        onClick={navigateToDeviceManagementPage}
      />
    </Container>
  );
};