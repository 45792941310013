import _ from 'lodash';
import { DevicesState, LOAD_DEVICES_SUCCESS, Device, UPDATE_DEVICE_SUCCESS, DevicesActionTypes, UPDATE_DEVICE, UPDATE_DEVICE_FAILURE, DELETE_DEVICE, DELETE_DEVICE_FAILURE, SIGN_IN_SUCCESS, SIGN_IN_FAILURE, REGISTER_SUCCESS, LOAD_DEVICES, LOAD_DEVICES_FAILURE } from './types';
import { Reducer } from 'redux';

const initialState: DevicesState = {
  byId: {},
  allIds: [],
  loadingIds: [],
  loading: false,
  currentId: undefined
};

const devicesReducer: Reducer<DevicesState, DevicesActionTypes> = (state = initialState, action) => {
  switch (action.type) {

  case LOAD_DEVICES: {
    return {
      ...state,
      loading: true
    };
  }

  case LOAD_DEVICES_SUCCESS: {
    const deviceIds = action.payload.map((x: Device) => x.id);
    const devices = Object.assign({}, ...action.payload.map((x: Device) => ({ [x.id]: x })));

    return {
      ...state,
      byId: devices,
      allIds: deviceIds,
      loading: false
    };
  }

  case LOAD_DEVICES_FAILURE: {
    return {
      ...state,
      loading: false
    };
  }

  case UPDATE_DEVICE: {
    return {
      ...state,
      loadingIds: _.union(state.loadingIds, [action.payload.device.id])
    };
  }

  case UPDATE_DEVICE_SUCCESS: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.device.id]: action.payload.device
      },
      loadingIds: state.loadingIds.filter(x => x !== action.payload.device.id)
    };
  }

  case UPDATE_DEVICE_FAILURE: {
    return {
      ...state,
      loadingIds: state.loadingIds.filter(x => x !== action.payload.deviceId)
    };
  }

  case DELETE_DEVICE: {
    return {
      ...state,
      allIds: state.allIds.filter(x => x !== action.payload.deviceId),
      currentId: state.currentId === action.payload.deviceId ? undefined : state.currentId
    };
  }

  case DELETE_DEVICE_FAILURE: {
    return {
      ...state,
      allIds: _.union(state.allIds, [action.payload.deviceId])
    };
  }

  case SIGN_IN_SUCCESS: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload
      },
      currentId: action.payload.id,
      allIds: _.union(state.allIds, [action.payload.id])
    };
  }

  case SIGN_IN_FAILURE: {
    return {
      ...state,
      currentId: undefined
    };
  }

  default:
    return state;
  }
};

export { devicesReducer };
