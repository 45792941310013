import { get } from '../../utils/api';
import { Item, ProductionItem } from '../production/types';

const endpoint = '/api/search';

export async function searchForItems (partialItemCode: string): Promise<Item[]> {
  return get<Item[]>(`${endpoint}/for-items/starting-with/${partialItemCode}`);
}

export async function searchForProductionOrders (productionOrderId: number): Promise<ProductionItem[]> {
  return get<ProductionItem[]>(`${endpoint}/for-production-orders/starting-with/${productionOrderId}`);
}
