import _ from 'lodash';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { LoadingContainer, LoadingDimmer } from '../../../../../components';
import { WorkInstruction } from '../../types';
import { WorkInstructionTableRow } from './WorkInstructionTableRow';

export interface WorkInstructionTableProps {
    loading?: boolean;
    data: WorkInstruction[];
    onDelete: (instruction: WorkInstruction) => void;
    onPreview: (instruction: WorkInstruction) => void;
}

const m = defineMessages({
  documentHeader: { id: 'WorkInstructionTable.documentHeader', defaultMessage: 'Document' },
  itemHeader: { id: 'WorkInstructionTable.itemHeader', defaultMessage: 'For item' },
  operationHeader: { id: 'WorkInstructionTable.operationHeader', defaultMessage: 'For operation' },
  salesOrderHeader: { id: 'WorkInstructionTable.salesOrderHeader', defaultMessage: 'For sales order' }
});

export const WorkInstructionTable: React.FC<WorkInstructionTableProps> = (props) => {
  const { formatMessage } = useIntl();

  const renderWorkInstruction = (instruction: WorkInstruction) => {
    return (
      <WorkInstructionTableRow
        key={instruction.id}
        workInstruction={instruction}
        onDelete={() => props.onDelete(instruction)}
        onPreview={() => props.onPreview(instruction)}
      />
    );
  };

  return (
    <LoadingContainer>
      <LoadingDimmer active={props.loading} />

      <Table sortable selectable style={{ cursor: 'pointer' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{formatMessage(m.documentHeader)}</Table.HeaderCell>
            <Table.HeaderCell>{formatMessage(m.itemHeader)}</Table.HeaderCell>
            <Table.HeaderCell>{formatMessage(m.operationHeader)}</Table.HeaderCell>
            <Table.HeaderCell>{formatMessage(m.salesOrderHeader)}</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_(props.data)
            .sortBy(x => x.documentName)
            .map(renderWorkInstruction)
            .value()
          }
        </Table.Body>
      </Table>
    </LoadingContainer>
  );
};
