import _ from 'lodash';

import { ApplicationState } from '../../../store';
import { RoadmapSortEntry, SortDirection } from '../../administration/roadmaps/views/components/RoadmapFieldsSort';
import { SalesOrder } from '../../schedule';
import { Lot, ProductionStatus } from '../lots/types';
import { getDefaultTemplate, getTemplateById } from '../roadmaps/selectors';
import { RoadmapField } from '../roadmaps/types';
import {
  getAllLots,
  getAllProductionItems,
  getLotById,
  getOperationById,
  getProductionItemById,
  getProductionNotesByProductionOrder,
  getSalesOrderById,
  getWorkInstructions
} from '../selectors';
import { ManufacturedItem, ManufacturedItemOperation, ProductionItem } from '../types';
import { LotOverviewByOperation, ProductionOrderOverview, RoadmapItem } from './types';

export const getLotRoadmapItems = (state: ApplicationState, lotId: number, operationId: number): RoadmapItem[] => {
  const lot = getLotById(state, lotId);
  if (lot == null) {
    return [];
  }

  const manufacturedItems = getProductionItemsByLot(state, lot)
    .flatMap(productionItem => getManufacturedItemsByProductionItem(state, productionItem)
      .filter(manufacturedItem => getManufacturedItemOperationsByManufacturedItem(state, manufacturedItem)
        .filter(operation => operation != null)
        .some(operation => operation.operationId === operationId)));

  const roadmapSortings = getRoadmapSortings(state, operationId);
  const roadmapSortingNames = roadmapSortings.map(x => x.field.propertyName);
  const roadmapSortingDirections = roadmapSortings.map(x => x.sortDirection === SortDirection.ascending ? 'asc' : 'desc');

  const roadmapItems = _.chain(manufacturedItems)
    .map(manufacturedItem => {
      const productionItemId = manufacturedItem.productionItemId;
      const productionItem = getProductionItemById(state, productionItemId);
      const item = state.production.items.byId[manufacturedItem.itemId];
      const manufactureItemOperations = getManufacturedItemOperationsByManufacturedItem(state, manufacturedItem)
        .filter(x => x != null && x.operationId === operationId);

      return {
        itemId: item.id,
        itemCode: item.itemCode,
        itemCover: '',
        description: item.description,
        referenceNumber: '',
        quantity: manufacturedItem.quantity,
        material: item.material,
        dimension: item.dimension,
        notes: item.notes,
        quantityManufactured: _.sum(manufactureItemOperations.map(p => p.quantityManufactured)),
        manufacturedItemOperations: manufactureItemOperations.map(x => x.id),
        operationId,
        sequenceNumber: manufactureItemOperations[0]?.sequenceNumber ?? 0,
        workInstructions: getWorkInstructions(state, item.id, operationId, manufacturedItem.productionItemId, productionItem?.salesOrderId),
        salesOrderId: productionItem?.salesOrderId,
        productionNotes: getProductionNotesByProductionOrder(state, productionItemId),
        productionStatus: manufactureItemOperations.every(
          (operation) => operation.productionStatus === ProductionStatus.completed
        )
          ? ProductionStatus.completed
          : ProductionStatus.created,
        operationSequence: getManufacturingSequence(state, manufacturedItem),
        isReadyForProduction: manufactureItemOperations.find(p => p.isReadyForProduction) != undefined
      };
    })
    .orderBy(roadmapSortingNames, roadmapSortingDirections)
    .value();
  return roadmapItems;
};

export const getProductionItemRoadmapItems = (state: ApplicationState, productionOrderId: number, operationId: number | undefined): RoadmapItem[] => {
  const productionItem = getProductionItemById(state, productionOrderId);
  if (!productionItem) {
    return [];
  }

  const manufacturedItems = getManufacturedItemsByProductionItem(state, productionItem)
    .map(manufacturedItem => {
      const manufacturedItemOperations = getManufacturedItemOperationsByManufacturedItem(state, manufacturedItem);

      const manufacturedItemOperation = operationId
        ? manufacturedItemOperations.filter((operation) => operation.operationId === operationId)[0]
        : _.chain(manufacturedItemOperations)
          .orderBy((operation) => operation.sequenceNumber)
          .find(
            (operation) =>
              operation.productionStatus != ProductionStatus.completed
          )
          .value();

      return {
        manufacturedItem,
        manufacturedItemOperation
      };
    })
    .filter(p => p.manufacturedItemOperation != null);

  const roadmapSortings = getRoadmapSortings(state, operationId);
  const roadmapSortingNames = roadmapSortings.map(x => x.field.propertyName);
  const roadmapSortingDirections = roadmapSortings.map(x => x.sortDirection === SortDirection.ascending ? 'asc' : 'desc');

  const roadmapItems = _
    .chain(manufacturedItems)
    .map((value, key): RoadmapItem => {
      const item = state.production.items.byId[value.manufacturedItem.itemId];
      const itemCover = productionItem?.itemCoverId != null ? state.production.items.byId[productionItem!.itemCoverId] : undefined;

      return {
        itemId: item.id,
        itemCode: item.itemCode,
        description: item.description,
        itemCover: itemCover != null ? itemCover.description : '',
        referenceNumber: productionItem?.referenceNumber,
        quantity: value.manufacturedItem.quantity,
        material: item.material,
        dimension: item.dimension,
        notes: item.notes,
        quantityManufactured: value.manufacturedItemOperation!.quantityManufactured,
        manufacturedItemOperations: [value.manufacturedItemOperation!.id],
        operationId: value.manufacturedItemOperation!.operationId,
        sequenceNumber: value.manufacturedItemOperation!.sequenceNumber,
        workInstructions: getWorkInstructions(
          state,
          item.id,
          value.manufacturedItemOperation!.id,
          value.manufacturedItem.productionItemId,
          productionItem.salesOrderId
        ),
        salesOrderId: productionItem.salesOrderId,
        productionNotes: getProductionNotesByProductionOrder(state, productionOrderId),
        productionStatus: value.manufacturedItemOperation!.productionStatus,
        operationSequence: getManufacturingSequence(state, value.manufacturedItem),
        isReadyForProduction: value.manufacturedItemOperation!.isReadyForProduction
      };
    })
    .orderBy(roadmapSortingNames, roadmapSortingDirections)
    .value();
  return roadmapItems;
};

export const getCompletedProductionOrderRoadmapItems = (state: ApplicationState, productionOrderId: number, operationId: number): RoadmapItem[] => {
  return getProductionItemRoadmapItems(state, productionOrderId, operationId).filter(x => x.productionStatus === ProductionStatus.completed);
};

export const getRoadmapSortings = (state: ApplicationState, operationId: number | undefined): RoadmapSortEntry[] => {
  const operation = operationId ? getOperationById(state, operationId) : undefined;
  const roadmapTemplate = getTemplateById(state, operation?.roadmapId || -1) ||
    getDefaultTemplate(state);

  return roadmapTemplate != null
    ? _.sortBy(roadmapTemplate.sortings, x => x.orderIndex)
    : [];
};

export const getRoadmapFields = (state: ApplicationState, operationId: number): RoadmapField[] => {
  const operation = getOperationById(state, operationId);
  const roadmapTemplate = getTemplateById(state, operation?.roadmapId || -1) ||
    getDefaultTemplate(state);

  return roadmapTemplate != null
    ? _.sortBy(roadmapTemplate.columns, x => x.orderIndex).map(x => x.field)
    : [];
};

export const getProductionItemsByLot = (state: ApplicationState, lot: Lot): ProductionItem[] => {
  if (lot) {
    const productionItemsId = state.production.productionItems.byLotId[lot.id] || [];
    return productionItemsId.map(x => state.production.productionItems.byId[x]).filter(x => x != null);
  }

  return [];
};

export const getProductionItemsBySalesOrder = (state: ApplicationState, salesOrder: SalesOrder): ProductionItem[] => {
  if (salesOrder) {
    const productionItemsId = state.production.productionItems.byOrderId[salesOrder.id] || [];
    return productionItemsId.map(x => state.production.productionItems.byId[x]).filter(x => x != null);
  }

  return [];
};

export const getManufacturedItemOperationsByManufacturedItem = (state: ApplicationState, manufactureItem: ManufacturedItem): ManufacturedItemOperation[] => {
  if (manufactureItem == null) {
    return [];
  }

  const manufacturedItemOperationIds = state.production.manufacturedItemOperations.byManufacturedItemId[manufactureItem.id] || [];
  return manufacturedItemOperationIds.map(x => state.production.manufacturedItemOperations.byId[x]).filter(x => x != null);
};

const getManufacturedItemOperationsByItemId = (state: ApplicationState, itemId: number) =>
  state.production.manufacturedItemOperations.byManufacturedItemId[itemId] ?? [];

const getManufacturedItemOperationById = (state: ApplicationState, id: number): ManufacturedItemOperation | undefined =>
  state.production.manufacturedItemOperations.byId[id];

export const getAllManufacturedItemOperationsByItemId = (state: ApplicationState, itemId: number | undefined): ManufacturedItemOperation[] => {
  if (itemId == null)
    return [];

  return <ManufacturedItemOperation[]>getManufacturedItemOperationsByItemId(state, itemId)
    .map(id => getManufacturedItemOperationById(state, id))
    .filter(manufacturedItemOperation => manufacturedItemOperation != null);
};

export const getManufacturedItemsByProductionItem = (state: ApplicationState, productionItem: ProductionItem): ManufacturedItem[] => {
  if (productionItem == null) {
    return [];
  }

  const manufacturedItemIds = state.production.manufacturedItems.byProductionItemId[productionItem.id] || [];
  return manufacturedItemIds.map(x => state.production.manufacturedItems.byId[x]).filter(x => x != null);
};

export const getManufacturingSequence = (state: ApplicationState, manufactureItem: ManufacturedItem): string => {
  return _.orderBy(getManufacturedItemOperationsByManufacturedItem(state, manufactureItem), manufacturedItemOperation => manufacturedItemOperation.sequenceNumber)
    .map(manufactureItemOperation => {
      const operation = state.production.operations.byId[manufactureItemOperation.operationId];
      return operation ? operation.code : '[unknown operation]';
    })
    .join('-');
};

export const getCompletedRoadmapItems = (state: ApplicationState, lotId: number, operationId: number): RoadmapItem[] => {
  return getLotRoadmapItems(state, lotId, operationId).filter(x => x.productionStatus === ProductionStatus.completed);
};

export const getLotOverviewsForOperation = (state: ApplicationState, operationId: number, productionUnitId?: number): LotOverviewByOperation[] => {
  return getAllLots(state)
    .filter(lot => productionUnitId != null ? lot.productionUnitId === productionUnitId : true)
    .map(lot => {
      return { lot, roadmapItems: getLotRoadmapItems(state, lot.id, operationId) };
    })
    .filter(lotWithItems => lotWithItems.roadmapItems.length > 0)
    .map(lotWithItems => {
      return {
        lot: lotWithItems.lot,
        productionStatus: getProductionStatus(lotWithItems.roadmapItems)
      };
    });
};

export const getProductionOrderOverviewsForSalesOrder = (
  state: ApplicationState,
  salesOrderId: number
): ProductionOrderOverview[] => {
  const singleItemOrders = getAllProductionItems(state)
    .filter(
      (productionItem) =>
        productionItem.lotId == null
    )
    .map((productionItem) => {
      const order = getSalesOrderById(state, productionItem.salesOrderId);
      return {
        order,
        productionItem,
      };
    })
    .filter(
      (productionItemWithOrder) =>
        productionItemWithOrder.order != null &&
        productionItemWithOrder.order.id === salesOrderId
    )
    .map((productionItemWithOrder) => {
      return {
        order: productionItemWithOrder.order as SalesOrder,
        productionItem: productionItemWithOrder.productionItem,
        roadmapItems: getProductionItemRoadmapItems(
          state,
          productionItemWithOrder.productionItem.id,
          undefined
        ),
      };
    })
    .filter(
      (productionOrderWithItems) =>
        productionOrderWithItems.roadmapItems.length > 0
    )
    .filter((p) => p.order.orderIndex != null)
    .flatMap((productionOrderWithItems): ProductionOrderOverview[] => {
      return productionOrderWithItems.roadmapItems.map((roadmapItem) => {
        return {
          type: 'production-item',
          lot: undefined,
          salesOrder: productionOrderWithItems.order,
          productionItem: productionOrderWithItems.productionItem,
          productionStatus: productionOrderWithItems.productionItem.status,
          isReadyForProduction: roadmapItem.isReadyForProduction,
          roadmapItemsProductionStatus: getProductionStatus([roadmapItem]),
          orderIndex: 0,
          operationId: roadmapItem.operationId,
        };});
    }
    );

  const lots = getAllLots(state)
    .filter((lot) => lot?.isReadyForProduction)
    .map((lot) => ({
      lot,
      productionItems: getProductionItemsByLot(state, lot),
      roadmapItems: getProductionItemsByLot(state, lot).flatMap((item) =>
        getProductionItemRoadmapItems(state, item.id, undefined)
      ),
    }))
    .filter((lotWithItems) =>
      lotWithItems.productionItems.some(
        (productionItem) => productionItem.salesOrderId === salesOrderId
      )
    )
    .map((lotWithItems) => ({
      lot: lotWithItems.lot,
      roadmapItems: lotWithItems.roadmapItems
    }))
    .filter(
      (productionOrderWithItems) =>
        productionOrderWithItems.roadmapItems.length > 0
    )
    .map((lotWithItems): ProductionOrderOverview => {
      return {
        type: 'lot',
        lot: lotWithItems.lot,
        salesOrder: undefined,
        productionItem: undefined,
        productionStatus: lotWithItems.lot!.status,
        isReadyForProduction:
          lotWithItems.roadmapItems.find((p) => p.isReadyForProduction) !=
          undefined,
        roadmapItemsProductionStatus: getProductionStatus(
          lotWithItems.roadmapItems
        ),
        orderIndex: 0,
        operationId: _.chain(lotWithItems.roadmapItems).orderBy(x => x.sequenceNumber).first().value().operationId,
      };
    });

  return [...singleItemOrders, ...lots];
};

export const getProductionItemOverviews = (state: ApplicationState, unitId: number, operationId: number): ProductionOrderOverview[] => {
  const singleItemOrders = getAllProductionItems(state)
    .filter(productionItem => productionItem?.lotId == null)
    .filter(productionItem => productionItem?.productionUnitId === unitId)
    .map(productionItem => {
      const order = getSalesOrderById(state, productionItem!.salesOrderId);
      return {
        order,
        productionItem,
        roadmapItems: getProductionItemRoadmapItems(state, productionItem!.id, operationId)
      };
    })
    .filter(productionOrderWithItems => productionOrderWithItems.roadmapItems.length > 0)
    .filter(p => p.order?.orderIndex != null)
    .map((productionOrderWithItems): ProductionOrderOverview => {
      return {
        type: 'production-item',
        lot: undefined,
        salesOrder: productionOrderWithItems.order,
        productionItem: productionOrderWithItems.productionItem!,
        productionStatus: productionOrderWithItems.productionItem!.status,
        isReadyForProduction: productionOrderWithItems.roadmapItems.find(p => p.isReadyForProduction) != undefined,
        roadmapItemsProductionStatus: getProductionStatus(productionOrderWithItems.roadmapItems),
        orderIndex: productionOrderWithItems.order!.orderIndex!,
        operationId: operationId,
      };
    });

  const lots = state.production.lots.allIds.map(x => getLotById(state, x))
    .filter(lot => lot != null)
    .filter(lot => lot?.isReadyForProduction)
    .map(lot => ({
      lot,
      productionItems: getProductionItemsByLot(state, lot!),
      roadmapItems: getProductionItemsByLot(state, lot!).flatMap(item => getProductionItemRoadmapItems(state, item!.id, operationId))
    }))
    .map(lotWithItems => ({
      lot: lotWithItems.lot,
      roadmapItems: lotWithItems.roadmapItems,
      orderIndex: Math.min(...lotWithItems.productionItems.map(p => getSalesOrderById(state, p.salesOrderId)!.orderIndex ?? Number.MAX_VALUE)),
    }))
    .filter(productionOrderWithItems => productionOrderWithItems.roadmapItems.length > 0)
    .map((lotWithItems): ProductionOrderOverview => {
      return {
        type: 'lot',
        lot: lotWithItems.lot,
        salesOrder: undefined,
        productionItem: undefined,
        productionStatus: lotWithItems.lot!.status,
        isReadyForProduction: lotWithItems.roadmapItems.find(p => p.isReadyForProduction) != undefined,
        roadmapItemsProductionStatus: getProductionStatus(lotWithItems.roadmapItems),
        orderIndex: lotWithItems.orderIndex,
        operationId: operationId,
      };
    });

  return [...singleItemOrders, ...lots].sort((a, b) => a.orderIndex - b.orderIndex);
};


const getProductionStatus = (roadmapItems: RoadmapItem[]): ProductionStatus => {
  const isCompleted = roadmapItems.every(x => x.productionStatus === ProductionStatus.completed);
  if (isCompleted) {
    return ProductionStatus.completed;
  }

  const isNotStarted = roadmapItems.every(x => x.productionStatus === ProductionStatus.created);
  if (isNotStarted) {
    return ProductionStatus.created;
  }

  return ProductionStatus.started;
};

export const getLotFilter = (state: ApplicationState) => {
  return state.production.workstation.lotFilter;
};

