import { ManageDevicesPage, UpdateDevicePage } from 'modules/administration/devices/views';
import { ForbiddenErrorPage, NotFoundErrorPage, UnauthorizedErrorPage } from 'modules/application/views';
import { ProductionHistoryPage } from 'modules/production/productionHistory/views/ProductionHistoryPage';
import { UnplannedOrdersPage } from 'modules/production/unplanned/views';
import { ProductionOrderForSalesOrderManualSelectionPage } from 'modules/production/workstation/views/ProductionOrderForSalesOrderManualSelectionPage';
import { SalesOrderSearchPage } from 'modules/production/workstation/views/SalesOrderSearchPage';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/common';
import { PageLayout } from './components/layout';
import {
  DashboardPage, LoginPage,
  LotSelectionPage,
  OrdersPrioritizationPage, ProductionOrderManualSelectionPage,
  ProductionOrderSelectionPage
} from './modules';
import { isAuthenticated as isAuthenticatedSelector } from './modules/account/selectors';
import { CostCentersConfigurationPage } from './modules/administration/cost-centers';
import { CostCenterOverviewPage } from './modules/administration/cost-centers/views/CostCenterOverviewPage';
import { getCurrentDevice } from './modules/administration/devices/selectors';
import { Device, DeviceConfigurationStatus } from './modules/administration/devices/types';
import { ManageOperationsPage } from './modules/administration/operations';
import { ManageRoadmapsPage } from './modules/administration/roadmaps/views';
import { CreateRoadmapPage } from './modules/administration/roadmaps/views/CreateRoadmapPage';
import { UpdateRoadmapPage } from './modules/administration/roadmaps/views/UpdateRoadmapPage';
import { ManageUsersPage, UpdateUserPage } from './modules/administration/users';
import { CreateUserPage } from './modules/administration/users/views/CreateUserPage';
import { BulkImportPage, ManageInstructionsPage } from './modules/administration/work-instructions';
import { WorkstationSetupPage } from './modules/administration/workstations';
import { WorkstationsPage } from './modules/administration/workstations/views/WorkstationsPage';
import { OrdersSchedulePage } from './modules/production/cost-centers/views';
import { LotsManagementPage } from './modules/production/lots/views/LotsManagementPage';
import { LotRoadmapExecutionPage, ProductionOrderRoadmapExecutionPage } from './modules/production/roadmaps/views';
import { OperationSelectionPage } from './modules/production/workstation/views/OperationSelectionPage';
import { ProductionUnitSelectionPage } from './modules/production/workstation/views/ProductionUnitSelectionPage';
import { WorkstationSelectionPage } from './modules/production/workstation/views/WorkstationSelectionPage';
import { ProductionSchedulePage } from './modules/schedule';
import { ApplicationState } from './store';

const administrativeRoutes = () => (
  <PageLayout>
    <>
      <Switch>
        <Redirect exact={true} from="/" to="/orders-scheduling" />
        <PrivateRoute exact={true} path="/administration/cost-centers" component={CostCentersConfigurationPage} />
        <PrivateRoute exact={true} path="/administration/operations" component={ManageOperationsPage} />
        <PrivateRoute path="/administration/devices" component={ManageDevicesPage} />
        <PrivateRoute path="/administration/roadmaps" component={ManageRoadmapsPage} />
        <PrivateRoute path="/administration/users" component={ManageUsersPage} />
        <PrivateRoute exact={true} path="/administration/workstations" component={WorkstationsPage} />
        <PrivateRoute exact={true} path="/administration/work-instructions/manage" component={ManageInstructionsPage} />
        <PrivateRoute exact={true} path="/administration/work-instructions/bulk-import" component={BulkImportPage} />
        <PrivateRoute exact={true} path="/dashboard" component={DashboardPage} />
        <PrivateRoute exact={true} path="/orders-unplanified" component={UnplannedOrdersPage} />
        <PrivateRoute exact={true} path="/lots-management" component={LotsManagementPage} />
        <PrivateRoute exact={true} path="/orders-scheduling/:productionDate?" component={OrdersSchedulePage} />
        <PrivateRoute exact={true} path="/orders-prioritization" component={OrdersPrioritizationPage} />
        <PrivateRoute exact={true} path="/schedule/:productionDate?" component={ProductionSchedulePage} />
        <PrivateRoute exact={true} path="/cost-centers/:costCenterId/overview" component={CostCenterOverviewPage} />
        <PrivateRoute exact={true} path="/production-history" component={ProductionHistoryPage} />
        <PrivateRoute component={NotFoundErrorPage} />
      </Switch>

      <Switch>
        <PrivateRoute path="/administration/devices/:deviceId" component={UpdateDevicePage} />
      </Switch>

      <Switch>
        <PrivateRoute path="/administration/roadmaps/new" component={CreateRoadmapPage} />
        <PrivateRoute path="/administration/roadmaps/:roadmapId" component={UpdateRoadmapPage} />
      </Switch>

      <Switch>
        <PrivateRoute path="/administration/users/new" component={CreateUserPage} />
        <PrivateRoute path="/administration/users/:userId" component={UpdateUserPage} />
      </Switch>
    </>
  </PageLayout>
);

const workstationRoutes = () => (
  <PageLayout>
    <>
      <Switch>
        <Redirect exact={true} from="/" to="/setup" />
        <PrivateRoute exact={true} path="/workstations" component={WorkstationSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations" component={OperationSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/lots" component={LotSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/lots/:lotId/roadmap" component={LotRoadmapExecutionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/production-units" component={ProductionUnitSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/production-units/:unitId/lots" component={LotSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/production-orders" component={ProductionOrderSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/production-orders/list/:unitId" component={ProductionOrderManualSelectionPage} />
        <PrivateRoute exact={true} path="/workstations/:workstationId/operations/:operationId/production-orders/:productionOrderId/roadmap" component={ProductionOrderRoadmapExecutionPage} />
        <PrivateRoute exact={true} path="/sales-order/search" component={SalesOrderSearchPage} />
        <PrivateRoute exact={true} path="/sales-order/:salesOrderId/production-orders/list" component={ProductionOrderForSalesOrderManualSelectionPage} />
        <PrivateRoute component={NotFoundErrorPage} />
      </Switch>
    </>
  </PageLayout>
);

export const Routes = () => {
  const currentDevice = useSelector<ApplicationState, Device | undefined>(getCurrentDevice);
  const isAuthenticated = useSelector<ApplicationState, boolean>(state => isAuthenticatedSelector(state));
  const isEmployeeDevice = currentDevice != null;
  const configurationStatus = currentDevice != null ? currentDevice.configurationStatus : undefined;

  return (
    <Switch>
      <Route exact={true} path="/setup" component={WorkstationSetupPage} />
      <Route exact={true} path="/account/login" component={LoginPage} />
      <Route path="/error/401" component={UnauthorizedErrorPage} />
      <Route path="/error/403" component={ForbiddenErrorPage} />
      <Route path="/error/404" component={NotFoundErrorPage} />

      {isAuthenticated && isEmployeeDevice &&
        <React.Fragment>
          {configurationStatus === DeviceConfigurationStatus.pending && <Redirect to="/setup" />}
          {configurationStatus === DeviceConfigurationStatus.configured && <Route component={workstationRoutes} />}
        </React.Fragment>
      }

      {isAuthenticated && !isEmployeeDevice &&
        <Route component={administrativeRoutes} />
      }

      <Route component={() => <Redirect to="/account/login" />} />
    </Switch>
  );
};
