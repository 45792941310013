import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Confirm, ConfirmProps } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';

interface OwnProps {
    isDeleting?: boolean;
    title?: string;
    message?: string | React.ReactNode;
}

export type DeleteConfirmationProps =
    & ConfirmProps
    & OwnProps;

const m = defineMessages({
  defaultTitle: { id: 'DeleteConfirmation.defaultTitle', defaultMessage: 'Are you sure?' },
  defaultMessage: { id: 'DeleteConfirmation.defaultMessage', defaultMessage: 'Are you sure you want to delete this object? This action cannot be reversed.' }
});

export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = (props) => {
  const { isDeleting, ...confirmProps } = props;
  const { formatMessage } = useIntl();

  const confirmContent = (
    <div className="content">
      {props.message || formatMessage(m.defaultMessage)}
    </div>
  );

  return (
    <Confirm
      header={props.title || formatMessage(m.defaultTitle)}
      content={confirmContent}
      cancelButton={formatMessage(commonMessages.cancel)}
      confirmButton={<Button negative loading={isDeleting} disabled={isDeleting} content={formatMessage(commonMessages.delete)} />}
      {...confirmProps}
    />
  );
};
