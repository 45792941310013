import { Button, Stack, Typography } from '@mui/material';
import { commonMessages } from 'constants/messages';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

interface OwnProps {
  confirmationMessage?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export type MuiDeleteConfirmationProps =
    & OwnProps;

const m = defineMessages({
  defaultConfirmationMessage: { id: 'MuiDeleteConfirmation.defaultConfirmationMessage', defaultMessage: 'Are you sure you want to delete this entry?' }
});

export const MuiDeleteConfirmation: React.FC<MuiDeleteConfirmationProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography variant="body1" sx={{ flexGrow: 1 }}>
        {props.confirmationMessage ?? formatMessage(m.defaultConfirmationMessage)}
      </Typography>
      <Stack direction="row" spacing={1.5} sx={{ justifyContent: 'flex-end' }}>
        <Button variant="contained" color="secondary" onClick={props.onCancel}>{formatMessage(commonMessages.noCancel)}</Button>
        <Button variant="contained" color="error" onClick={props.onConfirm}>{formatMessage(commonMessages.yesDelete)}</Button>
      </Stack>
    </>
  );
};