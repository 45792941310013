import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon, Popup, Table } from 'semantic-ui-react';
import { SmallConfirmDeleteButton } from '../../../../../components';
import { WorkInstruction } from '../../types';

interface OwnProps {
    workInstruction: WorkInstruction;
    onDelete: () => void;
    onPreview: () => void;
}

export type WorkInstructionTableRowProps =
    & OwnProps;

const m = defineMessages({
  forSpecificProductionItem: { id: 'WorkInstructionTableRow.forSpecificProductionItem', defaultMessage: 'This document will be displayed only for the production order #{productionItemId}.' },
  deleteConfirmationTitle: { id: 'WorkInstructionTableRow.deleteConfirmationTitle', defaultMessage: 'Are you sure you want to remove this work instruction association?' },
  deleteConfirmationMessage: { id: 'WorkInstructionTableRow.deleteConfirmationMessage', defaultMessage: 'The association will be removed but the work instruction document will remain available for future use.' }
});

export const WorkInstructionTableRow: React.FC<WorkInstructionTableRowProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Table.Row onClick={props.onPreview}>
      <Table.Cell>
        {props.workInstruction.forProductionItemId != null &&
                    <Popup trigger={<Icon circular name="star" inverted color="yellow" />}>
                      {formatMessage(m.forSpecificProductionItem, { productionItemId: props.workInstruction.forProductionItemId })}
                    </Popup>
        }

        {props.workInstruction.documentName}
      </Table.Cell>
      <Table.Cell>{props.workInstruction.forItem?.itemCode || ''}</Table.Cell>
      <Table.Cell>{props.workInstruction.forOperation?.code || ''}</Table.Cell>
      <Table.Cell>{props.workInstruction.forSalesOrder?.salesOrderNumber || ''}</Table.Cell>
      <Table.Cell>
        <SmallConfirmDeleteButton
          onClick={props.onDelete}
          confirmationTitle={formatMessage(m.deleteConfirmationTitle)}
          confirmationMessage={formatMessage(m.deleteConfirmationMessage)}
        />
      </Table.Cell>
    </Table.Row>
  );
};
