import { getPriority } from 'os';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ProductionItem } from '..';
import { ApplicationState } from '../../../store';
import { getCurrentDevice } from '../../administration/devices/selectors';
import { Device } from '../../administration/devices/types';
import { SalesOrder } from '../../schedule';
import { Lot } from '../lots/types';
import * as roadmapActions from '../roadmaps/actions';
import { getLotById, getSalesOrderById, getSalesOrders } from '../selectors';
import { getProductionItemsBySalesOrder } from '../workstation/selectors';
import { LotUpdatedAction, LOT_UPDATED } from '../workstation/types';
import {
  SavePrioritiesSuccessAction,
  SAVE_PRIORITIES_SUCCESS
} from './types';

function* watchPrioritizeSuccess() { yield takeEvery(SAVE_PRIORITIES_SUCCESS, handlePrioritizeSuccess); }
function* handlePrioritizeSuccess(action: SavePrioritiesSuccessAction) {
  try {
    // When a new sales order is prioritized, we make sure we actually have the production data for it.
    // If we don't, we simply load it directly from the server.
    const currentDevice = (yield select(getCurrentDevice)) as Device | undefined;
    if (currentDevice != null) {
      for (const salesOrderId of action.payload.orderedSalesOrdersIds) {
        const salesOrder: SalesOrder | undefined = yield select(getSalesOrderById, salesOrderId);
        const productionItems: ProductionItem[] = salesOrder != null ? yield select(getProductionItemsBySalesOrder, salesOrder) : [];
        if (productionItems == null || productionItems.length <= 0) {
          yield put(roadmapActions.loadRoadmapItemsForDevice(currentDevice.id, salesOrderId));
        }
      }
    }
  } catch (ex) {
    console.error(ex);
  }
}

function* watchLotUpdated() { yield takeEvery(LOT_UPDATED, handleLotUpdated); }
function* handleLotUpdated(action: LotUpdatedAction) {
  try {
    const currentDevice = (yield select(getCurrentDevice)) as Device | undefined;
    const currentLot = (yield select(getLotById, action.payload.id)) as Lot | undefined;
    if (currentDevice != null && (currentLot == null || action.payload.isReadyForProduction === true)) {
      yield put(roadmapActions.loadRoadmapItemsForDevice(currentDevice.id, undefined, action.payload.id));
    }
  } catch (ex) {
    console.error(ex);
  }
}

function* lotPrioritizationSagas() {
  yield all([
    fork(watchPrioritizeSuccess),
    fork(watchLotUpdated)
  ]);
}

export default lotPrioritizationSagas;
