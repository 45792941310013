import * as React from 'react';
import styles from './TilePlaceholder.module.css';

interface OwnProps {
    style?: React.CSSProperties;
    className?: string;
}

export type TilePlaceholderProps =
    & OwnProps;

// tslint:disable-next-line: variable-name
const TilePlaceholder: React.StatelessComponent<TilePlaceholderProps> = (props) => {
  return (
    <div className={styles.placeholder + ' ' + props.className} style={props.style}>
      {props.children}
    </div>
  );
};

export { TilePlaceholder };
