import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { ItemsList } from './ItemsList';
import { LotsList } from './LotsList';
import React from 'react';
import axios from 'axios';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { LotManagementData } from '../../types';
import { useQuery } from '@tanstack/react-query';

export interface LotManagementForm {
  lotId: number | undefined;
  productionItemIds: number[];
  lotProductionItemIds: number[];
}
export const OrdersTransferList = () => {

  const { onSettledErrorHandler} = useApiErrorHandler();
  const dataQuery = useQuery<LotManagementData>(['lotManagementData'], async () =>
    (await axios.get<LotManagementData>('/api/lots/LotManagementData')).data,
  { onSettled: onSettledErrorHandler });

  const form = useForm<LotManagementForm>({ defaultValues: { productionItemIds: [], lotProductionItemIds: []}});

  return (
    <FormProvider {...form} >

      <Stack direction='row' justifyContent='space-evenly' width='100%'>
        <Backdrop
          sx={{ color: (theme) => theme.palette.common.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dataQuery.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <ItemsList/>
        <LotsList/>
      </Stack>
    </FormProvider>
  );
};