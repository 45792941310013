/* eslint-disable @typescript-eslint/no-empty-function */
import { defineMessages } from '@formatjs/intl';
import { MuiForm } from 'components/common/MuiForm';
import { MuiUpdateEntityModal } from 'components/common/UpdateEntityModal/MuiUpdateEntityModal';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from 'store';
import { getDeviceById } from '../selectors';
import { Device } from '../types';
import { DeviceFormControls } from './components/DeviceFormControls';
import * as DevicesActions from '../actions';

const m = defineMessages({
  title: { id: 'UpdateDevicePage.title', defaultMessage: 'Modify a device' },
});

interface RouteProps {
  deviceId: string;
}

export const UpdateDevicePage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<RouteProps>();
  const deviceId = Number(params.deviceId);
  const device = useSelector<ApplicationState>((state) => getDeviceById(state, deviceId)) as Device | undefined;

  const formProps = useForm<Device>({ defaultValues: { id: Number(params.deviceId) } });

  useEffect(() => {
    if (device != null) {
      formProps.reset({ ...device });
    }

  }, [device]);

  const closeModal = () => history.push('/administration/devices');

  const saveDevice = (device: Device) => {
    dispatch(DevicesActions.updateDevice(device));
    closeModal();
  };

  const deleteDevice = () => {
    dispatch(DevicesActions.deleteDevice(deviceId));
    closeModal();
  };

  return (
    <MuiUpdateEntityModal
      open
      maxWidth="sm"
      title={formatMessage(m.title)}
      onSubmit={formProps.handleSubmit(saveDevice)}
      onCancel={closeModal}
      onClose={closeModal}
      onDelete={deleteDevice}
    >
      <FormProvider {...formProps}>
        <MuiForm>
          <DeviceFormControls />
        </MuiForm>
      </FormProvider>
    </MuiUpdateEntityModal >
  );
};