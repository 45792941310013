import React, { useEffect } from 'react';
import { Form, Button, Message, Divider, Icon } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useIntl, defineMessages } from 'react-intl';
import { nameof } from '../../../../../utils';
import { commonMessages } from '../../../../../constants';
import { Operation, OperatingMode, GroupingMode } from '../../../../production';
import { CostCentersDropdown } from '../../../workstations/views/components';
import { OperatingModeDropdown } from './OperatingModeDropdown';
import { RoadmapsDropdown, defaultRoadmapValue } from '../../../roadmaps/views/components/RoadmapsDropdown';
import { GroupingModeDropdown } from './GroupingModeDropdown';

interface OwnProps {
    operation: Operation | undefined;
    onCancel: () => void;
    onConfirm: (operation: Operation) => void;
}

export type UpdateOperationFormProps =
    & OwnProps;

const m = defineMessages({
  operationCodeLabel: { id: 'UpdateOperationForm.operationCodeLabel', defaultMessage: 'Operation code' },
  operationCodeRequired: { id: 'UpdateOperationForm.operationCodeRequired', defaultMessage: 'Enter a short abbreviation to identify the operation (e.g. CNC).' },
  operationNameLabel: { id: 'UpdateOperationForm.operationNameLabel', defaultMessage: 'Operation name' },
  costCenterLabel: { id: 'UpdateOperationForm.costCenterLabel', defaultMessage: 'Cost center' },
  costCenterRequired: { id: 'UpdateOperationForm.costCenterRequired', defaultMessage: 'Choose the cost center associated with this operation.' },
  selectCostCenterPlaceholder: { id: 'UpdateOperationForm.selectCostCenterPlaceholder', defaultMessage: 'Select cost center' },
  operatingModeLabel: { id: 'UpdateOperationForm.operatingModeLabel', defaultMessage: 'Operating mode' },
  operatingModePlaceholder: { id: 'UpdateOperationForm.operatingModePlaceholder', defaultMessage: 'Choose the operating mode' },
  groupingModeLabel: { id: 'UpdateOperationForm.groupingModeLabel', defaultMessage: 'Grouping mode' },
  roadmapTemplateLabel: { id: 'UpdateOperationForm.roadmapTemplateLabel', defaultMessage: 'Roadmap template' },
  disabledFieldsInformation: { id: 'UpdateOperationForm.disabledFieldsInformation', defaultMessage: 'Some fields are disabled because they are synchronized.' }
});

export const UpdateOperationForm: React.FC<UpdateOperationFormProps> = (props) => {
  const { formatMessage } = useIntl();
  const { control, setValue, getValues, formState: { errors }, handleSubmit } = useForm<Operation>({ defaultValues: props.operation });

  const hasErrors = Object.keys(errors).filter(x => (errors as any)[x] != null).length > 0;
  const errorMessages = Object.keys(errors).map(x => (errors as any)[x]?.message);

  const confirm = (operation: Operation) => {
    const updatedOperation = { ...props.operation, ...operation };
    if (updatedOperation.roadmapId === defaultRoadmapValue) {
      updatedOperation.roadmapId = undefined;
    }

    props.onConfirm(updatedOperation);
  };

  return (
    <Form error={hasErrors}>
      <Message error list={errorMessages} />
      <Form.Group>
        <Controller
          name="code"
          control={control}
          rules={{ required: { value: true, message: formatMessage(m.operationCodeRequired) } }}
          render={() => (
            <Form.Input
              disabled
              width={4}
              value={getValues('code') || ''}
              label={formatMessage(m.operationCodeLabel)}
              required
              error={errors.code != null}
            />
          )}
        />

        <Controller
          name="name"
          control={control}
          render={() => (
            <Form.Input
              width={12}
              value={getValues('name')}
              label={formatMessage(m.operationNameLabel)}
              onChange={(e, data) => setValue('name', data.value)}
            />
          )}
        />
      </Form.Group>

      <Form.Field required disabled>
        <label>{formatMessage(m.costCenterLabel)}</label>
        <Controller
          name="costCenterId"
          control={control}
          rules={{ required: { value: true, message: formatMessage(m.costCenterRequired) } }}
          render={() => (
            <CostCentersDropdown
              error={errors.costCenterId != null}
              selection
              clearable
              search
              onChange={(_e: any, data: any) => setValue('costCenterId', data.value as number)}
              value={getValues('costCenterId')}
            />
          )}
        />
      </Form.Field>

      <Form.Field>
        <label>{formatMessage(m.operatingModeLabel)}</label>
        <Controller
          name={nameof<Operation>('operatingMode')}
          control={control}
          render={() => (
            <OperatingModeDropdown
              selection
              onChange={(_e: any, data: any) => setValue('operatingMode', data.value as OperatingMode)}
              value={getValues('operatingMode') || OperatingMode.perLot}
            />
          )}
        />
      </Form.Field>

      <Form.Field>
        <label>{formatMessage(m.groupingModeLabel)}</label>
        <Controller
          name="groupingMode"
          control={control}
          render={() => (
            <GroupingModeDropdown
              selection
              onChange={(_e: any, data: any) => setValue('groupingMode', data.value as GroupingMode)}
              value={getValues('groupingMode') || GroupingMode.none}
            />
          )}
        />
      </Form.Field>

      <Form.Field>
        <label>{formatMessage(m.roadmapTemplateLabel)}</label>
        <Controller
          name="roadmapId"
          control={control}
          render={() => (
            <RoadmapsDropdown
              selection
              search
              onChange={(_e: any, data: any) => setValue('roadmapId', data.value)}
              value={getValues('roadmapId') || defaultRoadmapValue}
            />
          )}
        />
      </Form.Field>

      <Divider section style={{ marginBottom: '.25rem' }} />

      <small><Icon name="info circle" color="blue" /> {formatMessage(m.disabledFieldsInformation)}</small>
      <div style={{ textAlign: 'right' }}>
        <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
        <Button primary content={formatMessage(commonMessages.modify)} onClick={handleSubmit(confirm)} style={{ minWidth: 90 }} />
      </div>
    </Form>
  );
};
