import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Form } from 'semantic-ui-react';
import { UpdateEntityModal } from '../../../../components/common/UpdateEntityModal';
import { User } from '../../../../models';
import useApiErrorHandler, { successToastOptions } from '../../../../utils/ApiErrorHandler';
import * as api from '../api';
import { UserFormControls } from './components/UserFormControls';

interface RouteProps {
    userId: string;
}

const m = defineMessages({
  title: { id: 'UpdateUserPage.title', defaultMessage: 'Modify a user account' },
  deleteUserConfirmation: { id: 'UpdateUserPage.deleteUserConfirmation', defaultMessage: 'Are you sure you want to deactivate the user account {name}? Any data associated to this user will be preserved.' },
  userDeletedMessage: { id: 'UpdateUserPage.userDeletedMessage', defaultMessage: 'The user {name} has been removed from the system.' }
});

export const UpdateUserPage = () => {
  const { formatMessage } = useIntl();
  const params = useParams<RouteProps>();
  const history = useHistory();
  const { handleError } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const formProps = useForm<User>({ criteriaMode: 'all', mode: 'onChange', defaultValues: { id: params.userId } });
  const { setValue } = formProps;

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: user, isFetching: isLoading } = useQuery(
    ['users', params.userId],
    () => api.getById(params.userId)
  );

  const navigateToUsersManagementPage = () => history.push('/administration/users');

  const deleteUser = () => {
    if (user != null) {
      setIsDeleting(true);

      api.deleteUser(user.id)
        .then(() => {
          toast.success(formatMessage(m.userDeletedMessage, { name: user.displayName }), successToastOptions);
          queryClient.invalidateQueries(['users']);
          navigateToUsersManagementPage();
        })
        .catch(handleError)
        .finally(() => setIsDeleting(false));
    }
  };

  const submitChanges = (user: User) => {
    setIsSaving(true);

    api.updateUser(user)
      .then(() => {
        queryClient.invalidateQueries(['users']);
        navigateToUsersManagementPage();
      })
      .catch(handleError)
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (user != null) {
      setValue('id', user.id);
      setValue('displayName', user.displayName);
      setValue('employeeNumber', user.employeeNumber);
      setValue('role', user.role);
      setValue('username', user.username);
    }
  }, [user, setValue]);

  return (
    <UpdateEntityModal
      open
      title={formatMessage(m.title)}
      loading={isLoading || isSaving}
      deleting={isDeleting}
      deleteConfirmationMessage={formatMessage(m.deleteUserConfirmation, { name: user?.displayName || '' })}
      onSubmit={formProps.handleSubmit(submitChanges)}
      onClose={navigateToUsersManagementPage}
      onDelete={deleteUser}
    >
      <FormProvider {...formProps}>
        <Form loading={isLoading}>
          <UserFormControls />
        </Form>
      </FormProvider>
    </UpdateEntityModal>
  );
};
