import React from 'react';
import { Header, HeaderProps, Icon, Divider } from 'semantic-ui-react';

interface OwnProps {
    title: string;
    subtitle?: string;
    centered?: boolean;
}

export type PageHeaderProps =
    & OwnProps
    & HeaderProps;

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  return (
    <React.Fragment>
      <Header as="h2" textAlign={props.centered ? 'center' : 'left'}>
        {props.icon && <Icon name={props.icon} />}
        <Header.Content>
          {props.title}
          {props.subtitle &&
                        <Header.Subheader>
                          {props.subtitle}
                        </Header.Subheader>
          }
        </Header.Content>
      </Header>

      <Divider />
    </React.Fragment>
  );
};
