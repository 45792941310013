import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, MessageDescriptor } from 'react-intl';
import { Label, Icon, SemanticCOLORS, Popup, LabelProps } from 'semantic-ui-react';
import { WorkstationStatus } from '../../types';
import { commonMessages } from '../../../../../constants';

interface WorkstationStatusLabelOwnProps {
    status: WorkstationStatus;
}

export type WorkstationStatusLabelProps =
    & WorkstationStatusLabelOwnProps
    & LabelProps
    & WrappedComponentProps;

interface StatusLabelContent {
    color: SemanticCOLORS;
    tooltip: MessageDescriptor;
}

const m = defineMessages({
  online: { id: 'WorkstationStatusLabel.online', defaultMessage: 'Online' },
  offline: { id: 'WorkstationStatusLabel.offline', defaultMessage: 'Offline' },
  unregistered: { id: 'WorkstationStatusLabel.unregistered', defaultMessage: 'Unregistered' }
});

const labelMap = new Map<WorkstationStatus, StatusLabelContent>([
  [WorkstationStatus.online, { color: 'green', tooltip: m.online }],
  [WorkstationStatus.offline, { color: 'red', tooltip: m.offline }],
  [WorkstationStatus.unregistered, { color: 'yellow', tooltip: m.unregistered }]
]);

class WorkstationStatusLabel extends React.Component<WorkstationStatusLabelProps> {
  public render () {
    const { formatMessage } = this.props.intl;
    const labelContent = labelMap.get(this.props.status) || { color: 'grey', tooltip: commonMessages.undefined };

    return (
      <Popup
        trigger={this.renderLabel(labelContent.color)}
        content={formatMessage(labelContent.tooltip)}
      />
    );
  }

  private renderLabel = (color: SemanticCOLORS) => {
    const { intl, ...labelProps } = this.props;

    return (
      <Label
        circular={true}
        empty={true}
        color={color}
        {...labelProps}
      />
    );
  };
}

const connectedComponent = injectIntl(WorkstationStatusLabel);
export { connectedComponent as WorkstationStatusLabel };
