import { ApplicationState } from '../../../store';
import { getCostCenterById, getOperationById } from '../../production';
import { CostCenter, Operation } from '../../production/types';
import { Workstation } from './types';
import { getCurrentDevice } from '../devices/selectors';

export const getWorkstationOperations = (state: ApplicationState, workstationId: number): Operation[] => {
  const workstation = getWorkstationById(state, workstationId);
  if (workstation != null) {
    const operations = workstation.operationIds
      .map(x => getOperationById(state, x))
      .filter(x => x != null);

    return operations as Operation[];
  }

  return [];
};

export const getWorkstationCostCenter = (state: ApplicationState, workstationId: number): CostCenter | undefined => {
  const workstation = getWorkstationById(state, workstationId);
  if (workstation != null) {
    return getCostCenterById(state, workstation.costCenterId);
  }

  return undefined;
};

export const getWorkstationById = (state: ApplicationState, id: number): Workstation | undefined => state.workstations.workstations.byId[id];

export const getWorkstationsByCostCenter = (state: ApplicationState, costCenterId: number): Workstation[] => {
  const workstationIds = state.workstations.workstations.byCostCenterId[costCenterId] || [];
  const workstations = workstationIds.map(id => getWorkstationById(state, id)).filter(x => x != null) as Workstation[];
  return workstations;
};

export const getCurrentWorkstations = (state: ApplicationState): Workstation[] => {
  const currentDevice = getCurrentDevice(state);
  if (currentDevice != null) {
    const workstations = currentDevice.workstationIds
      .map(x => getWorkstationById(state, x))
      .filter(x => x != null);

    return workstations as Workstation[];
  }

  return [];
};

export const getWorkstations = (state: ApplicationState): Workstation[] => state.workstations.workstations.allIds.map(x => state.workstations.workstations.byId[x]);
