import React from 'react';
import { Workstation } from '../../../../administration';
import { List, ListItemProps } from 'semantic-ui-react';
import { getUsersPresenceByWorkstation } from '../../../../administration/presences/selectors';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { UsersPresenceList } from '../../../../../components/presences/UsersPresenceList';
import styles from './WorkstationListItem.module.css';

interface OwnProps {
    workstation: Workstation;
}

export type WorkstationListItemProps =
    & ListItemProps
    & OwnProps;

export const WorkstationListItem: React.FC<WorkstationListItemProps> = (props) => {
  const { workstation, ...listItemProps } = props;
  const presences = useSelector((state: ApplicationState) => getUsersPresenceByWorkstation(state, workstation.id));

  return (
    <List.Item {...listItemProps}>
      <List.Content floated="right">
        <UsersPresenceList
          size={28}
          presences={presences}
          maximumCoins={3}
        />
      </List.Content>
      <List.Content>
        <List.Header className={styles.ellipsis}>{workstation.name}</List.Header>
        <List.Description className={styles.ellipsis}>{workstation.description}</List.Description>
      </List.Content>
    </List.Item>
  );
};
