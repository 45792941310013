import { Item } from '../production';
import {
  SearchActionTypes, SEARCH_ITEMS,
  SEARCH_ITEMS_FAILURE,
  SEARCH_ITEMS_SUCCESS
} from './types';

export function searchItems (itemCode: string): SearchActionTypes {
  return {
    type: SEARCH_ITEMS,
    payload: { itemCode }
  };
}

export function searchItemsSuccess (items: Item[]): SearchActionTypes {
  return {
    type: SEARCH_ITEMS_SUCCESS,
    payload: { items }
  };
}

export function searchItemsFailure (error: Error): SearchActionTypes {
  return {
    type: SEARCH_ITEMS_FAILURE,
    payload: { error }
  };
}
