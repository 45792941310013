/* global PublicKeyCredentialType */

enum AccountType {
  Administrator,
  FactoryWorker,
  FactorySupervisor,
}

export { AccountType };

export interface AuthenticatorAttestationRawResponseResponse {
  attestationObject: string;
  clientDataJson: string;
}

export interface AuthenticatorAttestationRawResponse {
  id: string;
  rawId: string;
  type: PublicKeyCredentialType;
  response: AuthenticatorAttestationRawResponseResponse;
}

export const DUPLICATE_AUTHENTICATOR_REGISTRATION_ERROR_CODE = 11;
export const AUTHENTICATOR_MISSING_OR_TIMEOUT_ERROR_CODE = 0;
