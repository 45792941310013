import { AuthenticatorAssertionRawResponse } from '.';
import { User } from '../../models/account/User';
import { get, post } from '../../utils/api';
import { decodeStringToArrayBuffer } from '../../utils/WebAuthHelper';
import { UserProfile } from './types';

const endpoint = '/api/account';

export async function logout(): Promise<void> {
  return get<void>(`${endpoint}/logout`);
}

export async function makeAssertionOptions(user?: User): Promise<PublicKeyCredentialRequestOptions> {
  const assertionOptions = await get<PublicKeyCredentialRequestOptions>(`${endpoint}/makeAssertionOptions/${user != null ? user.id : ''}`);

  // Array buffers are received as string from the api so perform some ugly casting to actually convert string to an ArrayBuffer.
  assertionOptions.challenge = decodeStringToArrayBuffer(assertionOptions.challenge as any as string);

  assertionOptions.allowCredentials!.forEach(cred => {
    cred.id = decodeStringToArrayBuffer(cred.id as any as string);
  });

  return assertionOptions;
}

export async function makeAllAssertionOptions(): Promise<PublicKeyCredentialRequestOptions> {
  const assertionOptions = await get<PublicKeyCredentialRequestOptions>(`${endpoint}/makeAllAssertionOptions`);
  assertionOptions.challenge = decodeStringToArrayBuffer(assertionOptions.challenge as any as string);
  assertionOptions.allowCredentials?.forEach(cred => {
    cred.id = decodeStringToArrayBuffer(cred.id as any as string);
  });

  return assertionOptions;
}

export async function loadProfile(): Promise<User> {
  return get<User>(`${endpoint}/profile`);
}

export async function updateProfile(profile: UserProfile): Promise<User> {
  return post<User>(`${endpoint}/profile`, profile);
}

export async function makeAssertion(credentials: AuthenticatorAssertionRawResponse): Promise<User> {
  return post<User>(`${endpoint}/makeAssertion`, credentials);
}

export async function validateAssertion(credentials: AuthenticatorAssertionRawResponse): Promise<User> {
  return post<User>(`${endpoint}/validateAssertion`, credentials);
}
