import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { commonMessages } from 'constants/messages';
import { SalesOrder } from 'modules/schedule';
import { useIntl } from 'react-intl';

interface CompleteOrderConfirmationDialogProps {
  open: boolean;
  salesOrder: SalesOrder | null;
  onClose: () => void;
  onConfirmed: () => void;
}

export const CompleteOrderConfirmationDialog = ({
  open,
  salesOrder,
  onClose,
  onConfirmed,
}: CompleteOrderConfirmationDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {formatMessage({
          id: 'CompleteOrderConfirmationDialog.Title',
          defaultMessage: 'Complete sales order',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formatMessage(
            {
              id: 'CompleteOrderConfirmationDialog.Message',
              defaultMessage:
                'Are you sure you want to complete the order #{order}?',
            },
            { order: salesOrder?.salesOrderNumber }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {formatMessage(commonMessages.cancel)}
        </Button>
        <Button
          onClick={() => {
            onConfirmed();
          }}
          color="warning"
          autoFocus
        >
          {formatMessage({
            id: 'CompleteOrderConfirmationDialog.ConfirmButton',
            defaultMessage: 'Yes, complete this sales order',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
