import { Container, Paper, Stack, Typography } from '@mui/material';
import { Lot, ProductionItemForPrioritization } from 'modules/production/lots/types';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProductionItemsTable } from '../ProductionItemsTable';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface OwnProps {
  lot: Lot;
}

export type LotSummaryProps =
  & OwnProps;

export const LotSummary: React.FC<LotSummaryProps> = (props) => {
  const { formatMessage } = useIntl();

  const { data, isFetching } = useQuery<ProductionItemForPrioritization[]>(
    [`lot-${props.lot.id}/production-items`],
    async () => (await axios.get<ProductionItemForPrioritization[]>(`/api/lots/${props.lot.id}/production-items`)).data,
  );

  return (
    <Container sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant='h6'>
          {formatMessage({ id: 'LotSummary.title', defaultMessage: 'Lot {number}' }, { number: props.lot.name })}
        </Typography>

        <ProductionItemsTable
          loading={isFetching}
          productionItems={data ?? []}
          emptyTitle={formatMessage({ id: 'Empty lot' })}
          emptySubtitle={formatMessage({ id: 'There are no products assigned to this lot.' })}
        />
      </Paper>
    </Container>
  );
};