import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from 'store/layout';
import { SubscriptionStatus } from '../types';
import { getCurrentUser } from 'modules/account/selectors';

const m = defineMessages({
  title: { id: 'InvalidLicenseModal.title', defaultMessage: 'Your license is not valid' },
  subtitle: { id: 'InvalidLicenseModal.subtitle', defaultMessage: 'Your license is {status}' },
  description: { id: 'InvalidLicenseModal.description', defaultMessage: 'Please contact your administrator to have this issue resolved.' },
  adminDescription: { id: 'InvalidLicenseModal.adminDescription', defaultMessage: 'In order to resolve this issue, you can manage your license or contact our support team.' },
  manageLicenses: { id: 'InvalidLicenseModal.manageLicenses', defaultMessage: 'Manage Licenses' },
});

interface InvalidLicenseModalProps {
  subscriptionStatus?: SubscriptionStatus;
  open: boolean;
}

export const InvalidLicenseModal = (props: InvalidLicenseModalProps) => {
  const { formatMessage } = useIntl();
  const currentUser = useSelector(getCurrentUser);
  const locale = useSelector(getCurrentLocale);

  const isAdmin = currentUser?.isAdmin ?? false;

  return (
    <Dialog open={props.open}>
      <DialogTitle>{formatMessage(m.title)}</DialogTitle>
      <DialogContent>
        <DialogContentText fontWeight={500}>{formatMessage(m.subtitle, {status: props.subscriptionStatus})}</DialogContentText>
        <DialogContentText>{isAdmin ? formatMessage(m.adminDescription) : formatMessage(m.description)}</DialogContentText>
      </DialogContent>
      {isAdmin && (
        <DialogActions>
          <Button autoFocus href={`/api/license/portal-url?locale=${locale}&returnUrl=${window.location.href}`}>{formatMessage(m.manageLicenses)}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

