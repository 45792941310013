import { ProductionSchedule, ProductionScheduleEntities } from './types';
import { get } from '../../utils/api';

const apiEndpoint = '/api/production-schedule';

export async function load (forDate: Date): Promise<ProductionScheduleEntities> {
  const productionSchedule = await get<ProductionSchedule>(`${apiEndpoint}/${forDate.toISOString()}`);
  const result: ProductionScheduleEntities = {
    items: Object.assign({}, ...productionSchedule.items.map(x => ({ [x.id]: x }))),
    lots: Object.assign({}, ...productionSchedule.lots.map(x => ({ [x.id]: x }))),
    productCollections: Object.assign({}, ...productionSchedule.productCollections.map(x => ({ [x.id]: x }))),
    productTypes: Object.assign({}, ...productionSchedule.productTypes.map(x => ({ [x.id]: x }))),
    productionItems: Object.assign({}, ...productionSchedule.productionItems.map(x => ({ [x.id]: x }))),
    productionUnits: Object.assign({}, ...productionSchedule.productionUnits.map(x => ({ [x.id]: x }))),
    salesOrders: Object.assign({}, ...productionSchedule.salesOrders.map(x => ({ [x.id]: x })))
  };

  return result;
}
