import { Checkbox, IconButton, Skeleton, Stack, TableCell, TableRow } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const PrioritizableSalesRowSkeleton: React.FC = () => {
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            disabled
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Checkbox disabled />
        </TableCell>
        <TableCell><Skeleton /></TableCell>
        <TableCell><Skeleton /></TableCell>
        <TableCell><Skeleton /></TableCell>
        <TableCell>
          <Stack direction="row" justifyContent="center">
            <Skeleton sx={{ width: '32px' }} />
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};