import { SetConnectionStatusOnlineAction, SET_CONNECTION_STATUS_ONLINE, ConnectionStatus, TurnOffLiveUpdatesAction, TURN_OFF_LIVE_UPDATES, TurnOnLiveUpdatesAction, TURN_ON_LIVE_UPDATES, SET_CONNECTION_STATUS_OFFLINE, SetConnectionStatusOfflineAction } from './types';

export function setOffline (): SetConnectionStatusOfflineAction {
  return {
    type: SET_CONNECTION_STATUS_OFFLINE
  };
}

export function setOnline (): SetConnectionStatusOnlineAction {
  return {
    type: SET_CONNECTION_STATUS_ONLINE
  };
}

export function turnOnLiveUpdates (): TurnOnLiveUpdatesAction {
  return { type: TURN_ON_LIVE_UPDATES };
}

export function turnOffLiveUpdates (): TurnOffLiveUpdatesAction {
  return { type: TURN_OFF_LIVE_UPDATES };
}
