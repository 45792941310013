import _ from 'lodash';
import { ValidationResult, User } from '../../../models';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { commonMessages } from '../../../constants';

export const LOAD_DEVICES = 'DEVICES_LOAD_ALL';
export const LOAD_DEVICES_SUCCESS = 'DEVICES_LOAD_ALL_SUCCESS';
export const LOAD_DEVICES_FAILURE = 'DEVICES_LOAD_ALL_FAILURE';

export const UPDATE_DEVICE = 'DEVICES_UPDATE_DEVICE';
export const UPDATE_DEVICE_SUCCESS = 'DEVICES_UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAILURE = 'DEVICES_UPDATE_DEVICE_FAILURE';

export const DELETE_DEVICE = 'DEVICES_DELETE_DEVICE';
export const DELETE_DEVICE_SUCCESS = 'DEVICES_DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAILURE = 'DEVICES_DELETE_DEVICE_FAILURE';

export const SIGN_IN = 'DEVICES_SIGN_IN';
export const SIGN_IN_SUCCESS = 'DEVICES_SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'DEVICES_SIGN_IN_FAILURE';

export const REGISTER = 'DEVICES_REGISTER';
export const REGISTER_SUCCESS = 'DEVICES_REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'DEVICES_REGISTER_FAILURE';

export interface LoadDevices { type: typeof LOAD_DEVICES; }
export interface LoadDevicesSuccess { type: typeof LOAD_DEVICES_SUCCESS; payload: Device[]; }
export interface LoadDevicesFailure { type: typeof LOAD_DEVICES_FAILURE; payload: Error; }

export interface UpdateDevice { type: typeof UPDATE_DEVICE; payload: { device: Device }; }
export interface UpdateDeviceSuccess { type: typeof UPDATE_DEVICE_SUCCESS; payload: { device: Device }; }
export interface UpdateDeviceFailure { type: typeof UPDATE_DEVICE_FAILURE; payload: { deviceId: number, error: Error }; }

export interface DeleteDevice { type: typeof DELETE_DEVICE; payload: { deviceId: number }; }
export interface DeleteDeviceSuccess { type: typeof DELETE_DEVICE_SUCCESS; payload: { deviceId: number }; }
export interface DeleteDeviceFailure { type: typeof DELETE_DEVICE_FAILURE; payload: { deviceId: number, error: Error }; }

export interface SignIn { type: typeof SIGN_IN; }
export interface SignInSuccess { type: typeof SIGN_IN_SUCCESS; payload: Device; }
export interface SignInFailure { type: typeof SIGN_IN_FAILURE; payload: Error; }

export interface Register { type: typeof REGISTER; }
export interface RegisterSuccess { type: typeof REGISTER_SUCCESS; payload: Device; }
export interface RegisterFailure { type: typeof REGISTER_FAILURE; payload: Error; }

export type DevicesActionTypes =
    | LoadDevices | LoadDevicesSuccess | LoadDevicesFailure
    | UpdateDevice | UpdateDeviceSuccess | UpdateDeviceFailure
    | DeleteDevice | DeleteDeviceSuccess | DeleteDeviceFailure
    | SignIn | SignInSuccess | SignInFailure
    | Register | RegisterSuccess | RegisterFailure;

export enum DeviceStatus {
    pendingConfiguration,
    online,
    offline
}

export enum DeviceConfigurationStatus {
    pending,
    configured
}

const cameraFacingModeMessages = defineMessages({
  default: { id: 'CameraFacingMode.default', defaultMessage: 'Default' },
  user: { id: 'CameraFacingMode.user', defaultMessage: 'Facing the user' },
  environment: { id: 'CameraFacingMode.environment', defaultMessage: 'Facing the environment' }
});

export function getFacingModeDescription (facingMode: CameraFacingMode): MessageDescriptor {
  switch (facingMode) {
  case CameraFacingMode.user: {
    return cameraFacingModeMessages.user;
  }

  case CameraFacingMode.environment: {
    return cameraFacingModeMessages.environment;
  }

  case CameraFacingMode.default: {
    return cameraFacingModeMessages.default;
  }

  default: {
    return commonMessages.undefined;
  }
  }
}

export enum CameraFacingMode {
    default,
    user,
    environment
}

export interface Device {
    id: number;
    createdOn: string | Date;
    name: string;
    cameraFacingMode: CameraFacingMode;
    configurationStatus: DeviceConfigurationStatus;
    workstationIds: number[];
}

export const getDeviceName = (device: Device) => {
  const deviceName = device.name || 'STEPS-' + _.padStart(device.id.toString(), 3, '0');
  return deviceName;
};

export interface DevicesState {
    readonly byId: { [id: number]: Device };
    readonly allIds: number[];
    readonly loadingIds: number[];
    readonly loading: boolean;
    readonly currentId: number | undefined;
}
