import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { workstationIcon } from '../../../../administration/workstations/views/components';

const m = defineMessages({
  noWorkstations: { id: 'CostCenterOverview.noWorkstations', defaultMessage: 'There are no workstations associated to this cost center.' }
});

export const NoWorkstationsPlaceholder: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Segment basic disabled placeholder size="tiny">
      <Header icon>
        <Icon name={workstationIcon} />
        {formatMessage(m.noWorkstations)}
      </Header>
    </Segment>
  );
};
