import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../../store';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { Unit } from '../../../../production/types';
import { getProductionUnits } from '../../../cost-centers/selectors';

interface StoreProps {
    productionUnits: Unit[];
}

export type ProductionUnitDropdownProps =
    & DropdownProps
    & StoreProps
    & WrappedComponentProps;

const m = defineMessages({
  allCells: { id: 'ProductionUnitDropdown.allCells', defaultMessage: 'All cells' }
});

export const allCellsValue = 0;

class ProductionUnitDropdown extends React.Component<ProductionUnitDropdownProps> {
  public render () {
    const { formatMessage } = this.props.intl;
    const { intl, productionUnits, ...dropdownProps } = this.props;
    const all: DropdownItemProps = { key: allCellsValue, value: allCellsValue, text: formatMessage(m.allCells) };
    const options = this.props.productionUnits
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((x: Unit): DropdownItemProps => ({
        id: x.id,
        value: x.id,
        text: x.name
      }));

    return (
      <Dropdown
        options={[all, ...options]}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    productionUnits: getProductionUnits(state)
  };
};

const mapDispatchToProps = () => {
  return {};
};

const intlComponent = injectIntl(ProductionUnitDropdown);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ProductionUnitDropdown };
