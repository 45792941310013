import React from 'react';
import { LotOverviewByOperation } from '../../../types';
import { Grid } from 'semantic-ui-react';
import { LotButton } from './LotButton';

interface OwnProps {
    lotOverview: LotOverviewByOperation
    onClick: () => void;
}

export type LotSelectionListItemProps =
    & OwnProps;

export const LotSelectionListItem: React.FC<LotSelectionListItemProps> = (props) => {
  return (
    <Grid.Row key={props.lotOverview.lot.id}>
      <Grid.Column textAlign="center">
        <LotButton
          lot={props.lotOverview.lot}
          productionStatus={props.lotOverview.productionStatus}
          onClick={props.onClick}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
