import * as React from 'react';
import { Icon, IconProps } from 'semantic-ui-react';

import styles from './SystemStatusIcon.module.css';

interface OwnProps {
    type?: SystemStatusIconType;
    bordered?: boolean;
    onClick?: () => void;
}

export enum SystemStatusIconType {
    default,
    information,
    success,
    warning,
    error,
}

export type SystemStatusIconProps =
    & IconProps
    & OwnProps;

class SystemStatusIcon extends React.Component<SystemStatusIconProps> {
  public render () {
    const { type, bordered, onClick, ...iconProps } = this.props;

    return (
      <div className={this.getClassName(type, bordered)} onClick={onClick}>
        {this.props.name && <Icon size="big" fitted {...iconProps} />}
        {this.props.children}
      </div>
    );
  }

  private getClassName (type?: SystemStatusIconType, bordered?: boolean) {
    const classNames: string[] = [styles.systemStatusIcon];

    switch (type) {
    case SystemStatusIconType.success: {
      classNames.push(styles.success);
      break;
    }

    case SystemStatusIconType.information: {
      classNames.push(styles.information);
      break;
    }

    case SystemStatusIconType.warning: {
      classNames.push(styles.warning);
      break;
    }

    case SystemStatusIconType.error: {
      classNames.push(styles.error);
      break;
    }
    }

    if (bordered) {
      classNames.push(styles.bordered);
    }

    return classNames.join(' ');
  }
}

export { SystemStatusIcon };
