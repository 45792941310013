import * as React from 'react';

import { SystemStatusIcon } from './SystemStatusIcon';

interface OwnProps {
    bordered?: boolean;
    onClick?: () => void;
}

export type SystemLoadingIconProps =
    & OwnProps;

class SystemLoadingIcon extends React.Component<SystemLoadingIconProps> {
  public render () {
    return (
      <SystemStatusIcon
        name="circle notch"
        loading
        {...this.props}
      />
    );
  }
}

export { SystemLoadingIcon };
