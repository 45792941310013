import * as React from 'react';
import { Card, SemanticCOLORS } from 'semantic-ui-react';
import { TileContent } from './TileContent';
import { TileHeader, TileHeaderStrictProps } from './TileHeader';
import { TileState } from '.';
import { defineMessages, useIntl } from 'react-intl';
import styles from './Tile.module.css';
import { LoadingDimmer } from '..';

export interface TileProps {
    state?: TileState;
    isLoading?: boolean;
    onChangeState?: (newState: TileState) => void;

    header?: TileHeaderStrictProps;
    editTemplate?: React.ReactElement;
    deleteTemplate?: React.ReactElement;
    reorderTemplate?: React.ReactElement;

    color?: SemanticCOLORS;
    className?: string;
    style?: React.CSSProperties;
}

const m = defineMessages({
  deleteMenuItem: { id: 'Tile.deleteMenuItem', defaultMessage: 'Delete' },
  modifyMenuItem: { id: 'Tile.modifyMenuItem', defaultMessage: 'Modify' },
  reorderMenuItem: { id: 'Tile.reorderMenuItem', defaultMessage: 'Reorder' }
});

// tslint:disable-next-line: variable-name
export const Tile: React.FunctionComponent<TileProps> = (props) => {
  const { formatMessage } = useIntl();

  if (props.deleteTemplate && props.state === TileState.deleting) {
    return props.deleteTemplate;
  }

  if (props.editTemplate && props.state === TileState.editing) {
    return props.editTemplate;
  }

  if (props.reorderTemplate && props.state === TileState.reordering) {
    return props.reorderTemplate;
  }

  const defaultContextMenu = [];

  if (props.editTemplate != null) {
    defaultContextMenu.push({
      key: formatMessage(m.modifyMenuItem),
      content: formatMessage(m.modifyMenuItem),
      icon: 'pencil',
      onClick: () => props.onChangeState && props.onChangeState(TileState.editing)
    });
  }

  if (props.reorderTemplate != null) {
    defaultContextMenu.push({
      key: formatMessage(m.reorderMenuItem),
      content: formatMessage(m.reorderMenuItem),
      icon: 'move',
      onClick: () => props.onChangeState && props.onChangeState(TileState.reordering)
    });
  }

  if (props.deleteTemplate != null) {
    defaultContextMenu.push({
      key: formatMessage(m.deleteMenuItem),
      content: formatMessage(m.deleteMenuItem),
      icon: 'trash alternate',
      style: { color: 'rgb(219, 40, 40)' },
      onClick: () => props.onChangeState && props.onChangeState(TileState.deleting)
    });
  }

  return (
    <Card raised className={styles.tile + ' ' + props.className + ' color-top'} style={props.style} color={props.color}>
      <TileHeader contextMenu={defaultContextMenu} {...props.header} />
      <TileContent>
        <>
          <LoadingDimmer active={props.isLoading} />
          {props.children}
        </>
      </TileContent>
    </Card>
  );
};
