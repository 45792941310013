import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { defineMessages, useIntl } from 'react-intl';
import { Ref } from 'semantic-ui-react';
import { SalesOrder } from '../../../schedule/types';
import { DraggableSalesOrderRow } from './DraggableSalesOrderRow';

export const droppableId = 'sales-orders-priority';

const m = defineMessages({
  headerSalesOrderNumber: { id: 'SalesOrdersPrioritiesTable.headerSalesOrderNumber', defaultMessage: 'Order #' },
  headerOrderedOn: { id: 'SalesOrdersPrioritiesTable.headerOrderedOn', defaultMessage: 'Ordered On' },
  headerClientName: { id: 'SalesOrdersPrioritiesTable.headerClientName', defaultMessage: 'Client' },
  headerClientCompany: { id: 'SalesOrdersPrioritiesTable.headerClientCompany', defaultMessage: 'Company' },
  headerStatus: { id: 'SalesOrdersPrioritiesTable.headerStatus', defaultMessage: 'Status' }
});

interface OwnProps {
    salesOrders: SalesOrder[];
    draggedDraggableId: string | undefined;
    onChange: (salesOrder: SalesOrder, newIndex: number) => void;
    onPrioritizeFirst: (salesOrder: SalesOrder) => void;
    onPrioritizeLast: (salesOrder: SalesOrder) => void;
    onUnprioritize: (salesOrder: SalesOrder) => void;
}

export type SalesOrdersPrioritiesProps =
    & OwnProps;

export const SalesOrdersPriorities: React.FC<SalesOrdersPrioritiesProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <Ref innerRef={provided.innerRef}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell align='center'>{formatMessage(m.headerSalesOrderNumber)}</TableCell>
                  <TableCell align='center'>{formatMessage(m.headerOrderedOn)}</TableCell>
                  <TableCell align='center'>{formatMessage(m.headerClientName)}</TableCell>
                  <TableCell align='center'>{formatMessage(m.headerClientCompany)}</TableCell>
                  <TableCell align='center'>{formatMessage(m.headerStatus)}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {props.salesOrders.map((salesOrder, index) =>
                  <DraggableSalesOrderRow
                    key={salesOrder.id}
                    salesOrder={salesOrder}
                    draggedDraggableId={props.draggedDraggableId}
                    index={index}
                    onPrioritizeFirst={() => props.onPrioritizeFirst(salesOrder)}
                    onPrioritizeLast={() => props.onPrioritizeLast(salesOrder)}
                    onUnprioritize={() => props.onUnprioritize(salesOrder)}
                  />
                )}
                {provided.placeholder}
              </TableBody>
            </Table>
          </Paper>
        </Ref>
      )}
    </Droppable>
  );
};
