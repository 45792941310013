import logo from '../../../../assets/images/logo.svg';
import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Container, Header, Image, Segment } from 'semantic-ui-react';

import { PageHeader } from '../../../../components/layout';
import { ApplicationState } from '../../../../store';
import * as DevicesActions from '../../../administration/devices/actions';
import { Device, DeviceConfigurationStatus, getDeviceName } from '../../devices/types';
import { getCurrentDevice } from '../../devices/selectors';
import { isSystemInitialized, isSystemLoading } from '../../../system';

interface DispatchProps {
    devicesActions: typeof DevicesActions;
}

interface StoreProps {
    currentDevice: Device | undefined;
    isInitializing: boolean;
    isInitialized: boolean;
}

export type WorkstationSetupPageProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps<Record<string, never>>
    & WrappedComponentProps;

const m = defineMessages({
  title: { id: 'WorkstationSignInPage.title', defaultMessage: 'Workstation sign in' },
  configurationSummary: { id: 'WorkstationSignInPage.configurationSummary', defaultMessage: 'To start using this workstation, an administrator must configure the workstation with the number displayed.' }
});

class WorkstationSetupPage extends React.Component<WorkstationSetupPageProps> {
  componentDidMount () {
    if (this.props.isInitialized && this.props.currentDevice == null) {
      this.props.devicesActions.register();
    }
  }

  public render () {
    const { formatMessage } = this.props.intl;
    const { currentDevice } = this.props;
    const configurationStatus = currentDevice != null ? currentDevice.configurationStatus : undefined;

    return (
      <React.Fragment>
        <Container>
          <Image src={logo} centered={true} size="medium" style={{ marginTop: '32px' }} />

          <PageHeader icon="tablet alternate" title={formatMessage(m.title)} subtitle={formatMessage(m.configurationSummary)} />
          <Container>
            <Segment placeholder loading={this.props.isInitializing}>
              {configurationStatus === DeviceConfigurationStatus.configured &&
                                <Redirect to={'/workstations'} />
              }

              {configurationStatus === DeviceConfigurationStatus.pending &&
                                <Container text>
                                  <Header size="huge" textAlign="center">
                                    {currentDevice != null && getDeviceName(currentDevice)}
                                  </Header>
                                </Container>
              }
            </Segment>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    currentDevice: getCurrentDevice(state),
    isInitializing: isSystemLoading(state),
    isInitialized: isSystemInitialized(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    devicesActions: bindActionCreators(DevicesActions, dispatch)
  };
};

const intlComponent = injectIntl(WorkstationSetupPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as WorkstationSetupPage };
