import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import styles from './PdfViewerToolbar.module.css';

interface OwnProps {
    totalPages: number;
    currentPage: number;
    onChangeScale: (newScale: number) => void;
    onChangePage: (pageNumber: number) => void;
    onRotate: (angle: number) => void;
}

const m = defineMessages({
  pages: { id: 'PdfViewer.pages', defaultMessage: '{current} of {total}' }
});

export type PdfViewerToolbarProps =
    & OwnProps;

const availableZoomScales = [0.6, 0.8, 1, 1.5, 2, 3, 4];
const defaultScaleIndex = 2;
export const defaultScale = availableZoomScales[defaultScaleIndex];

export const PdfViewerToolbar: React.FC<PdfViewerToolbarProps> = (props) => {
  const { formatMessage } = useIntl();

  const [scaleIndex, setScaleIndex] = useState(defaultScaleIndex);
  const [rotationAngle, setRotationAngle] = useState(0);

  const canNavigateBack = props.currentPage > 1;
  const canNavigateForward = props.currentPage < props.totalPages;
  const canZoomIn = scaleIndex < availableZoomScales.length - 1;
  const canZoomOut = scaleIndex > 0;

  const resetScaleIndex = () => {
    setScaleIndex(defaultScaleIndex);
    props.onChangeScale(availableZoomScales[defaultScaleIndex]);
  };

  const zoomIn = () => {
    if (canZoomIn) {
      setScaleIndex(scaleIndex + 1);
      props.onChangeScale(availableZoomScales[scaleIndex + 1]);
    }
  };

  const zoomOut = () => {
    if (canZoomOut) {
      setScaleIndex(scaleIndex - 1);
      props.onChangeScale(availableZoomScales[scaleIndex - 1]);
    }
  };

  const previousPage = () => {
    if (canNavigateBack) {
      props.onChangePage(props.currentPage - 1);
    }
  };

  const nextPage = () => {
    if (canNavigateForward) {
      props.onChangePage(props.currentPage + 1);
    }
  };

  const rotateCounterClockwise = () => {
    const newRotationAngle = rotationAngle - 90;
    setRotationAngle(newRotationAngle);
    props.onRotate(newRotationAngle);
  };

  const rotateClockwise = () => {
    const newRotationAngle = rotationAngle + 90;
    setRotationAngle(newRotationAngle);
    props.onRotate(newRotationAngle);
  };

  return (
    <div className={styles.toolbar}>
      <div style={{ width: '33%' }}>
        <Button icon="arrow up" disabled={!canNavigateBack} onClick={previousPage} />
        <Button icon="arrow down" disabled={!canNavigateForward} onClick={nextPage} />
        {props.totalPages > 0 && formatMessage(m.pages, { current: props.currentPage, total: props.totalPages })}
      </div>
      <div style={{ width: '33%', textAlign: 'center' }}>
        <Button icon="minus" disabled={!canZoomOut} onClick={zoomOut} />
        <Button icon="arrows alternate horizontal" onClick={resetScaleIndex} />
        <Button icon="plus" disabled={!canZoomIn} onClick={zoomIn} />
      </div>
      <div style={{ width: '33%', textAlign: 'right', paddingRight: '60px' }}>
        <Button icon="undo" onClick={rotateCounterClockwise} />
        <Button icon="redo" onClick={rotateClockwise} />
      </div>
    </div>
  );
};
