import { post } from '../../../utils/api';
import { RegisterUIProgressEventPayload, WorkstationProductionProgressUIEventType } from './types';

const baseUrl = '/api/progress';

export async function updateProgress (params: RegisterUIProgressEventPayload): Promise<void> {
  const updateProgressModel: UpdateProgressModel = {
    eventType: params.eventType,
    deviceId: params.deviceId,
    workstationId: params.workstationId,
    lotId: params.lotId,
    productionItemId: params.productionItemId,
    operationId: params.operationId,
    userIds: params.userIds
  };

  return post(`${baseUrl}/RegisterUIEvent`, updateProgressModel);
}

interface UpdateProgressModel {
    eventType: WorkstationProductionProgressUIEventType;
    deviceId: number | undefined;
    workstationId: number | undefined;
    lotId: number | undefined;
    productionItemId: number | undefined;
    manufacturedItemOperationId?: number;
    operationId: number;
    userIds: string[] | undefined;
}
