import React from 'react';
import { Button, Header, ButtonProps } from 'semantic-ui-react';
import { Unit } from '../../../../types';
import styles from './ProductionUnitButton.module.css';

interface OwnProps {
    productionUnit: Unit;
}

export type ProductionUnitButtonProps =
    & ButtonProps
    & OwnProps;

export const ProductionUnitButton: React.FC<ProductionUnitButtonProps> = (props) => {
  const { productionUnit, ...buttonProps } = props;

  return (
    <Button className={styles.button} primary fluid {...buttonProps}>
      <Header as="h2">
        {productionUnit.name}
      </Header>
    </Button>
  );
};
