import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, Icon, SemanticICONS, Input, IconProps, Form } from 'semantic-ui-react';
import { Tooltip } from '../common';
import { ValidationResult } from '../../models';
import styles from './TileEditHeader.module.css';

export interface TileEditHeaderStrictProps {
    name: string;
    nameFieldId?: string;
    namePlaceholder?: string;
    description?: string | React.ReactElement;
    canEditDescription?: boolean;
    tileIcon?: SemanticICONS;
    closeIcon?: React.ReactElement | null;
    validationResult?: ValidationResult;

    onUpdate: (fieldId: string, fieldValue: any) => void;
    onClose?: () => void;
}

export type TileEditHeaderProps =
    & TileEditHeaderStrictProps
    & WrappedComponentProps;

const m = defineMessages({
  namePlaceholder: { id: 'TileEditHeader.namePlaceholder', defaultMessage: 'Name' },
  closeTooltip: { id: 'TileEditHeader.closeTooltip', defaultMessage: 'Close' },
  descriptionPlaceholder: { id: 'TileEditHeader.descriptionPlaceholder', defaultMessage: 'Description (optional)' }
});

class TileEditHeader extends React.Component<TileEditHeaderProps> {
  private inputRef = React.createRef<Input>();

  public componentDidMount () {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  public render () {
    const { formatMessage } = this.props.intl;
    const hasCloseIcon = this.props.closeIcon !== null && this.props.onClose != null;
    const nameFieldId = this.props.nameFieldId || 'name';

    return (
      <Card.Content className={styles.header} style={{ flexGrow: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
          {this.props.tileIcon &&
                        <Icon
                          name={this.props.tileIcon}
                          value={this.props.name || ''}
                          size="large"
                          style={{ fontSize: '3em', marginRight: '15px' }}
                        />
          }

          <Form error={this.props.validationResult && !this.props.validationResult.isValid} style={{ flexGrow: 1 }}>
            <Form.Field>
              <Input
                id={nameFieldId}
                error={this.props.validationResult && this.props.validationResult.isFieldInvalid(nameFieldId)}
                ref={this.inputRef}
                className="bold"
                fluid
                transparent
                placeholder={this.props.namePlaceholder || formatMessage(m.namePlaceholder)}
                style={{ fontSize: '18px', marginLeft: '-1px', marginTop: '1px', marginBottom: '1px' }}
                onChange={(e, data) => this.props.onUpdate(e.target.id, data.value)}
                value={this.props.name}
              />
            </Form.Field>

            {this.props.canEditDescription &&
                            <span className="meta">
                              <Input
                                id="description"
                                fluid
                                transparent
                                placeholder={formatMessage(m.descriptionPlaceholder)}
                                onChange={(e, data) => this.props.onUpdate(e.target.id, data.value)}
                                value={this.props.description || ''}
                                style={{ marginLeft: '-1px' }}
                              />
                            </span>
            }
            {!this.props.canEditDescription && this.props.description &&
                            <span className="meta">
                              {this.props.description}
                            </span>
            }
          </Form>

          {hasCloseIcon &&
                        <React.Fragment>
                          {!this.props.closeIcon &&
                                <Tooltip trigger={<Icon name="cancel" link size="large" onClick={this.props.onClose} />}>
                                  {formatMessage(m.closeTooltip)}
                                </Tooltip>
                          }

                          {this.props.closeIcon && <React.Fragment>{this.props.closeIcon}</React.Fragment>}
                        </React.Fragment>
          }
        </div>
      </Card.Content>
    );
  }
}

const intlComponent = injectIntl(TileEditHeader);
export { intlComponent as TileEditHeader };
