import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Chip, Stack } from '@mui/material';
import { WorkstationChip } from './WorkstationChip';

const m = defineMessages({
  remainingWorkstations: { id: 'WorkstationChips.remainingWorkstations', defaultMessage: '+ {count}' }
});

export const WorkstationChips: React.FC<{
  workstationIds: number[],
  maximumWorkstationsDisplayed?: number,
}> = (props) => {
  const { formatMessage } = useIntl();

  const maximumWorkstationsDisplayed = props.maximumWorkstationsDisplayed || props.workstationIds.length;
  const displayedWorkstationIds = props.workstationIds.slice(0, maximumWorkstationsDisplayed);
  const remainingWorkstationIds = props.workstationIds.slice(maximumWorkstationsDisplayed, props.workstationIds.length);

  return (
    <Stack direction="row" spacing={0.5}>
      {displayedWorkstationIds.map(id => <WorkstationChip key={id} workstationId={id} />)}
      {remainingWorkstationIds.length > 0 && (
        <Chip
          label={formatMessage(m.remainingWorkstations, { count: remainingWorkstationIds.length })}
        />
      )}
    </Stack>
  );
};