import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { IconProps, Icon, SemanticCOLORS, Popup } from 'semantic-ui-react';

export type WorkstationPresenceIconProps =
  & WrappedComponentProps
  & IconProps;

interface PresenceInfo {
  color: SemanticCOLORS;
  tooltip: string;
}

const m = defineMessages({
  online: { id: 'WorkstationPresenceIcon.online', defaultMessage: 'Online' },
  offline: { id: 'WorkstationPresenceIcon.offline', defaultMessage: 'Offline' },
  offlineFor: { id: 'WorkstationPresenceIcon.offlineFor', defaultMessage: 'Offline for {date}' },
  unknown: { id: 'WorkstationPresenceIcon.unknown', defaultMessage: 'Unknown' }
});

class WorkstationPresenceIcon extends React.Component<WorkstationPresenceIconProps> {
  public render() {
    const presenceInfo = this.getPresenceInfo();

    return (
      <Popup
        trigger={<Icon name="circle" color={presenceInfo.color} {...this.props} />}
        content={presenceInfo.tooltip}
      />
    );
  }

  private getPresenceInfo = (): PresenceInfo => {
    const { formatMessage } = this.props.intl;

    return {
      color: 'grey',
      tooltip: formatMessage(m.unknown)
    };
  };
}

const intlComponent = injectIntl(WorkstationPresenceIcon);
export { intlComponent as WorkstationPresenceIcon };
