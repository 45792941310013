import React, { useState } from 'react';
import _ from 'lodash';
import { Document } from 'react-pdf';
import { PdfLoading } from './PdfLoading';
import { PdfError } from './PdfError';
import { PdfPage } from './PdfPage';
import styles from './PdfDocument.module.css';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';

interface OwnProps {
  documentUrl: string;
  width?: number;
  scale?: number;
  rotate?: number;

  onLoaded?: (totalPages: number) => void;
  onPageChanged?: (pageNumber: number) => void;
}

export type PdfDocumentProps =
  & OwnProps;

export const PdfDocument: React.FC<PdfDocumentProps> = (props) => {
  const [totalPages, setTotalPages] = useState(0);

  const handleDocumentLoaded = (document: DocumentCallback) => {
    setTotalPages(document.numPages);

    if (props.onLoaded) {
      props.onLoaded(document.numPages);
    }
  };

  const handlePageChanged = (pageNumber: number) => {
    if (props.onPageChanged) {
      props.onPageChanged(pageNumber);
    }
  };

  const documentPages = _.range(0, totalPages)
    .map(pageIndex => (
      <PdfPage
        key={pageIndex}
        className={styles.page}
        pageNumber={pageIndex + 1}
        width={props.width}
        scale={props.scale}
        rotate={props.rotate}
        onScrolledIntoView={() => handlePageChanged(pageIndex + 1)}
      />
    )
    );

  return (
    <Document
      file={props.documentUrl}
      loading={<PdfLoading />}
      error={<PdfError />}
      onLoadSuccess={handleDocumentLoaded}
    >
      {documentPages}
    </Document>
  );
};
