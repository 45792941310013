import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SalesOrder, SalesOrderStatus } from '../../../schedule/types';
import PrioritizationApi from '../../prioritization/api';
import { LotStatusIcon } from './LotStatusIcon';
import useApiErrorHandler from 'utils/ApiErrorHandler';
import { ProductionStatus } from 'modules/production/lots/types';
import { SalesOrderSummary } from 'modules/production/scheduling/components/SalesOrderSummary/SalesOrderSummary';
import moment from 'moment';

interface OwnProps {
    salesOrder: SalesOrder;
}

export type PrioritizableSalesRowProps =
    & OwnProps;

export const PrioritizableSalesRow = (props: PrioritizableSalesRowProps) => {
  const { onSettledErrorHandler } = useApiErrorHandler();
  const [expanded, setIsExpanded] = React.useState(false);
  const [isPrioritized, setIsPrioritized] = React.useState(props.salesOrder.orderIndex != null);
  const isCompleted = props.salesOrder.status === SalesOrderStatus.completed || props.salesOrder.productionStatus === ProductionStatus.completed;

  const queryClient = useQueryClient();

  const savePrioritiesMutation = useMutation(PrioritizationApi.savePriorities, {
    onSuccess: () => {
      queryClient.invalidateQueries(['planning-unprioritized-dates']);
      queryClient.invalidateQueries([`planning-data-${moment(props.salesOrder.orderedOn).startOf('day').toISOString()}`]);
      setIsPrioritized(previousValue => !previousValue);
    },
    onSettled: onSettledErrorHandler,
  });

  const { data: prioritizedSalesOrders, isFetching: isLoadingPriorities } = useQuery<SalesOrder[]>(
    ['sales-orders'],
    PrioritizationApi.getPrioritizedSalesOrders,
    {
      onSettled: onSettledErrorHandler
    });

  const isLoading = React.useCallback(
    () => isLoadingPriorities || savePrioritiesMutation.isLoading
    , [isLoadingPriorities, savePrioritiesMutation.isLoading]);

  const addOrderToPriorities = (currentSalesOrders: SalesOrder[] | undefined) => {
    return currentSalesOrders ? [... new Set([...currentSalesOrders.map(p => p.id), props.salesOrder.id])] : undefined;
  };

  const removeFromPriorities = (salesOrders: SalesOrder[] | undefined) => {
    if (salesOrders) {
      salesOrders.splice(salesOrders.findIndex(p => p.id === props.salesOrder.id), 1);
      return salesOrders.map(p => p.id);
    }
    else {
      return undefined;
    }
  };

  const handleCheckChange = (_event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    const newPriorities = isChecked
      ? addOrderToPriorities(prioritizedSalesOrders)
      : removeFromPriorities(prioritizedSalesOrders);

    savePrioritiesMutation.mutate(newPriorities ?? []);
  };

  return (
    <>
      <TableRow>
        <TableCell align='center'>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpanded(!expanded)}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center'>{props.salesOrder.salesOrderNumber}</TableCell>
        <TableCell align='center'>{props.salesOrder.clientFullName}</TableCell>
        <TableCell align='center'>{props.salesOrder.clientCompany}</TableCell>
        <TableCell align="center"><LotStatusIcon status={props.salesOrder.productionStatus} size="large" /></TableCell>
        <TableCell align='center'>
          <Checkbox
            checked={isPrioritized}
            onChange={handleCheckChange}
            disabled={isCompleted || isLoading()}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: '#f2f4f5' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" mountOnEnter={true}>
            <SalesOrderSummary salesOrder={props.salesOrder} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
