import { SupportedLocales } from '../../configureI18n';

export interface LayoutState {
    currentLocale: SupportedLocales;
    currentPageTitle: string;
    currentPageSubtitle?: string;
    globalNotification: GlobalNotification;
    isGlobalNotificationVisible: boolean;
    isNavigationMenuVisible: boolean;
    isSettingsDrawerVisible: boolean;
    isHistoryDrawerVisible: boolean;
    isSearchModalVisible: boolean;
}

export interface GlobalNotification {
    type: GlobalNotificationType;
    title: string;
    message: string;
}

export enum GlobalNotificationType {
    basic,
    information,
    success,
    warning,
    error
}

export enum LayoutActionTypes {
    SET_LOCALE = 'LAYOUT_SET_LOCALE',
    SET_PAGE_TITLE = 'LAYOUT_SET_PAGE_TITLE',
    SHOW_GLOBAL_NOTIFICATION = 'LAYOUT_SHOW_GLOBAL_NOTIFICATION',
    HIDE_GLOBAL_NOTIFICATION = 'LAYOUT_CLEAR_GLOBAL_NOTIFICATION',
    SHOW_NAVIGATION_MENU = 'LAYOUT_SHOW_NAVIGATION_MENU',
    HIDE_NAVIGATION_MENU = 'LAYOUT_HIDE_NAVIGATION_MENU',
    SHOW_SETTINGS_DRAWER = 'LAYOUT_SHOW_SETTINGS_DRAWER',
    HIDE_SETTINGS_DRAWER = 'LAYOUT_HIDE_SETTINGS_DRAWER',
    SHOW_HISTORY_DRAWER = 'LAYOUT_SHOW_HISTORY_DRAWER',
    HIDE_HISTORY_DRAWER = 'LAYOUT_HIDE_HISTORY_DRAWER',
    SHOW_SEARCH_MODAL = 'LAYOUT_SHOW_SEARCH_MODAL',
    HIDE_SEARCH_MODAL = 'LAYOUT_HIDE_SEARCH_MODAL'
}
