import _ from 'lodash';
import { CollaborationState, LocalCollaborativeUsersState, CollaborationActionTypes, ADD_LOCAL_USER, ADD_LOCAL_USER_FAILURE, ADD_LOCAL_USER_SUCCESS, REMOVE_LOCAL_USER } from './types';
import { Reducer, combineReducers } from 'redux';

const initialLocalUsersState: LocalCollaborativeUsersState = {
  byId: {},
  allIds: [],
  isLoading: false
};

const localCollaborativeUsersReducer: Reducer<LocalCollaborativeUsersState, CollaborationActionTypes> = (state = initialLocalUsersState, action) => {
  switch (action.type) {
  case ADD_LOCAL_USER: {
    return {
      ...state,
      isLoading: true
    };
  }

  case ADD_LOCAL_USER_SUCCESS: {
    return {
      ...state,
      byId: _.merge({}, state.byId, { [action.payload.user.id]: action.payload.user }),
      allIds: _.union(state.allIds, [action.payload.user.id]),
      isLoading: false
    };
  }

  case ADD_LOCAL_USER_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  case REMOVE_LOCAL_USER: {
    return {
      ...state,
      allIds: state.allIds.filter(x => x !== action.payload.userId)
    };
  }

  default:
    return state;
  }
};

const reducers = combineReducers<CollaborationState>({
  localUsers: localCollaborativeUsersReducer
});

export { reducers as collaborationReducer };
