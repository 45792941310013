import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Card, Icon, Input, SemanticICONS } from 'semantic-ui-react';

interface OwnProps {
    icon?: SemanticICONS;
    subtitle?: string;
    style?: React.CSSProperties;
    className?: string;

    onBlur: () => void;
    onFocus?: () => void;
    onClose?: () => void;
}

export type TileEditComponentProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
});

class TileEditComponent extends React.Component<TileEditComponentProps> {
  private inputRef: React.RefObject<Input>;

  public constructor (props: TileEditComponentProps) {
    super(props);

    this.inputRef = React.createRef<Input>();
  }

  public componentDidMount () {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <Card
        raised
        className={this.props.className}
        style={this.props.style}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
      >
        <Card.Content style={{ flexGrow: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
            {this.props.icon && <Icon name={this.props.icon} size="large" style={{ fontSize: '3em', marginRight: '15px' }} />}
            <div style={{ flexGrow: 1 }}>
              <Input ref={this.inputRef} className="bold" fluid transparent placeholder="Name" style={{ fontSize: '18px' }} />
              {this.props.subtitle && <span className="meta">{this.props.subtitle}</span>}
            </div>
            {this.props.onClose && <Icon name="cancel" link size="large" onClick={this.props.onClose} />}
          </div>
        </Card.Content>
        <Card.Content extra>
          {this.props.children}
        </Card.Content>
      </Card>
    );
  }
}

const intlComponent = injectIntl(TileEditComponent);
export { intlComponent as TileEditComponent };
