import {
  CreateWorkInstructionAction,
  CreateWorkInstructionFailureAction,
  CreateWorkInstructionSuccessAction,
  LoadAllAction,
  LoadAllFailureAction,
  LoadAllSuccessAction,
  WORK_INSTRUCTIONS_CREATE,
  WORK_INSTRUCTIONS_CREATE_FAILURE,
  WORK_INSTRUCTIONS_CREATE_SUCCESS,
  WORK_INSTRUCTIONS_LOAD_ALL,
  WORK_INSTRUCTIONS_LOAD_ALL_FAILURE,
  WORK_INSTRUCTIONS_LOAD_ALL_SUCCESS,
  WorkInstruction,
  WorkInstructionEdit
} from './types';

export function createWorkInstruction (workInstruction: WorkInstructionEdit): CreateWorkInstructionAction {
  return {
    type: WORK_INSTRUCTIONS_CREATE,
    payload: workInstruction
  };
}

export function createWorkInstructionSuccess (workInstruction: WorkInstruction): CreateWorkInstructionSuccessAction {
  return {
    type: WORK_INSTRUCTIONS_CREATE_SUCCESS,
    payload: workInstruction
  };
}

export function createWorkInstructionFailure (error: Error): CreateWorkInstructionFailureAction {
  return {
    type: WORK_INSTRUCTIONS_CREATE_FAILURE,
    payload: { error }
  };
}

export function loadAll (): LoadAllAction {
  return { type: WORK_INSTRUCTIONS_LOAD_ALL };
}

export function loadAllSuccess (workInstructions: WorkInstruction[]): LoadAllSuccessAction {
  return {
    type: WORK_INSTRUCTIONS_LOAD_ALL_SUCCESS,
    payload: workInstructions
  };
}

export function loadAllFailure (error: Error): LoadAllFailureAction {
  return {
    type: WORK_INSTRUCTIONS_LOAD_ALL_FAILURE,
    payload: { error }
  };
}
