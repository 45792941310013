import { ManufacturedItemOperation } from '../types';
import {
  CHANGE_LOT_FILTER,
  ChangeLotFilterAction,
  LotFilter,
  REGISTER_UI_PROGRESS_EVENT,
  REGISTER_UI_PROGRESS_EVENT_FAILURE,
  REGISTER_UI_PROGRESS_EVENT_SUCCESS,
  RegisterUIProgressEventAction,
  RegisterUIProgressEventPayload,
  RegisterUIProgressEventFailureAction,
  RegisterUIProgressEventSuccessAction,
  ProductionOrderFilter,
  ChangeProductionOrderFilterAction,
  CHANGE_PRODUCTION_ORDER_FILTER,
  ManufacturedItemOperationUpdatedAction,
  MANUFACTURED_ITEM_OPERATION_UPDATED
} from './types';

export function registerUIProgressEvent (params: RegisterUIProgressEventPayload): RegisterUIProgressEventAction {
  return {
    type: REGISTER_UI_PROGRESS_EVENT,
    payload: params
  };
}

export function registerUIProgressEventSuccess (params: RegisterUIProgressEventPayload): RegisterUIProgressEventSuccessAction {
  return {
    type: REGISTER_UI_PROGRESS_EVENT_SUCCESS,
    payload: params
  };
}

export function registerUIProgressEventFailure (error: Error): RegisterUIProgressEventFailureAction {
  return {
    type: REGISTER_UI_PROGRESS_EVENT_FAILURE,
    payload: { error }
  };
}

export function manufacturedItemOperationUpdated (params: Pick<ManufacturedItemOperation, 'id'> & Partial<ManufacturedItemOperation>): ManufacturedItemOperationUpdatedAction {
  return {
    type: MANUFACTURED_ITEM_OPERATION_UPDATED,
    payload: params
  };
}

export function changeLotFilter (lotFilter: LotFilter): ChangeLotFilterAction {
  return {
    type: CHANGE_LOT_FILTER,
    payload: lotFilter
  };
}

export function changeProductionOrderFilter (filter: ProductionOrderFilter): ChangeProductionOrderFilterAction {
  return {
    type: CHANGE_PRODUCTION_ORDER_FILTER,
    payload: filter
  };
}
