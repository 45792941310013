import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Ref } from 'semantic-ui-react';
import { DragDropIcon } from '../../../../components';
import { SalesOrder } from '../../../schedule';
import { LotStatusIcon } from '../../scheduling/components/LotStatusIcon';
import { PrioritizationActionMenu } from './PrioritizationActionMenu';
import { SalesOrderSummary } from 'modules/production/scheduling/components/SalesOrderSummary';

interface OwnProps {
  index: number;
  salesOrder: SalesOrder;
  draggedDraggableId: string | undefined;
  onPrioritizeFirst: () => void;
  onPrioritizeLast: () => void;
  onUnprioritize: () => void;
}

export type DraggableSalesOrderRowProps =
  & OwnProps;

export const DraggableSalesOrderRow = (props: DraggableSalesOrderRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const { index, salesOrder } = props;

  React.useEffect(() => {
    if (props.draggedDraggableId == salesOrder.id.toString()) {
      setIsExpanded(false);
    }

  }, [props.draggedDraggableId]);


  return (
    <Draggable key={salesOrder.id} draggableId={salesOrder.id.toString()} index={index} >
      {(provided) => {
        return (
          <Ref innerRef={provided.innerRef}>
            <>
              <TableRow {...provided.draggableProps} >
                <TableCell align="center" {...provided.dragHandleProps}><DragDropIcon size="large" /></TableCell>
                <TableCell align='center'>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell align='center'>{salesOrder.salesOrderNumber}</TableCell>
                <TableCell align='center'>{moment(salesOrder.orderedOn).local().format('ll')}</TableCell>
                <TableCell align='center'>{salesOrder.clientFullName}</TableCell>
                <TableCell align='center'>{salesOrder.clientCompany}</TableCell>
                <TableCell align="center"><LotStatusIcon status={salesOrder.productionStatus} size="large" /></TableCell>
                <TableCell align="right">
                  <PrioritizationActionMenu
                    onPrioritizeFirst={props.onPrioritizeFirst}
                    onPrioritizeLast={props.onPrioritizeLast}
                    onUnprioritize={props.onUnprioritize}
                  />
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: '#f2f4f5' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={isExpanded} timeout="auto" mountOnEnter={true}>
                    <SalesOrderSummary salesOrder={props.salesOrder} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          </Ref>
        );
      }
      }
    </Draggable>
  );
};
