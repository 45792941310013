import { Chip, ChipProps } from '@mui/material';
import { getWorkstationById, Workstation } from 'modules/administration';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

interface OwnProps {
  workstationId: number;
}

export type WorkstationChipProps =
  & ChipProps
  & OwnProps;

export const WorkstationChip: React.FC<WorkstationChipProps> = (props) => {
  const { workstationId, ...chipProps } = props;
  const workstation = useSelector<ApplicationState, Workstation | undefined>((state) => getWorkstationById(state, workstationId));

  return (
    <Chip label={workstation?.name || props.workstationId} {...chipProps} />
  );
};