import { MessageDescriptor } from 'react-intl';

export class ValidationResult {
  public isValid = true
  public errorMessages: MessageDescriptor[] = []
  public errorFields: string[] = []

  public static empty (): ValidationResult {
    return new ValidationResult();
  }

  public constructor (init?: Partial<ValidationResult>) {
    Object.assign(this, init);
  }

  public isFieldInvalid (field: string) {
    return this.errorFields.find(x => x === field) != null;
  }

  public isEmpty (): boolean {
    return this.isValid === true &&
            this.errorMessages.length === 0 &&
            this.errorFields.length === 0;
  }

  public localizeErrorMessages (localizer: (messageDescriptor: MessageDescriptor) => string): string[] {
    return this.errorMessages.map(x => localizer(x));
  }
}
