import _ from 'lodash';
import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Search, SearchProps, SearchResultData, SearchResultProps } from 'semantic-ui-react';
import { ApplicationState } from '../../../store';
import { Item } from '../../production';
import * as SearchActions from '../actions';
import { getItemsSearchResults, isSearchingForItems } from '../selectors';
import { ItemIcon } from './ItemIcon';

interface ItemsSearchControlActions {
  searchActions: typeof SearchActions;
}

interface ItemsSearchControlOwnProps {
  onItemSelected: (item: Item) => void;
  onItemCleared: () => void;
}

interface ItemsSearchControlConnectedProps {
  searchResults: Item[];
  isSearching: boolean;
}

export type ItemsSearchControlProps =
  & ItemsSearchControlConnectedProps
  & ItemsSearchControlOwnProps
  & ItemsSearchControlActions
  & SearchProps
  & WrappedComponentProps;

const m = defineMessages({
  noResults: { id: 'ItemsSearchControl.noResults', defaultMessage: 'No items found.' }
});

class ItemsSearchControl extends React.Component<ItemsSearchControlProps> {
  public render() {
    const { formatMessage } = this.props.intl;
    const { intl, searchResults, isSearching, onItemSelected, onItemCleared, searchActions, ...searchProps } = this.props;
    const formattedResults: SearchResultProps[] = this.props.searchResults.map(x => ({
      title: x.itemCode,
      description: x.description,
      item: x
    }));

    return (
      <Search
        fluid={true}
        loading={this.props.isSearching}
        onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
        onResultSelect={this.handleResultSelected}
        selectFirstResult={true}
        results={formattedResults}
        resultRenderer={this.renderSearchResult}
        noResultsMessage={formatMessage(m.noResults)}
        {...searchProps}
      />
    );
  }

  private renderSearchResult = (props: SearchResultProps) => {
    const item = props.item as Item;

    return (
      <div>
        <ItemIcon item={item} />
        <strong>{item.itemCode}</strong>
        <span style={{ marginLeft: '5px' }}>{props.description}</span>
      </div>
    );
  };

  private handleSearchChange = (_event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
    if (data.value != null && data.value !== '') {
      this.props.searchActions.searchItems(data.value);
    } else {
      this.props.onItemCleared();
    }
  };

  private handleResultSelected = (_event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
    this.props.onItemSelected(data.result.item);
  };
}

const mapStateToProps = (state: ApplicationState): ItemsSearchControlConnectedProps => {
  return {
    searchResults: getItemsSearchResults(state),
    isSearching: isSearchingForItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ItemsSearchControlActions => {
  return {
    searchActions: bindActionCreators(SearchActions, dispatch)
  };
};

const intlComponent = injectIntl(ItemsSearchControl);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as ItemsSearchControl };
