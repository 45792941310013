import BackspaceIcon from '@mui/icons-material/Backspace';
import { Grid, IconButton, Stack } from '@mui/material';
import range from 'lodash/range';
import { DigitButton } from './DigitButton';
import { hugeButtonHeight, hugeButtonWidth } from './HugeButton';

interface DigitInputProps {
  value: string;
  onChange: (newValue: string) => void;
}

export const DigitInput = ({ value, onChange }: DigitInputProps) => {
  const addDigit = (digit: number) => {
    if (!value) {
      onChange(digit.toString());
    } else {
      onChange(value + digit.toString());
    }
  };

  const removeDigit = () => {
    onChange(value.slice(0, value.toString().length - 1));
  };

  return (
    <>
      <Grid container spacing={2} padding={2}>
        {range(1, 10).map((digit) => (
          <DigitButton key={digit} digit={digit} onClick={addDigit} />
        ))}
        <Grid item xs={4}></Grid>
        <DigitButton digit={0} onClick={addDigit} />
        <Grid item xs={4}>
          <Stack
            onClick={removeDigit}
            sx={{ cursor: 'pointer' }}
            alignItems="center"
            justifyContent="center"
            width={hugeButtonWidth}
            height={hugeButtonHeight}
          >
            <IconButton>
              <BackspaceIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
