import { Paper } from '@mui/material';
import { DataTable, DataTableProps } from 'components/common/DataTable';
import dayjs from 'dayjs';
import { FragmentType, graphql, useFragment } from 'gql';
import { OrderList_QueryFragmentFragment } from 'gql/graphql';
import { LotStatusIcon } from 'modules/production/scheduling/components/LotStatusIcon';
import { useIntl } from 'react-intl';
import { ProductionItems } from './ProductionItems';

export const OrderList_QueryFragment = graphql(`
  fragment OrderList_QueryFragment on Query {
    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {
      totalCount
      items {
        id
        salesOrderNumber
        purchaseOrderNumber
        orderedOn
        customerFullName
        customerCompanyName
        productionStatus
      }
    }
  }
`);

type SalesOrderItem = NonNullable<
  NonNullable<OrderList_QueryFragmentFragment['salesOrders']>['items']
>[number];

interface OrdersTableProps {
  query: FragmentType<typeof OrderList_QueryFragment>;
}

export type UnplanifiedOrdersTableProps = OrdersTableProps &
  Omit<DataTableProps<SalesOrderItem>, 'columns' | 'rows'>;

export const OrdersTable = ({
  query: mainQuery,
  ...dataTableProps
}: UnplanifiedOrdersTableProps) => {
  const { formatMessage } = useIntl();
  const query = useFragment(OrderList_QueryFragment, mainQuery);

  return (
    <Paper>
      <DataTable
        rows={query.salesOrders?.items ?? []}
        columns={[
          {
            field: 'orderedOn',
            type: 'date',
            headerName: formatMessage({
              id: 'ProductionHistoryPage.OrdersTable.headerDate',
              defaultMessage: 'Date',
            }),
            valueGetter: (data) => new Date(data.row.orderedOn),
            renderCell: ({ row, value }) => row.id && dayjs(value).format('ll'),
            sortable: false,
          },
          {
            field: 'salesOrderNumber',
            type: 'number',
            headerName: formatMessage({
              id: 'ProductionHistoryPage.OrdersTable.headerSalesOrderNumber',
              defaultMessage: 'Order #',
            }),
            sortable: false,
          },
          {
            field: 'client',
            flex: 1,
            headerName: formatMessage({
              id: 'ProductionHistoryPage.OrdersTable.headerClient',
              defaultMessage: 'Client',
            }),
            valueGetter: (data) => data.row.customerFullName,
            sortable: false,
          },
          {
            field: 'company',
            flex: 1,
            headerName: formatMessage({
              id: 'ProductionHistoryPage.OrdersTable.headerCompany',
              defaultMessage: 'Company',
            }),
            valueGetter: (data) => data.row.customerCompanyName,
            sortable: false,
          },
          {
            field: 'purchaseOrderNumber',
            flex: 1,
            headerName: formatMessage({
              id: 'ProductionHistoryPage.OrdersTable.headerPurchaseOrderNumber',
              defaultMessage: 'PO #',
            }),
            sortable: false,
          },
          {
            field: 'productionStatus',
            headerName: formatMessage({
              id: 'ProductionHistoryPage.OrdersTable.headerProductionStatus',
              defaultMessage: 'Status',
            }),
            renderCell: ({ row, value }) =>
              row.id && <LotStatusIcon size="large" status={value} />,
            sortable: false,
          },
        ]}
        getDetailPanelContent={({ row }) => (
          <ProductionItems salesOrderId={row.id} />
        )}
        {...dataTableProps}
      />
    </Paper>
  );
};
