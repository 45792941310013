import { Autocomplete, Stack, TextField } from '@mui/material';
import { getWorkstations } from 'modules/administration';
import { CameraFacingMode, Device, getFacingModeDescription } from 'modules/administration/devices/types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const m = defineMessages({
  nameLabel: { id: 'DeviceFormControls.nameLabel', defaultMessage: 'Name' },
  cameraFacingModeLabel: { id: 'DeviceFormControls.cameraFacingModeLabel', defaultMessage: 'Camera facing direction' },
  workstationsLabel: { id: 'DeviceFormControls.workstationsLabel', defaultMessage: 'Workstations' },
});

interface CameraFacingOption { label: string, value: CameraFacingMode }

export const DeviceFormControls: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control, register } = useFormContext<Device>();

  const workstations = useSelector(getWorkstations);
  const getWorkstationName = (workstationId: number): string => workstations.find(workstation => workstation.id === workstationId)?.name || workstationId.toString();

  const cameraFacingOptions: CameraFacingOption[] = [
    { value: CameraFacingMode.default, label: formatMessage(getFacingModeDescription(CameraFacingMode.default)) },
    { value: CameraFacingMode.user, label: formatMessage(getFacingModeDescription(CameraFacingMode.user)) },
    { value: CameraFacingMode.environment, label: formatMessage(getFacingModeDescription(CameraFacingMode.environment)) },
  ];

  return (
    <Stack direction="column" gap={2}>
      <TextField
        fullWidth
        autoFocus
        label={formatMessage(m.nameLabel)}
        variant="outlined"
        {...register('name')}
      />

      <TextField
        fullWidth
        select
        label={formatMessage(m.cameraFacingModeLabel)}
        SelectProps={{ native: true }}
        {...register('cameraFacingMode')}
      >
        {cameraFacingOptions.map(option => (
          <option key={option.value.toString()} value={option.value}>{option.label}</option>
        ))}
      </TextField>

      <Controller
        name="workstationIds"
        control={control}
        render={(form) => (
          <Autocomplete
            multiple
            fullWidth
            options={workstations.map(x => x.id)}
            getOptionLabel={getWorkstationName}
            disableCloseOnSelect={true}
            filterSelectedOptions={true}
            renderInput={(params) => (
              <TextField {...params} label={formatMessage(m.workstationsLabel)} />
            )}
            {...form.field}
            onChange={(e, value) => form.field.onChange(value)}
            value={form.field.value || []}
          />
        )}
      />
    </Stack >
  );
};