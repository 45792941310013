import React from 'react';
import { RoadmapField } from '../../../../../production/roadmaps/types';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Ref } from 'semantic-ui-react';
import ReactDOM from 'react-dom';
import { RoadmapFieldListItem } from '../RoadmapFieldsSelection/RoadmapFieldListItem';
import styles from './DraggableRoadmapField.module.css';

interface OwnProps {
    field: RoadmapField;
    index: number;
}

export type DraggableFieldProps =
    & OwnProps;

const portal: HTMLElement = document.createElement('div');
portal.classList.add('ui', 'divided', 'selection', 'relaxed', 'list');
portal.classList.add(styles.draggingField);
document.body.appendChild(portal);

export const DraggableRoadmapField: React.FC<DraggableFieldProps> = (props) => {
  const node = (provided: DraggableProvided) => (
    <Ref innerRef={provided.innerRef}>
      <RoadmapFieldListItem
        key={props.field.id}
        field={props.field}
        draggable={provided}
      />
    </Ref>
  );

  // Since the draggable roadmap field can be displayed inside Semantic UI Modal components,
  // we need to move the component within a portal while rendering it, otherwise we'll have
  // issue where the component is rendered far away from the mouse cursor because of the
  // position: fixed attribute. See: https://github.com/atlassian/react-beautiful-dnd/issues/485
  return (
    <Draggable key={props.field.id} draggableId={props.field.id.toString()} index={props.index}>
      {(provided, snapshot) => snapshot.isDragging
        ? ReactDOM.createPortal(node(provided), portal)
        : node(provided)}
    </Draggable>
  );
};
