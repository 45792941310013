import React from 'react';
import { TopBarMenuItem } from './TopBarMenuItem';
import * as LiveUpdatesActions from '../../../modules/live-updates/actions';
import { useDispatch, useSelector } from 'react-redux';
import { areLiveUpdatesForcedOff } from 'modules/live-updates';

export const LiveUpdatesToggle: React.FC = () => {
  const dispatch = useDispatch();
  const isTurnedOff = useSelector(areLiveUpdatesForcedOff);

  const toggleLiveUpdates = () => {
    dispatch(isTurnedOff
      ? LiveUpdatesActions.turnOnLiveUpdates()
      : LiveUpdatesActions.turnOffLiveUpdates()
    );
  };

  return (
    <TopBarMenuItem
      style={{ minWidth: '72px', display: 'flex', justifyContent: 'center' }}
      icon="wifi"
      onClick={toggleLiveUpdates}
    />
  );
};