import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { areTemplatesLoading, getTemplateById } from '../../../production/roadmaps/selectors';
import { Roadmap } from '../../../production/roadmaps/types';
import { defineMessages, useIntl } from 'react-intl';
import { UpdateEntityModal } from '../../../../components/common/UpdateEntityModal';
import { FormProvider, useForm } from 'react-hook-form';
import { RoadmapFormControls } from './components/RoadmapFormControls';
import { Form } from 'semantic-ui-react';
import * as RoadmapActions from '../actions';
import * as api from '../api';
import { toast } from 'react-toastify';
import { successToastOptions } from '../../../../utils';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';

interface RouteProps {
    roadmapId: string;
}

const m = defineMessages({
  title: { id: 'UpdateRoadmapPage.title', defaultMessage: 'Create a new roadmap template' },
  deleteSuccess: { id: 'UpdateRoadmapPage.deleteSuccess', defaultMessage: 'The roadmap template {name} has been removed from the system.' }
});

export const UpdateRoadmapPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const params = useParams<RouteProps>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleError } = useApiErrorHandler();

  const roadmapId = Number(params.roadmapId);
  const roadmap = useSelector((state: ApplicationState) => getTemplateById(state, roadmapId));
  const isLoading = useSelector(areTemplatesLoading);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const formProps = useForm<Roadmap>({ defaultValues: { id: Number(params.roadmapId) } });
  const { setValue } = formProps;

  useEffect(() => {
    if (roadmap != null) {
      setValue('id', roadmap.id);
      setValue('name', roadmap.name);
      setValue('columns', roadmap.columns);
      setValue('sortings', roadmap.sortings);
      setValue('isDefault', roadmap.isDefault);
    }
  }, [roadmap, setValue]);

  const navigateToRoadmapsManagementPage = () => history.push('/administration/roadmaps');

  const saveRoadmap = (roadmap: Roadmap) => {
    if (roadmap != null) {
      setIsSaving(true);

      api.updateRoadmap(roadmap.id, roadmap)
        .then(result => {
          dispatch(RoadmapActions.updateRoadmapSuccess(result));
          navigateToRoadmapsManagementPage();
        })
        .catch(handleError)
        .finally(() => setIsSaving(false));
    }
  };

  const deleteRoadmap = () => {
    if (roadmap != null) {
      setIsDeleting(true);

      api.deleteRoadmap(roadmap.id)
        .then(() => {
          dispatch(RoadmapActions.deleteRoadmapSuccess(roadmap.id));
          toast.success(formatMessage(m.deleteSuccess, { name: roadmap.name }), successToastOptions);
          navigateToRoadmapsManagementPage();
        })
        .catch(handleError)
        .finally(() => setIsDeleting(false));
    }
  };

  return (
    <UpdateEntityModal
      open
      title={formatMessage(m.title)}
      loading={isLoading || isSaving}
      deleting={isDeleting}
      onSubmit={formProps.handleSubmit(saveRoadmap)}
      onClose={navigateToRoadmapsManagementPage}
      onDelete={deleteRoadmap}
    >
      <FormProvider {...formProps}>
        <Form loading={isLoading}>
          <RoadmapFormControls />
        </Form>
      </FormProvider>
    </UpdateEntityModal>
  );
};
