import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { NoContentPlaceholder } from '../../../../../../components';

const m = defineMessages({
  noContentTitle: { id: 'OperationSelectionPage.noContentTitle', defaultMessage: 'No associated work operation' },
  noContentSubtitle: { id: 'OperationSelectionPage.noContentSubtitle', defaultMessage: 'There are no operations associated to this workstation. Please contact an administrator to configure this workstation.' }
});

export const NoOperationsPlaceholder = () => {
  const { formatMessage } = useIntl();

  return (
    <NoContentPlaceholder
      icon="wrench"
      title={formatMessage(m.noContentTitle)}
      subtitle={formatMessage(m.noContentSubtitle)}
    />
  );
};
