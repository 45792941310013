import { DrawerProps, Drawer, Box, Divider } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isHistoryDrawerVisible } from 'store/layout';
import { WorkHistoryDrawerHeader } from './WorkHistoryDrawerHeader';
import { WorkHistoryInformationSection } from './HistoryInformationSection/WorkHistoryInformationSection';
import * as LayoutActions from 'store/layout/actions';

export const WorkHistoryDrawer: React.FC<DrawerProps> = (props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isHistoryDrawerVisible);
  const closeDrawer = () => dispatch(LayoutActions.hideHistoryDrawer());

  return (
    <Drawer open={isOpen} onClose={closeDrawer} {...props}>
      <Box sx={{ width: '400px' }}>
        <WorkHistoryDrawerHeader onClose={closeDrawer} />
        <Divider  />

        <WorkHistoryInformationSection />
      </Box>
    </Drawer>
  );
};