import React from 'react';
import _ from 'lodash';
import { Unit } from '../../../../types';
import { ProductionUnitButton } from './ProductionUnitButton';
import { Grid } from 'semantic-ui-react';

interface OwnProps {
    productionUnits: Unit[];
    onUnitClick: (productionUnit: Unit) => void;
}

export type ProductionUnitSelectionListProps =
    & OwnProps;

export const ProductionUnitSelectionList: React.FC<ProductionUnitSelectionListProps> = (props) => {
  const displayedUnits = _(props.productionUnits).sortBy(unit => unit.name).value();

  return (
    <Grid doubling padded>
      {displayedUnits.map(unit => (
        <Grid.Column key={unit.id} textAlign="center" mobile={8} tablet={4} computer={3} largeScreen={3} widescreen={2}>
          <ProductionUnitButton key={unit.id} productionUnit={unit} onClick={() => props.onUnitClick(unit)} />
        </Grid.Column>
      ))}
    </Grid>
  );
};
