import React, { useState, useEffect } from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { RoadmapField } from '../../../../../production/roadmaps/types';
import { getAllFields, areFieldsLoading } from '../../../../../production/roadmaps/selectors';
import { LoadingContainer, LoadingDimmer, ClearableInput } from '../../../../../../components/common';
import { RoadmapFieldsList } from './RoadmapFieldsList';
import _ from 'lodash';
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';

interface OwnProps {
    selectedFields: RoadmapField[];
    onSelect: (field: RoadmapField, orderIndex: number) => void;
    onDeselect: (field: RoadmapField) => void;
}

export type RoadmapFieldsSelectionProps =
    & OwnProps;

const m = defineMessages({
  availableFieldsLabel: { id: 'CreateOrUpdateRoadmapModal.availableFieldsLabel', defaultMessage: 'Available fields' },
  selectedFieldsLabel: { id: 'CreateOrUpdateRoadmapModal.selectedFieldsLabel', defaultMessage: 'Selected fields' },
  searchAvailableFieldsPlaceholder: { id: 'CreateOrUpdateRoadmapModal.searchAvailableFieldsPlaceholder', defaultMessage: 'Search for a field' }
});

const availableFieldsDroppableId = 'available-fields';
const selectedFieldsDroppableId = 'selected-fields';

export const RoadmapFieldsSelection: React.FC<RoadmapFieldsSelectionProps> = (props) => {
  const { formatMessage } = useIntl();

  const [availableFields, setAvailableFields] = useState<RoadmapField[]>([]);
  const [searchText, setSearchText] = useState('');

  const allFields = useSelector(getAllFields);
  const loading = useSelector(areFieldsLoading);

  const updateSearch = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setSearchText(data.value as string);
  const clearSearch = () => setSearchText('');

  const selectField = (field: RoadmapField, orderIndex: number) => {
    setAvailableFields(availableFields.filter(x => x.id !== field.id));
    props.onSelect(field, orderIndex);
  };

  const unselectField = (field: RoadmapField) => {
    setAvailableFields([...availableFields, field]);
    props.onDeselect(field);
  };

  const handleDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (result.reason === 'DROP') {
      const fieldId = Number(result.draggableId);
      const field = allFields.find(x => x.id === fieldId);

      if (field == null) {
        return;
      }

      if (result.source?.droppableId === selectedFieldsDroppableId &&
                result.destination?.droppableId === availableFieldsDroppableId) {
        unselectField(field);
      } else if (result.destination?.droppableId === selectedFieldsDroppableId) {
        selectField(field, result.destination?.index);
      }
    }
  };

  useEffect(() => {
    const normalizedSearchText = _.deburr(searchText.toLocaleLowerCase());
    const fields = _.differenceBy(allFields, props.selectedFields, x => x.id);
    const fieldsAfterSearch = searchText !== ''
      ? fields.filter(x => _.deburr(x.name).toLocaleLowerCase().includes(normalizedSearchText) ||
                _.deburr(x.propertyName).toLocaleLowerCase().includes(normalizedSearchText))
      : fields;

    setAvailableFields(fieldsAfterSearch);
  }, [allFields, props.selectedFields, searchText]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Form.Group widths="equal">
        <Form.Field>
          <label>{formatMessage(m.availableFieldsLabel)}</label>
          <LoadingContainer>
            <LoadingDimmer active={loading} />
            <ClearableInput
              value={searchText}
              placeholder={formatMessage(m.searchAvailableFieldsPlaceholder)}
              onChange={updateSearch}
              onClear={clearSearch}
            />

            <div style={{ height: '180px', overflowY: 'auto', marginTop: '.5rem' }}>
              <RoadmapFieldsList
                droppableId={availableFieldsDroppableId}
                fields={_(availableFields).orderBy(x => x.name).value()}
              />
            </div>
          </LoadingContainer>
        </Form.Field>
        <Form.Field>
          <label>{formatMessage(m.selectedFieldsLabel)}</label>
          <div style={{ height: '180px', overflowY: 'auto', marginTop: '3.5rem' }}>
            <RoadmapFieldsList
              droppableId={selectedFieldsDroppableId}
              fields={props.selectedFields}
            />
          </div>
        </Form.Field>
      </Form.Group>
    </DragDropContext>
  );
};
