import { useQueryClient } from '@tanstack/react-query';
import { commonMessages } from 'constants/messages';
import { graphql } from 'gql';
import { useGraphQLMutation } from 'gql/useGraphQL';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import useApiErrorHandler, { successToastOptions } from 'utils/ApiErrorHandler';
import {
  LotManagementData,
  LotManagementLot,
  LotManagementProductionItem,
  ProductionStatus,
} from '../../types';
import { DeleteLotConfirmationDialog } from './DeleteLotConfirmationDialog';

const deleteLotGqlMutation = graphql(`
  mutation DeleteLotMutation($input: DeleteLotInput!) {
    deleteLot(input: $input) {
      int
      errors {
        ... on Error {
          message
        }
      }
    }
  }
`);

interface DeleteLotButtonProps {
  lot: LotManagementLot;
  productionItems: LotManagementProductionItem[];
}

export const DeleteLotButton = ({
  lot,
  productionItems,
}: DeleteLotButtonProps) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { onSettledErrorHandler } = useApiErrorHandler();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isLoading, mutate: deleteLot } = useGraphQLMutation(
    deleteLotGqlMutation,
    {
      onSuccess: (response, variables) => {
        const queryData = queryClient.getQueryData<LotManagementData>([
          'lotManagementData',
        ]);
        if (queryData && variables.input.lotId) {
          queryClient.setQueryData(['lotManagementData'], {
            ...queryData,
            lots: {
              ...Object.fromEntries(
                Object.entries(queryData.lots).filter(
                  ([id, name]) => id != variables.input.lotId.toString()
                )
              ),
            },
          });
        }

        toast.success(
          formatMessage({
            id: 'DeleteLotButton.Success',
            defaultMessage: 'The lot has been deleted.',
          }),
          successToastOptions
        );
      },
      onSettled: (data, error) => {
        setIsDialogOpen(false);
        onSettledErrorHandler(data, error);
      },
    }
  );

  const handleDeleteLotConfirmed = () => {
    deleteLot({ input: { lotId: lot.id } });
  };

  const canDelete =
    productionItems.length === 0 &&
    lot.productionStatus !== ProductionStatus.completed &&
    !isLoading;

  return (
    <>
      <Button
        type="button"
        negative
        basic
        disabled={!canDelete}
        loading={isLoading}
        onClick={() => setIsDialogOpen(true)}
      >
        {formatMessage(commonMessages.delete)}
      </Button>
      <DeleteLotConfirmationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirmed={handleDeleteLotConfirmed}
      />
    </>
  );
};
