import * as React from 'react';
import { Card } from 'semantic-ui-react';

export interface TileContentProps {
  children: React.ReactNode
}

// tslint:disable-next-line: variable-name
export const TileContent = (props: TileContentProps) => {
  return (
    <Card.Content>
      {props.children}
    </Card.Content>
  );
};
