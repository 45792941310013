import { getCurrentDevice } from 'modules/administration/devices/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Header, Menu } from 'semantic-ui-react';
import { isNavigationMenuVisible, isNavigationTriggerVisible } from 'store/layout';
import * as LayoutActions from 'store/layout/actions';
import { LocalCollaborativeUsers } from '../../../modules/production/collaboration/components/LocalCollaborativeUsers';
import { ApplicationState } from '../../../store';
import { AccountControl } from './AccountControl';
import { FullscreenControl } from './FullscreenControl';
import { HistoryControl } from './HistoryControl/HistoryControl';
import { LiveUpdatesToggle } from './LiveUpdatesToggle';
import { ReloadApplicationButton } from './ReloadApplicationButton';
import { SearchControl } from './SearchControl/SearchControl';
import styles from './TopBar.module.css';
import { TopBarMenuItem } from './TopBarMenuItem';

export const TopBar = () => {
  const isNavigationTriggerVisibleState = useSelector<ApplicationState, boolean>(state => isNavigationTriggerVisible(state));
  const dispatch = useDispatch();
  const pageTitle = useSelector<ApplicationState, string | undefined>(state => state.layout.currentPageTitle);
  const pageSubtitle = useSelector<ApplicationState, string | undefined>(state => state.layout.currentPageSubtitle);
  const history = useHistory();
  const isNavigationMenuVisibleState = useSelector<ApplicationState, boolean>(state => isNavigationMenuVisible(state));
  const currentDevice = useSelector(getCurrentDevice);

  const previousPage = () => {
    history.goBack();
  };

  const homePage = () => {
    history.push('/');
  };

  return (
    <Menu className={styles.mainTopBar} fixed="top">
      {isNavigationTriggerVisibleState &&
        <TopBarMenuItem
          id={styles.toggleNavigationMenu}
          className={styles.wideTopBarButton}
          icon="bars"
          onClick={() => dispatch(LayoutActions.showNavigationMenu())}
          disabled={isNavigationMenuVisibleState}
        />
      }
      <TopBarMenuItem className={styles.wideTopBarButton} icon="arrow left" onClick={previousPage} />
      <TopBarMenuItem className={styles.wideTopBarButton} icon="home" onClick={homePage} />
      <Menu.Item className={styles.pageTitle}>
        <Header>
          {pageTitle}
          {pageSubtitle && <Header.Subheader>{pageSubtitle}</Header.Subheader>}
        </Header>
      </Menu.Item>
      <Menu.Menu position="right">
        {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' && <LiveUpdatesToggle />}
        {currentDevice != null && <>
          <SearchControl />
          <HistoryControl />
        </>}
        <ReloadApplicationButton />
        <FullscreenControl />
        <LocalCollaborativeUsers />
        <AccountControl />
      </Menu.Menu>
    </Menu>
  );
};
