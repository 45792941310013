import React from 'react';
import { InputProps, Input, Icon } from 'semantic-ui-react';

interface OwnProps {
  onClear: () => void;
}

export type ClearableInputProps =
  & OwnProps
  & InputProps;

export const ClearableInput: React.FC<ClearableInputProps> = (props) => {
  const { onClear, ...inputProps } = props;

  return (
    <Input icon={<Icon name="close" link onClick={onClear} />} {...inputProps} />
  );
};
