import _ from 'lodash';
import { getUnitById } from 'modules/production';
import * as ScheduleApi from 'modules/schedule/api';
import * as React from 'react';
import { defineMessages, FormattedNumber, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';
import { LoadingDimmer, Tile } from '../../../../components';
import { ApplicationState } from '../../../../store';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';
import { ProductionItem, Unit } from '../../../production';
import { ProductionScheduleEntry } from './ProductionScheduleEntry';
import {ProductionScheduleEntities} from 'modules/schedule/types';

interface ProductionUnitTileProps {
    productionUnitId: number;
    productionDate: Date;
}

const m = defineMessages({
  orderHeader: { id: 'ProductionUnitTile.orderHeader', defaultMessage: 'Order' },
  quantityHeader: { id: 'ProductionUnitTile.quantityHeader', defaultMessage: 'Qty' },
  lotHeader: { id: 'ProductionUnitTile.lotHeader', defaultMessage: 'Lot' },
  emptyContent: { id: 'ProductionUnitTile.emptyContent', defaultMessage: 'No orders planned' }
});

export const ProductionUnitTile = (props: ProductionUnitTileProps) => {
  const { handleError } = useApiErrorHandler();
  const [isLoading,setIsLoading]= React.useState(false);
  const productionUnit = useSelector<ApplicationState, Unit | undefined>(state => getUnitById(state, props.productionUnitId));
  const [productionSchedule, setProductionSchedule] = React.useState<ProductionScheduleEntities | undefined>(undefined);

  const { formatMessage } = useIntl();
  const getTotalValue = React.useCallback(() =>
    productionSchedule ?
      Object.values(productionSchedule.productionItems).map(x => x.monetaryValue).reduce((total, current) => total + current, 0)
      : 0
  , [productionSchedule]);

  const getGroupedOrders = React.useCallback(() => {
    const generateGroupKey = (productionOrder: ProductionItem) => `${productionOrder.referenceNumber}-${productionOrder.style}-${productionOrder.itemCoverType}`;
    const groupedOrders = !productionSchedule ? [] : _.chain(Object.values(productionSchedule.productionItems))
      .groupBy(generateGroupKey)
      .map((values, key) => ({ key, values }))
      .value();

    return groupedOrders;
  }, [productionSchedule]);

  React.useEffect(() => {
    setIsLoading(true);

    ScheduleApi.load(props.productionDate)
      .then(setProductionSchedule)
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, [props.productionDate]);

  return (
    <Tile header={{ title: productionUnit?.name }} style={{ margin: '10px' }}>
      <LoadingDimmer active={isLoading} />
      {!isLoading && productionSchedule &&
        Object.keys(productionSchedule.productionItems).length === 0 &&
                <Segment basic={true} disabled={true} textAlign="center" size="tiny">
                  <Header icon={true}>
                    <Icon name="pallet" />
                    {formatMessage(m.emptyContent)}
                  </Header>
                </Segment>
      }

      {!isLoading && productionSchedule
        && Object.keys(productionSchedule.productionItems).length > 0 &&
                  <Table basic="very" unstackable selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.orderHeader)}</Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.quantityHeader)}</Table.HeaderCell>
                        <Table.HeaderCell>{formatMessage(m.lotHeader)}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {getGroupedOrders().map(x => <ProductionScheduleEntry key={x.key} productionSchedule={productionSchedule} />)}
                    </Table.Body>
                    <Table.Footer style={{ fontWeight: 'bold' }}>
                      <Table.Row>
                        <Table.Cell colSpan={3} />
                        <Table.Cell textAlign="right">
                          {Object.values(productionSchedule.productionItems)
                            .map(x => x.quantity)
                            .reduce((total, current) => total + current, 0)
                          }
                        </Table.Cell>
                        <Table.Cell>
                          <FormattedNumber value={getTotalValue()} minimumFractionDigits={0} maximumFractionDigits={0} style="currency" currency="CAD" />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
      }
    </Tile>
  );
};
