import { get, post } from '../../../utils/api';
import { Operation } from '../../production';

const endpoint = '/api/operations';

export async function loadAll (): Promise<Operation[]> {
  return get<Operation[]>(endpoint);
}

export async function update (operation: Operation): Promise<Operation> {
  return post<Operation>(`${endpoint}/${operation.id}`, operation);
}
