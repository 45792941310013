import React from 'react';
import { ButtonProps, SemanticShorthandContent } from 'semantic-ui-react';

export interface OwnProps {
    acceptedFileTypes?: string;
    multiple?: boolean;
    onUpload: (files: FileList) => void;
    content: SemanticShorthandContent;
}

export type FileUploadButtonProps =
    & OwnProps
    & ButtonProps;

export const FileUploadButton: React.FC<FileUploadButtonProps> = (props) => {
  const { acceptedFileTypes, multiple, onUpload, content } = props;
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files) {
      onUpload(files);
    }
  };

  return (
    <label className="ui button" style={{ color: 'rgba(0,0,0,.6)', fontSize: '1rem', height: '36px' }}>
      {content}
      <input
        type="file"
        accept={acceptedFileTypes || '*'}
        style={{ display: 'none' }}
        multiple={multiple || false}
        onChange={handleChange}
      />
    </label>
  );
};
