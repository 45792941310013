import React from 'react';
import { Box, Card, Divider, List, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { LotManagementData } from '../../types';
import { LotCreationButton } from './LotCreationButton';
import { LotGroupListItem } from './LotGroupListItem';
import axios from 'axios';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { InformationMessage } from 'components/common/InformationMessage';

const m = defineMessages({
  lotsTitle: { id: 'LotsList.lotsTitle', defaultMessage: 'Lots' },
  lotsSubtitle: { id: 'LotsList.lotsSubtitle', defaultMessage: 'Select a lot where the items will be added' },
  hiddenLotsMessage: { id: 'LotsList.hiddenLotsMessage', defaultMessage: 'Completed lots and lots currently being produced are hidden from this list.' },
});

export const LotsList: React.FC = () => {
  const { setValue, watch } = useFormContext<{ lotId: number | undefined, productionItemIds: number[] }>();
  const { formatMessage } = useIntl();

  const { onSettledErrorHandler } = useApiErrorHandler();
  const queryData = useQuery<LotManagementData>(['lotManagementData'], async () =>
    (await axios.get<LotManagementData>('/api/lots/LotManagementData')).data,
  { onSettled: onSettledErrorHandler });
  const selectedLotId = watch('lotId');

  return (
    <Box>
      <Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Typography variant="h5">{formatMessage(m.lotsTitle)}</Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>{formatMessage(m.lotsSubtitle)}</Typography>
          </Box>
          <LotCreationButton key='create-lot' />
        </Box>
      </Box>
      <Card sx={{ width: '400px', bgcolor: 'background.paper', overflow: 'auto' }}>
        <List
          dense
          component="div"
          role="list"
          sx={{ overflow: 'auto', height: '600px', padding: 0 }}
        >
          {queryData.data && Object.values(queryData.data.lots).map((lot, index) => (
            <Box key={lot.id}>
              <LotGroupListItem
                lot={lot}
                isExpanded={selectedLotId === lot.id}
                onClick={() => setValue('lotId', selectedLotId === lot.id ? undefined : lot.id)}
              />
              <Divider key={`divider-${index}`} />
            </Box>
          ))}
        </List>
      </Card>

      <InformationMessage
        message={formatMessage(m.hiddenLotsMessage)}
        sx={{ width: '400px', marginTop: 1 }}
      />
    </Box>
  );
};