import React, { useState } from 'react';
import { MicrosoftLoginButton } from '../MicrosoftLoginButton';

export const MicrosoftAuthentication: React.FC = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  return (
    <form action="/api/account/sign-in" method="post" onSubmit={() => setIsLoggingIn(true)}>
      <input type="hidden" name="returnUrl" value="/" />
      <input type="hidden" name="provider" value="OpenIdConnect" />

      <MicrosoftLoginButton
        type="submit"
        variant="contained"
        loading={isLoggingIn}
        disabled={isLoggingIn}
        sx={{ width: '100%' }}
      />
    </form>
  );
};