import _ from 'lodash';
import { Reducer } from 'redux';
import { PresencesActionTypes, LOAD_USERS_PRESENCE_SUCCESS, UsersPresenceState } from './types';

const initialUsersState: UsersPresenceState = {
  byId: {},
  byCostCenterId: {},
  byWorkstationId: {},
  allIds: []
};

const usersPresenceReducer: Reducer<UsersPresenceState, PresencesActionTypes> = (state = initialUsersState, action) => {
  switch (action.type) {
  case LOAD_USERS_PRESENCE_SUCCESS: {
    const byId = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
    const allIds = action.payload.map(x => x.id);

    const byCostCenterId = Object.assign({}, ..._.chain(action.payload.filter(x => x.currentCostCenterId != null))
      .groupBy(x => x.currentCostCenterId)
      .map((presences, costCenterId) => ({ [Number(costCenterId)]: presences.map(x => x.id) }))
      .value());

    const byWorkstationId = Object.assign({}, ..._.chain(action.payload.filter(x => x.currentWorkstationId != null))
      .groupBy(x => x.currentWorkstationId)
      .map((presences, workstationId) => ({ [Number(workstationId)]: presences.map(x => x.id) }))
      .value());

    return {
      ...state,
      byId,
      byCostCenterId,
      byWorkstationId,
      allIds
    };
  }

  default:
    return state;
  }
};

export { usersPresenceReducer };
