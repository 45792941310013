import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { AccountType } from '../../..';
import { userRoleMessages } from '../../../messages';



export type UserRoleDropdownProps =
    & DropdownProps;

export const UserRoleDropdown: React.FC<UserRoleDropdownProps> = (props) => {
  const { formatMessage } = useIntl();
  const { ...dropdownProps } = props;

  const accountTypeOptions: DropdownItemProps[] = [
    { text: formatMessage(userRoleMessages.factoryWorker), value: AccountType.FactoryWorker },
    { text: formatMessage(userRoleMessages.factorySupervisor), value: AccountType.FactorySupervisor },
    { text: formatMessage(userRoleMessages.administrator), value: AccountType.Administrator }
  ];

  return (
    <Dropdown
      options={accountTypeOptions}
      {...dropdownProps}
    />
  );
};
