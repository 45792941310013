import _ from 'lodash';
import { RoadmapFieldsState, RoadmapActionTypes, RoadmapTemplatesState, RoadmapsState, LOAD_ALL_ROADMAP_FIELDS_SUCCESS, LOAD_ALL_ROADMAP_FIELDS_REQUEST, LOAD_ALL_ROADMAP_FIELDS_FAILURE, LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS, LOAD_ALL_ROADMAP_TEMPLATES_REQUEST, LOAD_ALL_ROADMAP_TEMPLATES_FAILURE } from './types';
import { Reducer, combineReducers } from 'redux';
import { AdministrativeRoadmapActionTypes, CREATE_ROADMAP_SUCCESS, UPDATE_ROADMAP_SUCCESS, DELETE_ROADMAP_SUCCESS, DELETE_ROADMAP_REQUEST, DELETE_ROADMAP_FAILURE } from '../../administration/roadmaps/types';

const initialRoadmapTemplatesState: RoadmapTemplatesState = {
  byId: {},
  allIds: [],
  isLoading: false
};

const roadmapTemplatesReducer: Reducer<RoadmapTemplatesState, RoadmapActionTypes | AdministrativeRoadmapActionTypes> = (state = initialRoadmapTemplatesState, action) => {
  switch (action.type) {
  case LOAD_ALL_ROADMAP_TEMPLATES_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }

  case LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS: {
    const byId = Object.assign({}, ...action.payload.templates.map(x => ({ [x.id]: x })));
    const allIds = action.payload.templates.map(x => x.id);

    return {
      ...state,
      byId,
      allIds,
      isLoading: false
    };
  }

  case LOAD_ALL_ROADMAP_TEMPLATES_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  case CREATE_ROADMAP_SUCCESS: {
    const byId = state.byId;
    if (action.payload.roadmap.isDefault) {
      Object.keys(byId).forEach(x => byId[Number(x)].isDefault = false);
    }

    return {
      ...state,
      byId: {
        ...byId,
        [action.payload.roadmap.id]: action.payload.roadmap
      },
      allIds: _.union(state.allIds, [action.payload.roadmap.id])
    };
  }

  case UPDATE_ROADMAP_SUCCESS: {
    const byId = state.byId;
    if (action.payload.roadmap.isDefault) {
      Object.keys(byId).forEach(x => byId[Number(x)].isDefault = false);
    }

    return {
      ...state,
      byId: {
        ...byId,
        [action.payload.roadmap.id]: action.payload.roadmap
      }
    };
  }

  case DELETE_ROADMAP_SUCCESS: {
    return {
      ...state,
      allIds: state.allIds.filter(x => x !== action.payload.roadmapId)
    };
  }

  default:
    return state;
  }
};

const initialRoadmapFieldsState: RoadmapFieldsState = {
  byId: {},
  allIds: [],
  isLoading: false
};

const roadmapFieldsReducer: Reducer<RoadmapFieldsState, RoadmapActionTypes> = (state = initialRoadmapFieldsState, action) => {
  switch (action.type) {
  case LOAD_ALL_ROADMAP_FIELDS_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }

  case LOAD_ALL_ROADMAP_FIELDS_SUCCESS: {
    const byId = Object.assign({}, ...action.payload.fields.map(x => ({ [x.id]: x })));
    const allIds = action.payload.fields.map(x => x.id);

    return {
      ...state,
      byId,
      allIds,
      isLoading: false
    };
  }

  case LOAD_ALL_ROADMAP_FIELDS_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  default:
    return state;
  }
};

export const roadmapsReducer = combineReducers<RoadmapsState>({
  templates: roadmapTemplatesReducer,
  fields: roadmapFieldsReducer
});