import React from 'react';
import { Label, Popup } from 'semantic-ui-react';
import { UserPresence } from '../../../modules/administration/presences/types';
import { UsersPresenceList } from '../UsersPresenceList';
import styles from './UserPresenceCoinGroup.module.css';

interface OwnProps {
    presences: UserPresence[];
    size?: number;
}

export type UserPresenceCoinGroupProps =
    & OwnProps;

export const UserPresenceCoinGroup: React.FC<UserPresenceCoinGroupProps> = (props) => {
  const presencesCount = props.presences.length < 100 ? props.presences.length : '99+';
  const trigger = (
    <div className={styles.coin}>
      <span className={styles.label}>{presencesCount}</span>
    </div>
  );

  return (
    <Popup trigger={trigger}>
      <UsersPresenceList
        presences={props.presences}
        size={props.size}
      />
    </Popup>
  );
};
