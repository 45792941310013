import React, { useState } from 'react';
import { TopBarMenuItem } from './TopBarMenuItem';
import styles from './TopBarItemButton.module.css';
import { useSelector } from 'react-redux';
import { isApplicationOffline } from '../../../modules/live-updates';
import { Confirm, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { commonMessages } from '../../../constants';

const m = defineMessages({
  confirmModalTitle: { id: 'ReloadApplicationButton.confirmModalTitle', defaultMessage: 'Are you sure?' },
  confirmModalDescription: { id: 'ReloadApplicationButton.confirmModalDescription', defaultMessage: 'No internet connection is currently detected on this device. Reloading this page while there is no internet will prevent you from accessing this application. Are you sure you want to force a reload of the current page?' },
  confirmButton: { id: 'ReloadApplicationButton.confirmButton', defaultMessage: 'Yes, force a reload' }
});

export const ReloadApplicationButton = () => {
  const { formatMessage } = useIntl();
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
  const isOffline = useSelector(isApplicationOffline);

  const handleClick = () => {
    if (!isOffline) {
      reloadPage();
    } else {
      setIsConfirmModalShown(true);
    }
  };

  const reloadPage = () => {
    setIsConfirmModalShown(false);
    window.location.reload();
  };

  return (
    <>
      <TopBarMenuItem
        className={styles.Button}
        icon="redo"
        onClick={handleClick}
      />

      <Confirm
        header={formatMessage(m.confirmModalTitle)}
        open={isConfirmModalShown}
        onConfirm={reloadPage}
        onCancel={() => setIsConfirmModalShown(false)}
        onClose={() => setIsConfirmModalShown(false)}
        content={formatMessage(m.confirmModalDescription)}
        cancelButton={formatMessage(commonMessages.cancel)}
        confirmButton={<Button negative content={formatMessage(m.confirmButton)} />}
      />
    </>
  );
};
