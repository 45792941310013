import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Header } from 'semantic-ui-react';
import { LoadingContainer, LoadingDimmer } from '../../../../../../components';
import { BulkImportJobProgress } from '../../../models';
import { NoRunningJobs } from './NoRunningJobs';
import { RunningJobProgress } from './RunningJobProgress';

interface OwnProps {
    jobs: BulkImportJobProgress[];
    isLoading?: boolean;
    onViewDetails: (job: BulkImportJobProgress) => void;
}

export type RunningBulkImportsProps =
    & OwnProps;

const m = defineMessages({
  title: { id: 'RunningBulkImports.title', defaultMessage: 'Current bulk imports' }
});

export const RunningBulkImports: React.FC<RunningBulkImportsProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Header content={formatMessage(m.title)} />
      <LoadingContainer>
        <LoadingDimmer active={props.isLoading} />
        {props.jobs.map(job => <RunningJobProgress key={job.jobId} job={job} onViewDetails={() => props.onViewDetails(job)} />)}
        {props.jobs.length === 0 &&
                    <NoRunningJobs />
        }
      </LoadingContainer>
    </>
  );
};
