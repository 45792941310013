import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Header, Icon } from 'semantic-ui-react';

const m = defineMessages({
  loadingMessage: { id: 'PdfLoading.loadingMessage', defaultMessage: 'Preparing document...' }
});

export const PdfLoading = () => {
  const { formatMessage } = useIntl();

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header icon inverted>
        <Icon name="circle notch" loading />
        {formatMessage(m.loadingMessage)}
      </Header>
    </div>
  );
};
