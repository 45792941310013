import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LoadingDimmer, NoContentPlaceholder, RaisedTile } from '../../../../components';
import { ApplicationState } from '../../../../store';
import * as LayoutActions from '../../../../store/layout/actions';
import { getCurrentUser } from '../../../account/selectors';
import { getCurrentDevice } from '../../../administration/devices/selectors';
import { OfflineBanner } from '../../../live-updates/components';
import { isSystemLoading } from '../../../system';
import { getLocalUsers } from '../../collaboration/selectors';
import { getAllOperations, getLotById, getOperationById, getProductionItemById } from '../../selectors';
import * as WorkstationActions from '../../workstation/actions';
import { getAllManufacturedItemOperationsByItemId, getProductionItemRoadmapItems, getRoadmapFields } from '../../workstation/selectors';
import { RegisterUIProgressEventPayload, WorkstationProductionProgressUIEventType } from '../../workstation/types';
import * as RoadmapActions from '../actions';
import { ProductionOrderRoadmapHeader } from './components/RoadmapHeader/ProductionOrderRoadmapHeader';
import { RoadmapExecutionTable } from './components/RoadmapExecutionTable';
import * as React from 'react';
import { getWorkstations } from 'modules/administration';

interface RouteProps {
  workstationId: string;
  operationId: string;
  productionOrderId: string;
}

const m = defineMessages({
  title: { id: 'ProductionOrderExecutionPage.title', defaultMessage: 'Roadmap' },
  noContentTitle: { id: 'ProductionOrderExecutionPage.noContentTitle', defaultMessage: 'There are no tasks required for this operation' }
});

export const ProductionOrderRoadmapExecutionPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const routeParams = useParams<RouteProps>();

  const workstationId = Number(routeParams.workstationId);
  const operationId = Number(routeParams.operationId);
  const productionOrderId = Number(routeParams.productionOrderId);

  const currentDevice = useSelector(getCurrentDevice);
  const roadmapItems = useSelector((state: ApplicationState) => getProductionItemRoadmapItems(state, productionOrderId, operationId));
  const roadmapFields = useSelector((state: ApplicationState) => getRoadmapFields(state, operationId));
  const operation = useSelector((state: ApplicationState) => getOperationById(state, operationId));
  const productionOrder = useSelector((state: ApplicationState) => getProductionItemById(state, productionOrderId));

  const operations = useSelector((state: ApplicationState) => getAllOperations(state)).filter(operation => operation.isDisabled == false);
  const workStations = useSelector((state: ApplicationState) => getWorkstations(state));
  const manufacturedOperationItems = useSelector((state: ApplicationState) => getAllManufacturedItemOperationsByItemId(state, productionOrder?.id));

  const lot = useSelector((state: ApplicationState) => getLotById(state, productionOrder?.lotId || -1));

  const isLoading = useSelector(isSystemLoading);

  const hasRoadmapItems = roadmapItems.length > 0;

  const currentUser = useSelector(getCurrentUser);
  let localUsers = useSelector(getLocalUsers);
  if (localUsers.length > 0 && currentUser != null) {
    // We include the current user when other users are collaborating on the device.
    localUsers = [currentUser, ...localUsers];
  }

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.title) }));
    dispatch(RoadmapActions.loadRoadmapItemsForOperation(operationId, undefined, productionOrderId));
    dispatch(WorkstationActions.registerUIProgressEvent(
      buildEventPayload(WorkstationProductionProgressUIEventType.LotRoadmapScreenOpened, []))
    );

    return () => {
      dispatch(WorkstationActions.registerUIProgressEvent(
        buildEventPayload(WorkstationProductionProgressUIEventType.LotRoadmapScreenClosed, [])
      ));
    };
  }, [dispatch, routeParams]);

  const buildEventPayload = (eventType: WorkstationProductionProgressUIEventType, manufacturedItemOperationIds: number[]): RegisterUIProgressEventPayload => ({
    eventType,
    manufacturedItemOperationIds,
    deviceId: currentDevice?.id,
    workstationId: !isNaN(workstationId) ? workstationId : undefined,
    operationId,
    lotId: productionOrder?.lotId,
    productionItemId: productionOrder?.id || undefined,
    userIds: localUsers.length > 0 ? localUsers.map(x => x.id) : undefined
  });

  return (
    <>
      <OfflineBanner />
      <RaisedTile>
        <LoadingDimmer active={isLoading} />
        <ProductionOrderRoadmapHeader
          workstations={workStations}
          operations={operations}
          manufacturedOperationItems={manufacturedOperationItems}
          operation={operation}
          lot={lot}
          productionOrder={productionOrder}
        />

        {hasRoadmapItems &&
          <RoadmapExecutionTable
            operationId={operationId}
            productionOrderId={productionOrderId}
            roadmapItems={roadmapItems}
            roadmapFields={roadmapFields}
          />
        }

        {!hasRoadmapItems &&
          <NoContentPlaceholder
            textAlign="center"
            icon="tasks"
            title={formatMessage(m.noContentTitle)}
          />
        }
      </RaisedTile>
    </>
  );
};
