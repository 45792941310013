import { User } from '../../models/account';
import {
  LOGIN_WEBAUTH,
  LOGIN_WEBAUTH_FAILURE,
  LOGIN_WEBAUTH_SUCCESS,
  LoginWebAuthAction,
  LoginWebAuthFailureAction,
  LoginWebAuthSuccessAction,
  LOGOUT,
  LogoutAction,
  LoadProfileAction,
  LOAD_PROFILE,
  LoadProfileSuccessAction,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_FAILURE,
  LoadProfileFailureAction,
  WebAuthUserRequiredAction,
  WEBAUTH_USER_REQUIRED,
  WebAuthUserProvidedAction,
  WEBAUTH_USER_PROVIDED,
  WebAuthUserRejectedAction,
  WEBAUTH_USER_REJECTED,
  UpdateProfileAction,
  UPDATE_PROFILE,
  UserProfile,
  UpdateProfileSuccessAction,
  UPDATE_PROFILE_SUCCESS,
  UpdateProfileFailureAction,
  UPdATE_PROFILE_FAILURE,
} from './types';

export function loginWebAuth (user?: User): LoginWebAuthAction {
  return {
    type: LOGIN_WEBAUTH,
    payload: { user }
  };
}

export function loginWebAuthSuccess (user: User): LoginWebAuthSuccessAction {
  return {
    type: LOGIN_WEBAUTH_SUCCESS,
    payload: user
  };
}

export function loginWebAuthFailure (error: Error): LoginWebAuthFailureAction {
  return {
    type: LOGIN_WEBAUTH_FAILURE,
    payload: { error }
  };
}

export function logout (): LogoutAction {
  return {
    type: LOGOUT,
    payload: {}
  };
}

export function loadProfile (): LoadProfileAction {
  return {
    type: LOAD_PROFILE,
    payload: {}
  };
}

export function loadProfileSuccess (user: User): LoadProfileSuccessAction {
  return {
    type: LOAD_PROFILE_SUCCESS,
    payload: user
  };
}

export function loadProfileFailure (error: Error): LoadProfileFailureAction {
  return {
    type: LOAD_PROFILE_FAILURE,
    payload: { error }
  };
}

export function updateProfile(profile: UserProfile): UpdateProfileAction {
  return {
    type: UPDATE_PROFILE,
    payload: { profile }
  };
}

export function updateProfileSuccess(user: User): UpdateProfileSuccessAction {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { user }
  };
}

export function updateProfileFailure(error: Error): UpdateProfileFailureAction {
  return {
    type: UPdATE_PROFILE_FAILURE,
    payload: { error }
  };
}

export function webAuthUserRequired(isCollaborativeUser?: boolean): WebAuthUserRequiredAction {
  return {
    type: WEBAUTH_USER_REQUIRED,
    payload: { isCollaborativeUser }
  };
}

export function webAuthUserProvided (user: User, isCollaborativeUser?: boolean): WebAuthUserProvidedAction {
  return {
    type: WEBAUTH_USER_PROVIDED,
    payload: { user, isCollaborativeUser }
  };
}

export function webAuthUserRejected (): WebAuthUserRejectedAction {
  return {
    type: WEBAUTH_USER_REJECTED,
    payload: {}
  };
}
