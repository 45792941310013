import React from 'react';
import { LowPriority, Delete } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ActionMenu } from 'components/ActionMenu';
import { defineMessages, useIntl } from 'react-intl';

interface OwnProps {
  onPrioritizeFirst: () => void;
  onPrioritizeLast: () => void;
  onUnprioritize: () => void;
}

export type PrioritizationActionMenuProps =
  & OwnProps;

const HighPriority: React.FC = () => (
  <LowPriority sx={{ transform: 'scaleY(-1)' }} />
);

export const PrioritizationActionMenu: React.FC<PrioritizationActionMenuProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <ActionMenu>
      <MenuItem onClick={props.onPrioritizeFirst}>
        <ListItemIcon><HighPriority /></ListItemIcon>
        <ListItemText>{formatMessage({ id: 'Prioritize first' })}</ListItemText>
      </MenuItem>
      <MenuItem onClick={props.onPrioritizeLast}>
        <ListItemIcon><LowPriority sx={{ transform: 'scaleX(-1)' }} /></ListItemIcon>
        <ListItemText>{formatMessage({ id: 'Prioritize last' })}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={props.onUnprioritize}>
        <ListItemIcon><Delete color="error" /></ListItemIcon>
        <ListItemText sx={{ color: '#d32f2f' }}>{formatMessage({ id: 'Unprioritize' })}</ListItemText>
      </MenuItem>
    </ActionMenu>
  );
};