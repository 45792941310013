import React, { useState } from 'react';
import { Header, InputOnChangeData } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { ClearableInput } from '../../../../../components';

interface OwnProps {
    totalCount: number;
    searchCount: number;
    onSearchChange: (searchTerm: string) => void;
}

export type OperationsSearchControlProps =
    & OwnProps;

const m = defineMessages({
  totalCount: { id: 'OperationsSearchControl.totalCount', defaultMessage: '{total, plural, =0 {No operations} one {{total} operation} other {{total} operations}}' },
  resultsCount: { id: 'OperationsSearchControl.resultsCount', defaultMessage: '{total, plural, =0 {No results found} one {{total} result found} other {{total} results found}}' },
  searchPlaceholder: { id: 'OperationsSearchControl.searchPlaceholder', defaultMessage: 'Search for an operation' }
});

export const OperationsSearchControl: React.FC<OperationsSearchControlProps> = (props) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const clearSearch = () => setSearchTerm('');
  const changeSearchTerm = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSearchTerm(data.value);
    props.onSearchChange(data.value);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1em' }}>
        <Header size="small" style={{ margin: 0, flexGrow: 1 }}>
          <Header.Content>{formatMessage(m.totalCount, { total: props.totalCount })}</Header.Content>
          <Header.Subheader>
            {searchTerm !== ''
              ? <React.Fragment>{formatMessage(m.resultsCount, { total: props.searchCount })}</React.Fragment>
              : <React.Fragment>&nbsp;</React.Fragment>
            }
          </Header.Subheader>
        </Header>
      </div>

      <ClearableInput
        fluid
        onChange={changeSearchTerm}
        onClear={clearSearch}
        placeholder={formatMessage(m.searchPlaceholder)}
        value={searchTerm}
        style={{ marginBottom: '1em' }}
      />
    </>
  );
};
