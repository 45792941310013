import {
  HIDE_TOAST,
  HideToastAction,
  PIN_TOAST,
  PinToastAction,
  SHOW_TOAST,
  ShowToastAction,
  Toast,
  UNPIN_TOAST,
  UnpinToastAction
} from './types';

export function showToast (toast: Toast): ShowToastAction {
  return {
    type: SHOW_TOAST,
    payload: toast
  };
}

export function hideToast (toast: Toast): HideToastAction {
  return {
    type: HIDE_TOAST,
    payload: toast
  };
}

export function pinToast (toast: Toast): PinToastAction {
  return {
    type: PIN_TOAST,
    payload: toast
  };
}

export function unpinToast (toast: Toast): UnpinToastAction {
  return {
    type: UNPIN_TOAST,
    payload: toast
  };
}
