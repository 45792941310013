import { User } from '../../../models';
import { get, httpDelete, post } from '../../../utils/api';
import { decodeStringToArrayBuffer } from '../../../utils/WebAuthHelper';
import { AuthenticatorAttestationRawResponse } from './types';

const endpoint = '/api/users';

export async function getById (userId: string): Promise<User> {
  return get<User>(`${endpoint}/${userId}`);
}

export async function create (user: User): Promise<User> {
  return post(endpoint, user);
}

export async function updateUser (user: User): Promise<User> {
  return post(`${endpoint}/${user.id}`, user);
}

export async function deleteUser (userId: string): Promise<void> {
  return httpDelete<void>(`${endpoint}/${userId}`);
}

export async function getUsersWithAuthenticators (): Promise<User[]> {
  return get<User[]>(`${endpoint}/with-authenticators`);
}

export async function makeCredentialOptions (userId: string): Promise<PublicKeyCredentialCreationOptions> {
  const response = await get<PublicKeyCredentialCreationOptions>(endpoint + '/makeCredentialOptions?userId=' + userId);

  // some strings received need to be converted to array buffers
  response.challenge = decodeStringToArrayBuffer(response.challenge as any as string);
  response.user.id = decodeStringToArrayBuffer(response.user.id as any as string);
  if (response.excludeCredentials) {
    response.excludeCredentials.forEach(cred => {
      cred.id = decodeStringToArrayBuffer(cred.id as any as string);
    });
  }

  return response;
}

export async function makeCredential (credentials: AuthenticatorAttestationRawResponse): Promise<any> {
  return post<any>(endpoint + '/makeCredential', credentials);
}

export async function deleteCredentials (userId: string): Promise<any> {
  return post<any>(endpoint + '/deleteCredentials', userId);
}
