import { getAllCostCenters } from 'modules/production';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Image, Menu, Sidebar } from 'semantic-ui-react';
import { getCurrentLocale, isNavigationMenuVisible } from 'store/layout';
import * as LayoutActions from 'store/layout/actions';
import logo from '../../../assets/images/logo.svg';
import { CostCenter } from '../../../modules';
import { AccountType } from '../../../modules/administration/users';
import { ApplicationState } from '../../../store';
import { NavigationMenuItem } from './NavigationMenuItem';
import { NavigationMenuSection } from './NavigationMenuSection';
import styles from './NavigationSidebar.module.css';


export const NavigationSidebar = () => {
  const isNavigationMenuVisibleState = useSelector<ApplicationState, boolean>(state => isNavigationMenuVisible(state));
  const dispatch = useDispatch();
  const costCenters = useSelector<ApplicationState, CostCenter[]>(state => getAllCostCenters(state));
  const { formatMessage} = useIntl();
  const administratorsOnly: AccountType[] = [AccountType.Administrator];
  const atLeastSupervisor: AccountType[] = [AccountType.Administrator, AccountType.FactorySupervisor];
  const anyOne: AccountType[] = [AccountType.Administrator, AccountType.FactorySupervisor, AccountType.FactoryWorker];
  const locale = useSelector(getCurrentLocale);

  const m = defineMessages({
    dashboardMenuItem: { id: 'PageLayout.dashboardMenuItem', defaultMessage: 'Dashboard' },
    lotsManagementMenuItem: { id: 'PageLayout.lotManagementMenuItem', defaultMessage: 'Lots Management' },
    ordersSchedulingMenuItem: { id: 'PageLayout.ordersSchedulingMenuItem', defaultMessage: 'Production Planning' },
    productionHistory: { id: 'PageLayout.productionHistoryMenuItem', defaultMessage: 'Production History' },
    ordersPrioritizationMenuItem: { id: 'PageLayout.ordersPrioritizationMenuItem', defaultMessage: 'Orders Prioritization' },
    costCentersMenuSection: { id: 'PageLayout.costCenterMenuSection', defaultMessage: 'Departments' },
    verificationMenuItem: { id: 'PageLayout.verificationMenuItem', defaultMessage: 'Verification' },
    administrationMenuSection: { id: 'PageLayout.administrationMenuSection', defaultMessage: 'Administration' },
    devicesMenuItem: { id: 'PageLayout.devicesMenuItem', defaultMessage: 'Devices' },
    usersMenuItem: { id: 'PageLayout.usersMenuItem', defaultMessage: 'Users' },
    costCentersMenuItem: { id: 'PageLayout.costCentersMenuItem', defaultMessage: 'Cost centers' },
    operationsMenuItem: { id: 'PageLayout.operationsMenuItem', defaultMessage: 'Operations' },
    workInstructionsMenuItem: { id: 'PageLayout.workInstructionsMenuItem', defaultMessage: 'Work instructions' },
    workstationsMenuItem: { id: 'PageLayout.workstationsMenuItem', defaultMessage: 'Workstations' },
    roadmapsMenuItem: { id: 'PageLayout.roadmapsMenuItem', defaultMessage: 'Roadmaps' },
    workInstructionsSection: { id: 'PageLayout.workInstructionsSection', defaultMessage: 'Work instructions' },
    manageInstructionsMenuItem: { id: 'PageLayout.manageInstructionsMenuItem', defaultMessage: 'Manage instructions' },
    importInstructionsMenuItem: { id: 'PageLayout.importInstructionsMenuItem', defaultMessage: 'Bulk import' },
    licensesMenuItem: { id: 'PageLayout.licensesMenuItem', defaultMessage: 'Licenses' }
  });

  return (
    <Sidebar className={styles.mainSidebar} as={Menu} vertical borderless
      size="large"
      animation="overlay"
      visible={isNavigationMenuVisibleState}
      onHide={() => dispatch(LayoutActions.hideNavigationMenu())}
    >
      <Accordion>
        <Menu.Item>
          <Image src={logo} alt="Steps" size="small" centered />
        </Menu.Item>
        <NavigationMenuItem rolesRequired={anyOne} title={formatMessage(m.dashboardMenuItem)} icon="home" to="/dashboard" />
        <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.lotsManagementMenuItem)} icon="th" to="/lots-management" />
        <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.ordersSchedulingMenuItem)} icon="calendar" to="/orders-scheduling" />
        <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.productionHistory)} icon="history" to="/production-history" />
        <NavigationMenuSection title={formatMessage(m.costCentersMenuSection)} icon="factory">
          {costCenters.map(x => <NavigationMenuItem rolesRequired={atLeastSupervisor} key={x.id} title={x.name} to={`/cost-centers/${x.id}/overview`} />)}
        </NavigationMenuSection>

        <NavigationMenuSection title={formatMessage(m.workInstructionsSection)} icon="file pdf" isExpandedByDefault={false}>
          <NavigationMenuItem rolesRequired={atLeastSupervisor} title={formatMessage(m.manageInstructionsMenuItem)} to="/administration/work-instructions/manage" />
          <NavigationMenuItem rolesRequired={atLeastSupervisor} title={formatMessage(m.importInstructionsMenuItem)} to="/administration/work-instructions/bulk-import" />
        </NavigationMenuSection>

        <NavigationMenuSection title={formatMessage(m.administrationMenuSection)} icon="setting" isExpandedByDefault={false}>
          <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.costCentersMenuItem)} to="/administration/cost-centers" />
          <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.roadmapsMenuItem)} to="/administration/roadmaps" />
          <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.operationsMenuItem)} to="/administration/operations" />
          <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.workstationsMenuItem)} to="/administration/workstations" />
          <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.devicesMenuItem)} to="/administration/devices" />
          <NavigationMenuItem rolesRequired={administratorsOnly} title={formatMessage(m.usersMenuItem)} to="/administration/users" />
          <NavigationMenuItem external rolesRequired={administratorsOnly} title={formatMessage(m.licensesMenuItem)} to={`/api/license/portal-url?locale=${locale}&returnUrl=${window.location.href}`} />
        </NavigationMenuSection>
      </Accordion>
    </Sidebar>
  );
};
