import React from 'react';
import moment from 'moment';
import { Icon, Table } from 'semantic-ui-react';
import { BulkImportJobProgress } from '../../../models';

interface OwnProps {
    job: BulkImportJobProgress;
    onClick?: () => void;
}

export type CompletedBulkImportRowProps =
    & OwnProps;

export const CompletedBulkImportRow: React.FC<CompletedBulkImportRowProps> = (props) => {
  const jobIcon = props.job.errors.length > 0
    ? <Icon name="warning sign" color="yellow" />
    : <Icon name="check circle" color="green" />;

  const completedOn = props.job.completedOn != null ? moment.utc(props.job.completedOn).local() : undefined;
  const startedOn = moment.utc(props.job.startedOn).local();
  const jobDuration = completedOn != null ? moment.duration(completedOn?.diff(startedOn)) : undefined;

  return (
    <Table.Row onClick={props.onClick} style={{ cursor: 'pointer' }}>
      <Table.Cell>{jobIcon}</Table.Cell>
      <Table.Cell>{startedOn.fromNow()}</Table.Cell>
      <Table.Cell>{jobDuration != null && jobDuration.humanize()}</Table.Cell>
      <Table.Cell>{props.job.errors.length}</Table.Cell>
    </Table.Row>
  );
};
