import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { areFieldsLoading, getAllFields } from '../../../../../production/roadmaps/selectors';
import { RoadmapField } from '../../../../../production/roadmaps/types';
import { RoadmapFieldDescription } from './RoadmapFieldDescription';

interface OwnProps {
    onFieldSelected: (field: RoadmapField) => void;
}

export type RoadmapFieldsDropdownProps =
    & DropdownProps
    & OwnProps;

export const RoadmapFieldsDropdown: React.FC<RoadmapFieldsDropdownProps> = (props) => {
  const { onFieldSelected, ...dropdownProps } = props;

  const loading = useSelector(areFieldsLoading);
  const allFields = useSelector(getAllFields);

  const allFieldsOptions: DropdownItemProps[] = allFields.map(field => ({
    value: field.id,
    text: field.name,
    content: (
      <RoadmapFieldDescription field={field} />
    )
  }));

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const selectedField = allFields.find(field => field.id === data.value);

    if (selectedField != null) {
      props.onFieldSelected(selectedField);
    }

    event.preventDefault();
  };

  return (
    <Dropdown
      loading={loading}
      options={allFieldsOptions}
      onChange={handleChange}
      {...dropdownProps}
    />
  );
};
