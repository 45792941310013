import { ExpandLess, ExpandMore } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Box, Button, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { LotManagementData, LotManagementLot } from '../../types';
import { AddOrUpdateLotDrawer } from './AddOrUpdateLotDrawer';
import { LotManagementForm } from './OrdersTransferList';
import { ProductionItemListItemText } from './ProductionItemListItemText';

export interface LotGroupListItemProps {
  lot: LotManagementLot;
  onClick: () => void;
  isExpanded: boolean;
}

const m = defineMessages({
  emptyLot: { id: 'LotGroupListItem.emptyLot', defaultMessage: 'Lot is empty.' },
});

export const LotGroupListItem = (props: LotGroupListItemProps) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<LotManagementForm>();
  const { onSettledErrorHandler } = useApiErrorHandler();
  const [isEditingLot, setIsEditingLot] = useState(false);
  const queryClient = useQueryClient();

  const queryData = useQuery<LotManagementData>(['lotManagementData'], async () =>
    (await axios.get<LotManagementData>('/api/lots/LotManagementData')).data,
  { onSettled: onSettledErrorHandler });

  const removeItemFromLotMutation = useMutation(async (data: { lotId: number, productionItemId: number }) =>
    await axios.delete(`/api/lots/${data.lotId}/items/${data.productionItemId}`)
  , {
    onSettled: onSettledErrorHandler,
    onSuccess: (data, variables) => {
      const queryData = queryClient.getQueryData<LotManagementData>(['lotManagementData']);
      if (queryData) {
        queryClient.setQueryData(['lotManagementData'], {
          ...queryData,
          productionItems: {
            ...queryData.productionItems,
            [variables.productionItemId]: { ...queryData.productionItems[variables.productionItemId], lotId: undefined }
          }
        });
      }
    }
  });

  const productionItems = useMemo(() =>
    queryData.data ?
      Object.values(queryData.data.productionItems).filter(p => p.lotId == props.lot.id)
      : []
  , [queryData]);

  const handleEdit = () => {
    setValue('lotId', props.lot.id);
    setIsEditingLot(true);
  };

  const handleClose = () => {
    setIsEditingLot(false);
  };

  const handleRemove = (productionItemId: number) => {
    removeItemFromLotMutation.mutate({
      lotId: props.lot.id,
      productionItemId: productionItemId
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <ListItemButton onClick={props.onClick} selected={props.isExpanded}>
          <ListItemText primary={props.lot.name} />
          {props.isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Box>

      <Divider />
      <Collapse in={props.isExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ bgcolor: (theme) => theme.palette.action.selected }}
        >
          {productionItems.length === 0 && (
            <ListItem role="listitem" selected>
              <Typography variant="body2">
                {formatMessage(m.emptyLot)}
              </Typography>
            </ListItem>
          )}
          {queryData.data &&
            productionItems.map((value) => {
              const salesOrder = queryData.data.salesOrders[value.salesOrderId];
              const item = queryData.data.items[value.itemId];
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleRemove(value.id);
                    }}
                    sx={{ padding: '10px 13px 10px 13px', minWidth: '0px' }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                  <ProductionItemListItemText
                    item={item}
                    productionItem={value}
                    salesOrder={salesOrder}
                  />
                </ListItem>
              );
            })}
        </List>
        <AddOrUpdateLotDrawer
          lot={props.lot}
          productionItems={productionItems}
          onClose={handleClose}
          isOpen={isEditingLot}
        />
      </Collapse>
    </>
  );
};