import { LoadRequestAction, LOAD_PRODUCTION_SCHEDULE_REQUEST, LOAD_PRODUCTION_SCHEDULE_SUCCESS, LoadFailureAction, LOAD_PRODUCTION_SCHEDULE_FAILURE, LoadSuccessAction, ProductionScheduleEntities } from './types';

export function load (forDate: Date): LoadRequestAction {
  return {
    type: LOAD_PRODUCTION_SCHEDULE_REQUEST,
    payload: { forDate }
  };
}

export function loadSuccess (forDate: Date, entities: ProductionScheduleEntities): LoadSuccessAction {
  return {
    type: LOAD_PRODUCTION_SCHEDULE_SUCCESS,
    payload: { forDate, entities }
  };
}

export function loadFailure (forDate: Date, error: Error): LoadFailureAction {
  return {
    type: LOAD_PRODUCTION_SCHEDULE_FAILURE,
    payload: { forDate, error }
  };
}
