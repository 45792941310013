import React from 'react';
import { PageContentHeader } from '../../../../../../components';
import { ManufacturedItemOperation, Operation } from '../../../../types';
import { useIntl, defineMessages } from 'react-intl';
import { Lot } from '../../../../lots/types';
import { Workstation } from 'modules/administration';
import { LotRoadmapMiddleHeader } from './LotRoadmapMiddleHeader';

interface OwnProps {
  lot: Lot | undefined;
  operation: Operation | undefined;
  operations: Operation[];
  workstations: Workstation[];
  manufacturedOperationItems: ManufacturedItemOperation[];
}

export type LotRoadmapHeaderProps =
  & OwnProps;

const m = defineMessages({
  lotTitle: { id: 'LotRoadmapHeader.lotTitle', defaultMessage: 'Lot: {name}' },
});

export const LotRoadmapHeader: React.FC<LotRoadmapHeaderProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <PageContentHeader
      leftHeader={formatMessage(m.lotTitle, { name: props.lot?.name || '' })}
      middleHeader={<LotRoadmapMiddleHeader {...props} />}
    />
  );
};
