import React, { useState } from 'react';
import { Modal, ModalProps, Form, Button } from 'semantic-ui-react';
import { FileUploadList, FileUpload, FileUploadButton } from '../../../../../components';
import { useIntl, defineMessages } from 'react-intl';
import { commonMessages } from '../../../../../constants';
import { WorkInstructionBulkImport } from '../../types';

interface OwnProps {
    importing?: boolean;
    onImport: (importParameters: WorkInstructionBulkImport) => void;
    onCancel: () => void;
}

export type ImportModalProps =
    & OwnProps
    & ModalProps;

const m = defineMessages({
  title: { id: 'ImportModal.title', defaultMessage: 'Bulk import of the work instructions' },
  importParametersLabel: { id: 'ImportModal.importParametersLabel', defaultMessage: 'Import parameters' },
  importParametersDescription: { id: 'ImportModal.importParametersDescription', defaultMessage: 'Choose the CSV file that contains the import parameters. To configure the parameters, you can use the following file as a template.' },
  uploadCsvButton: { id: 'ImportModal.uploadCsvButton', defaultMessage: 'Upload' },
  uploadPdfLabel: { id: 'ImportModal.uploadPdfLabel', defaultMessage: 'Work instructions' },
  uploadPdfTitle: { id: 'ImportModal.uploadPdfTitle', defaultMessage: 'Select the PDF documents containing the work instructions.' },
  uploadPdfButton: { id: 'ImportModal.uploadPdfButton', defaultMessage: 'Upload' },
  confirmButton: { id: 'ImportModal.confirmButton', defaultMessage: 'Confirm' },
  filesCount: { id: 'ImportModal.filesCount', defaultMessage: '{count, plural, =0 {No file to upload} one {1 file to upload} other {{count} files to upload}}' }
});

export const ImportModal: React.FC<ImportModalProps> = (props) => {
  const { importing, onImport, onCancel, ...modalProps } = props;
  const { formatMessage } = useIntl();
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [pdfFiles, setPdfFiles] = useState<File[]>([]);

  const uploadCsv = (csv: FileList) => setCsvFile(Array.from(csv)[0]);
  const uploadPdfs = (pdfs: FileList) => setPdfFiles(Array.from(pdfs));
  const removePdfFile = (file: File) => setPdfFiles(pdfFiles.filter(x => x !== file));

  const confirmImport = () => {
    if (csvFile != null && pdfFiles.length > 0) {
      props.onImport({
        parametersFileName: csvFile?.name,
        documents: [csvFile, ...pdfFiles]
      });

      closeModal();
    }
  };

  const closeModal = () => {
    setCsvFile(undefined);
    setPdfFiles([]);

    props.onCancel();
  };

  return (
    <Modal size="tiny" onClose={closeModal} {...modalProps}>
      <Modal.Header>{formatMessage(m.title)}</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Field required>
            <label>{formatMessage(m.importParametersLabel)}</label>
            {csvFile == null &&
                            <div style={{ display: 'flex' }}>
                              <p style={{ paddingRight: '10px' }}>
                                {formatMessage(m.importParametersDescription)}
                              </p>
                              <FileUploadButton onUpload={uploadCsv} acceptedFileTypes=".csv" content={formatMessage(m.uploadCsvButton)} />
                            </div>
            }
            {csvFile != null &&
                            <FileUploadList files={[csvFile]} onDelete={() => setCsvFile(undefined)} />
            }
          </Form.Field>

          <Form.Field required>
            <label>{formatMessage(m.uploadPdfLabel)}</label>
            {pdfFiles.length <= 0 &&
                            <FileUpload
                              icon="file pdf outline"
                              acceptedFileTypes="application/pdf"
                              multiple={true}
                              title={formatMessage(m.uploadPdfTitle)}
                              buttonText={formatMessage(m.uploadPdfButton)}
                              onUpload={uploadPdfs}
                            />
            }
            {pdfFiles.length > 0 &&
                            <>
                              <span>{formatMessage(m.filesCount, { count: pdfFiles.length })}</span>
                              <FileUploadList
                                disabled={props.uploading}
                                files={pdfFiles}
                                onDelete={removePdfFile}
                              />
                            </>
            }
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
        <Button loading={props.importing} disabled={props.importing} primary content={formatMessage(m.confirmButton)} onClick={confirmImport} />
      </Modal.Actions>
    </Modal>
  );
};
