import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { Workstation, getCurrentWorkstations } from '../../../administration';
import { Grid } from 'semantic-ui-react'; import { WorkstationButton } from './components/WorkstationButton';
import { RouteComponentProps, Redirect } from 'react-router';

interface StoreProps {
    workstations: Workstation[];
}

export type WorkstationSelectionPageProps =
    & StoreProps
    & RouteComponentProps
    & WrappedComponentProps;

class WorkstationSelectionPage extends React.Component<WorkstationSelectionPageProps> {
  public render () {
    const hasSingleWorkstation = this.props.workstations.length === 1;

    if (hasSingleWorkstation) {
      return (
        <Redirect to={`/workstations/${this.props.workstations[0].id}/operations`} />
      );
    }

    return (
      <Grid doubling padded>
        {this.props.workstations.map(this.renderWorkstationButton)}
      </Grid>
    );
  }

  private renderWorkstationButton = (workstation: Workstation) => {
    return (
      <Grid.Column key={workstation.id} textAlign="center" mobile={8} tablet={4} computer={3} largeScreen={3} widescreen={2}>
        <WorkstationButton
          workstation={workstation}
          onClick={() => this.handleWorkstationClick(workstation)}
        />
      </Grid.Column>
    );
  };

  private handleWorkstationClick = (workstation: Workstation) => {
    this.props.history.push(`/workstations/${workstation.id}/operations`);
  };
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    workstations: getCurrentWorkstations(state)
  };
};

const mapDispatchToProps = () => {
  return {};
};

const intlComponent = injectIntl(WorkstationSelectionPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as WorkstationSelectionPage };
