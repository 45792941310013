import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Operation } from '../../../..';
import { OperationButton } from './OperationButton';

interface OwnProps {
    operations: Operation[];
    onSelected: (operation: Operation) => void;
}

export type OperationSelectionListProps =
    & OwnProps;

export const OperationSelectionList: React.FC<OperationSelectionListProps> = (props) => {
  return (
    <Grid doubling padded>
      {props.operations.map(operation => (
        <Grid.Column key={operation.id} textAlign="center" mobile={8} tablet={4} computer={3} largeScreen={3} widescreen={2}>
          <OperationButton
            operation={operation}
            onClick={() => props.onSelected(operation)}
          />
        </Grid.Column>
      ))}
    </Grid>
  );
};
