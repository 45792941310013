import { fork, all, call, takeLatest, put } from 'redux-saga/effects';
import * as api from './api';
import * as actions from './actions';
import { CREATE_ROADMAP_REQUEST, CreateRoadmapRequestAction, UPDATE_ROADMAP_REQUEST, UpdateRoadmapRequestAction, DELETE_ROADMAP_REQUEST, DeleteRoadmapRequestAction } from './types';
import { Roadmap } from '../../production/roadmaps/types';

function * watchCreateRoadmap () { yield takeLatest(CREATE_ROADMAP_REQUEST, handleCreateRoadmap); }
function * handleCreateRoadmap (action: CreateRoadmapRequestAction) {
  try {
    const result: Roadmap = yield call(api.createRoadmap, action.payload.roadmap);
    yield put(actions.createRoadmapSuccess(result));
  } catch (ex) {
    yield put(actions.createRoadmapFailure(ex as Error));
  }
}

function * watchUpdateRoadmap () { yield takeLatest(UPDATE_ROADMAP_REQUEST, handleUpdateRoadmap); }
function * handleUpdateRoadmap (action: UpdateRoadmapRequestAction) {
  try {
    const result: Roadmap = yield call(api.updateRoadmap, action.payload.roadmapId, action.payload.roadmap);
    yield put(actions.updateRoadmapSuccess(result));
  } catch (ex) {
    yield put(actions.updateRoadmapFailure(ex as Error));
  }
}

function * watchDeleteRoadmap () { yield takeLatest(DELETE_ROADMAP_REQUEST, handleDeleteRoadmap); }
function * handleDeleteRoadmap (action: DeleteRoadmapRequestAction) {
  try {
    yield call(api.deleteRoadmap, action.payload.roadmapId);
    yield put(actions.deleteRoadmapSuccess(action.payload.roadmapId));
  } catch (ex) {
    yield put(actions.deleteRoadmapFailure(action.payload.roadmapId, ex as Error));
  }
}

function * administrativeRoadmapSagas () {
  yield all([
    fork(watchCreateRoadmap),
    fork(watchUpdateRoadmap),
    fork(watchDeleteRoadmap)
  ]);
}

export default administrativeRoadmapSagas;
