import { CostCenterEdit } from '../../../models/cost-center/CostCenterEdit';
import { get, post } from '../../../utils/api';
import { CostCenter } from '../../production';
import { CostCenterOverview } from './types';

const endpoint = '/api/cost-centers';

export async function loadAll (): Promise<CostCenter[]> {
  return get<CostCenter[]>(endpoint);
}

export async function create (costCenter: CostCenterEdit): Promise<CostCenter> {
  return post<CostCenter>(endpoint, costCenter);
}

export async function update (costCenter: CostCenterEdit): Promise<CostCenter> {
  return post<CostCenter>(`${endpoint}/${costCenter.id}`, costCenter);
}

export async function loadOverview (costCenterId: number): Promise<CostCenterOverview[]> {
  return get<CostCenterOverview[]>(`${endpoint}/${costCenterId}/overview`);
}
