import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as LayoutActions from 'store/layout/actions';
import { OrdersTransferList } from './components/OrdersTransferList';

const m = defineMessages({
  title: { id: 'LotsManagementPage.title', defaultMessage: 'Lots Management' },
  subtitle: { id: 'LotsManagementPage.subtitle', defaultMessage: 'Group ordered items together to be manufactured as a lot.' },
});

export const LotsManagementPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({
      title: formatMessage(m.title),
      subtitle: formatMessage(m.subtitle) }));
  }, []);

  return (
    <Box sx={{ minHeight: '100%' }}>
      <Container>
        <Box sx={{ marginBottom: '30px' }} />
        <OrdersTransferList />
      </Container>
    </Box>
  );
};