import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getAllGroupingModes } from '../../../../production';
import { useIntl } from 'react-intl';
import { getGroupingModeDescription } from '../../messages';



export type GroupingModeDropdownProps =
    & DropdownProps;

export const GroupingModeDropdown: React.FC<GroupingModeDropdownProps> = (props) => {
  const { ...dropdownProps } = props;
  const { formatMessage } = useIntl();
  const operatingModes = useSelector(getAllGroupingModes);
  const dropdownOptions = operatingModes.map((x): DropdownItemProps => ({
    key: x,
    text: formatMessage(getGroupingModeDescription(x)),
    value: x
  }));

  return (
    <Dropdown
      options={dropdownOptions}
      {...dropdownProps}
    />
  );
};
