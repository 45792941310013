import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';
import { DeleteConfirmation } from './DeleteConfirmation';

interface OwnProps {
    title: string;
    loading?: boolean;
    deleting?: boolean;
    deleteConfirmationTitle?: string;
    deleteConfirmationMessage?: string | React.ReactNode;
    onSubmit: () => void;
    onDelete?: () => void;
}

export type UpdateEntityModalProps =
    & ModalProps
    & OwnProps;

export const UpdateEntityModal: React.FC<UpdateEntityModalProps> = (props) => {
  const { title, loading, deleting, deleteConfirmationTitle, deleteConfirmationMessage, onSubmit, onDelete, ...modalProps } = props;
  const { formatMessage } = useIntl();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const askForDeleteConfirmation = () => setIsDeleteConfirmationOpen(true);
  const closeDeleteConfirmation = () => setIsDeleteConfirmationOpen(false);

  return (
    <>
      <Modal size="tiny" {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {props.children}
        </Modal.Content>
        <Modal.Actions>
          {onDelete && <Button negative basic floated="left" content={formatMessage(commonMessages.delete)} onClick={askForDeleteConfirmation} />}

          <Button content={formatMessage(commonMessages.cancel)} onClick={(e) => props.onClose && props.onClose(e, props)} />
          <Button
            primary
            disabled={loading}
            loading={loading}
            content={formatMessage(commonMessages.modify)}
            onClick={onSubmit}
          />
        </Modal.Actions>
      </Modal>

      {onDelete &&
        <DeleteConfirmation
          open={isDeleteConfirmationOpen}
          title={deleteConfirmationTitle}
          message={deleteConfirmationMessage}
          isDeleting={deleting}
          onCancel={closeDeleteConfirmation}
          onConfirm={onDelete}
        />
      }
    </>
  );
};
