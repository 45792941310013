import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Grid, Header, Icon, Modal, ModalProps, Segment } from 'semantic-ui-react';

import { NoContentPlaceholder } from '../../../../../components';
import { ProductionNote } from '../../../types';
import { PdfModal } from '../../../work-instructions/views/components/PdfModal';
import styles from './TechnicalInstructionsModal.module.css';

interface OwnProps {
    title: string;
    hasWorkInstructions: boolean;
    itemId: number;
    operationId: number;
    productionItemId?: number;
    productionNotes: ProductionNote[];
    salesOrderId: number | undefined;
}

export type TechnicalInstructionsModalProps =
    & OwnProps
    & ModalProps;

const m = defineMessages({
  productionNotesTitle: { id: 'WorkInstructionsModal.productionNotesTitle', defaultMessage: 'Production notes' },
  workInstructionsTitle: { id: 'WorkInstructionsModal.workInstructionsTitle', defaultMessage: 'Work instructions' },
  viewWorkInstructionsButton: { id: 'WorkInstructionsModal.viewWorkInstructionsButton', defaultMessage: 'View' },
  viewWorkInstructionsTitle: { id: 'WorkInstructionsModal.viewWorkInstructionsTitle', defaultMessage: 'View the work instructions for this item.' },
  noWorkInstructionsTitle: { id: 'WorkInstructionsModal.noWorkInstructionsTitle', defaultMessage: 'There are no work instructions defined for this item.' }
});

export const TechnicalInstructionsModal: React.FC<TechnicalInstructionsModalProps> = (props) => {
  const { formatMessage } = useIntl();
  const { title, hasWorkInstructions, itemId, operationId, productionItemId, productionNotes, salesOrderId, ...modalProps } = props;
  const hasProductionNotes = productionNotes.length > 0;

  if (!hasProductionNotes) {
    return (
      <PdfModal
        title={props.title}
        url={`/api/work-instructions/combine?itemId=${itemId}&operationId=${operationId}&productionItemId=${productionItemId || ''}&salesOrderId=${salesOrderId || ''}`}
        {...modalProps}
      />
    );
  }

  const renderWorkInstructions = () => {
    if (!hasWorkInstructions) {
      return (
        <NoContentPlaceholder
          icon="file pdf outline"
          title={formatMessage(m.noWorkInstructionsTitle)}
        />
      );
    }

    return (
      <Segment basic placeholder>
        <Header icon>
          <Icon size="massive" color="red" name="file pdf outline" />
          {formatMessage(m.viewWorkInstructionsTitle)}
          <PdfModal
            title={props.title}
            url={`/api/work-instructions/combine?itemId=${itemId}&operationId=${operationId}&productionItemId=${productionItemId || ''}&salesOrderId=${salesOrderId || ''}`}
            trigger={<Button primary content={formatMessage(m.viewWorkInstructionsButton)} style={{ marginTop: '15px' }} />}
          />
        </Header>
      </Segment>
    );
  };

  return (
    <Modal id={styles.centeredModal} className={styles.modal} closeIcon size="fullscreen" {...modalProps}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content className={styles.content} scrolling>
        <Grid columns="equal">
          {hasProductionNotes &&
                        <Grid.Column>
                          <Segment style={{ height: '100%' }}>
                            <h4>{formatMessage(m.productionNotesTitle)}</h4>
                            <pre style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', fontSize: 'medium' }}>
                              {props.productionNotes.map(x => (<p key={x.id}>{x.note}</p>))}
                            </pre>
                          </Segment>
                        </Grid.Column>
          }
          <Grid.Column>
            <Segment style={{ height: '100%' }}>
              <h4>{formatMessage(m.workInstructionsTitle)}</h4>
              {renderWorkInstructions()}
            </Segment>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
