import {
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { graphql } from 'gql';
import { useGraphQL } from 'gql/useGraphQL';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { ProductionItemsEmptyState } from './ProductionItemsEmptyState';

const ProductionHistoryProductionItemsQuery = graphql(`
  query ProductionHistoryProductionItemsQuery($salesOrderId: Int!) {
    productionItems(where: { orderId: { eq: $salesOrderId } }) {
      id
      itemDescription
      quantity
      currentOperationId
    }
    operations {
      id
      name
    }
  }
`);

interface ProductionItemsProps {
  salesOrderId: number;
}

export const ProductionItems = ({ salesOrderId }: ProductionItemsProps) => {
  const { formatMessage } = useIntl();

  const { data, isFetched } = useGraphQL(
    ProductionHistoryProductionItemsQuery,
    {
      salesOrderId,
    }
  );

  const productionItems = data?.productionItems ?? [];
  const operations = data?.operations ?? [];

  const operationsMap = useMemo(
    () => new Map(operations.map((operation) => [operation.id, operation])),
    [operations]
  );

  const getCurrentItemOperationName = (
    item: (typeof productionItems)[number]
  ): string | React.ReactNode => {
    const currentOperationName =
      item.currentOperationId &&
      operationsMap.get(item.currentOperationId)?.name;

    return currentOperationName ? (
      currentOperationName
    ) : (
      <>
        <Icon name="check circle" color="green" />
        {formatMessage({
          id: 'SalesOrderPage.ProductionItems.AllOperationsCompleted',
          defaultMessage: 'Completed',
        })}
      </>
    );
  };

  return (
    <Container sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{formatMessage({ id: 'Product' })}</TableCell>
              <TableCell>{formatMessage({ id: 'Quantity' })}</TableCell>
              <TableCell>
                {formatMessage({
                  id: 'SalesOrderPage.ProductionItems.CurrentOperation',
                  defaultMessage: 'Current operation',
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetched ? (
              <>
                {productionItems.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.itemDescription}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{getCurrentItemOperationName(item)}</TableCell>
                  </TableRow>
                ))}
                {productionItems.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <ProductionItemsEmptyState />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};
