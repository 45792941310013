import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../../../modules/account/selectors';
import { UserPresenceCoin } from '../../../presences/UserPresenceCoin';
import { SignOutButton } from './SignOutButton';
import { getRoleDescription } from '../../../../modules/administration/users/helpers';
import * as AccountActions from '../../../../modules/account/actions';

export const CurrentUserSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  if (currentUser == null) {
    return <></>;
  }

  const signOutCurrentUser = () => dispatch(AccountActions.logout());

  return (
    <Stack alignItems='center' padding={2} gap={2}>
      <UserPresenceCoin displayName={currentUser.displayName} size={96} />

      <Stack alignItems='center'>
        <Typography variant='h6'>{currentUser.displayName}</Typography>
        <Typography variant='subtitle1'>{formatMessage(getRoleDescription(currentUser))}</Typography>
      </Stack>

      <SignOutButton onClick={signOutCurrentUser} />
    </Stack>
  );
};
