import { Subscription } from '../types';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { isAuthenticated as isAuthenticatedSelector } from 'modules/account/selectors';
import { getIsLicenseValid, getSubscription } from '../api';

interface Result {
  subscription?: Subscription;
  isValid: boolean,
}

export const useLicense = () : Result => {
  const isAuthenticated = useSelector<ApplicationState, boolean>(state => isAuthenticatedSelector(state));

  const { data: subscription } = useQuery(['licenseSubscription'], getSubscription, { enabled: isAuthenticated });
  const { data: isValid } = useQuery(['licenseIsValid'], getIsLicenseValid, { enabled: isAuthenticated });

  return {
    subscription: subscription,
    isValid: isValid ?? true
  };
};