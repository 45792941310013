import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Button, ButtonProps } from 'semantic-ui-react';
import { defaultToastOptions, successToastOptions } from '../../../../../utils';
import * as api from '../../api';
import * as webauthn from '../../webauthn';

interface OwnProps {
    forUserId: string;
}

export type SecurityKeyRegistrationButtonProps =
    & OwnProps
    & ButtonProps;

const m = defineMessages({
  removeAuthenticator: { id: 'SecurityKeyRegistrationButton.removeAuthenticator', defaultMessage: 'Remove security key' },
  attachAuthenticator: { id: 'SecurityKeyRegistrationButton.attachAuthenticator', defaultMessage: 'Register security key' },
  registrationError: { id: 'SecurityKeyRegistrationButton.registrationError', defaultMessage: 'Failed to register the security key. It might already be associated with an other user.' },
  registrationSuccess: { id: 'SecurityKeyRegistrationButton.registrationSuccess', defaultMessage: 'The security key is now associated with the user account.' },
  unregistrationError: { id: 'SecurityKeyRegistrationButton.unregistrationError', defaultMessage: 'Failed to remove the user\'s security key. Please try again later or contact your administrator if the problem persists.' },
  unregistrationSuccess: { id: 'SecurityKeyRegistrationButton.unregistrationSuccess', defaultMessage: 'The security key is now removed from the user account.' }
});

export const SecurityKeyRegistrationButton: React.FunctionComponent<SecurityKeyRegistrationButtonProps> = (props) => {
  const { forUserId, ...buttonProps } = props;
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { data: user } = useQuery(
    ['users', forUserId],
    () => api.getById(forUserId)
  );

  const registerAuthenticator = () => {
    webauthn.registerAuthenticator(props.forUserId)
      .then(() => {
        queryClient.invalidateQueries(['users']);
        toast.success(formatMessage(m.registrationSuccess), successToastOptions);
      })
      .catch(ex => {
        toast.error(`${formatMessage(m.registrationError)} (${ex})`, defaultToastOptions);
      });
  };

  const removeAuthenticator = () => {
    webauthn.unregisterAuthenticator(props.forUserId)
      .then(() => {
        queryClient.invalidateQueries(['users']);
        toast.success(formatMessage(m.unregistrationSuccess), successToastOptions);
      })
      .catch(ex => toast.error(`${formatMessage(m.registrationError)} (${ex})`, defaultToastOptions));
  };

  return user?.hasRegisteredAuthenticator === true
    ? <Button negative icon="delete" content={formatMessage(m.removeAuthenticator)} onClick={removeAuthenticator} {...buttonProps} />
    : <Button disabled={user == null} icon="key" content={formatMessage(m.attachAuthenticator)} onClick={registerAuthenticator} {...buttonProps} />;
};
