import './index.css';

import { createBrowserHistory } from 'history';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import { App } from './app';
import { i18nMessages } from './configureI18n';
import configureStore from './configureStore';
import { configureTelemetry } from './configureTelemetry';
import * as serviceWorker from './serviceWorker';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

const history = createBrowserHistory();
const store = configureStore(history, {} as any);

const reactPlugin = new ReactPlugin();
configureTelemetry(reactPlugin, history);

const licenseKey = process.env.REACT_APP_DATAGRIDPRO_LICENSE_KEY;
if (!licenseKey) throw new Error('Missing license key for data grid pro');

LicenseInfo.setLicenseKey(licenseKey);

ReactDOM.render(
  React.createElement(
    withAITracking(reactPlugin, () => (
      <StrictMode>
        <App history={history} store={store} messages={i18nMessages} reactPlugin={reactPlugin} />
      </StrictMode>
    ), undefined, 'app')
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
