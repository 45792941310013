import * as SignalR from '@microsoft/signalr';
import { take, delay, takeLatest, fork, all, put, takeEvery, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { SIGNALR_UPDATE_PROGRESS, SIGNALR_DISCONNECT, SIGNALR_DISCONNECTED, SIGNALR_RECONNECTED, SIGNALR_RECONNECTING, SIGNALR_PRIORITIZE_LOT, SIGNALR_UNPRIORITIZE_LOT, SIGNALR_SALES_ORDERS_PRIORITIES_UPDATED, TURN_ON_LIVE_UPDATES, TURN_OFF_LIVE_UPDATES } from './types';
import { LOGOUT, LOAD_PROFILE_SUCCESS, LOGIN_WEBAUTH_SUCCESS } from '../account';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Action, AnyAction } from 'redux';
import { isAuthenticated } from '../account/selectors';
import * as accountActions from '../account/actions';
import * as liveUpdatesActions from './actions';
import * as PrioritizationActions from '../production/prioritization/actions';
import * as workstationActions from '../production/workstation/actions';
import { areLiveUpdatesForcedOff, getCurrentUserLocation } from './selectors';
import { ApplicationState } from '../../store';
import { UserLocation } from '.';
import { LOT_UPDATED, MANUFACTURED_ITEM_OPERATION_UPDATED, MANUFACTURED_ITEM_UPDATED, PRODUCTION_ITEM_UPDATED, SALES_ORDER_UPDATED } from '../production/workstation/types';

const connection = new SignalR.HubConnectionBuilder()
  .withUrl('/productionHub')
  .withAutomaticReconnect()
  .configureLogging(SignalR.LogLevel.Warning)
  .build();

const inboundChannel = eventChannel(emitter => {
  connection.on(SIGNALR_UPDATE_PROGRESS, (payload: any) => emitter({ event: SIGNALR_UPDATE_PROGRESS, payload }));
  connection.on(SIGNALR_SALES_ORDERS_PRIORITIES_UPDATED, (payload: any) => emitter({ event: SIGNALR_SALES_ORDERS_PRIORITIES_UPDATED, payload }));
  connection.on(SIGNALR_PRIORITIZE_LOT, (payload: any) => emitter({ event: SIGNALR_PRIORITIZE_LOT, payload }));
  connection.on(SIGNALR_UNPRIORITIZE_LOT, (payload: any) => emitter({ event: SIGNALR_UNPRIORITIZE_LOT, payload }));
  connection.on(SIGNALR_DISCONNECT, (payload: any) => emitter({ event: SIGNALR_DISCONNECT, payload }));
  connection.on(MANUFACTURED_ITEM_UPDATED, (payload: any) => emitter({ event: MANUFACTURED_ITEM_UPDATED, payload }));
  connection.on(MANUFACTURED_ITEM_OPERATION_UPDATED, (payload: any) => emitter({ event: MANUFACTURED_ITEM_OPERATION_UPDATED, payload }));
  connection.on(SALES_ORDER_UPDATED, (payload: any) => emitter({ event: SALES_ORDER_UPDATED, payload }));
  connection.on(PRODUCTION_ITEM_UPDATED, (payload: any) => emitter({ event: PRODUCTION_ITEM_UPDATED, payload }));
  connection.on(LOT_UPDATED, (payload: any) => emitter({ event: LOT_UPDATED, payload }));
  connection.onclose(() => emitter({ event: SIGNALR_DISCONNECTED }));
  connection.onreconnecting(() => emitter({ event: SIGNALR_RECONNECTING }));
  connection.onreconnected(() => emitter({ event: SIGNALR_RECONNECTED }));

  return () => {
    // We need to return an unsubscribe event.
    // Here, we would put any cleanup if required.
  };
});

function* startLiveUpdates() {
  console.log('Initializing live updates');
  let isRunning = true;

  while (isRunning) {
    try {
      if (connection.state === SignalR.HubConnectionState.Disconnected) {
        const isUserAuthenticated: boolean = yield select(isAuthenticated);
        const isForcedOff: boolean = yield select(areLiveUpdatesForcedOff);

        if (isUserAuthenticated && !isForcedOff) {
          // The user is authenticated, so his SignalR connection should be running right now.
          console.log('Establishing connection with server...');
          yield connection.start();
          yield put(liveUpdatesActions.setOnline());
          console.log('Connected with server. Live updates are now online.');
        } else {
          // The user is logged out (or the developper has requested the live updates to be forced off),
          // so simply close the SignalR event listening loop.
          isRunning = false;
          break;
        }
      }

      const signalREvent: unknown = yield take(inboundChannel);
      const event = (signalREvent as any).event;
      const payload = (signalREvent as any).payload;

      switch (event) {
      case SIGNALR_UPDATE_PROGRESS: {
        yield put(workstationActions.registerUIProgressEventSuccess(payload));
        break;
      }

      case SIGNALR_SALES_ORDERS_PRIORITIES_UPDATED: {
        yield put(PrioritizationActions.savePrioritiesSuccess(payload));
        break;
      }

      case SIGNALR_PRIORITIZE_LOT: {
        yield put(PrioritizationActions.prioritizeSuccess(payload));
        break;
      }

      case SIGNALR_UNPRIORITIZE_LOT: {
        yield put(PrioritizationActions.unprioritizeSuccess(payload));
        break;
      }

      case SIGNALR_DISCONNECT: {
        yield put(accountActions.logout());
        isRunning = false;
        break;
      }

      case SIGNALR_DISCONNECTED:
      case SIGNALR_RECONNECTING: {
        yield put(liveUpdatesActions.setOffline());
        break;
      }

      case SIGNALR_RECONNECTED: {
        yield put(liveUpdatesActions.setOnline());
        const currentUserLocation: UserLocation = yield select((state: ApplicationState) => getCurrentUserLocation(state, window.location.pathname));
        yield connection.invoke('TrackLocation', currentUserLocation).catch((e) => console.error(e));
        break;
      }

      default: {
        yield put({ type: event, payload: payload});
      }
      }
    } catch (ex) {
      console.error(`A live update connection error occurred. Attempting to reconnect in 5 seconds... (${ex})`);
      yield delay(5000);
    }
  }
}

function* stopLiveUpdates() {
  console.log('Stopping live updates');
  yield connection.stop();
  yield put(liveUpdatesActions.setOffline());
}

function* handleLocationChanged(action: LocationChangeAction) {
  if (connection.state === SignalR.HubConnectionState.Connected) {

    const currentUserLocation: UserLocation = yield select((state: ApplicationState) => getCurrentUserLocation(state, action.payload.location.pathname));

    try {
      // Ignore errors, it has no major impact on functionnality.
      yield connection.invoke('TrackLocation', currentUserLocation).catch((e) => console.error(e));
    } catch (ex) {
      // Ignore errors, it has no major impact on functionnality.
    }
  }
}

function* handleEventPublished(action: AnyAction) {
  if (connection.state === SignalR.HubConnectionState.Connected) {
    try {
      yield connection
        .invoke(action.meta, action.payload)
        .catch(ex => {
          console.error(`Failed to publish a live update event (${ex})`);
        });
    } catch (ex) {
      console.error(`Failed to publish a live update event (${ex})`);
    }
  }
}

function* watchSessionStarted() { yield takeLatest([LOAD_PROFILE_SUCCESS, LOGIN_WEBAUTH_SUCCESS], startLiveUpdates); }
function* watchSessionStopped() { yield takeLatest([LOGOUT], stopLiveUpdates); }
function* watchLocationChanged() { yield takeLatest(LOCATION_CHANGE, handleLocationChanged); }
function* watchEventPublished() { yield takeEvery((action: Action) => /PUBLISH$/.test(action.type), handleEventPublished); }
function* watchTurnOnLiveUpdates() { yield takeLatest(TURN_ON_LIVE_UPDATES, startLiveUpdates); }
function* watchTurnOffLiveUpdates() { yield takeLatest(TURN_OFF_LIVE_UPDATES, stopLiveUpdates); }

function* liveUpdatesSagas() {
  yield all([
    fork(watchSessionStarted),
    fork(watchSessionStopped),
    fork(watchLocationChanged),
    fork(watchEventPublished),
    fork(watchTurnOnLiveUpdates),
    fork(watchTurnOffLiveUpdates)
  ]);
}

export { liveUpdatesSagas };
