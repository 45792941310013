import { all, call, fork, put, takeEvery, takeLatest, select, delay } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { LOAD_DEVICES, UpdateDevice, UPDATE_DEVICE, DeleteDevice, DELETE_DEVICE, SIGN_IN, REGISTER, Device, DeviceConfigurationStatus } from './types';
import { getCurrentDevice } from './selectors';

function * handleLoadDevices () {
  try {
    const devices: Device[] = yield call(api.loadDevices);
    yield put(actions.loadDevicesSuccess(devices));
  } catch (e) {
    yield put(actions.loadDevicesFailure(e as Error));
  }
}

function * handleUpdateDevice (action: UpdateDevice) {
  try {
    const device: Device = yield call(api.updateDevice, action.payload.device);
    yield put(actions.updateDeviceSuccess(device));
  } catch (e) {
    yield put(actions.updateDeviceFailure(action.payload.device.id, e as Error));
  }
}

function * handleDeleteDevice (action: DeleteDevice) {
  try {
    yield call(api.deleteDevice, action.payload.deviceId);
    yield put(actions.deleteDeviceSuccess(action.payload.deviceId));
  } catch (e) {
    yield put(actions.deleteDeviceFailure(action.payload.deviceId, e as Error));
  }
}

function * handleSignIn () {
  try {
    const result: Device = yield call(api.signIn);
    yield put(actions.signInSuccess(result));
  } catch (e) {
    yield put(actions.signInFailure(e as Error));
  }

  const currentDevice = (yield select(getCurrentDevice)) as Device | undefined;
  if (currentDevice == null || currentDevice.configurationStatus === DeviceConfigurationStatus.pending) {
    // attempt login every 5 seconds to see if administrator configured the workstation
    yield delay(5000);
    yield put(actions.signIn());
  }
}

function * handleRegister () {
  try {
    const result: Device = yield call(api.register);
    yield put(actions.registerSuccess(result));
    yield put(actions.signIn());
  } catch (e) {
    yield put(actions.registerFailure(e as Error));
  }
}

function * watchLoadDevices () { yield takeLatest(LOAD_DEVICES, handleLoadDevices); }
function * watchUpdateDevice () { yield takeEvery(UPDATE_DEVICE, handleUpdateDevice); }
function * watchDeleteDevice () { yield takeEvery(DELETE_DEVICE, handleDeleteDevice); }
function * watchSignIn () { yield takeLatest(SIGN_IN, handleSignIn); }
function * watchRegister () { yield takeLatest(REGISTER, handleRegister); }

function * devicesSagas () {
  yield all([
    fork(watchLoadDevices),
    fork(watchUpdateDevice),
    fork(watchDeleteDevice),
    fork(watchSignIn),
    fork(watchRegister)
  ]);
}

export default devicesSagas;
