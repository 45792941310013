import React from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Badge, IconButton, Stack, ToggleButtonGroup } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { ProductionDateButton } from './ProductionDateButton';

interface ProductionDateSelectorProps {
  selectedDate: Date;
  onChange: (date: Date) => void;
  datesWithNotifications: Date[];
}

export const ProductionDateSelector: React.FC<ProductionDateSelectorProps> = (props) => {
  const startOfWeek = moment(props.selectedDate).startOf('week');
  const endOfWeek = moment(props.selectedDate).endOf('week');

  const datesDisplayed = _.range(0, 7).map(index => startOfWeek.clone().add(index, 'day'));
  const hasNotificationInPreviousWeeks = props.datesWithNotifications.some(dateWithNotification => startOfWeek.isAfter(dateWithNotification));
  const hasNotificationsInFollowingWeeks  = props.datesWithNotifications.some(dateWithNotification => endOfWeek.isBefore(dateWithNotification));

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
      <IconButton aria-label="previous" onClick={() => props.onChange(startOfWeek.clone().add(-1, 'week').toDate())}>
        <Badge color="error" variant="dot" overlap="circular" invisible={!hasNotificationInPreviousWeeks} >
          <DoubleArrowIcon sx={{ transform: 'rotate(180deg)' }} />
        </Badge>
      </IconButton>

      <ToggleButtonGroup>
        {datesDisplayed.map((productionDate, index) => {
          const hasNotificationForProductionDate = props.datesWithNotifications.find(p => moment(p).startOf('day').isSame(productionDate.startOf('day'))) !== undefined;

          return (
            <ProductionDateButton
              key={index}
              value={productionDate}
              hasError={hasNotificationForProductionDate}
              selected={productionDate.isSame(props.selectedDate)}
              onClick={() => props.onChange(productionDate.toDate())}
            />);
        })}
      </ToggleButtonGroup>

      <IconButton aria-label="next" onClick={() => props.onChange(startOfWeek.clone().add(1, 'week').toDate())}>
        <Badge color="error" variant="dot" overlap="circular" invisible={!hasNotificationsInFollowingWeeks} >
          <DoubleArrowIcon />
        </Badge>
      </IconButton>
    </Stack>
  );
};
