import React, { useEffect } from 'react';
import { RaisedTile, LoadingDimmer, NoContentPlaceholder } from '../../../../components';
import { RoadmapExecutionTable } from './components/RoadmapExecutionTable';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getAllManufacturedItemOperationsByItemId, getLotRoadmapItems, getRoadmapFields } from '../../workstation/selectors';
import { isSystemLoading } from '../../../system';
import { getOperationById, getLotById, getAllOperations } from '../../selectors';
import { LotRoadmapHeader } from './components/RoadmapHeader/LotRoadmapHeader';
import { useIntl, defineMessages } from 'react-intl';
import * as LayoutActions from '../../../../store/layout/actions';
import * as RoadmapActions from '../actions';
import * as WorkstationActions from '../../workstation/actions';
import { RegisterUIProgressEventPayload, WorkstationProductionProgressUIEventType } from '../../workstation/types';
import { getCurrentDevice } from '../../../administration/devices/selectors';
import { isApplicationOffline } from '../../../live-updates';
import { getLocalUsers } from '../../collaboration/selectors';
import { getCurrentUser } from '../../../account/selectors';
import { OfflineBanner } from '../../../live-updates/components';
import { getWorkstations } from 'modules/administration';

export interface RouteProps {
  workstationId: string;
  operationId: string;
  lotId: string;
}

const m = defineMessages({
  title: { id: 'LotRoadmapExecutionPage.title', defaultMessage: 'Roadmap' },
  noContentTitle: { id: 'LotRoadmapExecutionPage.noContentTitle', defaultMessage: 'There are no tasks required for this operation' }
});

export const LotRoadmapExecutionPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const routeParams = useParams<RouteProps>();

  const workstationId = Number(routeParams.workstationId);
  const operationId = Number(routeParams.operationId);
  const lotId = Number(routeParams.lotId);

  const currentDevice = useSelector(getCurrentDevice);
  const roadmapItems = useSelector((state: ApplicationState) => getLotRoadmapItems(state, lotId, operationId));
  const roadmapFields = useSelector((state: ApplicationState) => getRoadmapFields(state, operationId));
  const operation = useSelector((state: ApplicationState) => getOperationById(state, operationId));
  const lot = useSelector((state: ApplicationState) => getLotById(state, lotId));

  const operations = useSelector(getAllOperations).filter(operation => operation.isDisabled == false);
  const workStations = useSelector(getWorkstations);
  const manufacturedOperationItems = useSelector((state: ApplicationState) => getAllManufacturedItemOperationsByItemId(state, lot?.id));

  const isOffline = useSelector(isApplicationOffline);
  const isLoading = useSelector(isSystemLoading);

  const hasRoadmapItems = roadmapItems.length > 0;

  const currentUser = useSelector(getCurrentUser);
  let localUsers = useSelector(getLocalUsers);
  if (localUsers.length > 0 && currentUser != null) {
    // We include the current user when other users are collaborating on the device.
    localUsers = [currentUser!, ...localUsers];
  }

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.title) }));
    dispatch(RoadmapActions.loadRoadmapItemsForOperation(operationId, lotId));
    dispatch(WorkstationActions.registerUIProgressEvent(
      buildEventPayload(WorkstationProductionProgressUIEventType.LotRoadmapScreenOpened, []))
    );

    return () => {
      dispatch(WorkstationActions.registerUIProgressEvent(
        buildEventPayload(WorkstationProductionProgressUIEventType.LotRoadmapScreenClosed, [])
      ));
    };
  }, [dispatch, routeParams]);

  const buildEventPayload = (eventType: WorkstationProductionProgressUIEventType, manufacturedItemOperationIds: number[]): RegisterUIProgressEventPayload => ({
    eventType,
    manufacturedItemOperationIds,
    deviceId: currentDevice?.id,
    workstationId: !isNaN(workstationId) ? workstationId : undefined,
    operationId,
    lotId: !isNaN(lotId) ? lotId : undefined,
    productionItemId: undefined,
    userIds: localUsers.length > 0 ? localUsers.map(x => x.id) : undefined
  });

  return (
    <>
      <OfflineBanner />
      <RaisedTile>
        <LoadingDimmer active={isLoading} />
        <LotRoadmapHeader
          operation={operation}
          lot={lot}
          workstations={workStations}
          operations={operations}
          manufacturedOperationItems={manufacturedOperationItems}
        />

        {hasRoadmapItems &&
          <RoadmapExecutionTable
            operationId={operationId}
            roadmapItems={roadmapItems}
            roadmapFields={roadmapFields}
          />
        }

        {!hasRoadmapItems &&
          <NoContentPlaceholder
            textAlign="center"
            icon="tasks"
            title={formatMessage(m.noContentTitle)}
          />
        }
      </RaisedTile>
    </>
  );
};
