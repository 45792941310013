import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dimmer, DimmerProps, Loader } from 'semantic-ui-react';

interface OwnProps {
    message?: string;
}

export type LoadingDimmerProps =
    & OwnProps
    & DimmerProps;

const m = defineMessages({
  loading: { id: 'LoadingDimmer.loading', defaultMessage: 'Loading...' }
});

export const LoadingDimmer: React.FC<LoadingDimmerProps> = (props) => {
  const { formatMessage } = useIntl();
  const { message, children, ...dimmerProps } = props;

  return (
    <Dimmer inverted={true} {...dimmerProps}>
      <Loader>
        <p>{message || formatMessage(m.loading)}</p>
        {children}
      </Loader>
    </Dimmer>
  );
};
