import { Reducer } from 'redux';

import { ApiError } from '../../utils/api';
import {
  AccountActionTypes,
  AccountState,
  LOGIN_WEBAUTH,
  LOGIN_WEBAUTH_FAILURE,
  LOGIN_WEBAUTH_SUCCESS,
  LOGOUT,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_FAILURE,
  WEBAUTH_USER_REQUIRED,
  WEBAUTH_USER_PROVIDED,
  WEBAUTH_USER_REJECTED
} from './types';

const initialState: AccountState = {
  user: undefined,
  isAuthenticating: false,
  isAuthenticatingWithWebAuth: false,
  isProfileLoaded: false,
  hasFailedAuthentication: false,
  hasWebAuthAuthenticationFailed: false,
  isAuthenticated: false,
  isWebAuthUserRequested: false
};

const reducer: Reducer<AccountState, AccountActionTypes> = (state = initialState, action) => {
  if (action.type !== LOGIN_WEBAUTH_FAILURE && action.type !== LOAD_PROFILE_FAILURE) {
    if (action.payload && ((action.payload as any).error instanceof ApiError || action.payload instanceof ApiError)) {
      const error = ((action.payload as any).error || action.payload) as ApiError;
      if (error.statusCode === 401) {
        return {
          ...state,
          isAuthenticated: false
        };
      }
    }
  }

  switch (action.type) {
  case LOGIN_WEBAUTH:
    return {
      ...state,
      isAuthenticatingWithWebAuth: true,
      hasFailedAuthentication: false
    };

  case LOGIN_WEBAUTH_SUCCESS:
    return {
      ...state,
      user: action.payload,
      isAuthenticatingWithWebAuth: false,
      isAuthenticated: true
    };

  case LOGIN_WEBAUTH_FAILURE:
    return {
      ...state,
      isAuthenticatingWithWebAuth: false
    };

  case LOAD_PROFILE_SUCCESS: {
    return {
      ...state,
      user: action.payload,
      isAuthenticating: false,
      hasFailedAuthentication: false,
      isAuthenticated: true,
      isProfileLoaded: true
    };
  }

  case LOAD_PROFILE_FAILURE: {
    return {
      ...state,
      isProfileLoaded: true
    };
  }

  case LOGOUT:
    return { ...state, isAuthenticated: false };

  case WEBAUTH_USER_REQUIRED: {
    return { ...state, isWebAuthUserRequested: true };
  }

  case WEBAUTH_USER_PROVIDED: {
    return { ...state, isWebAuthUserRequested: false };
  }

  case WEBAUTH_USER_REJECTED: {
    return {
      ...state,
      isWebAuthUserRequested: false,
      isAuthenticatingWithWebAuth: false
    };
  }

  default:
    return state;
  }
};

export { reducer as accountReducer };
