export interface PaginatedResults<T> {
    results: T[];
    pageNumber: number;
    totalPages: number;
    totalResults: number;
}

export const emptyPaginatedResults = <T>(): PaginatedResults<T> => ({
  results: [],
  pageNumber: 1,
  totalPages: 1,
  totalResults: 0
});
