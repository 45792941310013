import * as React from 'react';
import { Header, Icon, Segment, SegmentProps, SemanticICONS } from 'semantic-ui-react';
import styles from './NoContentPlaceholder.module.css';

interface OwnProps {
    title: string;
    subtitle?: string;
    icon?: SemanticICONS;
}

export type NoContentPlaceholderProps =
    & OwnProps
    & SegmentProps;

export const NoContentPlaceholder: React.FC<NoContentPlaceholderProps> = (props) => {
  const { title, subtitle, icon, ...segmentProps } = props;

  return (
    <Segment basic {...segmentProps} textAlign="center">
      <Header icon={icon != null}>
        {icon && <Icon name={icon} className={styles.icon} />}
        <span className={styles.title}>{title}</span>
        {subtitle && <Header.Subheader className={styles.subtitle}>{subtitle}</Header.Subheader>}
      </Header>
    </Segment>
  );
};
