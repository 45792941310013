import { ApplicationState } from '../../store';
import { InitializationStatus } from './types';

export const isSystemInitialized = (state: ApplicationState) => state.system.isInitialized;
export const getOperationsStatus = (state: ApplicationState) => state.system.operationsStatus;
export const getCostCentersStatus = (state: ApplicationState) => state.system.costCentersStatus;
export const getRoadmapStatus = (state: ApplicationState) => state.system.roadmapStatus;
export const getLotPrioritiesStatus = (state: ApplicationState) => state.system.prioritiesStatus;

export const isSystemLoading = (state: ApplicationState) => state.system.isInitializing ||
    state.system.costCentersStatus === InitializationStatus.loading ||
    state.system.operationsStatus === InitializationStatus.loading ||
    state.system.prioritiesStatus === InitializationStatus.loading ||
    state.system.roadmapStatus === InitializationStatus.loading;
