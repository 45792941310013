import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TopBarMenuItem } from '.';
import styles from './TopBarItemButton.module.css';

interface StateProps {
    isFullscreen: boolean;
}

export type FullscreenControlProps =
    & WrappedComponentProps;

class FullscreenControl extends React.Component<FullscreenControlProps, StateProps> {
  public constructor (props: FullscreenControlProps) {
    super(props);

    this.state = { isFullscreen: false };
  }

  public componentDidMount () {
    document.onfullscreenchange = this.handleFullscreenChange;
    this.setState({ isFullscreen: document.fullscreen });
  }

  public render () {
    const isFullscreenSupported = document.fullscreenEnabled;
    if (!isFullscreenSupported) {
      return;
    }

    return (
      <TopBarMenuItem
        icon={this.state.isFullscreen ? 'compress' : 'expand'}
        onClick={this.triggerFullscreen}
        className={styles.Button}
      />
    );
  }

  private handleFullscreenChange = () => {
    this.setState({ isFullscreen: document.fullscreen });
  };

  private triggerFullscreen = () => {
    if (this.state.isFullscreen) {
      document.exitFullscreen();
    } else {
      const documentElement = document.documentElement as any;
      if (documentElement.requestFullscreen) {
        documentElement.requestFullscreen();
      } else {
        console.error('No requestFullscreen method. Please update to the latest version of Mozilla Firefox or Google Chrome.');
      }
    }
  };
}

const intlComponent = injectIntl(FullscreenControl);
export { intlComponent as FullscreenControl };
