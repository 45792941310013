import { createStandardAction } from 'typesafe-actions';
import { WorkstationConfiguration, AddWorkstationAction, ADD_WORKSTATION, AddWorkstationSuccessAction, ADD_WORKSTATION_SUCCESS, Workstation, AddWorkstationFailureAction, ADD_WORKSTATION_FAILURE, LoadWorkstationsAction, LOAD_ALL_WORKSTATIONS, LoadWorkstationsSuccessAction, LOAD_ALL_WORKSTATIONS_SUCCESS, LoadWorkstationsFailureAction, LOAD_ALL_WORKSTATIONS_FAILURE, UpdateWorkstationAction, UPDATE_WORKSTATION, UPDATE_WORKSTATION_SUCCESS, UpdateWorkstationFailureAction, UPDATE_WORKSTATION_FAILURE, UpdateWorkstationSuccessAction, DeleteWorkstationAction, DELETE_WORKSTATION, DeleteWorkstationSuccessAction, DELETE_WORKSTATION_SUCCESS, DeleteWorkstationFailureAction, DELETE_WORKSTATION_FAILURE } from './types';
import { WorkstationEdit } from './models/WorkstationEdit';
import { Device } from '../devices/types';

export function addWorkstation (workstation: WorkstationEdit): AddWorkstationAction {
  return {
    type: ADD_WORKSTATION,
    payload: { workstation }
  };
}

export function addWorkstationSuccess (workstation: Workstation): AddWorkstationSuccessAction {
  return {
    type: ADD_WORKSTATION_SUCCESS,
    payload: { workstation }
  };
}

export function addWorkstationFailure (error: Error): AddWorkstationFailureAction {
  return {
    type: ADD_WORKSTATION_FAILURE,
    payload: error
  };
}

export function deleteWorkstation (workstationId: number): DeleteWorkstationAction {
  return {
    type: DELETE_WORKSTATION,
    payload: { workstationId }
  };
}

export function deleteWorkstationSuccess (workstationId: number): DeleteWorkstationSuccessAction {
  return {
    type: DELETE_WORKSTATION_SUCCESS,
    payload: { workstationId }
  };
}

export function deleteWorkstationFailure (workstationId: number, error: Error): DeleteWorkstationFailureAction {
  return {
    type: DELETE_WORKSTATION_FAILURE,
    payload: { workstationId, error }
  };
}

export function loadWorkstations (): LoadWorkstationsAction {
  return { type: LOAD_ALL_WORKSTATIONS };
}

export function loadWorkstationsSuccess (workstations: Workstation[]): LoadWorkstationsSuccessAction {
  return {
    type: LOAD_ALL_WORKSTATIONS_SUCCESS,
    payload: workstations
  };
}

export function loadWorkstationsFailure (error: Error): LoadWorkstationsFailureAction {
  return {
    type: LOAD_ALL_WORKSTATIONS_FAILURE,
    payload: error
  };
}

export function updateWorkstation (workstationId: number, workstation: WorkstationEdit): UpdateWorkstationAction {
  return {
    type: UPDATE_WORKSTATION,
    payload: { workstationId, workstation }
  };
}

export function updateWorkstationSuccess (workstation: Workstation): UpdateWorkstationSuccessAction {
  return {
    type: UPDATE_WORKSTATION_SUCCESS,
    payload: { workstation }
  };
}

export function updateWorkstationFailure (workstationId: number, error: Error): UpdateWorkstationFailureAction {
  return {
    type: UPDATE_WORKSTATION_FAILURE,
    payload: { workstationId, error }
  };
}
