import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { SortDirection } from '.';



export type SortDirectionDropdownProps =
    & DropdownProps;

const m = defineMessages({
  ascending: { id: 'SortDirectionDropdown.ascending', defaultMessage: 'Ascending' },
  descending: { id: 'SortDirectionDropdown.descending', defaultMessage: 'Descending' }
});

export const SortDirectionDropdown: React.FC<SortDirectionDropdownProps> = (props) => {
  const { formatMessage } = useIntl();
  const sortOptions: DropdownItemProps[] = [
    { text: formatMessage(m.ascending), value: SortDirection.ascending },
    { text: formatMessage(m.descending), value: SortDirection.descending }
  ];

  return (
    <Dropdown
      options={sortOptions}
      {...props}
    />
  );
};
