import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { NoContentPlaceholder } from '../../../../../../components';

const m = defineMessages({
  title: { id: 'NoRunningJobs.title', defaultMessage: 'All bulk imports completed' },
  subtitle: { id: 'NoRunningJobs.subtitle', defaultMessage: 'There are currently no running bulk imports. You can start a new one by clicking on the \'Launch an import\' button.' }
});

export const NoRunningJobs = () => {
  const { formatMessage } = useIntl();

  return (
    <NoContentPlaceholder
      title={formatMessage(m.title)}
      subtitle={formatMessage(m.subtitle)}
      style={{ padding: '20px' }}
    />
  );
};
