import * as React from 'react';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { List, Button } from 'semantic-ui-react';
import { FileIcon } from './FileIcon';

interface OwnProps {
  files: File[];
  disabled?: boolean;
  onDelete?: (file: File) => void;
}

export type FileUploadListProps =
  & OwnProps;

const m = defineMessages({
  lastModifiedOn: { id: 'FileUploadList.lastModifiedOn', defaultMessage: 'Last modified {date}' }
});

export const FileUploadList: React.FC<FileUploadListProps> = (props) => {
  const { formatMessage } = useIntl();
  const deleteFile = (file: File) => {
    if (props.onDelete != null) {
      props.onDelete(file);
    }
  };

  const renderListItem = (file: File) => (
    <List.Item key={file.name}>
      <List.Content floated="left">
        <FileIcon file={file} />
      </List.Content>
      <List.Content floated="right">
        {props.onDelete &&
          <Button negative={true} disabled={props.disabled} icon="trash" onClick={() => deleteFile(file)} />
        }
      </List.Content>
      <List.Content verticalAlign="middle">
        <List.Header style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{file.name}</List.Header>
        <List.Description>{formatMessage(m.lastModifiedOn, { date: moment(file.lastModified).fromNow() })}</List.Description>
      </List.Content>
    </List.Item>
  );

  return (
    <List divided={true} relaxed={true}>
      {props.files.map(renderListItem)}
    </List>
  );
};
