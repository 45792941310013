import { Button, ButtonProps, styled } from '@mui/material';

// Guidelines for the login button are provided directly by Microsoft
// https://docs.microsoft.com/en-ca/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps
export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  fontFamily: 'Segoe UI, sans-serif',
  fontSize: '15px',
  fontWeight: 600,
  height: '41px',
  display: 'inline-flex',
  alignItems: 'center',
  padding: '0 12px',
  color: theme.palette.mode === 'light' ? '#fff' : '#5e5e5e',
  backgroundColor: theme.palette.mode === 'light' ? '#2f2f2f' : '#fff',
  border: theme.palette.mode === 'light' ? 'solid 1px #2f2f2f' : 'solid 1px #8c8c8c',
  textTransform: 'none',
  borderRadius: 0,
  '&:hover': {
    color: theme.palette.mode === 'light' ? '#5e5e5e' : '#fff',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2f2f2f',
    borderColor: theme.palette.mode === 'light' ? '#8c8c8c' : '#2f2f2f',
    cursor: 'pointer',
  },
  '&:focus': {
    outline: 'none',
  },
  '.logo': {
    marginRight: '12px',
  }
}));