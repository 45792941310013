import React from 'react';
import { Accordion } from 'semantic-ui-react';
import { AuthenticationPropertiesSection } from './AuthenticationPropertiesSection';
import { ProfilePropertiesSection } from './ProfilePropertiesSection';
import { Alert, Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

export const UserFormControls = () => {
  const { formatMessage } = useIntl();

  const userId = useWatch({ name: 'id' });
  const isNewUser = userId == null;

  return (
    <Stack gap={1}>
      {isNewUser &&
        <Alert variant='outlined' severity='info'>
          {formatMessage({ id: 'A license will automatically be added and billed for this new user.' })}
        </Alert>
      }
      <Accordion>
        <ProfilePropertiesSection />
        <AuthenticationPropertiesSection />
      </Accordion>
    </Stack>
  );
};
