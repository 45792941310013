import React from 'react';
import { Device, getDeviceName } from 'modules/administration/devices/types';
import { ConfigurationStatusLabel } from './ConfigurationStatusLabel';
import { TableCell, TableRow, TableRowProps, Typography } from '@mui/material';
import { WorkstationChips } from '../WorkstationChips';
import { DevicesTableRowSkeleton } from './DevicesTableRowSkeleton';

interface OwnProps {
  device: Device
  isLoading?: boolean;
}

export type DevicesTableRowProps =
  & OwnProps
  & TableRowProps;

export const DevicesTableRow: React.FC<DevicesTableRowProps> = (props) => {
  const { device, isLoading, ...tableRowProps } = props;
  const deviceName = getDeviceName(device);

  if (isLoading) {
    return <DevicesTableRowSkeleton device={device} />;
  }

  return (
    <TableRow {...tableRowProps}>
      <TableCell>
        <Typography>{deviceName}</Typography>
      </TableCell>
      <TableCell>
        <WorkstationChips workstationIds={device.workstationIds} maximumWorkstationsDisplayed={4} />
      </TableCell>
      <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
        <ConfigurationStatusLabel status={device.configurationStatus} />
      </TableCell>
    </TableRow>
  );
};