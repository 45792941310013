import { LinearProgress, Paper, PaperProps, styled, useTheme } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps, frFR } from '@mui/x-data-grid-pro';
import { frenchLocale } from 'configureI18n';
import { merge } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from 'store/layout';
import { ExpandableSearchBar } from './ExpandableSearchBar';

export interface DataTableProps<T extends GridValidRowModel> extends DataGridProProps<T> {
  searchTerm?: string;
  onSearch?: (searchTerm: string) => void;
  searchBarPlaceholder?: string;
  noSearchResultsMessage?: string;
  noDataMessage?: string;
  height?: number | string;
  paperProps?: PaperProps;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  '& .MuiDataGrid-root': {
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}));

const FloatingLinearProgress = styled(LinearProgress)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
}));

export const DataTable = <T extends GridValidRowModel>({
  searchTerm: searchTermControlled, onSearch, noSearchResultsMessage, noDataMessage, searchBarPlaceholder, loading, height, paperProps,
  ...dataGridProps
}: DataTableProps<T>) => {
  const [searchTermUncontrolled, setSearchTerm] = useState('');
  const theme = useTheme();

  const searchTerm = searchTermControlled ?? searchTermUncontrolled;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch?.(e.target.value);
  };

  const hasData = dataGridProps.rows.length > 0;
  const heavyLoading = loading && !hasData;

  const [paginationModel, setPaginationModel] = useState(dataGridProps.paginationModel);

  const showFooter = dataGridProps.rows.length > (paginationModel?.pageSize || 100);

  const locale = useSelector(getCurrentLocale);

  const localeText =
    locale == frenchLocale
      ? frFR.components.MuiDataGrid.defaultProps.localeText
      : undefined;

  return (
    <StyledPaper {...paperProps} sx={merge({}, { height: height ?? 'auto', width: '100%' }, paperProps?.sx)}>
      <div style={{ position: 'relative' }}>
        {onSearch && (
          <ExpandableSearchBar
            fullWidth
            placeholder={searchBarPlaceholder}
            value={searchTerm}
            onChange={handleSearch}
            sx={{ pt: 1, '& fieldset': { border: 'none' } }}
          />
        )}

        {(loading && !heavyLoading) && (
          <FloatingLinearProgress />
        )}
      </div>

      <DataGridPro<T>
        localeText={localeText}
        scrollbarSize={16} // fixes an issue that would cause the horizontal scrollbar to appear when the vertical scrollbar appears
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={heavyLoading}
        hideFooter={!showFooter}
        slotProps={{
          footer: {
            hidden: true
          }
        }}
        {...dataGridProps}
      />
    </StyledPaper>
  );
};