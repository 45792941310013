import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Container, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { defineMessages, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { SalesOrder } from '../../../schedule';
import PrioritizationApi from '../api';
import { SalesOrdersPriorities } from '../components';
import { SalesOrdersTabs } from '../components/SalesOrdersTabs';
import * as LayoutActions from '../../../../store/layout/actions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import useApiErrorHandler from 'utils/ApiErrorHandler';

const m = defineMessages({
  pageTitle: { id: 'OrdersPrioritizationPage.pageTitle', defaultMessage: 'Orders Prioritization' },
  unsavedChangesWarning: { id: 'OrdersPrioritizationPage.unsavedChangesWarning', defaultMessage: 'You have unsaved changes, are you sure you want to leave?' },
  loadingErrorTitle: { id: 'OrdersPrioritizationPage.loadingErrorTitle', defaultMessage: 'Failed to load the sales order priorities' },
  loadingErrorMessage: { id: 'OrdersPrioritizationPage.loadingErrorMessage', defaultMessage: 'An error occurred while loading the sales orders priorities. Please <a>try again</a> in a few minutes.' },
  totalCount: { id: 'OrdersPrioritizationPage.totalCount', defaultMessage: '{total, plural, =0 {No sales orders to prioritize} one {{total} sales order} other {{total} sales orders}}' },
  saveButton: { id: 'OrdersPrioritizationPage.saveButton', defaultMessage: 'Save priorities' }
});

export const OrdersPrioritizationPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { onSettledErrorHandler } = useApiErrorHandler();
  const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [draggedDraggableId, setDraggedDraggableId] = useState<string>();

  const savePrioritiesMutation = useMutation(PrioritizationApi.savePriorities, {
    onSuccess: () => setHasUnsavedChanges(false),
    onSettled: onSettledErrorHandler,
  });

  const { data, isFetching: isLoadingPriorities, isError } = useQuery<SalesOrder[]>(
    ['sales-orders'],
    PrioritizationApi.getPrioritizedSalesOrders,
    {
      onSettled: onSettledErrorHandler
    });

  useEffect(() => {
    setSalesOrders(data ?? []);
  }, [data]);

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.pageTitle) }));
  }, []);

  const changePriority = (salesOrder: SalesOrder, toIndex: number) => {
    const fromIndex = salesOrders.findIndex(x => x === salesOrder);
    moveSalesOrderInPriorities(fromIndex, toIndex);
  };

  const prioritizeFirst = (salesOrder: SalesOrder) => {
    changePriority(salesOrder, 0);
  };

  const prioritizeLast = (salesOrder: SalesOrder) => {
    changePriority(salesOrder, salesOrders.length);
  };

  const removeFromPriorities = (salesOrder: SalesOrder) => {
    setSalesOrders(salesOrders.filter(x => x !== salesOrder));
    setHasUnsavedChanges(true);
  };

  const moveSalesOrderInPriorities = (fromIndex: number, toIndex: number) => {
    const clonedSalesOrders = _.clone(salesOrders);
    clonedSalesOrders.splice(toIndex, 0, clonedSalesOrders.splice(fromIndex, 1)[0]);
    setSalesOrders(clonedSalesOrders);
    setHasUnsavedChanges(true);
  };

  const save = () => {
    savePrioritiesMutation.mutate(salesOrders.map(x => x.id));
  };

  const handleDragEnd = (result: DropResult) => {
    if (result.reason === 'DROP') {
      if (result.destination != null) {
        const fromIndex = salesOrders.findIndex(x => x.id === Number(result.draggableId));
        moveSalesOrderInPriorities(fromIndex, Number(result.destination.index));
      }
    }

    setDraggedDraggableId(undefined);
  };

  const isLoading = isLoadingPriorities || savePrioritiesMutation.isLoading;

  return (
    <>
      <SalesOrdersTabs />

      <Container>
        <Prompt when={hasUnsavedChanges} message={formatMessage(m.unsavedChangesWarning)} />

        <Box display='flex' alignItems='center' justifyContent='space-between' mb='24px' mt="24px">
          <Typography variant="body1">{formatMessage(m.totalCount, { total: salesOrders?.length ?? 0 })}</Typography>
          <Stack direction="row" gap={2}>
            <LoadingButton
              variant='contained'
              onClick={save}
              disabled={!hasUnsavedChanges || isLoading}
              loading={savePrioritiesMutation.isLoading}
            >
              {formatMessage(m.saveButton)}
            </LoadingButton>
          </Stack>
        </Box>

        {!isError &&
          <DragDropContext onDragEnd={handleDragEnd} onDragStart={(initial) => setDraggedDraggableId(initial.draggableId)}>
            <SalesOrdersPriorities
              salesOrders={salesOrders}
              onChange={changePriority}
              onUnprioritize={removeFromPriorities}
              onPrioritizeFirst={prioritizeFirst}
              onPrioritizeLast={prioritizeLast}
              draggedDraggableId={draggedDraggableId}
            />
          </DragDropContext>
        }
      </Container>
    </>
  );
};
