import React from 'react';
import { Box, Tab, TabProps, Tabs } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

const m = defineMessages({
  planificationTab: { id: 'SalesOrdersTabs.planificationTab', defaultMessage: 'Planning' },
  lotsTab: { id: 'SalesOrdersTabs.lotsTab', defaultMessage: 'Lots' },
  priorizationTab: { id: 'SalesOrdersTabs.priorizationTab', defaultMessage: 'Prioritization' },
  unplanifiedTab: { id: 'SalesOrdersTabs.unplanifiedTab', defaultMessage: 'Unplanned'}
});

export const SalesOrdersTabs: React.FC = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const currentActiveTab = `/${location.pathname.split('/')[1]}`;
  const createTab = (label: string, navigationRoute: string) => ({
    label: label,
    value: navigationRoute,
    onClick: () => history.push(navigationRoute)
  });

  const tabs: TabProps[] = [
    createTab(formatMessage(m.unplanifiedTab), '/orders-unplanified'),
    createTab(formatMessage(m.planificationTab), '/orders-scheduling'),
    createTab(formatMessage(m.priorizationTab), '/orders-prioritization'),
  ];

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={currentActiveTab} centered>
        {tabs.map(tab => <Tab key={tab.value} {...tab} />)}
      </Tabs>
    </Box>
  );
};