import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

import { ProductionItem, Item } from '../../../../types';
import { Lot, ProductionStatus } from '../../../../lots/types';
import { ProductionStatusIcon } from './ProductionStatusIcon';
import { useSelector } from 'react-redux';
import { getProductionItemsByLot } from '../../../selectors';
import { ApplicationState } from '../../../../../../store';
import { lotsReducer } from '../../../../lots/reducer';
import { SalesOrder } from '../../../../../schedule/types';
import { getItemById } from '../../../../selectors';

interface LotButtonProps {
    lot: Lot;
    productionStatus: ProductionStatus;
    onClick: () => void
}

export const LotButton = (props: LotButtonProps) => {
  const productionItems = useSelector<ApplicationState, ProductionItem[]>(state => getProductionItemsByLot(state, props.lot));
  const firstSalesOrder = useSelector<ApplicationState, SalesOrder | undefined>(state => productionItems[0]?.salesOrderId != null ? state.production.salesOrders.byId[productionItems[0].salesOrderId] : undefined);
  const firstItem = useSelector<ApplicationState, Item | undefined>(state => getItemById(state, productionItems[0].itemId));

  const hasSalesOrderInfoToDisplay = productionItems.length === 1
  && (firstSalesOrder?.salesOrderNumber || firstSalesOrder?.clientFullName);

  return (
    <Button icon labelPosition="right" primary size="huge" fluid onClick={() => props.onClick()}>
      <ProductionStatusIcon productionStatus={props.productionStatus} fontSize="large" />
      <div style={{ display: 'flex', minHeight: '3.2em', width: '100%', justifyContent: hasSalesOrderInfoToDisplay ? 'left' : 'center', alignItems: 'center' }}>
        {hasSalesOrderInfoToDisplay && (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'left', justifyContent:'center', fontSize: '0.8em', marginRight: '60px', width: '150px' }}>
            {firstSalesOrder?.salesOrderNumber && (<span>{`# ${firstSalesOrder?.salesOrderNumber}`}</span>)}
            {firstSalesOrder?.clientFullName && (<span>{firstSalesOrder?.clientFullName}</span>)}
            {firstSalesOrder?.clientCompany && (<span>{firstSalesOrder?.clientCompany}</span>)}
          </div>
        )}
        <div>{productionItems.length === 1 && firstItem?.description ? firstItem.description : props.lot.name}</div>
      </div>
    </Button>
  );
};
