import React, { useState } from 'react';
import { Modal, StrictModalProps } from 'semantic-ui-react';
import styles from './PdfModal.module.css';
import { PdfViewer } from './PdfViewer';
import { PdfViewerToolbar, defaultScale } from './PdfViewerToolbar';

interface OwnProps {
    title: string;
    url: string;
}

export type PdfModalProps =
    & OwnProps
    & StrictModalProps;

export const PdfModal: React.FC<PdfModalProps> = (props) => {
  const { title, url, ...modalProps } = props;
  const [zoomScale, setZoomScale] = useState(defaultScale);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const focusPage = (pageNumber: number) => {
    const page = document.querySelector(`.react-pdf__Page[data-page-number="${pageNumber}"]`);
    if (page != null) {
      page.scrollIntoView();
      setCurrentPage(pageNumber);
    }
  };

  return (
    <Modal id={styles.centeredPdfModal} className={styles.pdfModal} closeIcon size="fullscreen" {...modalProps}>
      <Modal.Header>
        <PdfViewerToolbar
          currentPage={currentPage}
          totalPages={totalPages}
          onChangeScale={setZoomScale}
          onChangePage={focusPage}
          onRotate={setRotationAngle}
        />
      </Modal.Header>
      <Modal.Content className={styles.content} scrolling>
        <PdfViewer
          documentUrl={url}
          scale={zoomScale}
          rotate={rotationAngle}
          onLoaded={setTotalPages}
          onPageChanged={setCurrentPage}
        />
      </Modal.Content>
    </Modal>
  );
};
