import * as React from 'react';
import { defineMessages, WrappedComponentProps, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Container, Grid } from 'semantic-ui-react';

import { LoadingDimmer, NoContentPlaceholder, PageHeader, RaisedTile } from '../../../../components';
import { ApplicationState } from '../../../../store';
import * as LayoutActions from '../../../../store/layout/actions';
import * as WorkstationActions from '../actions';
import { isSystemLoading } from '../../../system/selectors';
import { getOperationById } from '../../selectors';
import { Operation } from '../../types';
import { getProductionItemOverviews } from '../selectors';
import { ProductionOrderOverview, ProductionOrderFilter } from '../types';
import { ProductionOrderButton } from './components/ProductionOrderButton';
import { Box, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { ProductionStatus } from '../../lots/types';

export type ProductionOrderManualSelectionPageProps =
  & RouteComponentProps<{ workstationId: string, operationId: string, unitId: string }>
  & WrappedComponentProps;

const m = defineMessages({
  title: { id: 'ProductionOrderManualSelectionPage.title', defaultMessage: 'Select a production item' },
  noContentTitle: { id: 'ProductionOrderManualSelectionPage.noContentTitle', defaultMessage: 'All production orders completed!' },
  noContentSubtitle: { id: 'ProductionOrderManualSelectionPage.noContentSubtitle', defaultMessage: 'There are no more production orders to process for the operation "{name}".' },
  filterOptionReady: { id: 'ProductionOrderManualSelectionPage.filterOptionReady', defaultMessage: 'Ready' },
  filterOptionUpcoming: { id: 'ProductionOrderManualSelectionPage.filterOptionUpcoming', defaultMessage: 'Upcoming' },
  filterOptionCompleted: { id: 'ProductionOrderManualSelectionPage.filterOptionCompleted', defaultMessage: 'Completed' }
});

export const ProductionOrderManualSelectionPage = (props: ProductionOrderManualSelectionPageProps) => {
  const operationId = Number(props.match.params.operationId);
  const operation = useSelector<ApplicationState, Operation | undefined>(state => getOperationById(state, operationId));
  const productionUnitId = Number(props.match.params.unitId);
  const productionOrders = useSelector<ApplicationState, ProductionOrderOverview[]>(state => getProductionItemOverviews(state, productionUnitId, operationId));
  const isSystemInitializing = useSelector<ApplicationState, boolean>(state => isSystemLoading(state));
  const filter = useSelector<ApplicationState, ProductionOrderFilter>(state => state.production.workstation.productionOrderFilter);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (operation) {
      dispatch(LayoutActions.setPageTitle({
        title: formatMessage(m.title),
        subtitle: operation ? operation.name : ''
      }));
    }
  }, []);

  const isLoading = isSystemInitializing;
  const filteredOrders = React.useCallback(() => {
    switch (filter) {
    case ProductionOrderFilter.Ready:
      return productionOrders.filter(p => p.isReadyForProduction && p.roadmapItemsProductionStatus !== ProductionStatus.completed);
    case ProductionOrderFilter.Upcoming:
      return productionOrders.filter(p => !p.isReadyForProduction && p.productionStatus === ProductionStatus.started);
    case ProductionOrderFilter.Completed:
      return productionOrders.filter(p => p.roadmapItemsProductionStatus === ProductionStatus.completed);
    default:
      return productionOrders;
    }
  }, [filter, productionOrders]);

  const onFilterTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    dispatch(WorkstationActions.changeProductionOrderFilter(value as ProductionOrderFilter));
  };

  const renderProductionOrderRow = (productionOrderOverview: ProductionOrderOverview) => {
    return (
      <Grid.Row key={`${productionOrderOverview.type}-${productionOrderOverview.productionItem?.id ?? productionOrderOverview.lot?.id}`}>
        <Grid.Column textAlign="center">
          <ProductionOrderButton
            lot={productionOrderOverview.lot}
            salesOrder={productionOrderOverview.salesOrder}
            productionItem={productionOrderOverview.productionItem}
            productionStatus={productionOrderOverview.productionStatus}
            onClick={() => goToProductionOrderExecution(productionOrderOverview)}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const goToProductionOrderExecution = (productionOrder: ProductionOrderOverview) => {
    const { workstationId, operationId } = props.match.params;

    if (productionOrder.type === 'production-item') {
      props.history.push(`/workstations/${workstationId}/operations/${operationId}/production-orders/${productionOrder.productionItem?.id}/roadmap`);
    }
    else {
      props.history.push(`/workstations/${workstationId}/operations/${operationId}/lots/${productionOrder.lot?.id}/roadmap`);
    }
  };

  const renderProductionItems = () => (<Grid padded centered>
    <LoadingDimmer active={isLoading} />
    <React.Fragment>
      {filteredOrders().map(renderProductionOrderRow)}
      {productionOrders.length === 0 &&
        <NoContentPlaceholder
          icon="check circle"
          title={formatMessage(m.noContentTitle)}
          subtitle={formatMessage(m.noContentSubtitle, { name: operation ? operation.name : '' })}
        />
      }
    </React.Fragment>
  </Grid>);

  return (
    <RaisedTile>
      <>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column width={4}></Grid.Column>
            <Grid.Column width={8} textAlign="center"><PageHeader title={formatMessage(m.title)} centered></PageHeader></Grid.Column>
            <Grid.Column width={4} textAlign="right">
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Container>
          <TabContext value={ProductionOrderFilter.Ready.toString()}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={filter} onChange={onFilterTabChange}>
                <Tab label={formatMessage(m.filterOptionReady)} />
                <Tab label={formatMessage(m.filterOptionUpcoming)} />
                <Tab label={formatMessage(m.filterOptionCompleted)} />
              </Tabs>
            </Box>
            <TabPanel value={ProductionOrderFilter.Ready.toString()}>
              {renderProductionItems()}
            </TabPanel>
            <TabPanel value={ProductionOrderFilter.Upcoming.toString()}>
              {renderProductionItems()}
            </TabPanel>
            <TabPanel value={ProductionOrderFilter.Completed.toString()}>
              {renderProductionItems()}
            </TabPanel>
          </TabContext>
        </Container>
      </>
    </RaisedTile>
  );

};