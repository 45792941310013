import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';

interface OwnProps {
    onClose: () => void;
}

export type SettingsDrawerHeaderProps =
    & OwnProps;

const m = defineMessages({
  title: { id: 'SettingsDrawerHeader.title', defaultMessage: 'Settings' }
});

export const SettingsDrawerHeader: React.FC<SettingsDrawerHeaderProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' padding={2}>
      <Typography variant='body1' fontWeight='500'>{formatMessage(m.title)}</Typography>
      <IconButton onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
