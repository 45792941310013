import _ from 'lodash';
import { Reducer } from 'redux';

import {
  OPERATIONS_LOAD_ALL,
  OPERATIONS_LOAD_ALL_FAILURE,
  OPERATIONS_LOAD_ALL_SUCCESS,
  OPERATIONS_UPDATE_SUCCESS,
  OperationsActionTypes,
  OperationsState,
  OPERATIONS_UPDATE,
  OPERATIONS_UPDATE_FAILURE
} from './types';

const initialOperationsState: OperationsState = {
  byId: {},
  allIds: [],
  isLoading: false,
  loadingIds: []
};

const operationsReducer: Reducer<OperationsState, OperationsActionTypes> = (state = initialOperationsState, action) => {
  switch (action.type) {
  case OPERATIONS_LOAD_ALL: {
    return {
      ...state,
      isLoading: true
    };
  }

  case OPERATIONS_LOAD_ALL_SUCCESS: {
    const operations = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
    const operationIds = action.payload.map(x => x.id);

    return {
      ...state,
      isLoading: false,
      byId: operations,
      allIds: operationIds
    };
  }

  case OPERATIONS_LOAD_ALL_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  case OPERATIONS_UPDATE: {
    return {
      ...state,
      loadingIds: _.union(state.loadingIds, [action.payload.id])
    };
  }

  case OPERATIONS_UPDATE_SUCCESS: {
    const operation = action.payload;
    return {
      ...state,
      byId: {
        ...state.byId,
        [operation.id]: operation
      },
      allIds: _.union(state.allIds, [operation.id]),
      loadingIds: state.loadingIds.filter(x => x !== operation.id)
    };
  }

  case OPERATIONS_UPDATE_FAILURE: {
    return {
      ...state,
      loadingIds: state.loadingIds.filter(x => x !== action.payload.operationId)
    };
  }

  default:
    return state;
  }
};

export { operationsReducer };
