import { LiveUpdatesState, ConnectionStatus, LiveUpdatesActions, SET_CONNECTION_STATUS_ONLINE, TURN_ON_LIVE_UPDATES, TURN_OFF_LIVE_UPDATES, SET_CONNECTION_STATUS_OFFLINE } from './types';
import { Reducer } from 'redux';

const initialLiveUpdatesState: LiveUpdatesState = {
  connectionStatus: ConnectionStatus.online,
  areLiveUpdatesForcedOff: false
};

const liveUpdatesReducer: Reducer<LiveUpdatesState, LiveUpdatesActions> = (state = initialLiveUpdatesState, action: LiveUpdatesActions) => {
  switch (action.type) {
  case SET_CONNECTION_STATUS_ONLINE: {
    return {
      ...state,
      connectionStatus: ConnectionStatus.online
    };
  }

  case SET_CONNECTION_STATUS_OFFLINE: {
    return {
      ...state,
      connectionStatus: ConnectionStatus.offline
    };
  }

  case TURN_ON_LIVE_UPDATES: {
    return {
      ...state,
      areLiveUpdatesForcedOff: false
    };
  }

  case TURN_OFF_LIVE_UPDATES: {
    return {
      ...state,
      areLiveUpdatesForcedOff: true
    };
  }

  default:
    return state;
  }
};

export { liveUpdatesReducer };
