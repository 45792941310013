import { encodeArrayBufferToString } from '../../../utils/WebAuthHelper';
import * as api from './api';

export async function registerAuthenticator (userId: string): Promise<void> {
  const credentialCreationOptions = await api.makeCredentialOptions(userId);
  const credentials = await navigator.credentials.create({ publicKey: credentialCreationOptions }) as PublicKeyCredential;

  const attestationObject = (credentials.response as AuthenticatorAttestationResponse).attestationObject;
  const clientDataJSON = credentials.response.clientDataJSON;
  const rawId = credentials.rawId;
  const data = {
    id: credentials.id,
    rawId: encodeArrayBufferToString(rawId),
    type: credentials.type,
    response: {
      attestationObject: encodeArrayBufferToString(attestationObject),
      clientDataJson: encodeArrayBufferToString(clientDataJSON)
    }
  };

  await api.makeCredential(data as any);
}

export async function unregisterAuthenticator (userId: string): Promise<void> {
  await api.deleteCredentials(userId);
}
