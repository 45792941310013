import Cookies from 'js-cookie';
import { delay } from 'q';
import { all, fork, put, take, takeEvery, select, takeLatest } from 'redux-saga/effects';

import { LOAD_PROFILE_SUCCESS, LOGIN_WEBAUTH_SUCCESS } from '../account';
import { COST_CENTERS_LOAD_ALL_FAILURE, COST_CENTERS_LOAD_ALL_SUCCESS } from '../administration/cost-centers';
import * as CostCenterActions from '../administration/cost-centers/actions';
import * as DevicesActions from '../administration/devices/actions';
import { OPERATIONS_LOAD_ALL_FAILURE, OPERATIONS_LOAD_ALL_SUCCESS } from '../administration/operations';
import * as OperationActions from '../administration/operations/actions';
import * as WorkstationsActions from '../administration/workstations/actions';
import * as ProductionActions from '../production/actions';
import * as RoadmapActions from '../production/roadmaps/actions';
import {
  LOAD_PRODUCTION_UNITS_FAILURE,
  LOAD_PRODUCTION_UNITS_SUCCESS,
} from '../production/types';
import { initialize, initializeFailure, initializeSuccess, loadData, loadDataFailure, loadDataSuccess } from './actions';
import { isAuthenticated } from '../account/selectors';

import { SIGN_IN_SUCCESS, SIGN_IN_FAILURE } from '../administration/devices/types';
import { LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS, LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE, LOAD_ALL_ROADMAP_FIELDS_SUCCESS, LOAD_ALL_ROADMAP_FIELDS_FAILURE, LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS, LOAD_ALL_ROADMAP_TEMPLATES_FAILURE } from '../production/roadmaps/types';
import { LOAD_ALL_WORKSTATIONS_SUCCESS, LOAD_ALL_WORKSTATIONS_FAILURE } from '../administration';
import { SET_CONNECTION_STATUS_ONLINE } from '../live-updates';

const workstationIdCookieName = 'WorkstationId';
const eightHours = 8 * 60 * 60 * 1000;

export function* initializeApplication() {
  try {
    yield put(initialize());

    const currentWorkstationId = getCurrentWorkstationId();
    if (currentWorkstationId != null) {
      yield put(DevicesActions.signIn());
      yield take([SIGN_IN_SUCCESS, SIGN_IN_FAILURE]);
      yield put(initializeSuccess(currentWorkstationId));
    } else {
      yield put(initializeSuccess());
    }

    // Normally, we wait for the LOGIN_SUCCESS event to load the application data. However, if the user is already
    // authenticated, this event will never occur. So, we simply load the data immediately.
    const isAlreadyAuthenticated: boolean = yield select(isAuthenticated);
    if (isAlreadyAuthenticated) {
      yield loadApplicationData();
    }
  } catch (e) {
    console.error('An error occurred while initializing the application.');
    yield put(initializeFailure());
  }
}

export function* loadApplicationData() {
  try {
    yield put(loadData());

    const currentWorkstationId = getCurrentWorkstationId();
    yield currentWorkstationId != null
      ? loadWorkstationDataContinuously(currentWorkstationId)
      : loadAdministrativeData();

    yield put(loadDataSuccess());
  } catch (e) {
    console.error('An error occurred while loading the application data.');
    yield put(loadDataFailure());
  }
}

function* loadWorkstationDataContinuously(currentWorkstationId: number) {
  while (true) {
    yield delay(eightHours);
    yield loadWorkstationData(currentWorkstationId);
  }
}

function* loadAdministrativeData() {
  yield put(ProductionActions.loadProductionUnits());
  yield take([LOAD_PRODUCTION_UNITS_SUCCESS, LOAD_PRODUCTION_UNITS_FAILURE]);
  yield put(CostCenterActions.loadAll());
  yield take([COST_CENTERS_LOAD_ALL_SUCCESS, COST_CENTERS_LOAD_ALL_FAILURE]);
  yield put(OperationActions.loadAll());
  yield take([OPERATIONS_LOAD_ALL_SUCCESS, OPERATIONS_LOAD_ALL_FAILURE]);
  yield put(WorkstationsActions.loadWorkstations());
  yield take([LOAD_ALL_WORKSTATIONS_SUCCESS, LOAD_ALL_WORKSTATIONS_FAILURE]);
  yield put(RoadmapActions.loadRoadmapTemplates());
  yield take([LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS, LOAD_ALL_ROADMAP_TEMPLATES_FAILURE]);
  yield put(RoadmapActions.loadRoadmapFields());
  yield take([LOAD_ALL_ROADMAP_FIELDS_SUCCESS, LOAD_ALL_ROADMAP_FIELDS_FAILURE]);
}

function* loadWorkstationData(currentWorkstationId: number) {
  yield put(ProductionActions.loadProductionUnits());
  yield take([LOAD_PRODUCTION_UNITS_SUCCESS, LOAD_PRODUCTION_UNITS_FAILURE]);
  yield put(CostCenterActions.loadAll());
  yield take([COST_CENTERS_LOAD_ALL_SUCCESS, COST_CENTERS_LOAD_ALL_FAILURE]);
  yield put(OperationActions.loadAll());
  yield take([OPERATIONS_LOAD_ALL_SUCCESS, OPERATIONS_LOAD_ALL_FAILURE]);
  yield put(WorkstationsActions.loadWorkstations());
  yield take([LOAD_ALL_WORKSTATIONS_SUCCESS, LOAD_ALL_WORKSTATIONS_FAILURE]);
  yield put(RoadmapActions.loadRoadmapTemplates());
  yield take([LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS, LOAD_ALL_ROADMAP_TEMPLATES_FAILURE]);
  yield put(RoadmapActions.loadRoadmapFields());
  yield take([LOAD_ALL_ROADMAP_FIELDS_SUCCESS, LOAD_ALL_ROADMAP_FIELDS_FAILURE]);
  yield put(RoadmapActions.loadRoadmapItemsForDevice(currentWorkstationId));
  yield take([LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS, LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE]);
}

function* reloadApplicationData() {
  const currentWorkstationId = getCurrentWorkstationId();
  if (currentWorkstationId != null) {
    yield loadWorkstationData(currentWorkstationId);
  }
}

function getCurrentWorkstationId(): number | undefined {
  const currentWorkstationCookie = Cookies.get(workstationIdCookieName);
  return currentWorkstationCookie != null
    ? Number(currentWorkstationCookie)
    : undefined;
}

function* watchLoginWebAuthSuccess() { yield takeEvery(LOGIN_WEBAUTH_SUCCESS, loadApplicationData); }
function* watchLoadProfileSuccess() { yield takeEvery(LOAD_PROFILE_SUCCESS, loadApplicationData); }
function* watchLiveUpdatesReconnected() { yield takeLatest(SET_CONNECTION_STATUS_ONLINE, reloadApplicationData); }

function* systemSagas() {
  yield all([
    fork(watchLoginWebAuthSuccess),
    fork(watchLoadProfileSuccess),
    fork(watchLiveUpdatesReconnected),
  ]);
}

export default systemSagas;
