import * as React from 'react';
import _ from 'lodash';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { TilePlaceholder } from './TilePlaceholder';

export interface TileDeleteTemplateStrictProps {
    message?: string | React.ReactNode;
    onCancel: () => void;
    onConfirm: () => void;

    className?: string;
    style?: React.CSSProperties;
}

export type TileDeleteTemplateProps =
    & TileDeleteTemplateStrictProps
    & WrappedComponentProps;

const m = defineMessages({
  cancelButton: { id: 'TileDeleteTemplate.cancelButton', defaultMessage: 'No, cancel' },
  confirmButton: { id: 'TileDeleteTemplate.confirmButton', defaultMessage: 'Yes, delete' },
  defaultConfirmationMessage: { id: 'TileDeleteTemplate.defaultConfirmationMessage', defaultMessage: 'Are you sure you want to the delete this object?' }
});

// tslint:disable-next-line: variable-name
const TileDeleteTemplate: React.StatelessComponent<TileDeleteTemplateProps> = (props) => {
  const { formatMessage } = props.intl;
  const message = props.message || formatMessage(m.defaultConfirmationMessage);
  const style = _.merge({}, props.style, { borderColor: 'rgb(208, 25, 25)' });

  return (
    <TilePlaceholder className={props.className} style={style}>
      <p style={{ textAlign: 'center' }}>{message}</p>
      <div>
        <Button content={formatMessage(m.cancelButton)} onClick={props.onCancel} />
        <Button negative content={formatMessage(m.confirmButton)} onClick={props.onConfirm} />
      </div>
    </TilePlaceholder>
  );
};

const intlComponent = injectIntl(TileDeleteTemplate);
export { intlComponent as TileDeleteTemplate };
