import { ButtonProps } from '@mui/material';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ProductionNote } from '../../../types';
import { TechnicalInstructionsModal } from './TechnicalInstructionsModal';
import { ViewInstructionsButton } from './ViewInstructionsButton';

interface OwnProps {
    hasWorkInstructions: boolean;
    hasProductionNotes: boolean;
    itemId: number;
    operationId: number;
    productionItemId?: number;
    productionNotes: ProductionNote[];
    itemCode: string;
    salesOrderId: number | undefined;
}

const m = defineMessages({
  instructionsModalTitle: { id: 'WorkInstructionsButton.instructionsModalTitle', defaultMessage: 'Instructions for the item {itemCode}' }
});

export type TechnicalInstructionsButtonProps =
    & OwnProps
    & ButtonProps;

export const TechnicalInstructionsButton: React.FC<TechnicalInstructionsButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const { hasWorkInstructions, hasProductionNotes, itemId, operationId, productionItemId, productionNotes, itemCode, salesOrderId, ...buttonProps } = props;

  return (
    <TechnicalInstructionsModal
      trigger={<ViewInstructionsButton disabled={!hasWorkInstructions && !hasProductionNotes} {...buttonProps} />}
      title={formatMessage(m.instructionsModalTitle, { itemCode: props.itemCode })}
      hasWorkInstructions={hasWorkInstructions}
      itemId={itemId}
      operationId={operationId}
      productionItemId={productionItemId}
      productionNotes={productionNotes}
      salesOrderId={salesOrderId}
    />
  );
};
