import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ProductionDateSelector } from '../../../components';
import { ApplicationState } from '../../../store';
import { getAllUnits, Unit } from '../../production';
import { ProductionUnitTile } from './components/ProductionUnitTile';

export const ProductionSchedulePage = () => {
  const productionUnits = useSelector<ApplicationState, Unit[]>(getAllUnits);
  const params = useParams<{ productionDate?: string }>();
  const history = useHistory();

  const getSelectedProductionDate = () => moment(params.productionDate).local().startOf('day').toDate();
  const changeSelectedProductionDate = (date: Date) => history.push(`/schedule/${moment(date).format('YYYY-MM-DD')}`);

  return (
    <React.Fragment>
      <ProductionDateSelector
        selectedDate={getSelectedProductionDate()}
        onChange={changeSelectedProductionDate}
        datesWithNotifications={[]}
      />
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {productionUnits
          .map(p => (
            <ProductionUnitTile
              key={p.id}
              productionUnitId={p.id}
              productionDate={getSelectedProductionDate()}
            />
          ))
        }
      </div>
    </React.Fragment>
  );
};