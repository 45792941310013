import { Button, Stack } from '@mui/material';
import React from 'react';
import { ErrorDetails } from '../components/ErrorDetails';
import { ReactComponent as ForbiddenErrorImage } from '../../../assets/images/forbidden-error.svg';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ErrorLayout } from 'components/layout/ErrorLayout';

export const ForbiddenErrorPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const navigateToPreviousPage = () => history.goBack();
  const navigateToHomePage = () => history.push('/');

  return (
    <ErrorLayout>
      <ErrorDetails
        image={<ForbiddenErrorImage />}
        title={formatMessage({ id: 'You do not have access' })}
        message={formatMessage({ id: 'Your current user role does not allow you to access this resource. Please try again with a different account.' })}
        errorCode="403"
      />

      <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
        <Button variant="outlined" onClick={navigateToPreviousPage}>{formatMessage({ id: 'Previous page' })}</Button>
        <Button variant="contained" onClick={navigateToHomePage}>{formatMessage({ id: 'Home page' })}</Button>
      </Stack>
    </ErrorLayout>
  );
};