import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid } from 'semantic-ui-react';

import { getToasts, Toast } from '../../../modules/notifications';
import * as NotificationsActions from '../../../modules/notifications/actions';
import { ToastNotifications } from '../../../modules/notifications/views/components/ToastNotifications';
import { isSystemLoading } from '../../../modules/system/selectors';
import { isApplicationOffline } from '../../../modules/live-updates';
import { SystemLoadingIcon, SystemLoadingProgress, SystemOfflineIcon } from '../../../modules/system/views/components';
import { ApplicationState } from '../../../store';
import styles from './NotificationBar.module.css';

interface DispatchProps {
    notificationsActions: typeof NotificationsActions;
}

interface StoreProps {
    toasts: Toast[];
    isLoading: boolean;
    isOffline: boolean;
}

export type NotificationBarProps =
    & StoreProps
    & DispatchProps
    & WrappedComponentProps;

const m = defineMessages({
  loadingTitle: { id: 'NotificationBar.loadingTitle', defaultMessage: 'Loading production data' }
});

class NotificationBar extends React.Component<NotificationBarProps> {
  public render () {
    return (
      <Grid columns="equal" verticalAlign="bottom">
        <Grid.Column>
        </Grid.Column>
        <Grid.Column width={8}>
          <ToastNotifications
            toasts={this.props.toasts}
            onDismiss={toast => this.props.notificationsActions.hideToast(toast)}
            onViewDetails={toast => this.props.notificationsActions.pinToast(toast)}
            onHideDetails={toast => this.props.notificationsActions.unpinToast(toast)}
          />
        </Grid.Column>
        <Grid.Column id={styles.statusIcons}>
          {this.props.isLoading && <SystemLoadingIcon bordered onClick={this.showSystemLoadingToast} />}
          {this.props.isOffline && <SystemOfflineIcon bordered />}
        </Grid.Column>
      </Grid>
    );
  }

  private showSystemLoadingToast = () => {
    const { formatMessage } = this.props.intl;
    this.props.notificationsActions.showToast({ id: 1, title: formatMessage(m.loadingTitle), icon: <SystemLoadingIcon />, details: <SystemLoadingProgress /> });
  };
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    toasts: getToasts(state),
    isLoading: isSystemLoading(state),
    isOffline: isApplicationOffline(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    notificationsActions: bindActionCreators(NotificationsActions, dispatch)
  };
};

const intlComponent = injectIntl(NotificationBar);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as NotificationBar };
