import * as React from 'react';
import { WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { ApplicationState } from '../../../../../store';
import { CostCenter, getAllCostCenters } from '../../../../production';

interface StoreProps {
    costCenters: CostCenter[];
}

export type CostCentersDropdownProps =
    & StoreProps
    & DropdownProps
    & WrappedComponentProps;

class CostCentersDropdown extends React.Component<CostCentersDropdownProps> {
  public render () {
    const { costCenters, ...dropdownProps } = this.props;
    const costCenterOptions: DropdownItemProps[] = costCenters
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((x): DropdownItemProps => ({
        text: x.name,
        value: x.id
      }));

    return (
      <Dropdown
        options={costCenterOptions}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    costCenters: getAllCostCenters(state)
  };
};

const mapDispatchToProps = () => {
  return {};
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CostCentersDropdown);
export { connectedComponent as CostCentersDropdown };
