import { all, put, call, takeLatest, fork, select } from 'redux-saga/effects';
import { LoadRoadmapItemsForDeviceRequestAction, LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST, LOAD_ALL_ROADMAP_FIELDS_REQUEST, LoadAllRoadmapFieldsRequestAction, LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST, LoadRoadmapItemsForOperationRequestAction, LOAD_ALL_ROADMAP_TEMPLATES_REQUEST, LoadAllRoadmapTemplatesRequestAction, Roadmap, RoadmapField } from './types';
import * as actions from './actions';
import * as api from './api';
import { getLotRoadmapItems, getProductionItemRoadmapItems } from '../workstation/selectors';
import { RoadmapItem } from '../workstation/types';
import { ProductionEntities } from '..';

function* watchLoadRoadmapItemsForOperation() { yield takeLatest(LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST, handleLoadRoadmapItemsForOperation); }
function* handleLoadRoadmapItemsForOperation(action: LoadRoadmapItemsForOperationRequestAction) {
  try {
    const { lotId, productionItemId, operationId } = action.payload;
    const roadmapItems: RoadmapItem[] = productionItemId != null
      ? yield select(state => getProductionItemRoadmapItems(state, productionItemId, operationId))
      : yield select(state => getLotRoadmapItems(state, lotId || 0, operationId));

    const areRoadmapItemsAlreadyLoaded = roadmapItems != null && roadmapItems.length > 0;
    if (areRoadmapItemsAlreadyLoaded) {
      return;
    }

    const result: ProductionEntities = yield call(api.loadRoadmapItemsForOperation, action.payload.operationId, action.payload.lotId, action.payload.productionItemId);
    yield put(actions.loadRoadmapItemsForOperationSuccess(result));
  } catch (ex) {
    yield put(actions.loadRoadmapItemsForOperationFailure(ex as Error));
  }
}

function* watchLoadRoadmapItemsForDevice() { yield takeLatest(LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST, handleLoadRoadmapItemsForDevice); }
function* handleLoadRoadmapItemsForDevice(action: LoadRoadmapItemsForDeviceRequestAction) {
  try {
    const result: ProductionEntities = yield call(api.loadRoadmapItemsForDevice, action.payload.deviceId, action.payload.salesOrderId, action.payload.lotId);
    yield put(actions.loadRoadmapItemsForDeviceSuccess(result));
  } catch (ex) {
    console.error(ex);
    yield put(actions.loadRoadmapItemsForDeviceFailure(ex as Error));
  }
}

function* watchLoadAllRoadmapTemplates() { yield takeLatest(LOAD_ALL_ROADMAP_TEMPLATES_REQUEST, handleLoadAllRoadmapTemplates); }
function* handleLoadAllRoadmapTemplates(action: LoadAllRoadmapTemplatesRequestAction) {
  try {
    const result: Roadmap[] = yield call(api.loadRoadmapTemplates);
    yield put(actions.loadRoadmapTemplatesSuccess(result));
  } catch (ex) {
    yield put(actions.loadRoadmapTemplatesFailure(ex as Error));
  }
}

function* watchLoadAllRoadmapFields() { yield takeLatest(LOAD_ALL_ROADMAP_FIELDS_REQUEST, handleLoadAllRoadmapFields); }
function* handleLoadAllRoadmapFields(action: LoadAllRoadmapFieldsRequestAction) {
  try {
    const result: RoadmapField[] = yield call(api.loadRoadmapFields);
    yield put(actions.loadRoadmapFieldsSuccess(result));
  } catch (ex) {
    yield put(actions.loadRoadmapFieldsError(ex as Error));
  }
}

function* roadmapSagas() {
  yield all([
    fork(watchLoadRoadmapItemsForOperation),
    fork(watchLoadRoadmapItemsForDevice),
    fork(watchLoadAllRoadmapTemplates),
    fork(watchLoadAllRoadmapFields)
  ]);
}

export default roadmapSagas;
