
export const LOAD_PRIORITIES_SUCCESS = 'PRIORITIZATION_LOAD_PRIORITIES_SUCCESS';
export const LOAD_PRIORITIES_FAILURE = 'PRIORITIZATION_LOAD_PRIORITIES_FAILURE';

export const SAVE_PRIORITIES_REQUEST = 'PRIORITIZATION_SAVE_PRIORITIES_REQUEST';
export const SAVE_PRIORITIES_SUCCESS = 'PRIORITIZATION_SAVE_PRIORITIES_SUCCESS';
export const SAVE_PRIORITIES_FAILURE = 'PRIORITIZATION_SAVE_PRIORITIES_FAILURE';

export const PRIORITIZE_SALES_ORDER_SUCCESS = 'PRIORITIZE_SALES_ORDER_SUCCESS';

export const UNPRIORITIZE_SALES_ORDER_SUCCESS = 'UNPRIORITIZE_SALES_ORDER_SUCCESS';

export interface LoadPrioritiesSuccessAction { type: typeof LOAD_PRIORITIES_SUCCESS; payload: { salesOrderIds: number[] }; }
export interface LoadPrioritiesFailureAction { type: typeof LOAD_PRIORITIES_FAILURE; payload: Error; }

export interface SavePrioritiesSuccessAction { type: typeof SAVE_PRIORITIES_SUCCESS; payload: { orderedSalesOrdersIds: number[] }; }
export interface PrioritizeSalesOrderSuccessAction { type: typeof PRIORITIZE_SALES_ORDER_SUCCESS; payload: { salesOrderId: number }; }
export interface UnprioritizeSalesOrderSuccessAction { type: typeof UNPRIORITIZE_SALES_ORDER_SUCCESS; payload: { salesOrderId: number }; }

export type PrioritizationActionTypes =
    | LoadPrioritiesSuccessAction | LoadPrioritiesFailureAction
    | SavePrioritiesSuccessAction
    | PrioritizeSalesOrderSuccessAction | PrioritizeSalesOrderSuccessAction
    | UnprioritizeSalesOrderSuccessAction | UnprioritizeSalesOrderSuccessAction;

export interface PrioritizationState {
    order: number[];
    isLoading: boolean;
    errors: {
        loadPriorities: boolean;
    }
}