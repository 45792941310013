import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { WorkInstruction } from '.';

import * as actions from './actions';
import * as api from './api';
import { CreateWorkInstructionAction, WORK_INSTRUCTIONS_CREATE } from './types';

function * handleCreate (action: CreateWorkInstructionAction) {
  try {
    const workInstruction: WorkInstruction = yield call(api.create, action.payload);
    yield put(actions.createWorkInstructionSuccess(workInstruction));
  } catch (e) {
    yield put(actions.createWorkInstructionFailure(e as Error));
  }
}

function * watchCreate () { yield takeEvery(WORK_INSTRUCTIONS_CREATE, handleCreate); }

function * workInstructionsSagas () {
  yield all([
    fork(watchCreate)
  ]);
}

export default workInstructionsSagas;
