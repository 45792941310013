import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Icon, List, Message, Progress, Table } from 'semantic-ui-react';
import { WorkInstructionBulkImport } from '..';
import { PageHeader, RaisedTile } from '../../../../components';
import { ImportModal } from './components/ImportModal';
import * as api from '../api';
import { RunningBulkImports } from './components/RunningBulkImports/RunningBulkImports';
import { CompletedBulkImports } from './components/CompletedBulkImports/CompletedBulkImports';
import { BulkImportJobProgress } from '../models';
import { JobDetailsModal } from './components/JobDetailsModal';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';

const m = defineMessages({
  title: { id: 'BulkImportPage.title', defaultMessage: 'Bulk import' },
  subtitle: { id: 'BulkImportPage.subtitle', defaultMessage: 'Import a large amount of work instructions into the system.' },
  downloadTemplateButton: { id: 'BulkImportPage.downloadTemplateButton', defaultMessage: 'Download template' },
  importButton: { id: 'BulkImportPage.importButton', defaultMessage: 'Launch an import' },
  failedToUploadWorkInstructionsTitle: { id: 'WorkInstructionsPage.failedToUploadWorkInstructionsTitle', defaultMessage: 'Failed to upload the work instructions' },
  failedToUploadWorkInstructionsMessage: { id: 'WorkInstructionsPage.failedToUploadWorkInstructionsMessage', defaultMessage: 'An error occurred while importing the work instructions. Please try again later or contact your administrator if the problem persists.' },
  failedToLoadCurrentJobsTitle: { id: 'WorkInstructionsPage.failedToLoadCurrentJobsTitle', defaultMessage: 'Failed to load the current bulk import jobs' },
  failedToLoadCurrentJobsMessage: { id: 'WorkInstructionsPage.failedToLoadCurrentJobsMessage', defaultMessage: 'An error occurred while loading the current bulk import jobs. Please try again later or contact your administrator if the problem persists.' }
});

export const BulkImportPage = () => {
  const { formatMessage } = useIntl();
  const { handleError } = useApiErrorHandler();

  const [isImporting, setIsImporting] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const openImportModal = () => setIsImportModalVisible(true);
  const closeImportModal = () => setIsImportModalVisible(false);

  const importInstructions = (importParameters: WorkInstructionBulkImport) => {
    setIsImporting(true);

    api.importCsv(importParameters)
      .catch(handleError)
      .finally(() => setIsImporting(false));
  };

  const [runningJobs, setRunningJobs] = useState<BulkImportJobProgress[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const hasRunningJobs = runningJobs.length > 0;

  const loadJobsProgress = () => api.loadRunningJobs()
    .then(result => setRunningJobs(result));

  useEffect(() => {
    setIsLoading(true);

    loadJobsProgress()
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const refreshJobsProgressTimer = setInterval(() => loadJobsProgress(), 5000);
    return () => clearInterval(refreshJobsProgressTimer);
  }, []);

  const [selectedJob, setSelectedJob] = useState<BulkImportJobProgress | undefined>(undefined);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const closeDetailsModal = () => setIsDetailsModalOpen(false);
  const openDetailsModal = () => setIsDetailsModalOpen(true);
  const viewJobDetails = (job: BulkImportJobProgress) => {
    setSelectedJob(job);
    openDetailsModal();
  };

  return (
    <RaisedTile>
      <PageHeader icon="cloud upload" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

      <div style={{ textAlign: 'right' }}>
        <Button disabled content={formatMessage(m.downloadTemplateButton)} />
        <ImportModal
          importing={isImporting}
          open={isImportModalVisible}
          closeOnDimmerClick={false}
          trigger={<Button primary loading={isImporting || isLoading} disabled={isImporting || isLoading || hasRunningJobs} content={formatMessage(m.importButton)} onClick={openImportModal} />}
          onImport={importInstructions}
          onClose={closeImportModal}
          onCancel={closeImportModal}
        />
      </div>

      <RunningBulkImports isLoading={isLoading} jobs={runningJobs} onViewDetails={viewJobDetails} />
      <CompletedBulkImports onViewDetails={viewJobDetails} />

      <JobDetailsModal
        open={isDetailsModalOpen}
        closeIcon
        job={selectedJob}
        onClose={closeDetailsModal}
      />
    </RaisedTile>
  );
};
