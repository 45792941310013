import { Action, AnyAction } from 'redux';
import { all, delay, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ApiError } from '../../utils/api';
import * as actions from './actions';
import { isToastPinned } from './selectors';
import { SHOW_TOAST, ToastType, UNPIN_TOAST } from './types';

function * handleShowToast (action: ReturnType<typeof actions.showToast>) {
  try {
    // Auto-hide the toasts after 3 seconds (or 7 seconds for errors), unless it is pinned.
    yield delay(action.payload.type !== ToastType.error ? 3000 : 7000);

    const isPinned: boolean = yield select(state => isToastPinned(state, action.payload.id));
    if (!isPinned) {
      yield put(actions.hideToast(action.payload));
    }
  } catch (e) {
    console.error(e);
  }
}

function * handleUnpinToast (action: ReturnType<typeof actions.unpinToast>) {
  try {
    // Auto-hide the toasts after 3 seconds, unless it is pinned.
    yield delay(3000);

    const isPinned: boolean = yield select(state => isToastPinned(state, action.payload.id));
    if (!isPinned) {
      yield put(actions.hideToast(action.payload));
    }
  } catch (e) {
    console.error(e);
  }
}

function * handleFailures (action: AnyAction) {
  const hasApiError = action.payload != null && (action.payload.error instanceof ApiError || action.payload instanceof ApiError);
  const apiError = hasApiError ? (action.payload.error || action.payload) as ApiError : undefined;
  const errorDetails = hasApiError ? ` Détails : ${apiError}` : '';

  // The 401 Unauthorized errors are already handled by the application correctly. There is no need to
  // display an error message to the user, since he will be redirected to the login page automatically.
  if (hasApiError && apiError?.statusCode !== 401) {
    yield put(actions.showToast({
      id: 2,
      title: 'Une erreur est survenue',
      icon: 'times circle',
      details: `Une erreur est survenue en exécutant l'opération '${action.type}'.${errorDetails}`,
      type: ToastType.error
    }));
  }
}

function * watchShowToast () { yield takeLatest(SHOW_TOAST, handleShowToast); }
function * watchUnpinToast () { yield takeLatest(UNPIN_TOAST, handleUnpinToast); }
function * watchFailures () { yield takeEvery((action: Action) => /FAILURE$/.test(action.type), handleFailures); }

function * notificationsSagas () {
  yield all([
    fork(watchShowToast),
    fork(watchUnpinToast),
    fork(watchFailures)
  ]);
}

export default notificationsSagas;
