import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Operation } from '../..';

import * as actions from './actions';
import * as api from './api';
import { OPERATIONS_LOAD_ALL, OPERATIONS_UPDATE, UpdateAction } from './types';

function * handleLoadAll () {
  try {
    const result: Operation[] = yield call(api.loadAll);
    yield put(actions.loadAllSuccess(result));
  } catch (e) {
    yield put(actions.loadAllFailure(e as Error));
  }
}

function * handleUpdate (action: UpdateAction) {
  try {
    const operation: Operation = yield call(api.update, action.payload);
    yield put(actions.updateSuccess(operation));
  } catch (e) {
    yield put(actions.updateFailure(action.payload.id, e as Error));
  }
}

function * watchLoadAll () { yield takeLatest(OPERATIONS_LOAD_ALL, handleLoadAll); }
function * watchUpdate () { yield takeEvery(OPERATIONS_UPDATE, handleUpdate); }

function * operationsSagas () {
  yield all([
    fork(watchLoadAll),
    fork(watchUpdate)
  ]);
}

export default operationsSagas;
