import { Reducer } from 'redux';

import {
  SEARCH_ITEMS,
  SEARCH_ITEMS_FAILURE,
  SEARCH_ITEMS_SUCCESS,
  SearchActionTypes,
  SearchState
} from './types';

const initialState: SearchState = {
  itemsResults: [],
  isSearchingForItems: false
};

const searchReducer: Reducer<SearchState, SearchActionTypes> = (state = initialState, action) => {
  switch (action.type) {
  case SEARCH_ITEMS: {
    return {
      ...state,
      isSearchingForItems: true
    };
  }

  case SEARCH_ITEMS_SUCCESS: {
    return {
      ...state,
      itemsResults: action.payload.items,
      isSearchingForItems: false
    };
  }

  case SEARCH_ITEMS_FAILURE: {
    return {
      ...state,
      itemsResults: [],
      isSearchingForItems: false
    };
  }

  default:
    return state;
  }
};

export { searchReducer };
