import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { print } from 'graphql';
import { DomainError } from 'utils';
import { ApiError } from 'utils/api';

async function customFetcher<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const response = await fetch('/graphql', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'accept-language': dayjs.locale(),
    },
    body: JSON.stringify({
      query: print(document),
      variables,
    }),
  });

  if (!response.ok) {
    throw new ApiError(response.status, response.statusText);
  }

  const json = await response.json();

  if (json.error?.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
    throw new ApiError(401, json.error.message);
  }

  const resultQueryNames = Object.keys(json.data);
  for (const index in resultQueryNames) {
    if (json.data[resultQueryNames[index]]?.errors) {
      const error = json.data[resultQueryNames[index]].errors[0];
      throw new DomainError(error.message);
    }
  }

  return json.data;
}

export function useGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): UseQueryResult<TResult> {
  return useQuery(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [(document.definitions[0] as any).name.value, variables],
    async ({ queryKey }) =>
      customFetcher(document, queryKey[1] ? queryKey[1] : undefined)
  );
}

export function useGraphQLMutation<
  TResult,
  TVariables,
  TError = unknown,
  TContext = unknown
>(
  document: TypedDocumentNode<TResult, TVariables>,
  options?: UseMutationOptions<TResult, TError, TVariables, TContext>
): UseMutationResult<TResult, TError, TVariables, TContext> {
  return useMutation(async (variables?: TVariables) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return customFetcher(document, variables as any);
  }, options);
}
