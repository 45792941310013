import React, { Fragment } from 'react';
import { WorkHistory } from '../types';
import { Divider } from '@mui/material';
import { WorkHistoryRow } from './WorkHistoryRow';
import { Grid } from 'semantic-ui-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { NoContentPlaceholder } from 'components/common';
import _, { Dictionary } from 'lodash';
dayjs.extend(localizedFormat);
dayjs.extend(utc);

interface Props {
  workHistoryByDates: Dictionary<WorkHistory[]>;
  noHistoryTitle: string;
}

export const WorkHistoryByDate: React.FC<Props> = ({ workHistoryByDates, noHistoryTitle }) => {
  return (
    <>
      {
        Object.keys(workHistoryByDates).length === 0
          ? <NoContentPlaceholder title={noHistoryTitle} icon='history' />
          : <>
            {_.map(workHistoryByDates, (workHistoryArray, key) => {
              return (
                <Fragment key={key}>
                  <Divider>{key}</Divider>
                  <Grid padded centered>
                    {workHistoryArray.map((workHistory, index) => (
                      <WorkHistoryRow key={index} workHistory={workHistory} />
                    ))}
                  </Grid>
                </Fragment>
              );
            })}
          </>
      }
    </>
  );
};