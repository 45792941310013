import React from 'react';
import { Grid } from 'semantic-ui-react';
import { LoadingDimmer } from '../../../../../../components';
import { LotOverviewByOperation } from '../../../types';
import { LotSelectionListItem } from './LotSelectionListItem';
import { Operation } from '../../../..';
import { NoLotsPlaceholder } from './NoLotsPlaceholder';

interface OwnProps {
    lots: LotOverviewByOperation[];
    operation?: Operation;
    loading?: boolean;
    onLotClick: (lotId: number) =>void;
}

export type LotSelectionListProps =
    & OwnProps;

export const LotSelectionList: React.FC<LotSelectionListProps> = (props) => {
  const hasLots = props.lots != null && props.lots.length > 0;

  const renderLots = () => {
    return props.lots.map(lotOverview => (
      <LotSelectionListItem
        key={lotOverview.lot.id}
        lotOverview={lotOverview}
        onClick={() => props.onLotClick(lotOverview.lot.id)}
      />
    ));
  };

  return (
    <Grid padded centered>
      <LoadingDimmer active={props.loading} />
      <>
        {hasLots && renderLots()}
        {!hasLots && <NoLotsPlaceholder operationName={props.operation ? props.operation.name : ''} />}
      </>
    </Grid>
  );
};
