import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Statistic } from 'semantic-ui-react';

const m = defineMessages({
  lotsCompletedLabel: { id: 'CostCenterOverview.lotsCompletedLabel', defaultMessage: 'Lots completed' },
  completionPercentageLabel: { id: 'CostCenterOverview.completionPercentageLabel', defaultMessage: 'Completion %' }
});

export const CostCenterStatistics = () => {
  const { formatMessage } = useIntl();

  return (
    <Statistic.Group size="small">
      <Statistic value="---" label={formatMessage(m.lotsCompletedLabel)} />
      <Statistic value="---" label={formatMessage(m.completionPercentageLabel)} />
    </Statistic.Group>
  );
};
