import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../store';
import { WorkstationTile } from './components';

import styles from './WorkstationsPage.module.css';
import { Workstation } from '..';
import { WorkstationEdit } from '../models/WorkstationEdit';
import { getWorkstations } from '../selectors';
import * as WorkstationsActions from '../actions';
import { TilePlaceholder } from '../../../../components';
import { Button } from 'semantic-ui-react';
import { CreateWorkstationTile } from './components/tiles/CreateWorkstationTile';
import { Operation, getOperations, getAllOperations } from '../../../production';

interface DispatchProps {
    workstationsActions: typeof WorkstationsActions;
}

interface StoreProps {
    workstations: Workstation[];
    manufacturingOperations: Operation[];
}

interface StateProps {
    newWorkstation: WorkstationEdit | undefined;
}

export type WorkstationsPageProps =
    & StoreProps
    & DispatchProps
    & WrappedComponentProps;

const m = defineMessages({
  addWorkstationButton: { id: 'WorkstationsPage.addWorkstationButton', defaultMessage: 'Add workstation' }
});

class WorkstationsPage extends React.Component<WorkstationsPageProps, StateProps> {
  public constructor (props: WorkstationsPageProps) {
    super(props);

    this.state = { newWorkstation: undefined };
  }

  public componentDidMount () {
    this.props.workstationsActions.loadWorkstations();
  }

  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={styles.container}>
          {this.state.newWorkstation &&
                        <CreateWorkstationTile
                          className={styles.workstation}
                          onCreate={this.addWorkstation}
                          onCancel={() => this.setState({ newWorkstation: undefined })}
                        />
          }

          {!this.state.newWorkstation &&
                        <TilePlaceholder style={{ margin: '10px' }}>
                          <Button
                            primary
                            content={formatMessage(m.addWorkstationButton)}
                            onClick={() => this.setState({ newWorkstation: new WorkstationEdit() })}
                          />
                        </TilePlaceholder>
          }

          {this.props.workstations.map(x => (
            <WorkstationTile
              key={x.id}
              workstation={x}
              onSave={(workstation) => this.saveWorkstation(x.id, workstation)}
              onDelete={() => this.deleteWorkstation(x.id)}
              className={styles.workstation}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }

  private addWorkstation = (workstation: WorkstationEdit) => {
    this.props.workstationsActions.addWorkstation(workstation);
    this.setState({ newWorkstation: undefined });
  }

  private saveWorkstation = (workstationId: number, workstation: WorkstationEdit) => {
    this.props.workstationsActions.updateWorkstation(workstationId, workstation);
  }

  private deleteWorkstation = (workstationId: number) => {
    this.props.workstationsActions.deleteWorkstation(workstationId);
  }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    workstations: getWorkstations(state),
    manufacturingOperations: getAllOperations(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    workstationsActions: bindActionCreators(WorkstationsActions, dispatch)
  };
};

const intlComponent = injectIntl(WorkstationsPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as WorkstationsPage };
