import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { LOAD_PRODUCTION_UNITS } from './types';
import { Unit } from '.';

function * watchLoadProductionUnits () { yield takeLatest(LOAD_PRODUCTION_UNITS, handleLoadProductionUnits); }
function * handleLoadProductionUnits () {
  try {
    const productionUnits: Unit[] = yield call(api.loadProductionUnits);
    yield put(actions.loadProductionUnitsSuccess(productionUnits));
  } catch (ex) {
    yield put(actions.loadProductionUnitsFailure(ex as Error));
  }
}

function * productionSagas () {
  yield all([
    fork(watchLoadProductionUnits)
  ]);
}

export default productionSagas;
