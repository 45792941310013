import { List } from '@mui/material';
import React from 'react';
import { allLocales, SupportedLocales } from '../../../../configureI18n';
import { LanguageSwitcherButton } from './LanguageSwitcherButton';

interface OwnProps {
  onChange: (locale: SupportedLocales) => void;
}

export type LanguageSwitcherProps =
  & OwnProps;

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => {
  return (
    <List>
      {allLocales.map(locale => (
        <LanguageSwitcherButton
          key={locale}
          locale={locale}
          onClick={() => props.onChange(locale)}
        />
      ))}
    </List>
  );
};
