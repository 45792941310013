import React, { useState, useEffect, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Container } from 'semantic-ui-react';
import { RoadmapsTable } from './components/RoadmapsTable';
import { useHistory } from 'react-router-dom';
import { Roadmap } from '../../../production/roadmaps/types';
import { useSelector, useDispatch } from 'react-redux';
import { getAllTemplates, areTemplatesLoading } from '../../../production/roadmaps/selectors';
import * as layoutActions from '../../../../store/layout/actions';
import { SearchControls } from '../../../../components/common/SearchControls';
import { searchRoadmaps } from '../utils';

const m = defineMessages({
  title: { id: 'RoadmapsPage.title', defaultMessage: 'Customize the production roadmaps' },
  subtitle: { id: 'RoadmapsPage.subtitle', defaultMessage: 'Manage the fields that are displayed in the production roadmaps.' },
  roadmapsCount: { id: 'RoadmapsPage.roadmapsCount', defaultMessage: '{count, plural, =0 {No roadmaps} one {{count} roadmap} other {{count} roadmaps}}' },
  addRoadmapButton: { id: 'RoadmapsPage.addRoadmapButton', defaultMessage: 'Add Roadmap' }
});

export const ManageRoadmapsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const allRoadmaps = useSelector(getAllTemplates);
  const isLoadingRoadmaps = useSelector(areTemplatesLoading);
  const [displayedRoadmaps, setDisplayedRoadmaps] = useState<Roadmap[]>([]);

  const navigateToRoadmapModificationPage = (roadmap: Roadmap) => history.push(`/administration/roadmaps/${roadmap.id}`);
  const navigateToRoadmapCreationPage = () => history.push('/administration/roadmaps/new');

  useEffect(() => {
    dispatch(layoutActions.setPageTitle({ title: formatMessage(m.title), subtitle: formatMessage(m.subtitle) }));
  }, [dispatch, formatMessage]);

  useEffect(() => {
    setDisplayedRoadmaps(allRoadmaps);
  }, [allRoadmaps]);

  const clearSearchResults = () => setDisplayedRoadmaps(allRoadmaps);
  const performSearch = useCallback((searchTerm: string) => {
    const searchResults = searchRoadmaps(allRoadmaps, searchTerm);
    setDisplayedRoadmaps(searchResults);
  }, [allRoadmaps, setDisplayedRoadmaps]);

  return (
    <Container>
      <SearchControls
        totalResultsCount={allRoadmaps.length}
        totalResultsMessage={m.roadmapsCount}
        searchResultsCount={displayedRoadmaps.length}
        onChange={performSearch}
        onClear={clearSearchResults}
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Button primary content={formatMessage(m.addRoadmapButton)} onClick={navigateToRoadmapCreationPage} />
      </SearchControls>

      <RoadmapsTable
        roadmaps={displayedRoadmaps}
        loading={isLoadingRoadmaps}
        onClick={navigateToRoadmapModificationPage}
      />
    </Container>
  );
};
