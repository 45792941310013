import * as React from 'react';

interface OwnProps {
    onClickOutside: () => void;
    children: React.ReactChild;
}

export type DetectClickOutsideProps =
    & OwnProps;

// A simple component that detects when the user clicks outside the component's children.
class DetectClickOutside extends React.Component<DetectClickOutsideProps> {
  private divRef = React.createRef<HTMLDivElement>();

  public componentDidMount () {
    document.addEventListener('mousedown', this.detectClickOutside);
  }

  public componentWillUnmount () {
    document.removeEventListener('mousedown', this.detectClickOutside);
  }

  public render () {
    return (
      <div ref={this.divRef}>
        {this.props.children}
      </div>
    );
  }

  private detectClickOutside = (ev: MouseEvent) => {
    const divRef = this.divRef.current;
    if (divRef != null && ev.target != null) {
      const isClickOutsideComponent = !divRef.contains(ev.target as Node);
      if (isClickOutsideComponent) {
        this.props.onClickOutside();
      }
    }
  };
}

export { DetectClickOutside };
