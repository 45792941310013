import { ApplicationState } from '../../../store';
import { Roadmap, RoadmapField } from './types';

export const areTemplatesLoading = (state: ApplicationState): boolean => state.production.roadmaps.templates.isLoading;
export const getTemplateById = (state: ApplicationState, id: number): Roadmap | undefined => state.production.roadmaps.templates.byId[id];
export const getAllTemplates = (state: ApplicationState): Roadmap[] => {
  const templates = state.production.roadmaps.templates.allIds
    .map(id => getTemplateById(state, id))
    .filter(x => x != null);

  return templates as Roadmap[];
};

export const getDefaultTemplate = (state: ApplicationState): Roadmap | undefined => {
  const allTemplates = getAllTemplates(state);
  const defaultTemplate = allTemplates.find(x => x.isDefault) ||
        allTemplates[0];

  return defaultTemplate;
};

export const areFieldsLoading = (state: ApplicationState) => state.production.roadmaps.fields.isLoading;
export const getFieldById = (state: ApplicationState, id: number): RoadmapField | undefined => state.production.roadmaps.fields.byId[id];
export const getAllFields = (state: ApplicationState): RoadmapField[] => {
  const fields = state.production.roadmaps.fields.allIds
    .map(x => getFieldById(state, x))
    .filter(x => x != null);

  return fields as RoadmapField[];
};
