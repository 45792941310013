import { all, call, fork, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { AddWorkstationAction, ADD_WORKSTATION, ADD_WORKSTATION_FAILURE, LOAD_ALL_WORKSTATIONS, UpdateWorkstationAction, UPDATE_WORKSTATION, DELETE_WORKSTATION, DeleteWorkstationAction } from './types';

import * as api from './api';
import * as actions from './actions';
import { Workstation } from '..';

function * handleAdd (action: AddWorkstationAction) {
  try {
    const result: Workstation = yield call(api.addWorkstation, action.payload.workstation);
    yield put(actions.addWorkstationSuccess(result));
  } catch (e) {
    yield put(actions.addWorkstationFailure(e as Error));
  }
}

function * handleDelete (action: DeleteWorkstationAction) {
  try {
    yield call(api.deleteWorkstation, action.payload.workstationId);
    yield put(actions.deleteWorkstationSuccess(action.payload.workstationId));
  } catch (e) {
    yield put(actions.deleteWorkstationFailure(action.payload.workstationId, e as Error));
  }
}

function * handleLoadAll () {
  try {
    const result: Workstation[] = yield call(api.loadAll);
    yield put(actions.loadWorkstationsSuccess(result));
  } catch (e) {
    yield put(actions.loadWorkstationsFailure(e as Error));
  }
}

function * handleUpdate (action: UpdateWorkstationAction) {
  try {
    const result: Workstation = yield call(api.updateWorkstation, action.payload.workstationId, action.payload.workstation);
    yield put(actions.updateWorkstationSuccess(result));
  } catch (e) {
    yield put(actions.updateWorkstationFailure(action.payload.workstationId, e as Error));
  }
}

function * watchAdd () { yield takeEvery(ADD_WORKSTATION, handleAdd); }
function * watchDelete () { yield takeEvery(DELETE_WORKSTATION, handleDelete); }
function * watchLoadAll () { yield takeEvery(LOAD_ALL_WORKSTATIONS, handleLoadAll); }
function * watchUpdate () { yield takeEvery(UPDATE_WORKSTATION, handleUpdate); }

function * accountSagas () {
  yield all([
    fork(watchAdd),
    fork(watchDelete),
    fork(watchLoadAll),
    fork(watchUpdate)
  ]);
}

export default accountSagas;
