import { DropdownItemProps } from 'semantic-ui-react';
import { ApplicationState } from '../../../store';
import { CostCenterOverview } from './types';
import { Unit } from '../..';

export const getProductionUnits = (state: ApplicationState): Unit[] => {
  return state.production.units.allIds
    .map(x => state.production.units.byId[x])
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getProductionUnitDropdownOptions = (state: ApplicationState, costCenterId: number | undefined): DropdownItemProps[] => {
  if (costCenterId) {
    const costCenter = state.production.costCenters.byId[costCenterId];
    return state.production.units.allIds
      .map(x => state.production.units.byId[x])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((x): DropdownItemProps => ({
        key: x.id,
        value: x.id,
        text: x.name,
        selected: costCenter.productionUnits.indexOf(x.id) >= 0
      }));
  } else {
    return state.production.units.allIds
      .map(x => state.production.units.byId[x])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((x): DropdownItemProps => ({
        key: x.id,
        value: x.id,
        text: x.name,
        selected: false
      }));
  }
};

export const areCostCentersLoading = (state: ApplicationState): boolean => state.production.costCenters.isLoading;

export const getCostCenterOverview = (state: ApplicationState, costCenterId: number): CostCenterOverview[] => state.production.costCenters.overviews.byId[costCenterId] || [];
export const isCostCenterOverviewLoading = (state: ApplicationState, costCenterId: number): boolean => state.production.costCenters.overviews.loadingIds.filter(x => x === costCenterId).length > 0;
