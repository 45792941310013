import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { Ref } from 'semantic-ui-react';
import ReactDOM from 'react-dom';
import { RoadmapFieldsSortEntry } from './RoadmapFieldsSortEntry';
import { RoadmapSortEntry } from '.';
import styles from './DraggableRoadmapFieldsSortEntry.module.css';

interface OwnProps {
    entry: RoadmapSortEntry;
    index: number;
    onChange: (updatedEntry: RoadmapSortEntry) => void;
    onRemove: () => void;
}

export type DraggableRoadmapFieldsSortEntryProps =
    & OwnProps;

const portal: HTMLElement = document.createElement('table');
portal.classList.add('ui', 'selectable', 'very', 'basic', 'fixed', 'table');
portal.classList.add(styles.draggingField);
document.body.appendChild(portal);

export const DraggableRoadmapFieldsSortEntry: React.FC<DraggableRoadmapFieldsSortEntryProps> = (props) => {
  const node = (provided: DraggableProvided) => (
    <Ref innerRef={provided.innerRef}>
      <RoadmapFieldsSortEntry
        className={styles.draggableField}
        key={props.entry.field.id}
        entry={props.entry}
        draggable={provided}
        onChange={props.onChange}
        onRemove={props.onRemove}
      />
    </Ref>
  );

  // Since the draggable roadmap field can be displayed inside Semantic UI Modal components,
  // we need to move the component within a portal while rendering it, otherwise we'll have
  // issue where the component is rendered far away from the mouse cursor because of the
  // position: fixed attribute. See: https://github.com/atlassian/react-beautiful-dnd/issues/485
  return (
    <Draggable key={props.entry.field.id} draggableId={props.entry.field.id.toString()} index={props.index}>
      {(provided, snapshot) => snapshot.isDragging
        ? ReactDOM.createPortal((
          <tbody>
            {node(provided)}
          </tbody>
        ), portal)
        : node(provided)}
    </Draggable>
  );
};
