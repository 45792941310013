import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { DropdownProps, Icon, Table, TableRowProps } from 'semantic-ui-react';
import { RoadmapSortEntry, SortDirection } from '.';
import { DragDropIcon } from '../../../../../../components';
import { RoadmapFieldDescription } from './RoadmapFieldDescription';
import { SortDirectionDropdown } from './SortDirectionDropdown';

interface OwnProps {
    entry: RoadmapSortEntry;
    draggable?: DraggableProvided;

    onChange: (updatedEntry: RoadmapSortEntry) => void;
    onRemove: () => void;
}

export type RoadmapFieldsSortEntryProps =
    & TableRowProps
    & OwnProps;

export const RoadmapFieldsSortEntry: React.FC<RoadmapFieldsSortEntryProps> = (props) => {
  const { entry, draggable, onChange, onRemove, ...tableRowProps } = props;

  const canBeDragAndDropped = props.draggable != null;
  const draggableProps = props.draggable != null ? props.draggable.draggableProps : {};
  const dragHandleProps = props.draggable != null ? props.draggable.dragHandleProps : {};

  const changeSortDirection = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    props.onChange({ ...props.entry, sortDirection: data.value as SortDirection || SortDirection.ascending });
  };

  return (
    <Table.Row {...draggableProps} {...dragHandleProps} {...tableRowProps}>
      <Table.Cell collapsing>
        {canBeDragAndDropped && <DragDropIcon size="large" />}
      </Table.Cell>

      <Table.Cell>
        <RoadmapFieldDescription field={props.entry.field} />
      </Table.Cell>

      {/* rgba(0,0,0,.4) */}

      <Table.Cell>
        <SortDirectionDropdown
          basic
          value={props.entry.sortDirection}
          onChange={changeSortDirection}
        />
      </Table.Cell>

      <Table.Cell collapsing>
        <Icon link size="large" name="trash alternate" onClick={props.onRemove} />
      </Table.Cell>
    </Table.Row>
  );
};
