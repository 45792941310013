import { DateCalendar, DateCalendarProps, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import useApiErrorHandler from 'utils/ApiErrorHandler';
import styles from './PlanningCalendar.module.css';

interface OwnProps {
    forProductionDate?: Dayjs;
}

export type PlanningCalendarProps =
    & OwnProps
    & DateCalendarProps<Dayjs>;

export const PlanningCalendar: React.FC<PlanningCalendarProps> = (props) => {
  const { onSettledErrorHandler } = useApiErrorHandler();

  const { forProductionDate, ...otherProps } = props;

  const { data: datesWithUnprioritizedOrders } = useQuery<string[]>(
    ['planning-unprioritized-dates'],
    async () => (await axios.get<string[]>('/api/planning/unprioritized-dates')).data,
    { onSettled: onSettledErrorHandler }
  );

  const renderDay = (pickersDayProps: PickersDayProps<Dayjs>) => {
    const hasUnprioritizedOrders = (datesWithUnprioritizedOrders ?? []).indexOf(pickersDayProps.day.format('YYYY-MM-DDTHH:mm:ss')) >= 0;

    return (
      <PickersDay
        className={hasUnprioritizedOrders ? styles.hasUnprioritizedOrders : undefined}
        {...pickersDayProps}
      />
    );
  };

  return (
    <DateCalendar
      defaultValue={forProductionDate ?? dayjs()}
      slots={{
        day: renderDay,
      }}
      {...otherProps}
    />
  );
};