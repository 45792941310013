import * as React from 'react';
import * as _ from 'lodash';

import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { allLocales, SupportedLocales } from '../../../configureI18n';

interface LanguageSelectorOwnProps {
    locale: SupportedLocales;
    onChange: (locale: SupportedLocales) => void;
}

export type LanguageSelectorProps =
    & LanguageSelectorOwnProps
    & WrappedComponentProps;

class LanguageSelector extends React.Component<LanguageSelectorProps> {
  public render () {
    const localeOptions = allLocales.map((x): DropdownItemProps => ({
      key: x,
      text: x.toUpperCase(),
      value: x,
      active: this.props.locale === x
    }));

    return (
      <Dropdown
        item={true}
        options={localeOptions}
        defaultValue={this.props.locale}
        onChange={(_event, data) => this.props.onChange(data.value as SupportedLocales)}
      />
    );
  }
}

const connectedComponent = injectIntl(LanguageSelector);
export { connectedComponent as LanguageSelector };
