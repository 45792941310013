import { ApplicationState } from '..';
import { getCurrentDevice } from '../../modules/administration/devices/selectors';

export const getCurrentLocale = (state: ApplicationState) => state.layout.currentLocale;
export const getCurrentPageTitle = (state: ApplicationState) => state.layout.currentPageTitle;
export const getGlobalNotification = (state: ApplicationState) => state.layout.globalNotification;
export const isGlobalNotificationVisible = (state: ApplicationState) => state.layout.isGlobalNotificationVisible;
export const isNavigationMenuVisible = (state: ApplicationState) => state.layout.isNavigationMenuVisible;
export const isNavigationTriggerVisible = (state: ApplicationState) => getCurrentDevice(state) == null;
export const isSettingsDrawerVisible = (state: ApplicationState) => state.layout.isSettingsDrawerVisible;
export const isHistoryDrawerVisible = (state: ApplicationState) => state.layout.isHistoryDrawerVisible;
export const isSearchModalVisible = (state: ApplicationState) => state.layout.isSearchModalVisible;
