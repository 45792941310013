import { LoadDevices, LOAD_DEVICES, LoadDevicesSuccess, LOAD_DEVICES_SUCCESS, Device, LoadDevicesFailure, LOAD_DEVICES_FAILURE, UpdateDevice, UPDATE_DEVICE, UpdateDeviceSuccess, UPDATE_DEVICE_SUCCESS, UpdateDeviceFailure, UPDATE_DEVICE_FAILURE, DeleteDevice, DELETE_DEVICE_SUCCESS, DELETE_DEVICE, DeleteDeviceSuccess, DeleteDeviceFailure, DELETE_DEVICE_FAILURE, SignIn, SIGN_IN, SignInSuccess, SIGN_IN_SUCCESS, SignInFailure, SIGN_IN_FAILURE, REGISTER, Register, RegisterSuccess, REGISTER_SUCCESS, RegisterFailure, REGISTER_FAILURE } from './types';

export function loadDevices (): LoadDevices {
  return {
    type: LOAD_DEVICES
  };
}

export function loadDevicesSuccess (devices: Device[]): LoadDevicesSuccess {
  return {
    type: LOAD_DEVICES_SUCCESS,
    payload: devices
  };
}

export function loadDevicesFailure (error: Error): LoadDevicesFailure {
  return {
    type: LOAD_DEVICES_FAILURE,
    payload: error
  };
}

export function updateDevice (device: Device): UpdateDevice {
  return {
    type: UPDATE_DEVICE,
    payload: { device }
  };
}

export function updateDeviceSuccess (device: Device): UpdateDeviceSuccess {
  return {
    type: UPDATE_DEVICE_SUCCESS,
    payload: { device }
  };
}

export function updateDeviceFailure (deviceId: number, error: Error): UpdateDeviceFailure {
  return {
    type: UPDATE_DEVICE_FAILURE,
    payload: { deviceId, error }
  };
}

export function deleteDevice (deviceId: number): DeleteDevice {
  return {
    type: DELETE_DEVICE,
    payload: { deviceId }
  };
}

export function deleteDeviceSuccess (deviceId: number): DeleteDeviceSuccess {
  return {
    type: DELETE_DEVICE_SUCCESS,
    payload: { deviceId }
  };
}

export function deleteDeviceFailure (deviceId: number, error: Error): DeleteDeviceFailure {
  return {
    type: DELETE_DEVICE_FAILURE,
    payload: { deviceId, error }
  };
}

export function signIn (): SignIn {
  return { type: SIGN_IN };
}

export function signInSuccess (currentDevice: Device): SignInSuccess {
  return {
    type: SIGN_IN_SUCCESS,
    payload: currentDevice
  };
}

export function signInFailure (error: Error): SignInFailure {
  return {
    type: SIGN_IN_FAILURE,
    payload: error
  };
}

export function register (): Register {
  return { type: REGISTER };
}

export function registerSuccess (currentDevice: Device): RegisterSuccess {
  return {
    type: REGISTER_SUCCESS,
    payload: currentDevice
  };
}

export function registerFailure (error: Error): RegisterFailure {
  return {
    type: REGISTER_FAILURE,
    payload: error
  };
}
