import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Button, Header } from 'semantic-ui-react';
import styles from './WorkstationButton.module.css';
import { Workstation } from '../../../../administration';

interface OwnProps {
    workstation: Workstation;
    onClick: () => void;
}

export type WorkstationButtonProps =
    & OwnProps
    & WrappedComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const m = defineMessages({
});

class WorkstationButton extends React.Component<WorkstationButtonProps> {
  public render () {
    return (
      <Button className={styles.button} primary fluid onClick={this.props.onClick}>
        <Header as="h2">
          {this.props.workstation.name}
          <Header.Subheader>{this.props.workstation.description}</Header.Subheader>
        </Header>
      </Button>
    );
  }
}

const intlComponent = injectIntl(WorkstationButton);
export { intlComponent as WorkstationButton };
