import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styles from './InternalLink.module.css';

export type InternalLinkProps =
    & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { children, ...buttonProps } = props;

  return (
    <button className={styles.linkButton} {...buttonProps}>
      {children}
    </button>
  );
};
