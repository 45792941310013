import { LowPriority } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Paper } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid/components';
import { DataTable, DataTableProps } from 'components/common/DataTable';
import { LotStatusIcon } from 'modules/production/scheduling/components/LotStatusIcon';
import { SalesOrder } from 'modules/schedule';
import moment from 'moment';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CompleteOrderConfirmationDialog } from './CompleteOrderConfirmationDialog';

const m = defineMessages({
  headerDate: {
    id: 'UnplanifiedOrdersTable.headerDate',
    defaultMessage: 'Date',
  },
  headerSalesOrderNumber: {
    id: 'UnplanifiedOrdersTable.headerSalesOrderNumber',
    defaultMessage: 'Order #',
  },
  headerClient: {
    id: 'UnplanifiedOrdersTable.headerClient',
    defaultMessage: 'Client',
  },
  headerCompany: {
    id: 'UnplanifiedOrdersTable.headerCompany',
    defaultMessage: 'Company',
  },
  headerStatus: {
    id: 'UnplanifiedOrdersTable.headerStatus',
    defaultMessage: 'Status',
  },
  noOrderSales: {
    id: 'UnplanifiedOrdersTable.noOrderSales',
    defaultMessage: 'No sales orders to planify',
  },
});

const HighPriorityIcon = () => <LowPriority sx={{ transform: 'scaleY(-1)' }} />;
const LowhPriorityIcon = () => <LowPriority sx={{ transform: 'scaleX(-1)' }} />;

interface OwnProps {
  salesOrders: SalesOrder[];
  onPrioritizeFirst: (salesOrderId: number) => void;
  onPrioritizeLast: (salesOrderId: number) => void;
  onComplete: (salesOrderId: number) => void;
}

export type UnplanifiedOrdersTableProps = OwnProps &
  Omit<DataTableProps<SalesOrder>, 'columns' | 'rows'>;

export const UnplanifiedOrdersTable: React.FC<UnplanifiedOrdersTableProps> = (
  props
) => {
  const { formatMessage } = useIntl();

  const [
    isConfirmCompleteOrderDialogOpen,
    setIsConfirmCompleteOrderDialogOpen,
  ] = useState(false);
  const [orderToConfirm, setOrderToConfirm] = useState<SalesOrder | null>(null);

  const {
    salesOrders,
    onPrioritizeFirst,
    onPrioritizeLast,
    onComplete,
    ...dataTableProps
  } = props;

  const handleCompleteOrderRequest = (salesOrder: SalesOrder) => {
    setOrderToConfirm(salesOrder);
    setIsConfirmCompleteOrderDialogOpen(true);
  };

  const handleCompleteOrderConfirmed = () => {
    setIsConfirmCompleteOrderDialogOpen(false);
    orderToConfirm && onComplete(orderToConfirm.id);
  };

  return (
    <>
      <Paper>
        <DataTable
          checkboxSelection
          rows={salesOrders ?? []}
          columns={[
            {
              field: 'orderedOn',
              type: 'date',
              headerName: formatMessage(m.headerDate),
              valueGetter: (data) => moment(data.row.orderedOn).toDate(),
              renderCell: ({ row, value }) =>
                row.id && moment(value).format('ll'),
            },
            {
              field: 'salesOrderNumber',
              flex: 1,
              type: 'number',
              headerName: formatMessage(m.headerSalesOrderNumber),
            },
            {
              field: 'client',
              flex: 1,
              headerName: formatMessage(m.headerClient),
              valueGetter: (data) => data.row.clientFullName,
            },
            {
              field: 'company',
              flex: 1,
              headerName: formatMessage(m.headerCompany),
              valueGetter: (data) => data.row.clientCompany,
            },
            {
              field: 'productionStatus',
              headerName: formatMessage(m.headerStatus),
              renderCell: ({ row, value }) =>
                row.id && <LotStatusIcon size="large" status={value} />,
            },
            {
              field: 'actions',
              type: 'actions',
              width: 80,
              getActions: (params) => [
                <GridActionsCellItem
                  key="first"
                  icon={<HighPriorityIcon />}
                  label={formatMessage({ id: 'Prioritize first' })}
                  showInMenu
                  onClick={() => onPrioritizeFirst(params.row.id)}
                />,
                <GridActionsCellItem
                  key="last"
                  icon={<LowhPriorityIcon />}
                  label={formatMessage({ id: 'Prioritize last' })}
                  showInMenu
                  onClick={() => onPrioritizeLast(params.row.id)}
                />,
                <GridActionsCellItem
                  key="complete"
                  onClick={() => handleCompleteOrderRequest(params.row)}
                  icon={<CheckIcon />}
                  label={formatMessage({ id: 'Complete' })}
                  showInMenu
                />,
              ],
            },
          ]}
          {...dataTableProps}
        />
      </Paper>
      <CompleteOrderConfirmationDialog
        open={isConfirmCompleteOrderDialogOpen}
        salesOrder={orderToConfirm}
        onClose={() => setIsConfirmCompleteOrderDialogOpen(false)}
        onConfirmed={handleCompleteOrderConfirmed}
      />
    </>
  );
};
