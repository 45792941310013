import { createTheme, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

const lightTheme = createTheme({
  palette: {
    background: {
      default: '#f2f4f5'
    },
    secondary: {
      main: grey[300]
    },
  }
});

export const allThemes: Record<string, Theme> = {
  default: lightTheme,
  light: lightTheme
};
