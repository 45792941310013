import { defineMessages } from 'react-intl';

import { SalesOrder } from 'modules/schedule';
import { ValidationResult } from '../../../models';
import { nameof } from '../../../utils';
import { CoverType, Item, Operation, ProductionItem } from '../../production';

export const WORK_INSTRUCTIONS_CREATE = 'WORK_INSTRUCTIONS_CREATE';
export const WORK_INSTRUCTIONS_CREATE_SUCCESS = 'WORK_INSTRUCTIONS_CREATE_SUCCESS';
export const WORK_INSTRUCTIONS_CREATE_FAILURE = 'WORK_INSTRUCTIONS_CREATE_FAILURE';

export const WORK_INSTRUCTIONS_LOAD_ALL = 'WORK_INSTRUCTIONS_LOAD_ALL';
export const WORK_INSTRUCTIONS_LOAD_ALL_SUCCESS = 'WORK_INSTRUCTIONS_LOAD_ALL_SUCCESS';
export const WORK_INSTRUCTIONS_LOAD_ALL_FAILURE = 'WORK_INSTRUCTIONS_LOAD_ALL_FAILURE';

export interface CreateWorkInstructionAction { type: typeof WORK_INSTRUCTIONS_CREATE; payload: WorkInstructionEdit; }
export interface CreateWorkInstructionSuccessAction { type: typeof WORK_INSTRUCTIONS_CREATE_SUCCESS; payload: WorkInstruction; }
export interface CreateWorkInstructionFailureAction { type: typeof WORK_INSTRUCTIONS_CREATE_FAILURE; payload: { error: Error }; }

export interface LoadAllAction { type: typeof WORK_INSTRUCTIONS_LOAD_ALL; }
export interface LoadAllSuccessAction { type: typeof WORK_INSTRUCTIONS_LOAD_ALL_SUCCESS; payload: WorkInstruction[]; }
export interface LoadAllFailureAction { type: typeof WORK_INSTRUCTIONS_LOAD_ALL_FAILURE; payload: { error: Error }; }

export type WorkInstructionsActionTypes =
    | CreateWorkInstructionAction | CreateWorkInstructionSuccessAction | CreateWorkInstructionFailureAction
    | LoadAllAction | LoadAllSuccessAction | LoadAllFailureAction;

export interface WorkInstructionFilter {
    itemId?: string;
    costCenterId?: number;
    operationId?: number;
    productionOrderId?: number;
}

export interface WorkInstructionBulkImport {
    parametersFileName: string;
    documents: File[];
}

export const initialWorkInstructionFilter: WorkInstructionFilter = {
  itemId: undefined,
  costCenterId: undefined,
  operationId: undefined,
  productionOrderId: undefined
};

export interface WorkInstructionsState {
    byId: { [id: number]: WorkInstruction };
    allIds: number[];
    isLoading: boolean;
}

export interface WorkInstruction {
    id: number;
    workInstructionId: number;
    createdOn: Date;
    effectiveOn: Date;
    documentUrl: string;
    documentName: string;
    documentContentType: string;
    forItemId: number;
    forItem: Item;
    forProductionItemId: number | undefined;
    forProductionItem: ProductionItem | undefined;
    forOperationId: number | undefined;
    forOperation: Operation | undefined;
    forSalesOrderId: number | undefined;
    forSalesOrder: SalesOrder | undefined;
}

export interface WorkInstructionAssociation {
    id: number;
    workInstructionId: number;
    forItemId: number;
    forItem: Item;
    forProductionItemId: number | undefined;
    forProductionItem: ProductionItem | undefined;
    forOperationId: number | undefined;
    forOperation: Operation | undefined;
    forSalesOrderId: number | undefined;
}

export interface BulkImportResult {
    documentName: string;
    hasError: boolean;
    errorMessage: string;
}

export class WorkInstructionEdit {
  private readonly m = defineMessages({
    documentRequired: { id: 'WorkInstructionEdit.documentRequired', defaultMessage: 'You must upload a PDF document that contains the work instruction.' },
    itemRequired: { id: 'WorkInstructionEdit.itemRequired', defaultMessage: 'You must assign the work instruction to an item.' },
    operationRequired: { id: 'WorkInstructionEdit.operationRequired', defaultMessage: 'You must assign the work instruction to an operation.' },
    productionOrderRequired: { id: 'WorkInstructionEdit.productionOrderRequired', defaultMessage: 'You must assign the work instruction to a production order.' },
    salesOrderRequired: { id: 'WorkInstructionEdit.salesOrderRequired', defaultMessage: 'You must assign the work instruction to a sales order' },
  })

  effectiveOn?: Date
  forItemId?: number
  forCostCenterId?: number
  forOperationId?: number
  forSalesOrderId?: number
  isItemRequired?: boolean;
  isOperationRequired?: boolean
  forProductionItemId?: number
  isProductionItemRequired?: boolean
  isSalesOrderRequired?: boolean
  forCoverType?: CoverType
  isCoverTypeRequired?: boolean
  document?: File

  public constructor (init?: Partial<WorkInstructionEdit>) {
    Object.assign(this, init);
  }

  public validate (): ValidationResult {
    const result = new ValidationResult();

    if (this.isItemRequired && this.forItemId == null) {
      result.isValid = false;
      result.errorFields.push(nameof<WorkInstructionEdit>('forItemId'));
      result.errorMessages.push(this.m.itemRequired);
    }

    if (this.isOperationRequired && this.forOperationId == null) {
      result.isValid = false;
      result.errorFields.push(nameof<WorkInstructionEdit>('forOperationId'));
      result.errorMessages.push(this.m.operationRequired);
    }

    if (this.isProductionItemRequired && this.forProductionItemId == null) {
      result.isValid = false;
      result.errorFields.push(nameof<WorkInstructionEdit>('forProductionItemId'));
      result.errorMessages.push(this.m.productionOrderRequired);
    }

    if (this.isSalesOrderRequired && this.forSalesOrderId == null) {
      result.isValid = false;
      result.errorFields.push(nameof<WorkInstructionEdit>('forSalesOrderId'));
      result.errorMessages.push(this.m.salesOrderRequired);
    }

    if (this.document == null) {
      result.isValid = false;
      result.errorFields.push(nameof<WorkInstructionEdit>('document'));
      result.errorMessages.push(this.m.documentRequired);
    }

    return result;
  }
}
