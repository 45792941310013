import React from 'react';
import { Segment } from 'semantic-ui-react';

interface OwnProps {
  style?: React.CSSProperties;
}

export type RaisedTileProps =
  & OwnProps;

export const RaisedTile: React.FC<RaisedTileProps> = (props) => {
  return (
    <Segment raised style={props.style}>
      {props.children}
    </Segment>
  );
};