import React from 'react';
import { Icon, IconProps } from 'semantic-ui-react';

interface OwnProps {
  file: File;
}

export type FileIconProps =
  & IconProps
  & OwnProps;

const pdfIcon: IconProps = { name: 'file pdf outline', color: 'red' };
const wordIcon: IconProps = { name: 'file word outline', color: 'blue' };
const defaultIcon: IconProps = { name: 'file outline', color: 'grey' };

const fileTypeMap = new Map<string, IconProps>([
  ['application/pdf', pdfIcon],
  ['application/msword', wordIcon],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', wordIcon]
]);

export const FileIcon: React.FC<FileIconProps> = (props) => {
  const { file, ...ownIconProps } = props;
  const defaultIconProps = fileTypeMap.get(file.type) || defaultIcon;

  return (
    <Icon size="big" {...defaultIconProps} {...ownIconProps} />
  );
};