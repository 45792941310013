import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';
import { Lot, ProductionStatus } from '../../lots/types';
import { LotStatusIcon } from './LotStatusIcon';
import { PlanningData } from './PlanningDataContainer';
import { LotSummary } from './LotSummary';


interface OwnProps {
    lot: Lot;
}

export type PrioritizableLotRowProps =
    & OwnProps;

export const PrioritizableLotRow = (props: PrioritizableLotRowProps) => {
  const [expanded, setIsExpanded] = React.useState(false);
  const [isPrioritized, setIsPrioritized] = React.useState(props.lot.isReadyForProduction);
  const isCompleted = props.lot.status === ProductionStatus.completed;
  const { onSettledErrorHandler } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const updateLotReadinessMutation = useMutation<void, unknown, boolean>(isReadyForProduction =>
    axios.post(`/api/lots/${props.lot.id}/UpdateProductionReadiness`, { isReadyForProduction: isReadyForProduction}),
  {
    onSettled: onSettledErrorHandler,
    onSuccess: async (data, isReadyForProduction) => {

      setIsPrioritized(isReadyForProduction);
      await queryClient.invalidateQueries(['planning-unprioritized-dates']);
      queryClient.setQueryData<PlanningData | undefined>([`planning-data-${moment(props.lot.createdOn).startOf('day').toISOString()}`],
        (oldData) => {
          if (oldData) {
            const lotIndex = oldData.lots.findIndex(p => p.id === props.lot.id);
            const updatedLots = [...oldData.lots];
            updatedLots[lotIndex] = {...oldData.lots[lotIndex], isReadyForProduction: isReadyForProduction };
            return {
              ...oldData,
              lots: updatedLots
            };
          }

          return undefined;
        }
      );
    }
  }
  );

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateLotReadinessMutation.mutate(checked);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpanded(!expanded)}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{props.lot.name}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell align="center"><LotStatusIcon status={props.lot.status} size="large" /></TableCell>
        <TableCell align="center">
          <Checkbox
            checked={isPrioritized}
            onChange={handleCheckChange}
            disabled={isCompleted || updateLotReadinessMutation.isLoading}

          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: '#f2f4f5' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" mountOnEnter={true}>
            <LotSummary lot={props.lot} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
