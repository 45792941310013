import React from 'react';
import _ from 'lodash';
import { Table, TableProps } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { OperationsTableRow } from './OperationsTableRow';
import { Operation } from '../../../../production';
import { useSelector } from 'react-redux';
import { getLoadingOperationIds } from '../../selectors';

interface OwnProps {
    operations: Operation[];
    loading?: boolean;
    onClick: (operation: Operation) => void;
}

export type OperationsTableProps =
    & TableProps
    & OwnProps;

const m = defineMessages({
  operationCode: { id: 'OperationsConfigurationPage.operationCode', defaultMessage: 'Code' },
  operationName: { id: 'OperationsConfigurationPage.operationName', defaultMessage: 'Operation' },
  costCenter: { id: 'OperationsConfigurationPage.costCenter', defaultMessage: 'Cost center' },
  operatingModeHeader: { id: 'OperationsConfigurationPage.operatingModeHeader', defaultMessage: 'Operating mode' },
  roadmapHeader: { id: 'OperationsConfigurationPage.roadmapHeader', defaultMessage: 'Roadmap' }
});

export const OperationsTable: React.FC<OperationsTableProps> = (props) => {
  const { formatMessage } = useIntl();
  const { operations, loading, onClick, ...tableProps } = props;
  const sortedOperations = _.sortBy(operations, x => x.code);
  const loadingOperationIds = useSelector(getLoadingOperationIds);

  return (
    <Table selectable {...tableProps}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing>{formatMessage(m.operationCode)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(m.operationName)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(m.costCenter)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(m.operatingModeHeader)}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage(m.roadmapHeader)}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedOperations.map(x => (
          <OperationsTableRow
            key={x.id}
            disabled={loadingOperationIds.find(id => id === x.id) != null}
            operation={x}
            onClick={() => props.onClick(x)} />
        ))}
      </Table.Body>
    </Table>
  );
};
