import { get, httpDelete, post } from '../../../utils/api';
import { Device } from './types';

const baseUrl = '/api/devices';

export async function loadDevices(): Promise<Device[]> {
  return get<Device[]>(`${baseUrl}`);
}

export async function updateDevice(device: Device): Promise<Device> {
  return post<Device>(`${baseUrl}/${device.id}`, device);
}

export async function deleteDevice(deviceId: number): Promise<void> {
  return httpDelete(`${baseUrl}/${deviceId}`);
}

export async function register(): Promise<Device> {
  return get<Device>(`${baseUrl}/register`);
}

export async function signIn(): Promise<Device> {
  return get<Device>(`${baseUrl}/signin`);
}
