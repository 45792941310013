import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as api from '../api';
import * as RoadmapActions from '../actions';

import { useDispatch } from 'react-redux';
import { Roadmap } from '../../../production/roadmaps/types';
import { CreateEntityModal } from '../../../../components/common/CreateEntityModal';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import { RoadmapFormControls } from './components/RoadmapFormControls';
import { defineMessages, useIntl } from 'react-intl';
import useApiErrorHandler, { successToastOptions } from '../../../../utils/ApiErrorHandler';
import { toast } from 'react-toastify';

const m = defineMessages({
  title: { id: 'CreateRoadmapPage.title', defaultMessage: 'Create a roadmap template' },
  successMessage: { id: 'CreateRoadmapPage.successMessage', defaultMessage: 'The roadmap template {name} has been added to the system.' }
});

export const CreateRoadmapPage: React.FC = (props) => {
  const { formatMessage } = useIntl();

  const history = useHistory();
  const dispatch = useDispatch();
  const formProps = useForm<Roadmap>();
  const { handleError } = useApiErrorHandler();

  const [isCreating, setIsCreating] = useState(false);
  const navigateToRoadmapsManagementPage = () => history.push('/administration/roadmaps');

  const saveRoadmap = (roadmap: Roadmap) => {
    setIsCreating(true);

    api.createRoadmap(roadmap)
      .then(result => {
        dispatch(RoadmapActions.createRoadmapSuccess(result));
        toast.success(formatMessage(m.successMessage, { name: result.name }), successToastOptions);
        navigateToRoadmapsManagementPage();
      })
      .catch(handleError)
      .finally(() => setIsCreating(false));
  };

  return (
    <CreateEntityModal
      open
      title={formatMessage(m.title)}
      loading={isCreating}
      onSubmit={formProps.handleSubmit(saveRoadmap)}
      onClose={navigateToRoadmapsManagementPage}
    >
      <FormProvider {...formProps}>
        <Form loading={isCreating}>
          <RoadmapFormControls />
        </Form>
      </FormProvider>
    </CreateEntityModal>
  );
};
