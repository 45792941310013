import { push } from 'connected-react-router';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { User } from '../../models';

import { encodeArrayBufferToString } from '../../utils/WebAuthHelper';
import { LOGIN_WEBAUTH, LOGOUT, LOAD_PROFILE, LoginWebAuthAction, WEBAUTH_USER_PROVIDED, WebAuthUserProvidedAction, UPDATE_PROFILE, UpdateProfileAction } from './types';
import * as actions from './actions';
import * as api from './api';
import * as LayoutActions from '../../store/layout/actions';
import { SupportedLocales } from '../../configureI18n';

function* handleLoginWebAuth(action: LoginWebAuthAction) {
  try {
    const options = (yield call(api.makeAssertionOptions, action.payload.user)) as PublicKeyCredentialRequestOptions;

    if (options.allowCredentials == null || options.allowCredentials.length <= 0) {
      // We didn't receive any credentials to challenge from the server. On Chrome for Android,
      // this will result in an error. As such, we need to ask for the user and get its credentials
      // before we can keep authenticating with webauth.
      yield put(actions.webAuthUserRequired());
      return;
    }

    let hasNotReadableExceptionOccurred = false;
    let assertion: PublicKeyCredential | null = null;
    do {
      hasNotReadableExceptionOccurred = false;
      try {
        assertion = (yield call(() => navigator.credentials!.get({ publicKey: options }))) as PublicKeyCredential;
      }
      catch (e) {
        if (e instanceof DOMException && e.name === 'NotReadableError') {
          // this error can happen when the user moves the security key instead of holding it still against the NFC reader
          hasNotReadableExceptionOccurred = true;
        } else if (e instanceof DOMException && e.name === 'InvalidStateError') {
          yield put(actions.webAuthUserRequired());
          return;
        } else {
          throw e;
        }
      }
    } while (hasNotReadableExceptionOccurred);

    if (!assertion) {
      throw new Error('Expected credentials of type: PublicKeyCredential');
    }

    const userHandle = (assertion.response as AuthenticatorAssertionResponse).userHandle;
    const data = {
      id: assertion.id,
      rawId: encodeArrayBufferToString(assertion.rawId),
      type: assertion.type,
      response: {
        authenticatorData: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).authenticatorData),
        signature: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).signature),
        clientDataJson: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).clientDataJSON),
        userHandle: userHandle && userHandle.byteLength > 0 ? encodeArrayBufferToString(userHandle) : undefined
      }
    };

    const result: User = yield call(api.makeAssertion, data as any);
    yield put(actions.loginWebAuthSuccess(result));
    yield put(push('/'));
  } catch (e) {
    yield put(actions.loginWebAuthFailure(e as Error));
    yield put(actions.webAuthUserRequired());
  }
}

function* handleLogout() {
  try {
    yield call(api.logout);
    yield put(push('/account/login'));
  } catch (e) {
    console.error('An error occurred while performing the logout operation');
  }
}

function* handleLoadProfile() {
  try {
    const result: User = yield call(api.loadProfile);
    yield put(actions.loadProfileSuccess(result));
    yield put(LayoutActions.setLocale(result.preferredLocale as SupportedLocales));

  } catch (e) {
    yield put(actions.loadProfileFailure(e as Error));
  }
}

function* handleUpdateProfile(profile: UpdateProfileAction) {
  try {
    const result: User = yield call(api.updateProfile, profile.payload.profile);
    yield put(actions.updateProfileSuccess(result));
  } catch (e) {
    yield put(actions.updateProfileFailure(e as Error));
  }
}

function* handleWebauthUserProvided(action: WebAuthUserProvidedAction) {
  if (!action.payload.isCollaborativeUser) {
    yield put(actions.loginWebAuth(action.payload.user));
  }
}

function* watchLoginWebAuth() { yield takeEvery(LOGIN_WEBAUTH, handleLoginWebAuth); }
function* watchLogout() { yield takeEvery(LOGOUT, handleLogout); }
function* watchLoadProfile() { yield takeEvery(LOAD_PROFILE, handleLoadProfile); }
function* watchUpdateProfile() { yield takeEvery(UPDATE_PROFILE, handleUpdateProfile); }
function* watchWebauthUserProvided() { yield takeEvery(WEBAUTH_USER_PROVIDED, handleWebauthUserProvided); }

function* accountSagas() {
  yield all([
    fork(watchLoginWebAuth),
    fork(watchLogout),
    fork(watchLoadProfile),
    fork(watchUpdateProfile),
    fork(watchWebauthUserProvided),
  ]);
}

export default accountSagas;