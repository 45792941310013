import {
  LoadingDimmer,
  NoContentPlaceholder,
  PageHeader,
  RaisedTile,
} from 'components';
import { getWorkstations } from 'modules/administration';
import { getSalesOrderById } from 'modules/production/selectors';
import { SalesOrder } from 'modules/schedule';
import { isSystemLoading } from 'modules/system';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';
import { ApplicationState } from 'store';
import * as layoutActions from '../../../../store/layout/actions';
import { getProductionOrderOverviewsForSalesOrder } from '../selectors';
import { ProductionOrderOverview } from '../types';
import { ProductionOrderButton } from './components/ProductionOrderButton';

interface RouteProps {
  salesOrderId: string;
}

export const ProductionOrderForSalesOrderManualSelectionPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const routeProps = useParams<RouteProps>();
  const salesOrderId = Number(routeProps.salesOrderId);
  const salesOrder = useSelector<ApplicationState, SalesOrder | undefined>(
    (state) => getSalesOrderById(state, salesOrderId)
  );

  useEffect(() => {
    dispatch(
      layoutActions.setPageTitle({
        title: formatMessage(
          {
            id: 'ProductionOrderForSalesOrderManualSelectionPage.topBarTitle',
            defaultMessage: 'Order #{orderNumber}',
          },
          { orderNumber: salesOrder?.salesOrderNumber }
        ),
      })
    );
  }, [dispatch, formatMessage, salesOrder?.salesOrderNumber]);

  const productionOrders = useSelector<
    ApplicationState,
    ProductionOrderOverview[]
  >((state) => getProductionOrderOverviewsForSalesOrder(state, salesOrderId));

  const workstations = useSelector(getWorkstations);
  const isSystemInitializing = useSelector<ApplicationState, boolean>(
    isSystemLoading
  );
  const isLoading = isSystemInitializing;

  const renderProductionOrderRow = (
    productionOrderOverview: ProductionOrderOverview
  ) => {
    return (
      <Grid.Row
        key={`${productionOrderOverview.type}-${
          productionOrderOverview.productionItem?.id ??
          productionOrderOverview.lot?.id
        }`}
      >
        <Grid.Column textAlign="center">
          <ProductionOrderButton
            lot={productionOrderOverview.lot}
            operationId={productionOrderOverview.operationId}
            productionItem={productionOrderOverview.productionItem}
            productionStatus={productionOrderOverview.productionStatus}
            onClick={() =>
              goToProductionOrderExecution(productionOrderOverview)
            }
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const goToProductionOrderExecution = (
    productionOrder: ProductionOrderOverview
  ) => {
    const workstationId = workstations.find((workstation) =>
      workstation.operationIds.includes(productionOrder.operationId)
    )?.id;

    if (productionOrder.type === 'production-item') {
      history.push(
        `/workstations/${workstationId}/operations/${productionOrder.operationId}/production-orders/${productionOrder.productionItem?.id}/roadmap`
      );
    } else {
      history.push(
        `/workstations/${workstationId}/operations/${productionOrder.operationId}/lots/${productionOrder.lot?.id}/roadmap`
      );
    }
  };

  const renderProductionItems = () => (
    <Grid padded centered>
      <LoadingDimmer active={isLoading} />
      <>
        {productionOrders.map(renderProductionOrderRow)}
        {productionOrders.length === 0 && (
          <NoContentPlaceholder
            icon="check circle"
            title={formatMessage({
              id: 'ProductionOrderForSalesOrderManualSelectionPage.noContentTitle',
              defaultMessage: 'All production orders completed!',
            })}
          />
        )}
      </>
    </Grid>
  );

  return (
    <RaisedTile>
      <>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column width={4}></Grid.Column>
            <Grid.Column width={8} textAlign="center">
              <PageHeader
                title={formatMessage({
                  id: 'ProductionOrderForSalesOrderManualSelectionPage.title',
                  defaultMessage: 'Select a production item',
                })}
                centered
              ></PageHeader>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right"></Grid.Column>
          </Grid.Row>
        </Grid>
        <Container>{renderProductionItems()}</Container>
      </>
    </RaisedTile>
  );
};
