import { Box } from '@mui/material';
import React from 'react';

export const MuiForm: React.FC = (props) => {
  return (
    <Box
      component="form"
      autoComplete="off"
    >
      {props.children}
    </Box>
  );
};