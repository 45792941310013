import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Accordion, Button, DropdownProps, Form, Message, Modal, ModalProps, Segment } from 'semantic-ui-react';

import { FileUpload, FileUploadList, ToggleAccordionSection } from '../../../../../components';
import { commonMessages } from '../../../../../constants';
import { ValidationResult } from '../../../../../models';
import { nameof } from '../../../../../utils';
import { Item, ProductionItem } from '../../../../production';
import { ProductionOrdersSearchControl } from '../../../../search/components';
import { ItemsSearchControl } from '../../../../search/components/ItemsSearchControl';
import { CostCentersDropdown, OperationsDropdown } from '../../../workstations/views/components';
import { WorkInstructionEdit } from '../../types';
import { SalesOrderSearchControl } from './SalesOrderSearchControl';

interface OwnProps {
  onAdd: (workInstruction: WorkInstructionEdit) => void;
  onCancel: () => void;
}

export type AddWorkInstructionModalProps =
  & OwnProps
  & ModalProps;

const m = defineMessages({
  title: { id: 'AddWorkInstructionModal.title', defaultMessage: 'Add a work instruction' },
  effectiveOnLabel: { id: 'AddWorkInstructionModal.effectiveOnLabel', defaultMessage: 'Effective on' },
  assignToItem: { id: 'AddWorkInstructionModal.assignToItem', defaultMessage: 'Assign to an item' },
  item: { id: 'AddWorkInstructionModal.itemLabel', defaultMessage: 'Item' },
  itemPlaceholder: { id: 'AddWorkInstructionModal.itemPlaceholder', defaultMessage: 'Search for items by their identifier' },
  assignToOperation: { id: 'AddWorkInstructionModal.assignToOperation', defaultMessage: 'Assign to a work operation' },
  costCenterLabel: { id: 'AddWorkInstructionModal.costCenterLabel', defaultMessage: 'Department' },
  costCenterPlaceholder: { id: 'AddWorkInstructionModel.costCenterPlaceholder', defaultMessage: 'Select a cost center' },
  operationLabel: { id: 'AddWorkInstructionModal.operationLabel', defaultMessage: 'Operation' },
  operationPlaceholder: { id: 'AddWorkInstructionModal.operationPlaceholder', defaultMessage: 'Select an operation' },
  assignToProductionOrder: { id: 'AddWorkInstructionModal.assignToProductionOrder', defaultMessage: 'Assign to a production order' },
  productionOrderLabel: { id: 'AddWorkInstructionModal.productionOrderLabel', defaultMessage: 'Production order' },
  productionOrderPlaceholder: { id: 'AddWorkInstructionModal.productionOrderPlaceholder', defaultMessage: 'Select a production order' },
  salesOrderPlaceholder: { id: 'AddWorkInstructionModal.salesOrderPlaceholder', defaultMessage: 'Select a sales order' },
  assignToSalesOrder: { id: 'AddWorkInstructionModal.assignToSalesOrder', defaultMessage: 'Assign to a sales order' },
  assignDocumentLabel: { id: 'AddWorkInstructionModal.assignDocumentLabel', defaultMessage: 'Assign a document to the work instruction' },
  assignDocumentButton: { id: 'AddWorkInstructionModal.assignDocumentButton', defaultMessage: 'Choose a document' },
  salesOrder: { id: 'AddWorkInstructionModal.salesOrderLabel', defaultMessage: 'Sales order' },
});

enum FormSection {
  operation,
  productionItem,
  item,
  salesOrder
}

export const AddWorkInstructionModal: React.FC<AddWorkInstructionModalProps> = (props) => {
  const { formatMessage } = useIntl();
  const { onAdd, onCancel, ...modalProps } = props;

  const [workInstruction, setWorkInstruction] = useState<WorkInstructionEdit>(new WorkInstructionEdit());
  const [validationResult, setValidationResult] = useState<ValidationResult>(ValidationResult.empty());
  const [activeSections, setActiveSections] = useState<number[]>([]);

  const toggleSection = (index: number) => {
    const wasSectionActive = activeSections.findIndex(x => x === index) > -1;
    const newActiveSections = wasSectionActive
      ? activeSections.filter(x => x !== index)
      : activeSections.concat([index]);

    setActiveSections(newActiveSections);
  };

  useEffect(() => {
    const isItemSectionActive = activeSections.find(x => x === FormSection.item) != null;
    const isOperationSectionActive = activeSections.find(x => x === FormSection.operation) != null;
    const isProductionItemSectionActive = activeSections.find(x => x === FormSection.productionItem) != null;
    const isSalesOrderSectionActive = activeSections.find(x => x === FormSection.salesOrder) != null;

    setWorkInstruction(new WorkInstructionEdit({
      ...workInstruction,
      isItemRequired: isItemSectionActive,
      forItemId: isItemSectionActive ? workInstruction.forItemId : undefined,
      isOperationRequired: isOperationSectionActive,
      forCostCenterId: isOperationSectionActive ? workInstruction.forCostCenterId : undefined,
      forOperationId: isOperationSectionActive ? workInstruction.forOperationId : undefined,
      isProductionItemRequired: isProductionItemSectionActive,
      forProductionItemId: isProductionItemSectionActive ? workInstruction.forProductionItemId : undefined,
      isSalesOrderRequired: isSalesOrderSectionActive,
      forSalesOrderId: isSalesOrderSectionActive ? workInstruction.forSalesOrderId : undefined,
    }));
  }, [activeSections]);

  const selectItem = (item: Item | undefined) => {
    setWorkInstruction(new WorkInstructionEdit({
      ...workInstruction,
      forItemId: item ? item.id : undefined
    }));
  };

  const selectProductionOrder = (productionOrder: ProductionItem | undefined) => {
    setWorkInstruction(new WorkInstructionEdit({
      ...workInstruction,
      forProductionItemId: productionOrder ? productionOrder.id : undefined
    }));
  };

  const selectDropdownValue = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const fieldId = event.currentTarget.parentElement?.parentElement?.id ||
      event.currentTarget.parentElement?.id ||
      undefined;

    if (fieldId != null) {
      setWorkInstruction(new WorkInstructionEdit({
        ...workInstruction,
        [fieldId]: data.value !== '' ? data.value : undefined
      }));
    }
  };

  const selectSalesOrder = (salesOrderId: number | undefined) => {
    setWorkInstruction(new WorkInstructionEdit({
      ...workInstruction,
      forSalesOrderId: salesOrderId ?? undefined
    }));
  };

  const selectDocument = (files: FileList) => {
    setWorkInstruction(new WorkInstructionEdit({
      ...workInstruction,
      document: files.length > 0 ? files[0] : undefined
    }));
  };

  const removeDocument = () => {
    setWorkInstruction(new WorkInstructionEdit({
      ...workInstruction,
      document: undefined
    }));
  };

  const add = () => {
    const result = workInstruction.validate();
    setValidationResult(result);

    if (result.isValid) {
      props.onAdd(workInstruction);

      setWorkInstruction(new WorkInstructionEdit());
      setValidationResult(ValidationResult.empty());
      setActiveSections([]);
    }
  };

  return (
    <Modal size="tiny" {...modalProps}>
      <Modal.Header>
        {formatMessage(m.title)}
      </Modal.Header>
      <Modal.Content>
        <Form error={!validationResult.isValid}>
          <Message error={true} list={validationResult.localizeErrorMessages(formatMessage)} />

          <Accordion fluid={true} exclusive={false}>
            <ToggleAccordionSection
              index={FormSection.item}
              activeIndexes={activeSections}
              title={formatMessage(m.assignToItem)}
              onClick={toggleSection}
            >
              <Form.Field error={validationResult.isFieldInvalid(nameof<WorkInstructionEdit>('forItemId'))}>
                <label>{formatMessage(m.item)}</label>
                <ItemsSearchControl
                  placeholder={formatMessage(m.itemPlaceholder)}
                  onItemSelected={selectItem}
                  onItemCleared={() => selectItem(undefined)}
                />
              </Form.Field>
            </ToggleAccordionSection>

            <ToggleAccordionSection
              index={FormSection.operation}
              activeIndexes={activeSections}
              title={formatMessage(m.assignToOperation)}
              onClick={toggleSection}
            >
              <Form.Group widths="equal">
                <Form.Field required={true}>
                  <label>{formatMessage(m.costCenterLabel)}</label>
                  <CostCentersDropdown
                    id={nameof<WorkInstructionEdit>('forCostCenterId')}
                    selection
                    clearable
                    search
                    placeholder={formatMessage(m.costCenterPlaceholder)}
                    onChange={selectDropdownValue}
                    value={workInstruction.forCostCenterId || ''}
                  />
                </Form.Field>
                <Form.Field required error={validationResult.isFieldInvalid(nameof<WorkInstructionEdit>('forOperationId'))}>
                  <label>{formatMessage(m.operationLabel)}</label>
                  <OperationsDropdown
                    id={nameof<WorkInstructionEdit>('forOperationId')}
                    selection
                    clearable
                    costCenterId={workInstruction.forCostCenterId}
                    value={workInstruction.forOperationId || ''}
                    placeholder={formatMessage(m.operationPlaceholder)}
                    onChange={selectDropdownValue}
                  />
                </Form.Field>
              </Form.Group>
            </ToggleAccordionSection>

            <ToggleAccordionSection
              index={FormSection.productionItem}
              activeIndexes={activeSections}
              disabled
              title={formatMessage(m.assignToProductionOrder)}
              onClick={toggleSection}
            >
              <Form.Field required error={validationResult.isFieldInvalid(nameof<WorkInstructionEdit>('forProductionItemId'))}>
                <label>{formatMessage(m.productionOrderLabel)}</label>
                <ProductionOrdersSearchControl
                  placeholder={formatMessage(m.productionOrderPlaceholder)}
                  onProductionOrderSelected={selectProductionOrder}
                  onProductionOrderCleared={() => selectProductionOrder(undefined)}
                />
              </Form.Field>
            </ToggleAccordionSection>

            <ToggleAccordionSection
              index={FormSection.salesOrder}
              activeIndexes={activeSections}
              title={formatMessage(m.assignToSalesOrder)}
              onClick={toggleSection}
            >
              <Form.Field error={validationResult.isFieldInvalid(nameof<WorkInstructionEdit>('forSalesOrderId'))}>
                <label>{formatMessage(m.salesOrder)}</label>
                <SalesOrderSearchControl
                  placeholder={formatMessage(m.salesOrderPlaceholder)}
                  onSelected={selectSalesOrder}
                  onCleared={() => selectSalesOrder(undefined)}
                />
              </Form.Field>
            </ToggleAccordionSection>
          </Accordion>
        </Form>

        {workInstruction.document == null &&
          <FileUpload
            icon="file pdf outline"
            acceptedFileTypes="application/pdf"
            title={formatMessage(m.assignDocumentLabel)}
            buttonText={formatMessage(m.assignDocumentButton)}
            onUpload={selectDocument}
          />
        }

        {workInstruction.document != null &&
          <Segment>
            <FileUploadList
              files={[workInstruction.document]}
              onDelete={removeDocument}
            />
          </Segment>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
        <Button primary={true} content={formatMessage(commonMessages.add)} onClick={add} />
      </Modal.Actions>
    </Modal>
  );
};
