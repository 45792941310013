import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Roadmap } from '../../../../../production/roadmaps/types';
import { RoadmapsTableRow } from './RoadmapsTableRow';

interface OwnProps {
    roadmaps: Roadmap[];
    loading?: boolean;
    onClick: (roadmap: Roadmap) => void;
}

export type RoadmapsTableProps =
    & OwnProps;

const m = defineMessages({
  nameHeader: { id: 'RoadmapsTable.nameHeader', defaultMessage: 'Name' },
  fieldsHeader: { id: 'RoadmapsTable.fieldsHeader', defaultMessage: 'Fields' },
  isDefaultHeader: { id: 'RoadmapsTable.isDefaultHeader', defaultMessage: 'Is default?' }
});

type SortDirection = 'ascending' | 'descending';

export const RoadmapsTable: React.FC<RoadmapsTableProps> = (props) => {
  const { formatMessage } = useIntl();
  const [sortBy, setSortBy] = useState<(keyof Roadmap)>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('ascending');

  const displayedRoadmaps = useMemo<Roadmap[]>(() => {
    return sortDirection === 'ascending'
      ? _.sortBy(props.roadmaps, [sortBy])
      : _.sortBy(props.roadmaps, [sortBy]).reverse();
  }, [props.roadmaps, sortBy, sortDirection]);

  const getSortDirection = (column: keyof Roadmap): SortDirection | undefined => sortBy === column ? sortDirection : undefined;

  const sortColumn = (column: keyof Roadmap) => {
    if (sortBy !== column) {
      setSortBy(column);
      setSortDirection('ascending');
    } else {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
    }
  };

  return (
    <Table sortable selectable style={{ cursor: 'pointer' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell sorted={getSortDirection('name')} onClick={() => sortColumn('name')}>{formatMessage(m.nameHeader)}</Table.HeaderCell>
          <Table.HeaderCell sorted={getSortDirection('columns')} onClick={() => sortColumn('columns')}>{formatMessage(m.fieldsHeader)}</Table.HeaderCell>
          <Table.HeaderCell sorted={getSortDirection('isDefault')} onClick={() => sortColumn('isDefault')}>{formatMessage(m.isDefaultHeader)}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {displayedRoadmaps.map(x => (
          <RoadmapsTableRow
            key={x.id}
            roadmap={x}
            canDelete={!x.isDefault}
            onRoadmapClick={() => props.onClick(x)}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
