import { get, postMultiPart, post, fetchRequest, httpDelete } from '../../../utils/api';
import { WorkInstruction, WorkInstructionEdit, WorkInstructionBulkImport, BulkImportResult } from './types';
import { PaginatedResults } from '../../../utils/PaginatedResults';
import { BulkImportJobProgress } from './models';

const endpoint = '/api/work-instructions';

export async function loadAll (): Promise<PaginatedResults<WorkInstruction>> {
  return get<PaginatedResults<WorkInstruction>>(endpoint);
}

export async function create (edit: WorkInstructionEdit): Promise<WorkInstruction> {
  return postMultiPart(endpoint, edit);
}

export async function deleteAssociation (associationId: number): Promise<void> {
  return httpDelete(`${endpoint}/associations/${associationId}`);
}

export async function importCsv (parameters: WorkInstructionBulkImport): Promise<BulkImportResult[]> {
  return postMultiPart(`${endpoint}/import`, parameters);
}

export async function search (searchTerm: string): Promise<PaginatedResults<WorkInstruction>> {
  return get<PaginatedResults<WorkInstruction>>(`${endpoint}/search/${searchTerm}`);
}

export async function loadRunningJobs (): Promise<BulkImportJobProgress[]> {
  return get<BulkImportJobProgress[]>(`${endpoint}/import/jobs/running`);
}

export async function loadCompletedJobs (): Promise<BulkImportJobProgress[]> {
  return get<BulkImportJobProgress[]>(`${endpoint}/import/jobs/completed`);
}
