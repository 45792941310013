import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { commonMessages } from '../../../constants';

interface OwnProps {
    title: string;
    loading?: boolean;
    onSubmit: () => void;
}

export type CreateEntityModalProps =
    & ModalProps
    & OwnProps;

export const CreateEntityModal: React.FC<CreateEntityModalProps> = (props) => {
  const { title, ...modalProps } = props;
  const { formatMessage } = useIntl();

  return (
    <>
      <Modal size="tiny" {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {props.children}
        </Modal.Content>
        <Modal.Actions>
          <Button content={formatMessage(commonMessages.cancel)} onClick={(e) => props.onClose && props.onClose(e, props)} />
          <Button
            primary
            disabled={props.loading}
            loading={props.loading}
            content={formatMessage(commonMessages.add)}
            onClick={props.onSubmit}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
