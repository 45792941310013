import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getAllOperatingModes } from '../../../../production';
import { useIntl } from 'react-intl';
import { getOperatingModeDescription } from '../../messages';



export type OperatingModeDropdownProps =
    & DropdownProps;

export const OperatingModeDropdown: React.FC<OperatingModeDropdownProps> = (props) => {
  const { ...dropdownProps } = props;
  const { formatMessage } = useIntl();
  const operatingModes = useSelector(getAllOperatingModes);
  const dropdownOptions = operatingModes.map((x): DropdownItemProps => ({
    key: x,
    text: formatMessage(getOperatingModeDescription(x)),
    value: x
  }));

  return (
    <Dropdown
      options={dropdownOptions}
      {...dropdownProps}
    />
  );
};
