import React from 'react';
import { DropdownProps, Dropdown } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { commonMessages } from '../../../../../constants';
import { LotFilter } from '../../types';



export type LotFilterDropdownProps =
    & DropdownProps;

const m = defineMessages({
  filterOptionIncompleteOnly: { id: 'LotSelectionPage.filterOptionIncompleteOnly', defaultMessage: 'Incomplete lots only' },
  filterOptionAll: { id: 'LotSelectionPage.filterOptionAll', defaultMessage: 'All' }
});

export const LotFilterDropdown: React.FC<LotFilterDropdownProps> = (props) => {
  const { formatMessage } = useIntl();
  const { ...dropdownProps } = props;

  const filterOptions = [
    { key: LotFilter.IncompleteLotsOnly, text: formatMessage(m.filterOptionIncompleteOnly), value: LotFilter.IncompleteLotsOnly },
    { key: LotFilter.All, text: formatMessage(m.filterOptionAll), value: LotFilter.All }
  ];

  return (
    <Dropdown
      text={formatMessage(commonMessages.filter)}
      icon="filter"
      floating
      labeled
      button
      className="icon"
      options={filterOptions}
      {...dropdownProps}
    />
  );
};
