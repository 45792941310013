import { Icon, IconProps } from '@mui/material';
import React from 'react';
import { ProductionStatus } from '../../../../lots/types';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export interface OwnProps {
    productionStatus: ProductionStatus;
    fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
}

export type ProductionStatusIconProps =
    & OwnProps;

export const ProductionStatusIcon = (props: ProductionStatusIconProps) => {
  const { productionStatus, ...iconProps } = props;

  switch (props.productionStatus) {
  case ProductionStatus.started:
    return (<PlayCircleIcon {...iconProps} fontSize={props.fontSize} color='info' />);
  case ProductionStatus.completed:
    return (<AssignmentTurnedInIcon {...iconProps} color='success' />);
  default:
    return (<StopCircleIcon {...iconProps} color='error' />);
  }
};
