import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const m = defineMessages({
  headerSalesOrderNumber: { id: 'LotsTable.headerLotNumber', defaultMessage: 'Lot #' },
  headerStatus: { id: 'LotsTable.headerStatus', defaultMessage: 'Status' },
});

type ColumnDefinition = {
  id: string;
  label: string;
  disablePadding?: boolean;
  align?: 'right' | 'inherit' | 'left' | 'center' | 'justify' | undefined
}

export const LotsTable: React.FC = (props) => {
  const { formatMessage } = useIntl();

  const columns: ColumnDefinition[] = [
    { id: 'headerLotName', label: formatMessage(m.headerSalesOrderNumber), align: 'center', disablePadding: false },
    { id: 'padding1', label: '', align: 'center', disablePadding: false },
    { id: 'padding2', label: '', align: 'center', disablePadding: false },
    { id: 'headerStatus', label: formatMessage(m.headerStatus), align: 'center', disablePadding: false }
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align ?? 'left'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>

          <TableBody>
            {props.children}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};
