import { Stack, Typography } from '@mui/material';
import { SalesOrder } from 'modules/schedule';
import { useHistory } from 'react-router-dom';
import { Button, ButtonProps } from 'semantic-ui-react';

interface SalesOrderButtonProps extends ButtonProps {
  salesOrder: SalesOrder;
}

export const SalesOrderButton = ({
  salesOrder,
  ...buttonProps
}: SalesOrderButtonProps) => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(
      `/sales-order/${salesOrder.id}/production-orders/list`
    );
  };

  return (
    <Button
      icon
      labelPosition="right"
      primary
      size="huge"
      fluid
      onClick={handleOnClick}
      {...buttonProps}
    >
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Typography variant="h5" sx={{ flex: 1, textAlign: 'left' }}>
          {salesOrder.salesOrderNumber}
        </Typography>

        <Stack sx={{ flex: 1 }}>
          <Typography variant="h5">{salesOrder.clientFullName}</Typography>
          <Typography variant="h5">{salesOrder.clientCompany}</Typography>
        </Stack>
      </Stack>
    </Button>
  );
};
