import React from 'react';
import { Popup, StrictPopupProps, Icon } from 'semantic-ui-react';

export type InformationBubbleProps =
    & StrictPopupProps;

export const InformationBubble: React.FC<InformationBubbleProps> = (props) => {
  const { ...popupProps } = props;
  return (
    <Popup
      trigger={<Icon name="question circle outline" />}
      {...popupProps}
    />
  );
};
