import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import CssBaseline from '@mui/material/CssBaseline';

import { I18nProvider, SplashScreen } from './components/app';
import { I18nMessages } from './configureI18n';
import { Routes } from './routes';
import { ApplicationState } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './app.css';
import { allThemes } from './modules/application/themes';
import { ThemeProvider } from '@mui/material';
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ErrorBoundaryPage } from 'modules/application/views';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseValidator } from 'modules/administration/licenses/components/LicenseValidator';

interface AppProps {
  store: Store<ApplicationState>;
  history: History;
  messages: I18nMessages;
  reactPlugin: ReactPlugin;
}

const queryClient = new QueryClient();

export class App extends React.Component<AppProps> {
  public render() {
    return (
      <>
        <ThemeProvider theme={allThemes.light}>
          <CssBaseline />
          <Provider store={this.props.store}>
            <I18nProvider messages={this.props.messages}>
              <ConnectedRouter history={this.props.history}>
                <AppInsightsErrorBoundary appInsights={this.props.reactPlugin} onError={ErrorBoundaryPage}>
                  <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <SplashScreen>
                        <LicenseValidator />
                        <Routes />
                      </SplashScreen>
                    </LocalizationProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </AppInsightsErrorBoundary>
              </ConnectedRouter>
            </I18nProvider>
          </Provider>
        </ThemeProvider>
      </>
    );
  }
}
