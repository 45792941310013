import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Form, Header, Icon, Segment, SemanticICONS } from 'semantic-ui-react';

interface FileUploadOwnProps {
    title: string;

    multiple?: boolean;
    acceptedFileTypes?: string;
    icon?: SemanticICONS;
    buttonText?: string;

    onUpload: (files: FileList) => void;
}

export type FileUploadProps =
    & FileUploadOwnProps
    & WrappedComponentProps;

const m = defineMessages({
  defaultButtonText: { id: 'FileUpload.defaultButtonText', defaultMessage: 'Upload' }
});

class FileUpload extends React.Component<FileUploadProps> {
  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <Segment textAlign="center">
        <Header icon={this.props.icon != null}>
          <Icon name={this.props.icon} color="grey" />
          <span>{this.props.title}</span>
        </Header>
        <div>
          <label className="ui button primary">
            {this.props.buttonText || formatMessage(m.defaultButtonText)}
            <input
              type="file"
              accept={this.props.acceptedFileTypes || '*'}
              style={{ display: 'none' }}
              multiple={this.props.multiple || false}
              onChange={this.handleChange}
            />
          </label>
        </div>
      </Segment>
    );
  }

  private handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files) {
      this.props.onUpload(files);
    }
  };
}

const connectedComponent = injectIntl(FileUpload);
export { connectedComponent as FileUpload };
