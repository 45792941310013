import { defineMessages, MessageDescriptor } from 'react-intl';
import { ProductionSorting } from './types';

const productionSortingMessages = defineMessages({
  unspecified: { id: 'productionSortingMessages.unspecified', defaultMessage: 'Unspecified' },
  byProductionSchedule: { id: 'productionSortingMessages.byProductionSchedule', defaultMessage: 'Production schedule' },
  manually: { id: 'productionSortingMessages.manually', defaultMessage: 'Manually' }
});

export const getProductionSortingDescription = (sortBy: ProductionSorting): MessageDescriptor => {
  const productionSortingMap: Map<ProductionSorting, MessageDescriptor> = new Map([
    [ProductionSorting.byProductionSchedule, productionSortingMessages.byProductionSchedule],
    [ProductionSorting.manually, productionSortingMessages.manually]
  ]);

  return productionSortingMap.get(sortBy) || productionSortingMessages.unspecified;
};
