import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { WorkHistory } from '../types';
import { WorkHistoryButton } from './WorkHistoryButton';

interface Props {
  workHistory: WorkHistory;
}

export const WorkHistoryRow: React.FC<Props> = ({ workHistory }) => {
  const history = useHistory();

  const handleRowClick = () => {
    if (!workHistory.lot?.id) {
      history.push(`/workstations/${workHistory.workstationId}/operations/${workHistory.operation?.id}/production-orders/${workHistory.productionItem?.id}/roadmap`);
    }
    else {
      history.push(`/workstations/${workHistory.workstationId}/operations/${workHistory.operation?.id}/lots/${workHistory.lot?.id}/roadmap`);
    }
  };

  return (
    <Grid.Row style={{padding:'9px 0'}}>
      <Grid.Column textAlign="center" style={{ padding: '0' }}>
        <WorkHistoryButton workHistory={workHistory} onClick={handleRowClick} />
      </Grid.Column>
    </Grid.Row>
  );
};