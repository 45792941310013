import React from 'react';
import { Accordion, Checkbox } from 'semantic-ui-react';

interface OwnProps {
    index: number;
    activeIndexes: number[];
    title: string;
    disabled?: boolean;
    onClick: (index: number) => void;
}

export type ToggleAccordionSectionProps =
    & OwnProps;

export const ToggleAccordionSection: React.FC<ToggleAccordionSectionProps> = (props) => {
  const isActive = props.activeIndexes.findIndex(x => x === props.index) > -1;

  const handleClick = () => {
    if (!props.disabled) {
      props.onClick(props.index);
    }
  };

  return (
    <React.Fragment>
      <Accordion.Title
        index={props.index}
        active={isActive}
        style={{ cursor: props.disabled ? 'default' : undefined }}
        onClick={handleClick}
      >
        <Checkbox
          toggle
          disabled={props.disabled}
          checked={isActive}
          label={props.title}
        />
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        {props.children}
      </Accordion.Content>
    </React.Fragment>
  );
};
