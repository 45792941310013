import React from 'react';
import { Card, CardProps, List, ListItemProps } from 'semantic-ui-react';
import styles from './CostCenterOverviewCard.module.css';
import { CostCenter } from '../../../../production';
import { CostCenterStatistics } from './CostCenterStatistics';
import { NoWorkstationsPlaceholder } from './NoWorkstationsPlaceholder';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { getWorkstationsByCostCenter, Workstation } from '../../../../administration';
import { WorkstationListItem } from './WorkstationListItem';
import { useHistory } from 'react-router-dom';

interface OwnProps {
    costCenter: CostCenter;
}

export type CostCenterOverviewCardProps =
    & CardProps
    & OwnProps;

export const CostCenterOverviewCard: React.FC<CostCenterOverviewCardProps> = (props) => {
  const { costCenter, ...cardProps } = props;
  const history = useHistory();

  const workstations = useSelector((state: ApplicationState) => getWorkstationsByCostCenter(state, costCenter.id));
  const hasWorkstations = workstations.length > 0;

  const navigateToCostCenterOverview = (workstation: Workstation) => {
    history.push(`/cost-centers/${workstation.costCenterId}/overview`);
  };

  return (
    <Card className={styles.overview + ' raised color-top'} color={'green'} {...cardProps}>
      <Card.Content textAlign="center">
        <Card.Header>{props.costCenter.name}</Card.Header>
      </Card.Content>

      <Card.Content>
        <CostCenterStatistics />
      </Card.Content>

      <Card.Content>
        {hasWorkstations &&
                    <List selection divided relaxed>
                      {workstations.map(workstation => (
                        <WorkstationListItem
                          key={workstation.id}
                          workstation={workstation}
                          onClick={() => navigateToCostCenterOverview(workstation)}
                        />
                      ))}
                    </List>
        }

        {!hasWorkstations && <NoWorkstationsPlaceholder />}
      </Card.Content>
    </Card>
  );
};
