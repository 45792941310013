import React, { useEffect } from 'react';
import { Box, LinearProgress, Stack, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { WorkHistory, WorkHistoryFilter } from '../types';
import { defineMessages, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { TabContext, TabPanel } from '@mui/lab';
import { groupWorkHistoryByDate, removeDuplicateWorkHistoriesInARow } from '../utils';
import { WorkHistoryByDate } from './WorkHistoryByDate';
import { getUserWorkHistory } from '../api';

const m = defineMessages({
  noCurrentUser: {
    id: 'HistoryInformationSection.noCurrentUser',
    defaultMessage: 'There is no current user'
  },
  lastDay: {
    id: 'HistoryInformationSection.lastDay',
    defaultMessage: 'Past 24 hours'
  },
  lastWeek: {
    id: 'HistoryInformationSection.lastWeek',
    defaultMessage: 'Past week'
  },
  lastMonth: {
    id: 'HistoryInformationSection.lastMonth',
    defaultMessage: 'Past month'
  },
  noHistoryLastDay: {
    id: 'HistoryInformationSection.noHistoryLastDay',
    defaultMessage: 'No work history in the past 24 hours'
  },
  noHistoryLastWeek: {
    id: 'HistoryInformationSection.noHistoryLastWeek',
    defaultMessage: 'No work history in the past week'
  },
  noHistoryLastMonth: {
    id: 'HistoryInformationSection.noHistoryLastMonth',
    defaultMessage: 'No work history in the past month'
  }
});

export const WorkHistoryInformationSection: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [workHistory, setWorkHistory] = useState<WorkHistory[]>([]);
  const [filter, setFilter] = useState<WorkHistoryFilter>(WorkHistoryFilter.LastDay);
  const { formatMessage } = useIntl();

  const getWorkHistory = async () => {
    setIsLoading(true);
    let workHistory = await getUserWorkHistory();
    workHistory = workHistory
      .filter(workHistory => workHistory.lot?.id !== null || workHistory.productionItem?.id !== null)
      .sort((a, b) => (dayjs(b.eventDate).diff(dayjs(a.eventDate))));
    workHistory = removeDuplicateWorkHistoriesInARow(workHistory);
    setWorkHistory(workHistory);
    setIsLoading(false);
  };

  useEffect(() => {
    getWorkHistory();
  }, []);

  const onFilterTabChange = (_: React.SyntheticEvent<Element, Event>, data: WorkHistoryFilter) => {
    setFilter(data);
  };

  return (
    <Stack alignItems='center'>
      {isLoading
        ? <LinearProgress sx={{ width: '100%' }} />
        : <>
          <TabContext value={filter.toString()}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={filter} onChange={onFilterTabChange}>
                <Tab label={formatMessage(m.lastDay)} />
                <Tab label={formatMessage(m.lastWeek)} />
                <Tab label={formatMessage(m.lastMonth)} />
              </Tabs>
            </Box>
            <TabPanel value={WorkHistoryFilter.LastDay.toString()} sx={{ width: '100%' }}>
              <WorkHistoryByDate
                workHistoryByDates={groupWorkHistoryByDate(workHistory.filter(workHistory => dayjs(workHistory.eventDate).isAfter(dayjs().subtract(1, 'day'))))}
                noHistoryTitle={formatMessage(m.noHistoryLastDay)}
              />
            </TabPanel>
            <TabPanel value={WorkHistoryFilter.LastWeek.toString()} sx={{ width: '100%' }}>
              <WorkHistoryByDate
                workHistoryByDates={groupWorkHistoryByDate(workHistory.filter(workHistory => dayjs(workHistory.eventDate).isAfter(dayjs().subtract(1, 'week'))))}
                noHistoryTitle={formatMessage(m.noHistoryLastWeek)}
              />
            </TabPanel>
            <TabPanel value={WorkHistoryFilter.LastMonth.toString()} sx={{ width: '100%' }}>
              <WorkHistoryByDate
                workHistoryByDates={groupWorkHistoryByDate(workHistory.filter(workHistory => dayjs(workHistory.eventDate).isAfter(dayjs().subtract(1, 'month'))))}
                noHistoryTitle={formatMessage(m.noHistoryLastMonth)}
              />
            </TabPanel>
          </TabContext>
        </>
      }
    </ Stack>
  );
};