import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { List } from 'semantic-ui-react';

const m = defineMessages({
  description: { id: 'NoWarningsListItem.description', defaultMessage: 'No warnings' }
});

export const NoWarningsListItem = () => {
  const { formatMessage } = useIntl();

  return (
    <List.Item>
      <List.Icon name="check circle" color="green" />
      <List.Content>{formatMessage(m.description)}</List.Content>
    </List.Item>
  );
};
