import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { isAuthenticated as isAuthenticatedSelector } from '../../modules/account/selectors';
import { ApplicationState } from '../../store';

export type PrivateRouteProps = RouteProps;

const PrivateRoute = (props: RouteProps) => {
  const isAuthenticated = useSelector<ApplicationState, boolean>(state => isAuthenticatedSelector(state));

  return (
    isAuthenticated
      ? <Route {...props} />
      : <Redirect to="/account/login" />
  );
};

export { PrivateRoute };
