import * as React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { workstationIcon } from '.';
import { DetectClickOutside, TileEditTemplate, Tooltip } from '../../../../../../components';
import { ValidationResult } from '../../../../../../models';
import { WorkstationEdit } from '../../../models/WorkstationEdit';
import { WorkstationForm } from './WorkstationForm';

interface OwnProps {
    style?: React.CSSProperties;
    className?: string;

    onCreate: (workstation: WorkstationEdit) => void;
    onCancel: () => void;
}

interface StateProps {
    workstation: WorkstationEdit;
    validationResult: ValidationResult;
}

export type CreateWorkstationTileProps =
    & WrappedComponentProps
    & OwnProps;

const m = defineMessages({
  deleteTooltip: { id: 'CreateWorkstationTile.deleteTooltip', defaultMessage: 'Delete' }
});

class CreateWorkstationTile extends React.Component<CreateWorkstationTileProps, StateProps> {
  public constructor (props: CreateWorkstationTileProps) {
    super(props);

    this.state = {
      workstation: new WorkstationEdit(),
      validationResult: ValidationResult.empty()
    };
  }

  public render () {
    return (
      <DetectClickOutside onClickOutside={this.createWorkstation}>
        <TileEditTemplate
          tileIcon={workstationIcon}
          name={this.state.workstation.name || ''}
          description={this.state.workstation.description || ''}
          canEditDescription={true}
          validationResult={this.state.validationResult}
          closeIcon={this.renderCloseIcon()}
          onUpdate={this.changeWorkstation}
          onClose={this.props.onCancel}
          className={this.props.className}
          style={this.props.style}
        >
          <WorkstationForm
            workstation={this.state.workstation}
            validationResult={this.state.validationResult}
            onUpdate={this.changeWorkstation}
          />
        </TileEditTemplate>
      </DetectClickOutside>
    );
  }

  private renderCloseIcon = () => {
    const { formatMessage } = this.props.intl;

    return (
      <Tooltip trigger={<Icon name="trash alternate" color="red" link size="large" onClick={this.props.onCancel} />}>
        {formatMessage(m.deleteTooltip)}
      </Tooltip>
    );
  };

  private createWorkstation = () => {
    const validationResult = this.state.workstation.validate();
    this.setState({ validationResult });

    if (validationResult.isValid) {
      this.props.onCreate(this.state.workstation);
    }
  };

  private changeWorkstation = (fieldId: string, fieldValue: any) => {
    this.setState((current) => ({
      ...current,
      workstation: new WorkstationEdit({
        ...current.workstation,
        [fieldId]: fieldValue
      })
    }));
  };
}

const intlComponent = injectIntl(CreateWorkstationTile);
export { intlComponent as CreateWorkstationTile };
