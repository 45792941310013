import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, DropdownProps, Grid } from 'semantic-ui-react';

import { PageHeader, RaisedTile } from '../../../../components';
import { ApplicationState } from '../../../../store';
import { isSystemLoading } from '../../../system/selectors';
import { getOperationById } from '../../selectors';
import { getLotOverviewsForOperation, getLotFilter } from '../selectors';
import { LotFilter } from '../types';
import { LotFilterDropdown } from './components/LotFilterDropdown';
import { LotSelectionList } from './components/LotSelectionList';

import * as LayoutActions from '../../../../store/layout/actions';
import * as WorkstationActions from '../actions';
import { ProductionStatus } from '../../lots/types';

const m = defineMessages({
  title: { id: 'LotSelectionPage.title', defaultMessage: 'Lots by priority' },
  selectLot: { id: 'LotSelectionPage.selectLot', defaultMessage: 'Select a lot' }
});

interface RouteProps {
    workstationId: string;
    operationId: string;
    unitId: string;
}

export const LotSelectionPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { workstationId, operationId, unitId } = useParams<RouteProps>();
  const dispatch = useDispatch();
  const history = useHistory();

  const loadingApplication = useSelector(isSystemLoading);
  const lots = useSelector((state: ApplicationState) => getLotOverviewsForOperation(state, Number(operationId), unitId != null ? Number(unitId) : undefined));
  const lotFilter = useSelector(getLotFilter);

  const isLoading = loadingApplication;
  const displayedLots = lotFilter === LotFilter.All
    ? lots
    : lots.filter(x => x.productionStatus !== ProductionStatus.completed);

  const operation = useSelector((state: ApplicationState) => getOperationById(state, Number(operationId)));

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({
      title: formatMessage(m.title),
      subtitle: operation != null ? operation.name : ''
    }));
  }, [dispatch, formatMessage, operation]);

  const onLotsFilterChanged = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    dispatch(WorkstationActions.changeLotFilter(data.value as LotFilter));
  };

  const goToLotExecution = (lotId: number): void => {
    history.push(`/workstations/${workstationId}/operations/${operationId}/lots/${lotId}/roadmap`);
  };

  return (
    <RaisedTile>
      <Container>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column />
            <Grid.Column textAlign="center">
              <PageHeader title={formatMessage(m.selectLot)} centered />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <LotFilterDropdown
                value={lotFilter}
                onChange={onLotsFilterChanged}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <LotSelectionList
          lots={displayedLots}
          operation={operation}
          loading={isLoading}
          onLotClick={goToLotExecution}
        />
      </Container>
    </RaisedTile>
  );
};
