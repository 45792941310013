import { Avatar, Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { PersonaPresence } from '../../../modules';
import { getInitials } from '../../../modules/account/selectors';

interface OwnProps {
    displayName: string;
    size?: number;
    style?: React.CSSProperties;
    presence?: PersonaPresence;
}

export type UserPresenceCoinProps =
    & OwnProps

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}));

const OnlineBadge = styled(StyledBadge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700'
  }
}));

const OfflineBadge = styled(StyledBadge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.main
  }
}));

export const UserPresenceCoin: React.FC<UserPresenceCoinProps> = (props) => {
  const { displayName, size, style, ...personaProps } = props;

  const PresenceAvatar = () => (<Avatar
    { ...personaProps}
    sx={{ width: size || 40, height: size || 40 }}
  >
    {getInitials(displayName)}
  </Avatar>);

  // We have to wrap the coin inside a div, because the 'style' property doesn't seem to be applied
  // on the Persona component.
  return (
    <div style={style}>
      {props.presence === PersonaPresence.Offline && (
        <OfflineBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <PresenceAvatar />
        </OfflineBadge>
      )}
      {props.presence === PersonaPresence.Online && (
        <OnlineBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <PresenceAvatar />
        </OnlineBadge>
      )}
      {props.presence == null && (
        <PresenceAvatar />
      )}
    </div>
  );
};
