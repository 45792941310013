import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export const ProductionItemsEmptyState = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography variant="body1" align="center">
        {formatMessage({ id: 'No production items' })}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {formatMessage({
          id: 'There are no production items assigned to this order.',
        })}
      </Typography>
    </>
  );
};
