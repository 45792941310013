import * as React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Sidebar } from 'semantic-ui-react';
import { ApplicationState } from '../../store';
import { isNavigationMenuVisible } from '../../store/layout';
import { WorkHistoryDrawer } from './HistoryDrawer';
import { NavigationSidebar } from './navigation';
import { NotificationBar } from './notifications/NotificationBar';
import styles from './PageLayout.module.css';
import { SearchModal } from './SearchModal';
import { SettingsDrawer } from './SettingsDrawer';
import { TopBar } from './top-navigation/TopBar';


export type PageLayoutProps = {
    children: React.ReactChild
};

export const PageLayout: React.FunctionComponent<PageLayoutProps> = (props: PageLayoutProps) => {
  const isNavigationMenuVisibleState = useSelector<ApplicationState, boolean>(state => isNavigationMenuVisible(state));

  return (
    <>
      <div className={styles.pageContainer}>
        <ToastContainer position="top-center" style={{ width: '500px' }} />

        <TopBar />

        <Sidebar.Pushable as="div">
          <NavigationSidebar />

          <Sidebar.Pusher dimmed={isNavigationMenuVisibleState} style={{ overflow: 'initial' }}>
            <div className={styles.pageContent}>
              {props.children}
              <div style={{ width: '100%', position: 'fixed', bottom: 0, padding: 10 }}>
                <NotificationBar />
              </div>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>

      <SettingsDrawer anchor="right" />
      <WorkHistoryDrawer anchor="right" />
      <SearchModal />
    </>
  );
};
