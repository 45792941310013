import { CreateRoadmapRequestAction, CREATE_ROADMAP_REQUEST, CreateRoadmapSuccessAction, CREATE_ROADMAP_SUCCESS, CreateRoadmapFailureAction, CREATE_ROADMAP_FAILURE, UPDATE_ROADMAP_SUCCESS, UPDATE_ROADMAP_REQUEST, UPDATE_ROADMAP_FAILURE, DELETE_ROADMAP_FAILURE, UpdateRoadmapRequestAction, UpdateRoadmapSuccessAction, UpdateRoadmapFailureAction, DeleteRoadmapRequestAction, DeleteRoadmapSuccessAction, DELETE_ROADMAP_REQUEST, DELETE_ROADMAP_SUCCESS, DeleteRoadmapFailureAction } from './types';
import { Roadmap } from '../../production/roadmaps/types';

export function createRoadmap (roadmap: Roadmap): CreateRoadmapRequestAction {
  return {
    type: CREATE_ROADMAP_REQUEST,
    payload: { roadmap }
  };
}

export function createRoadmapSuccess (roadmap: Roadmap): CreateRoadmapSuccessAction {
  return {
    type: CREATE_ROADMAP_SUCCESS,
    payload: { roadmap }
  };
}

export function createRoadmapFailure (error: Error): CreateRoadmapFailureAction {
  return {
    type: CREATE_ROADMAP_FAILURE,
    payload: { error }
  };
}

export function updateRoadmap (roadmapId: number, roadmap: Roadmap): UpdateRoadmapRequestAction {
  return {
    type: UPDATE_ROADMAP_REQUEST,
    payload: { roadmapId, roadmap }
  };
}

export function updateRoadmapSuccess (roadmap: Roadmap): UpdateRoadmapSuccessAction {
  return {
    type: UPDATE_ROADMAP_SUCCESS,
    payload: { roadmap }
  };
}

export function updateRoadmapFailure (error: Error): UpdateRoadmapFailureAction {
  return {
    type: UPDATE_ROADMAP_FAILURE,
    payload: { error }
  };
}

export function deleteRoadmap (roadmapId: number): DeleteRoadmapRequestAction {
  return {
    type: DELETE_ROADMAP_REQUEST,
    payload: { roadmapId }
  };
}

export function deleteRoadmapSuccess (roadmapId: number): DeleteRoadmapSuccessAction {
  return {
    type: DELETE_ROADMAP_SUCCESS,
    payload: { roadmapId }
  };
}

export function deleteRoadmapFailure (roadmapId: number, error: Error): DeleteRoadmapFailureAction {
  return {
    type: DELETE_ROADMAP_FAILURE,
    payload: { roadmapId, error }
  };
}
