import _, { update } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { Ref, Table } from 'semantic-ui-react';
import { RoadmapSortEntry, SortDirection } from '.';
import { CreateRoadmapFieldsSortEntry } from './CreateRoadmapFieldsSortEntry';
import { DraggableRoadmapFieldsSortEntry } from './DraggableRoadmapFieldsSortEntry';

interface OwnProps {
    selectedSortings: RoadmapSortEntry[];
    onChange: (sortings: RoadmapSortEntry[]) => void;
}

export type RoadmapFieldsSortProps =
    & OwnProps;

export const RoadmapFieldsSort: React.FC<RoadmapFieldsSortProps> = (props) => {
  const [entries, setEntries] = useState<RoadmapSortEntry[]>(props.selectedSortings);

  useEffect(() => {
    setEntries(props.selectedSortings);
  }, [props.selectedSortings, setEntries]);

  const changeEntries = (updatedEntries: RoadmapSortEntry[]) => {
    updatedEntries.forEach((entry, index) => entry.orderIndex = index);
    setEntries(updatedEntries);
    props.onChange(updatedEntries);
  };

  const addEntry = (entry: RoadmapSortEntry) => {
    const existingEntry = entries.find(x => x.field.id === entry.field.id);

    if (existingEntry == null) {
      entry.orderIndex = entries.length;
      changeEntries([...entries, entry]);
    }
  };

  const removeEntry = (entry: RoadmapSortEntry) => {
    changeEntries(entries.filter(x => x.field.id !== entry.field.id));
  };

  const updateEntry = (updatedEntry: RoadmapSortEntry) => {
    const updatedEntryIndex = entries.findIndex(x => x.field.id === updatedEntry.field.id);
    const updatedEntries = _(entries).clone();
    updatedEntries.splice(updatedEntryIndex, 1, updatedEntry);
    changeEntries(updatedEntries);
  };

  const handleDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (result.reason === 'DROP') {
      const fieldId = Number(result.draggableId);
      const draggedEntry = entries.find(x => x.field.id === fieldId);

      if (draggedEntry == null || result.destination == null) {
        return;
      }

      const toIndex = result.destination.index;
      const fromIndex = entries.findIndex(x => x.field.id === fieldId);

      const reorderedEntries = _.clone(entries);
      reorderedEntries.splice(toIndex, 0, reorderedEntries.splice(fromIndex, 1)[0]);
      changeEntries(reorderedEntries);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Table basic="very" selectable>
        {entries.length > 0 &&
                    <Droppable droppableId="roadmapFieldsSort">
                      {(provided) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body>
                            {entries.map(entry => (
                              <DraggableRoadmapFieldsSortEntry
                                key={entry.field.id}
                                index={entry.field.id}
                                entry={entry}
                                onChange={updateEntry}
                                onRemove={() => removeEntry(entry)}
                              />
                            ))}

                            {provided.placeholder}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
        }

        <Table.Footer>
          <CreateRoadmapFieldsSortEntry
            onAdd={addEntry}
          />
        </Table.Footer>
      </Table>
    </DragDropContext>
  );
};
