import { ApplicationState } from '../../store';
import { ConnectionStatus, UserLocation } from './types';
import { getCurrentDevice } from '../administration/devices/selectors';
import { getWorkstationById } from '../administration';

export const getConnectionStatus = (state: ApplicationState) => state.liveUpdates.connectionStatus;
export const isApplicationOffline = (state: ApplicationState) => getConnectionStatus(state) === ConnectionStatus.offline;
export const areLiveUpdatesForcedOff = (state: ApplicationState) => state.liveUpdates.areLiveUpdatesForcedOff;

export const getCurrentUserLocation = (state: ApplicationState, path: string): UserLocation => {
  const locationRegex = /^\/workstations\/(?<workstationId>\d+)(\/operations\/(?<operationId>\d+)(\/lots\/(?<lotId>\d+)|\/production-orders\/(?<productionItemId>\d+))?)?/;
  const locationResult = locationRegex.exec(path);
  const workstationId = Number(locationResult?.groups?.workstationId) || undefined;
  const workstation = workstationId != null ? getWorkstationById(state, workstationId) : undefined;
  const currentDevice = getCurrentDevice(state);

  return {
    deviceId: currentDevice?.id,
    workstationId: Number(locationResult?.groups?.workstationId) || undefined,
    costCenterId: workstation?.costCenterId,
    operationId: Number(locationResult?.groups?.operationId) || undefined,
    lotId: Number(locationResult?.groups?.lotId) || undefined,
    productionItemId: Number(locationResult?.groups?.productionItemId) || undefined,
    locationUrl: path
  };
};
