export const LOAD_LOTS = 'LOAD_LOTS';
export const LOAD_LOTS_SUCCESS = 'PRODUCTION_LOAD_LOTS_SUCCESS';
export const LOAD_LOTS_FAILURE = 'PRODUCTION_LOAD_LOTS_FAILURE';

export const LOAD_PRIORITIZED_LOTS_REQUEST = 'PRODUCTION_LOAD_PRIORITIZED_SALES_ORDERS_REQUEST';
export const LOAD_PRIORITIZED_LOTS_SUCCESS = 'PRODUCTION_LOAD_PRIORITIZED_SALES_ORDERS_SUCCESS';
export const LOAD_PRIORITIZED_LOTS_FAILURE = 'PRODUCTION_LOAD_PRIORITIZED_SALES_ORDERS_FAILURE';

export interface LoadLotsAction { type: typeof LOAD_LOTS; payload: { productionUnitId: number, productionDate: Date }; }
export interface LoadLotsSuccessAction { type: typeof LOAD_LOTS_SUCCESS; payload: { productionUnitId: number, lots: Lot[] }; }
export interface LoadLotsFailureAction { type: typeof LOAD_LOTS_FAILURE; payload: { productionUnitId: number, error: Error }; }

export interface LoadPrioritizedSalesOrdersRequestAction { type: typeof LOAD_PRIORITIZED_LOTS_REQUEST; payload: Record<string, never> }
export interface LoadPrioritizedLotsSuccessAction { type: typeof LOAD_PRIORITIZED_LOTS_SUCCESS; payload: { lots: Lot[] }; }
export interface LoadPrioritizedLotsFailureAction { type: typeof LOAD_PRIORITIZED_LOTS_FAILURE; payload: { error: Error }; }

export type LotsActionTypes =
    | LoadLotsAction | LoadLotsSuccessAction | LoadLotsFailureAction
    | LoadPrioritizedSalesOrdersRequestAction | LoadPrioritizedLotsSuccessAction | LoadPrioritizedLotsFailureAction;

export interface LotsState {
    readonly byId: {
        [lotId: number]: Lot;
    };

    readonly byUnitId: {
        [unitId: string]: number[];
    };

    readonly allIds: number[];

    readonly isRoadmapLoading: boolean;
}

export interface Lot {
    id: number;
    name: string;
    status: ProductionStatus;
    productionUnitId: number;
    productionItems: number[];
    createdOn: string;
    isReadyForProduction: boolean;
}

export enum ProductionStatus {
    created = 0,
    started = 1,
    completed = 2
}

export interface LotOverviewForPrioritization {
    id: number;
    name: string;
    quantity: number;
    productionDate: Date | string;
    productionUnitId: number;
    productionStatus: ProductionStatus;
    orderIndex: number | undefined;
}

export interface ProductionItemForPrioritization {
    id: number;
    itemDescription: string;
    quantity: number;
}

export interface LotManagementData
{
    salesOrders: { [id: number]: LotManagementSalesOrder };
    lots: { [id: number]: LotManagementLot };
    items: { [id: number]: LotManagementItem};
    productionItems: { [id: number]: LotManagementProductionItem };
}

export interface LotManagementSalesOrder
{
    id: number;
    orderedOn: string;
    salesOrderNumber: string;
    productionStatus: ProductionStatus;
}

export interface LotManagementLot
{
    id: number;
    name: string;
    productionStatus: ProductionStatus;
}

export interface LotManagementItem
{
    id: number;
    itemCode: string;
    itemDescription: string;
}

export interface LotManagementProductionItem
{
    id: number;
    salesOrderId: number;
    lotId?: number;
    itemId: number;
    quantity: number;
    productionStatus: ProductionStatus;
}