import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

export type ViewInstructionsButtonProps =
    & ButtonProps;

const m = defineMessages({
  instructionsButton: { id: 'WorkInstructionsButton.instructionsButton', defaultMessage: 'Instructions' }
});

export const ViewInstructionsButton: React.FC<ViewInstructionsButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const { ...buttonProps } = props;

  return (
    <Button
      variant="contained"
      style={{ textTransform: 'uppercase' }}
      {...buttonProps}
    >
      {formatMessage(m.instructionsButton)}
    </Button>
  );
};
