import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Label, SemanticICONS, List } from 'semantic-ui-react';
import { Tile, TileState } from '../../../../../../components/tiles';
import { Workstation } from '../../../types';
import { WorkstationEdit } from '../../../models/WorkstationEdit';
import { EditWorkstationTile } from './EditWorkstationTile';
import { DeleteWorkstationTile } from './DeleteWorkstationTile';
import { Operation, getCostCenterById, CostCenter, getAllOperations, Unit, getUnitById } from '../../../../../production';
import { ApplicationState } from '../../../../../../store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

interface OwnProps {
    workstation: Workstation;
    style?: React.CSSProperties;
    className?: string;
    onSave?: (workstation: WorkstationEdit) => void;
    onDelete?: () => void;
}

interface StoreProps {
    costCenter: CostCenter | undefined;
    operations: Operation[];
    productionUnit: Unit | undefined;
}

interface StateProps {
    currentState: TileState;
}

export type WorkstationTileProps =
    & OwnProps
    & StoreProps
    & WrappedComponentProps;

export const workstationIcon: SemanticICONS = 'street view';

const m = defineMessages({
  costCenterLabel: { id: 'WorkstationTile.costCenterLabel', defaultMessage: 'Department' },
  operationsLabel: { id: 'WorkstationTile.operationsLabel', defaultMessage: 'Manufacturing operations' },
  productionUnitLabel: { id: 'WorkstationTile.productionUnitLabel', defaultMessage: 'Handles work orders from' },
  productionUnitPlaceholder: { id: 'WorkstationTile.productionUnitPlaceholder', defaultMessage: 'All cells' }
});

class WorkstationTile extends React.Component<WorkstationTileProps, StateProps> {
  public constructor (props: WorkstationTileProps) {
    super(props);

    this.state = { currentState: TileState.default };
  }

  public render () {
    const { formatMessage } = this.props.intl;
    const costCenterName = this.props.costCenter ? this.props.costCenter.name : '';

    return (
      <Tile
        state={this.state.currentState}
        onChangeState={(newState) => this.setState({ currentState: newState })}
        header={{ icon: workstationIcon, title: this.props.workstation.name, subtitle: this.props.workstation.description }}
        editTemplate={this.renderEditTemplate()}
        deleteTemplate={this.renderDeleteTemplate()}
        className={this.props.className}
        style={this.props.style}
      >
        <List relaxed style={{ marginTop: 0 }}>
          <List.Item>
            <List.Header>{formatMessage(m.costCenterLabel)}</List.Header>
            {costCenterName}
          </List.Item>
          <List.Item>
            <List.Header>{formatMessage(m.operationsLabel)}</List.Header>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
              {this.props.workstation.operationIds.map(x => <Label key={x} style={{ margin: '2px' }} content={this.getOperationName(x)} />)}
            </div>
          </List.Item>
          <List.Item>
            <List.Header>{formatMessage(m.productionUnitLabel)}</List.Header>
            {this.props.productionUnit ? this.props.productionUnit.name : formatMessage(m.productionUnitPlaceholder)}
          </List.Item>
        </List>
      </Tile>
    );
  }

  private renderEditTemplate (): JSX.Element | undefined {
    if (this.props.onSave == null) {
      return undefined;
    }

    return (
      <EditWorkstationTile
        workstation={new WorkstationEdit(this.props.workstation)}
        onClose={(workstation) => this.saveWorkstation(workstation)}
        className={this.props.className}
        style={this.props.style}
      />
    );
  }

  private renderDeleteTemplate (): JSX.Element | undefined {
    if (this.props.onDelete == null) {
      return undefined;
    }

    return (
      <DeleteWorkstationTile
        workstation={new WorkstationEdit(this.props.workstation)}
        onCancel={() => this.setState({ currentState: TileState.default })}
        onConfirm={this.props.onDelete}
        className={this.props.className}
        style={this.props.style}
      />
    );
  }

  private saveWorkstation = (workstation: WorkstationEdit) => {
    this.setState({ currentState: TileState.default });

    if (this.props.onSave) {
      this.props.onSave(workstation);
    }
  };

  private getOperationName = (operationId: number): string => {
    const operation = this.props.operations.find(x => x.id === operationId);
    return operation ? operation.code : operationId.toString();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StoreProps => {
  return {
    costCenter: getCostCenterById(state, ownProps.workstation.costCenterId),
    operations: getAllOperations(state),
    productionUnit: ownProps.workstation.productionUnitId ? getUnitById(state, ownProps.workstation.productionUnitId) : undefined
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

const intlComponent = injectIntl(WorkstationTile);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as WorkstationTile };
