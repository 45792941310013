import { ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { localeNames, SupportedLocales } from '../../../../configureI18n';
import { commonMessages } from '../../../../constants';
import { getCurrentLocale } from '../../../../store/layout';

interface OwnProps {
    locale: SupportedLocales;
    onClick: () => void;
}

export type LanguageSwitcherButtonProps =
    & OwnProps;

export const LanguageSwitcherButton: React.FC<LanguageSwitcherButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  const currentLocale = useSelector(getCurrentLocale);
  const nextLocaleName = localeNames[props.locale] || commonMessages.undefined;

  return (
    <ListItemButton selected={currentLocale === props.locale} onClick={props.onClick}>
      <ListItemText primary={formatMessage(nextLocaleName)} />
    </ListItemButton>
  );
};