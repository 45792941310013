import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RaisedTile } from '../../../../components';
import { PageHeader } from '../../../../components/layout';
import { getAllOperations, Operation } from '../../../production';
import * as OperationsActions from '../actions';
import { areOperationsLoading } from '../selectors';
import { OperationsSearchControl } from './components/OperationsSearchControl';
import { OperationsTable } from './components/OperationsTable';
import { UpdateOperationModal } from './components/UpdateOperationModal';


const m = defineMessages({
  title: { id: 'OperationsConfigurationPage.title', defaultMessage: 'Configure Operations' },
  subtitle: { id: 'OperationsConfigurationPage.subtitle', defaultMessage: 'View and configure operations.' }
});

export const ManageOperationsPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOperation, selectOperation] = useState<Operation | undefined>(undefined);
  const [displayedOperations, setDisplayedOperations] = useState<Operation[]>([]);

  const operations = useSelector(getAllOperations);
  const loading = useSelector(areOperationsLoading);

  useEffect(() => {
    setDisplayedOperations(operations);
  }, [operations]);

  const searchOperations = (searchTerm: string) => {
    if (searchTerm === '') {
      setDisplayedOperations(operations);
      return;
    }

    const normalizedSearchTerm = _.deburr(searchTerm).toLocaleLowerCase();
    const searchResults = operations.filter(x => _.deburr(x.name).toLocaleLowerCase().includes(normalizedSearchTerm) ||
            _.deburr(x.code).toLocaleLowerCase().includes(normalizedSearchTerm));

    setDisplayedOperations(searchResults);
  };

  const openModalWithOperation = (operation: Operation) => {
    selectOperation(operation);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const updateOperation = (operation: Operation) => {
    dispatch(OperationsActions.update(operation));
    closeModal();
  };

  return (
    <RaisedTile>
      <PageHeader icon="wrench" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />

      <OperationsSearchControl
        totalCount={operations.length}
        searchCount={displayedOperations.length}
        onSearchChange={searchOperations}
      />

      <OperationsTable
        striped
        loading={loading}
        operations={displayedOperations}
        onClick={openModalWithOperation}
      />

      <UpdateOperationModal
        size="small"
        closeIcon
        closeOnDimmerClick={false}
        operation={selectedOperation}
        open={isModalOpen}
        onClose={closeModal}
        onCancel={closeModal}
        onConfirm={updateOperation}
      />
    </RaisedTile>
  );
};
