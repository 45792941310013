import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import { getCurrentUser } from '../../../../modules/account/selectors';
import { Stack } from '@mui/material';
import { CurrentDeviceSummary } from './CurrentDeviceSummary';
import { UserPresenceCoin } from '../../../presences/UserPresenceCoin';
import * as LayoutActions from '../../../../store/layout/actions';

export const AccountControl: React.FC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const openSettingsDrawer = () => dispatch(LayoutActions.showSettingsDrawer());

  return (
    <Menu.Item onClick={openSettingsDrawer}>
      <Stack direction="row">
        <UserPresenceCoin displayName={currentUser?.displayName || ''} size={40} />
        <CurrentDeviceSummary />
      </Stack>
    </Menu.Item>
  );
};
