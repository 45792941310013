import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  InputOnChangeData,
  Message,
  Modal,
  ModalProps
} from 'semantic-ui-react';

import { CostCenter } from '../../../..';
import { commonMessages } from '../../../../../constants';
import { ValidationResult } from '../../../../../models';
import { CostCenterEdit } from '../../../../../models/cost-center/CostCenterEdit';
import { ApplicationState } from '../../../../../store';
import { nameof } from '../../../../../utils';
import { ProductionSorting, getProductionSortingDescription } from '../../../../production';
import { getProductionUnitDropdownOptions } from '../../selectors';

interface OwnProps {
    costCenter?: CostCenter;
    onCancel: () => void;
    onConfirm: (costCenter: CostCenterEdit) => ValidationResult;
}

interface StoreProps {
    productionUnitsOptions: DropdownItemProps[];
}

export type AddOrUpdateCostCenterModalProps =
    & OwnProps
    & ModalProps
    & StoreProps
    & WrappedComponentProps;

export interface StateProps {
    costCenterEdit: CostCenterEdit;
    validationResult: ValidationResult;
}

const m = defineMessages({
  titleAdd: { id: 'CostCenterModal.titleAdd', defaultMessage: 'Add a new cost center' },
  titleModify: { id: 'CostCenterModal.titleModify', defaultMessage: 'Modify cost center' },
  nameLabel: { id: 'CostCenterModal.nameLabel', defaultMessage: 'Name' },
  productionUnitsLabel: { id: 'CostCenterModal.productionUnitsLabel', defaultMessage: 'Production units' },
  productionSortingLabel: { id: 'CostCenterModal.productionSortingLabel', defaultMessage: 'Sort lots and production items by' },
  chooseProductionUnitsPlaceholder: { id: 'CostCenterModal.chooseProductionUnitsPlaceholder', defaultMessage: 'Choose production units' }
});

class AddOrUpdateCostCenterModal extends React.Component<AddOrUpdateCostCenterModalProps, StateProps> {
  public constructor (props: AddOrUpdateCostCenterModalProps) {
    super(props);

    const costCenterEdit = new CostCenterEdit();
    if (this.props.costCenter) {
      costCenterEdit.id = this.props.costCenter.id;
      costCenterEdit.name = this.props.costCenter.name;
      costCenterEdit.productionUnits = [...this.props.costCenter.productionUnits];
      costCenterEdit.sortBy = this.props.costCenter.sortBy;
    }

    this.state = {
      costCenterEdit,
      validationResult: ValidationResult.empty()
    };
  }

  componentDidUpdate (prevProps: AddOrUpdateCostCenterModalProps) {
    if (this.props.costCenter !== prevProps.costCenter) {
      const costCenterEdit = new CostCenterEdit();
      if (this.props.costCenter) {
        costCenterEdit.id = this.props.costCenter.id;
        costCenterEdit.name = this.props.costCenter.name;
        costCenterEdit.productionUnits = this.props.costCenter.productionUnits ? [...this.props.costCenter.productionUnits] : [];
        costCenterEdit.sortBy = this.props.costCenter.sortBy;
      }

      this.setState({
        ...this.state,
        costCenterEdit,
        validationResult: ValidationResult.empty()
      });
    }
  }

  public render () {
    const { formatMessage } = this.props.intl;
    const { intl, costCenterEdit, costCenter, productionUnitsOptions, onCancel, onConfirm: onAdd, ...modalProps } = this.props;
    const localizedErrorMessages = this.state.validationResult.errorMessages.map(value => formatMessage(value));
    const sortByOptions: DropdownItemProps[] = [
      { text: formatMessage(getProductionSortingDescription(ProductionSorting.byProductionSchedule)), value: ProductionSorting.byProductionSchedule },
      { text: formatMessage(getProductionSortingDescription(ProductionSorting.manually)), value: ProductionSorting.manually }
    ];

    return (
      <Modal closeOnDimmerClick={false} {...modalProps} onClose={this.close}>
        <Modal.Header>{this.props.costCenter ? formatMessage(m.titleModify) : formatMessage(m.titleAdd)}</Modal.Header>
        <Modal.Content>
          <Form error={!this.state.validationResult.isValid}>
            <Message error={true} list={localizedErrorMessages} />
            <Form.Input
              id={nameof<CostCenterEdit>('name')}
              error={this.state.validationResult.isFieldInvalid(nameof<CostCenterEdit>('name'))}
              label={formatMessage(m.nameLabel)}
              value={this.state.costCenterEdit.name}
              onChange={this.handleInputChange}
              required
            />
            <Form.Field required={true}>
              <label>{formatMessage(m.productionUnitsLabel)}</label>
              <Dropdown
                selection={true}
                multiple={true}
                value={this.state.costCenterEdit.productionUnits}
                options={this.props.productionUnitsOptions}
                placeholder={formatMessage(m.chooseProductionUnitsPlaceholder)}
                onChange={this.onChangeProductionUnits}
              />
            </Form.Field>
            <Form.Field>
              <label>{formatMessage(m.productionSortingLabel)}</label>
              <Dropdown
                selection={true}
                options={sortByOptions}
                value={this.state.costCenterEdit.sortBy}
                onChange={this.onChangeSortBy}
              />
            </Form.Field>
            <Divider />
            <div style={{ textAlign: 'right' }}>
              <Button content={formatMessage(commonMessages.cancel)} onClick={this.cancel} />
              <Button primary content={this.props.costCenter ? formatMessage(commonMessages.modify) : formatMessage(commonMessages.add)} onClick={this.addCostCenter} style={{ minWidth: 90 }} />
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const fieldValue = data.value;
    const fieldId = event.currentTarget.id;

    if (fieldId !== '') {
      this.setState((current) => ({
        ...current,
        costCenterEdit: new CostCenterEdit({
          ...current.costCenterEdit,
          [fieldId]: fieldValue
        })
      }));
    }
  }

  private onChangeProductionUnits = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const productionUnitIds = data.value as number[];
    if (productionUnitIds) {
      this.setState(current => ({
        ...current,
        costCenterEdit: new CostCenterEdit({
          ...current.costCenterEdit,
          productionUnits: productionUnitIds
        })
      }));
    }
  }

  private onChangeSortBy = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const sortBy = data.value as ProductionSorting;
    this.setState(current => ({
      ...current,
      costCenterEdit: new CostCenterEdit({
        ...current.costCenterEdit,
        sortBy
      })
    }));
  }

  private addCostCenter = () => {
    const validationResult = this.props.onConfirm(this.state.costCenterEdit);
    this.setState({ validationResult });

    if (validationResult.isValid) {
      this.resetState();
    }
  }

  private cancel = () => {
    this.resetState();
    this.props.onCancel();
  }

  private close = (event: React.MouseEvent<HTMLElement>, data: ModalProps) => {
    this.resetState();

    if (this.props.onClose) {
      this.props.onClose(event, data);
    }
  }

  private resetState () {
    this.setState({
      costCenterEdit: new CostCenterEdit(),
      validationResult: ValidationResult.empty()
    });
  }
}

const mapStateToProps = (state: ApplicationState, props: OwnProps): StoreProps => {
  return {
    productionUnitsOptions: getProductionUnitDropdownOptions(state, props.costCenter ? props.costCenter.id : undefined)
  };
};

const intlComponent = injectIntl(AddOrUpdateCostCenterModal);
const connectedComponent = connect(mapStateToProps)(intlComponent);
export { connectedComponent as AddOrUpdateCostCenterModal };
