import React, { useRef, useState } from 'react';
import { Button, ButtonGroup, ButtonGroupProps, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export type SplitButtonOption = {
  label: string,
  onClick: () => void,
  disabled?: boolean;
};

interface OwnProps {
  loading?: boolean;
  options: SplitButtonOption[];
}

type SplitButtonProps = OwnProps
  & ButtonGroupProps;

export const SplitButton: React.FC<SplitButtonProps> = ({ options, loading, ...buttonGroupProps }) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  const handleItemClick = (index: number) => {
    options[index]?.onClick();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <ButtonGroup {...buttonGroupProps} disabled={options[0].disabled} ref={anchorRef}>
        <LoadingButton loading={loading} onClick={options[0].onClick} variant={buttonGroupProps.variant}>
          {options[0].label}
        </LoadingButton>

        {options.length > 1 && (
          <Button size="small" fullWidth={false} onClick={handleToggle} disabled={loading}>
            <ArrowDropDown />
          </Button>
        )}
      </ButtonGroup>

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement='bottom-end'
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem>
                  {options.map((opt, index) => (
                    <MenuItem
                      key={index}
                      selected={index === 0}
                      onClick={() => handleItemClick(index)}
                    >
                      {opt.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};