import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { LoadingDimmer } from '../../../../components';
import { ApplicationState } from '../../../../store';
import * as LayoutActions from '../../../../store/layout/actions';
import { getWorkstationOperations } from '../../../administration/workstations';
import { getOperationsStatus } from '../../../system/selectors';
import { InitializationStatus } from '../../../system/types';
import { OperatingMode, Operation, GroupingMode } from '../../types';
import { OperationSelectionList } from './components/OperationSelectionList';
import { NoOperationsPlaceholder } from './components/OperationSelectionList/NoOperationsPlaceholder';

const m = defineMessages({
  title: { id: 'OperationSelectionPage.title', defaultMessage: 'Operation selection' }
});

interface RouteProps {
    workstationId: string;
}

export const OperationSelectionPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { workstationId } = useParams<RouteProps>();
  const history = useHistory();
  const dispatch = useDispatch();

  const workstationOperations = useSelector((state: ApplicationState) => getWorkstationOperations(state, Number(workstationId)));
  const operationsInitializationStatus = useSelector(getOperationsStatus);

  const getOperationUrl = (operation: Operation) => {
    if (operation.groupingMode === GroupingMode.byProductionUnit) {
      return `/workstations/${workstationId}/operations/${operation.id}/production-units`;
    }

    return operation.operatingMode === OperatingMode.perProductionOrder
      ? `/workstations/${workstationId}/operations/${operation.id}/production-orders`
      : `/workstations/${workstationId}/operations/${operation.id}/lots`;
  };

  const handleOperationClick = (operation: Operation) => history.push(getOperationUrl(operation));

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.title) }));
  }, [dispatch, formatMessage]);

  if (operationsInitializationStatus !== InitializationStatus.initialized) {
    return (
      <div style={{ minHeight: '200px' }}>
        <LoadingDimmer active={true} />
      </div>
    );
  }

  const hasNoOperations = workstationOperations.length === 0;
  if (hasNoOperations) {
    return <NoOperationsPlaceholder />;
  }

  const hasOnlyOneOperation = workstationOperations.length === 1;
  if (hasOnlyOneOperation) {
    return <Redirect to={getOperationUrl(workstationOperations[0])} />;
  }

  return (
    <OperationSelectionList
      operations={workstationOperations}
      onSelected={handleOperationClick}
    />
  );
};
