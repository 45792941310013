import { Item } from '../production';

export const SEARCH_ITEMS = 'SEARCH_ITEMS';
export const SEARCH_ITEMS_SUCCESS = 'SEARCH_ITEMS_SUCCESS';
export const SEARCH_ITEMS_FAILURE = 'SEARCH_ITEMS_FAILURE';

export interface SearchItemsAction { type: typeof SEARCH_ITEMS; payload: { itemCode: string }; }
export interface SearchItemsSuccessAction { type: typeof SEARCH_ITEMS_SUCCESS; payload: { items: Item[] }; }
export interface SearchItemsFailureAction { type: typeof SEARCH_ITEMS_FAILURE; payload: { error: Error }; }

export type SearchActionTypes =
    | SearchItemsAction | SearchItemsSuccessAction | SearchItemsFailureAction;

export interface SearchState {
    itemsResults: Item[];
    isSearchingForItems: boolean;
}
