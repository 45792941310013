import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { FormSection } from '../../../../../../components/common/FormSection';
import { InformationBubble } from '../../../../../../components/common/InformationBubble';
import { User } from '../../../../../../models';
import { SecurityKeyRegistrationButton } from '../SecurityKeyRegistrationButton';
import { InformationMessage } from 'components/common/InformationMessage';
const m = defineMessages({
  authenticationSectionTitle: { id: 'AuthenticationPropertiesSection.authenticationSectionTitle', defaultMessage: 'Authentication properties' },
  authenticationSectionSubtitle: { id: 'AuthenticationPropertiesSection.authenticationSectionSubtitle', defaultMessage: 'Configure how the user logins into the application.' },
  emailLabel: { id: 'AuthenticationPropertiesSection.emailLabel', defaultMessage: 'Email' },
  emailPlaceholder: { id: 'AuthenticationPropertiesSection.emailPlaceholder', defaultMessage: 'test@example.com' },
  securityKeyLabel: { id: 'UserForm.securityKeyLabel', defaultMessage: 'Security key' },
  securityKeyTooltip: { id: 'UserForm.securityKeyTooltip', defaultMessage: 'A security key allows the user to connect to the system without specifying his username or password. Simply scanning the security key at the login prompt will connect him automatically.' },
  keyAlreadyRegisteredError: { id: 'UserForm.keyAlreadyRegisteredError', defaultMessage: 'This security key is already associated to another user.' }
});

export const AuthenticationPropertiesSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control, formState: { errors } } = useFormContext<User>();

  const userId = useWatch({ name: 'id' });
  const isNewUser = userId == null;

  return (
    <FormSection
      title={formatMessage(m.authenticationSectionTitle)}
      subtitle={formatMessage(m.authenticationSectionSubtitle)}
      isOpenByDefault={isNewUser}
    >
      <Form.Group widths="equal">
        <Controller
          name="username"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Input
              required
              label={formatMessage(m.emailLabel)}
              error={errors.username != null}
              placeholder={formatMessage(m.emailPlaceholder)}
              value={field.value || ''}
              onChange={(e, data) => field.onChange(data.value)}
            />
          )}
        />

        <Controller
          name="hasRegisteredAuthenticator"
          control={control}
          render={() => (
            <Form.Field disabled={isNewUser}>
              <label>
                {formatMessage(m.securityKeyLabel)}
                <InformationBubble content={formatMessage(m.securityKeyTooltip)} />
              </label>
              <SecurityKeyRegistrationButton fluid forUserId={userId} />
            </Form.Field>
          )}
        />
      </Form.Group>

      {isNewUser && <InformationMessage justifyContent="left" message={formatMessage({ id: 'A security key can only be assigned to a user after their account has been created.' })} />}
    </FormSection>
  );
};
