import React from 'react';
import { Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { StackProps } from '@mui/system';

interface OwnProps {
  message: string;
}

export type InformationMessageProps =
  & StackProps
  & OwnProps;

export const InformationMessage: React.FC<InformationMessageProps> = (props) => {
  const { message, ...stackProps } = props;

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap={1/2} {...stackProps}>
      <InfoIcon color="disabled"  />
      <Typography color="gray" variant="caption">{message}</Typography>
    </Stack>
  );
};