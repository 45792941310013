import React from 'react';
import { SemanticICONS, Icon } from 'semantic-ui-react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { AccountType } from '../../../modules/administration/users';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../modules/account/selectors';

interface OwnProps {
    title: string;
    icon?: SemanticICONS;
    rolesRequired?: AccountType[];
    external?: boolean;
}

export type NavigationMenuItemProps =
    & NavLinkProps
    & OwnProps;

export const NavigationMenuItem: React.FC<NavigationMenuItemProps> = (props): JSX.Element => {
  const { title, icon, rolesRequired, ...navLinkProps } = props;
  const currentUser = useSelector(getCurrentUser);

  if (currentUser != null && rolesRequired != null) {
    const hasRequiredRole = rolesRequired.find(role => currentUser.role === role) != null;
    if (!hasRequiredRole) {
      return <></>;
    }
  }

  return (
    <>
      {props.external ? (
        <a className="item" href={props.to.toString()}>
          <span>
            {icon && <Icon name={icon} />}
            {title}
          </span>
        </a>
      ): (
        <NavLink className="item" {...navLinkProps}>
          <span>
            {icon && <Icon name={icon} />}
            {title}
          </span>
        </NavLink>
      )}
    </>
  );
};
