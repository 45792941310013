import React from 'react';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isApplicationOffline } from '../selectors';

const m = defineMessages({
  title: { id: 'OfflineBanner.title', defaultMessage: 'The application is currently offline' },
  description: { id: 'OfflineBanner.description', defaultMessage: 'Some features may be disabled because the application is currently running in the offline mode.' }
});

export const OfflineBanner = () => {
  const { formatMessage } = useIntl();
  const isOffline = useSelector(isApplicationOffline);

  if (!isOffline) {
    return <></>;
  }

  return (
    <Message
      warning
      icon="exclamation triangle"
      header={formatMessage(m.title)}
      content={formatMessage(m.description)}
    />
  );
};
