import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Page } from 'react-pdf';

interface OwnProps {
    pageNumber: number;
    width?: number;
    scale?: number;
    rotate?: number;
    className?: string;

    onScrolledIntoView?: () => void;
}

export type PdfPageProps =
    & OwnProps;

export const PdfPage: React.FC<PdfPageProps> = (props) => {
  const [isRendered, setRendered] = useState(false);

  const handlePageRendered = () => setRendered(true);

  const handlePageScrolledIntoView = (inView: boolean, _entry: IntersectionObserverEntry) => {
    if (inView && isRendered && props.onScrolledIntoView) {
      props.onScrolledIntoView();
    }
  };

  return (
    <InView as="div" className={props.className} key={props.pageNumber} threshold={0.3} onChange={handlePageScrolledIntoView}>
      <Page
        pageNumber={props.pageNumber}
        width={props.width}
        scale={props.scale}
        rotate={props.rotate}
        onRenderSuccess={handlePageRendered}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </InView>
  );
};
