import { createStandardAction } from 'typesafe-actions';

import { SupportedLocales } from '../../configureI18n';
import { GlobalNotification, GlobalNotificationType, LayoutActionTypes } from './types';

export const setLocale = createStandardAction(LayoutActionTypes.SET_LOCALE)<SupportedLocales>();
export const setPageTitle = createStandardAction(LayoutActionTypes.SET_PAGE_TITLE)<{ title: string, subtitle?: string }>();

export const showGlobalNotification = createStandardAction(LayoutActionTypes.SHOW_GLOBAL_NOTIFICATION)<GlobalNotification>();
export const showSuccessNotification = (title: string, message: string) => showGlobalNotification({ type: GlobalNotificationType.success, title, message });
export const showErrorNotification = (title: string, message: string) => showGlobalNotification({ type: GlobalNotificationType.error, title, message });
export const showWarningNotification = (title: string, message: string) => showGlobalNotification({ type: GlobalNotificationType.warning, title, message });
export const showInformationNotification = (title: string, message: string) => showGlobalNotification({ type: GlobalNotificationType.information, title, message });

export const hideGlobalNotification = createStandardAction(LayoutActionTypes.HIDE_GLOBAL_NOTIFICATION)();

export const showNavigationMenu = createStandardAction(LayoutActionTypes.SHOW_NAVIGATION_MENU)();
export const hideNavigationMenu = createStandardAction(LayoutActionTypes.HIDE_NAVIGATION_MENU)();

export const showSettingsDrawer = createStandardAction(LayoutActionTypes.SHOW_SETTINGS_DRAWER)();
export const hideSettingsDrawer = createStandardAction(LayoutActionTypes.HIDE_SETTINGS_DRAWER)();

export const showHistoryDrawer = createStandardAction(LayoutActionTypes.SHOW_HISTORY_DRAWER)();
export const hideHistoryDrawer = createStandardAction(LayoutActionTypes.HIDE_HISTORY_DRAWER)();

export const showSearchModal = createStandardAction(LayoutActionTypes.SHOW_SEARCH_MODAL)();
export const hideSearchModal = createStandardAction(LayoutActionTypes.HIDE_SEARCH_MODAL)();
