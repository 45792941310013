import React, { useState } from 'react';
import { Accordion, Header, Icon } from 'semantic-ui-react';

interface OwnProps {
    title: string;
    subtitle?: string;
    isOpenByDefault?: boolean;
}

export type RoadmapFormSectionProps =
    & OwnProps;

export const RoadmapFormSection: React.FC<RoadmapFormSectionProps> = (props) => {
  const [isActive, setIsActive] = useState(props.isOpenByDefault || false);
  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <Accordion.Title active={isActive} onClick={toggleAccordion}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Icon name="dropdown" />
          <Header size="small" style={{ marginTop: 0 }}>
            <Header.Content>
              {props.title}
              {props.subtitle && <Header.Subheader>{props.subtitle}</Header.Subheader>}
            </Header.Content>
          </Header>
        </div>
      </Accordion.Title>
      <Accordion.Content active={isActive} style={{ paddingLeft: '20px', paddingTop: '10px' }}>
        {props.children}
      </Accordion.Content>
    </>
  );
};
