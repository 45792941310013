import { Operation } from '../..';
import { ApplicationState } from '../../../store';

export const getAllOperations = (state: ApplicationState): Operation[] => {
  return state.production.operations.allIds
    .map(x => state.production.operations.byId[x])
    .filter(x => x != null);
};

export const areOperationsLoading = (state: ApplicationState): boolean => state.production.operations.isLoading;
export const getLoadingOperationIds = (state: ApplicationState): number[] => state.production.operations.loadingIds;
