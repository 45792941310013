import _ from 'lodash';
import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Accordion, Icon, Message, SemanticICONS } from 'semantic-ui-react';

import { Toast, ToastType } from '../../types';
import styles from './ToastNotifications.module.css';

interface OwnProps {
    toasts: Toast[];
    onDismiss: (toast: Toast) => void;
    onViewDetails: (toast: Toast) => void;
    onHideDetails: (toast: Toast) => void;
}

interface StateProps {
    expandedToastIds: number[];
}

export type ToastProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
  showDetails: { id: 'ToastNotifications.showDetails', defaultMessage: 'More details' }
});

class ToastNotifications extends React.Component<ToastProps, StateProps> {
  public constructor (props: ToastProps) {
    super(props);

    this.state = { expandedToastIds: [] };
  }

  public render () {
    return (
      <div>
        {this.props.toasts.map(this.renderToast)}
      </div>
    );
  }

  private renderToast = (toast: Toast) => {
    const { formatMessage } = this.props.intl;
    const hasDetails = toast.details != null;
    const isSimpleIcon = typeof toast.icon === 'string';
    const isExpanded = this.state.expandedToastIds.find(x => x === toast.id) != null;

    return (
      <Message key={`${toast.id}${toast.title}`} className={this.getClassName(toast.type)} icon size="small" onDismiss={() => this.props.onDismiss(toast)}>
        {isSimpleIcon && <Icon name={toast.icon as SemanticICONS} />}
        {!isSimpleIcon && <div className={styles.complexIconContainer}>{toast.icon}</div>}
        <Message.Content>
          <Message.Header>{toast.title}</Message.Header>
          {hasDetails &&
                        <Accordion>
                          <Accordion.Title active={isExpanded} onClick={() => this.toggleDetails(toast)}>
                            <Icon name="dropdown" />
                            {formatMessage(m.showDetails)}
                          </Accordion.Title>
                          <Accordion.Content active={isExpanded}>
                            {toast.details}
                          </Accordion.Content>
                        </Accordion>
          }
        </Message.Content>
      </Message>
    );
  }

  private toggleDetails = (toast: Toast) => {
    const isExpanded = this.state.expandedToastIds.find(x => x === toast.id) != null;
    if (!isExpanded) {
      this.setState((current) => ({ expandedToastIds: _.union(current.expandedToastIds, [toast.id]) }));
      this.props.onViewDetails(toast);
    } else {
      this.setState((current) => ({ expandedToastIds: current.expandedToastIds.filter(x => x !== toast.id) }));
      this.props.onHideDetails(toast);
    }
  }

  private getClassName (type: ToastType | undefined) {
    const classNames: string[] = [styles.toastNotification];

    switch (type) {
    case ToastType.success: {
      classNames.push(styles.success);
      break;
    }

    case ToastType.information: {
      classNames.push(styles.information);
      break;
    }

    case ToastType.warning: {
      classNames.push(styles.warning);
      break;
    }

    case ToastType.error: {
      classNames.push(styles.error);
      break;
    }
    }

    return classNames.join(' ');
  }
}

const intlComponent = injectIntl(ToastNotifications);
export { intlComponent as ToastNotifications };
