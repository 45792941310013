import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../../store';
import { User } from '../../../../models';
import { getLocalUsers } from '../selectors';
import { LocalCollaborativeUser } from './LocalCollaborativeUser';
import * as CollaborationActions from '../actions';

interface DispatchProps {
    collaborationActions: typeof CollaborationActions;
}

interface StoreProps {
    localUsers: User[];
}

export type LocalCollaborativeUsersProps =
    & StoreProps
    & DispatchProps;

class LocalCollaborativeUsers extends React.Component<LocalCollaborativeUsersProps> {
  public render () {
    return this.props.localUsers.map(x => (
      <LocalCollaborativeUser
        key={x.id}
        user={x}
        onDisconnect={() => this.props.collaborationActions.removeLocalUser(x.id)}
      />
    ));
  }
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    localUsers: getLocalUsers(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    collaborationActions: bindActionCreators(CollaborationActions, dispatch)
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(LocalCollaborativeUsers);
export { connectedComponent as LocalCollaborativeUsers };
