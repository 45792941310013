import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ProductionItemForPrioritization } from 'modules/production/lots/types';
import React from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  productionItems: ProductionItemForPrioritization[];
  loading?: boolean;
  emptyTitle?: string;
  emptySubtitle?: string;
}

export type ProductionItemsTableProps =
  & OwnProps;

export const ProductionItemsTable: React.FC<ProductionItemsTableProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Table sx={{ marginTop: 2 }}>
      <TableHead>
        <TableRow>
          <TableCell>{formatMessage({ id: 'Product' })}</TableCell>
          <TableCell>{formatMessage({ id: 'Quantity' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.loading &&
          <TableRow key='loading'>
            <TableCell colSpan={2}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        }
        {props.productionItems.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.itemDescription}</TableCell>
            <TableCell>{row.quantity}</TableCell>
          </TableRow>
        ))}
        {!props.loading && props.productionItems.length <= 0 &&
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="body1" align="center">{props.emptyTitle ?? formatMessage({ id: 'No production items' })}</Typography>
              <Typography variant="body2" color="textSecondary" align="center">{props.emptySubtitle ?? formatMessage({ id: 'There are no production items assigned to this order.' })}</Typography>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  );
};