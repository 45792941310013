import React from 'react';
import { PrioritizableSalesRowSkeleton } from './PrioritizableSalesRowSkeleton';

export const LoadingSalesOrdersTableContent: React.FC = () => {
  return (
    <>
      <PrioritizableSalesRowSkeleton />
      <PrioritizableSalesRowSkeleton />
      <PrioritizableSalesRowSkeleton />
    </>
  );
};