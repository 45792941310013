import React from 'react';
import { List } from 'semantic-ui-react';
import { BulkImportJobError } from '../../../models';

interface OwnProps {
    item: BulkImportJobError;
}

export type JobWarningListItemProps =
    & OwnProps;

export const JobWarningListItem: React.FC<JobWarningListItemProps> = (props) => {
  return (
    <List.Item>
      <List.Icon name="warning sign" color="yellow" />
      <List.Content>
        <List.Header>{props.item.documentName}</List.Header>
        <List.Description>{props.item.errorMessage}</List.Description>
      </List.Content>
    </List.Item>
  );
};
