export const LOAD_DEVICES_PRESENCE = 'PRESENCES_LOAD_DEVICES_PRESENCE';
export const LOAD_DEVICES_PRESENCE_SUCCESS = 'PRESENCES_LOAD_DEVICES_PRESENCE_SUCCESS';
export const LOAD_DEVICES_PRESENCE_FAILURE = 'PRESENCES_LOAD_DEVICES_PRESENCE_FAILURE';

export const LOAD_DEVICES_PRESENCE_BY_COST_CENTER = 'PRESENCES_LOAD_DEVICES_PRESENCE_BY_COST_CENTER';
export const LOAD_DEVICES_PRESENCE_BY_COST_CENTER_SUCCESS = 'PRESENCES_LOAD_DEVICES_PRESENCE_BY_COST_CENTER_SUCCESS';
export const LOAD_DEVICES_PRESENCE_BY_COST_CENTER_FAILURE = 'PRESENCES_LOAD_DEVICES_PRESENCE_BY_COST_CENTER_FAILURE';

export const LOAD_USERS_PRESENCE = 'PRESENCES_LOAD_USERS_PRESENCE';
export const LOAD_USERS_PRESENCE_SUCCESS = 'PRESENCES_LOAD_USERS_PRESENCE_SUCCESS';
export const LOAD_USERS_PRESENCE_FAILURE = 'PRESENCES_LOAD_USERS_PRESENCE_FAILURE';

export interface LoadUsersPresence { type: typeof LOAD_USERS_PRESENCE; payload: Record<string, never>; }
export interface LoadUsersPresenceSuccess { type: typeof LOAD_USERS_PRESENCE_SUCCESS; payload: UserPresence[]; }
export interface LoadUsersPresenceFailure { type: typeof LOAD_USERS_PRESENCE_FAILURE; payload: { error: Error }; }

export type PresencesActionTypes =
    | LoadUsersPresence | LoadUsersPresenceSuccess | LoadUsersPresenceFailure;

export interface PresencesState {
    users: UsersPresenceState;
}

export interface UsersPresenceState {
    byId: { [userId: string]: UserPresence };
    byCostCenterId: { [costCenterId: number]: string[] };
    byWorkstationId: { [workstationId: number]: string[] };
    allIds: string[];
}

export enum UserPresenceStatus {
    unknown = 0,
    online = 1,
    offline = 2,
    idle = 3
}

export interface UserPresence {
    id: string;
    displayName: string;
    status: UserPresenceStatus;
    lastSeenOn?: Date | string;
    currentDeviceId?: number;
    currentCostCenterId?: number;
    currentWorkstationId?: number;
    currentOperationId?: number;
    currentLotId?: number;
    currentProductionItemId?: number;
    location: string;
}
