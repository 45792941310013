import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

export const SHOW_TOAST = 'NOTIFICATIONS_SHOW_TOAST';
export const HIDE_TOAST = 'NOTIFICATIONS_HIDE_TOAST';
export const PIN_TOAST = 'NOTIFICATIONS_PIN_TOAST';
export const UNPIN_TOAST = 'NOTIFICATIONS_UNPIN_TOAST';

export interface ShowToastAction { type: typeof SHOW_TOAST; payload: Toast; }
export interface HideToastAction { type: typeof HIDE_TOAST; payload: Toast; }
export interface PinToastAction { type: typeof PIN_TOAST; payload: Toast; }
export interface UnpinToastAction { type: typeof UNPIN_TOAST; payload: Toast; }

export type NotificationsActions =
    | ShowToastAction
    | HideToastAction
    | PinToastAction
    | UnpinToastAction;

export interface NotificationsState {
    toasts: ToastsState;
}

export interface ToastsState {
    byId: {
        [id: number]: Toast
    };

    allIds: number[];
}

export interface Toast {
    id: number;
    title: string;
    isPinned?: boolean;
    icon?: SemanticICONS | React.ReactNode;
    details?: React.ReactNode;
    type?: ToastType;
}

export enum ToastType {
    default,
    information,
    success,
    warning,
    error,
}
