import * as React from 'react';
import { Icon, Loader, Menu, SemanticICONS, Popup, MenuItemProps } from 'semantic-ui-react';

interface TopBarMenuItemOwnProps {
    icon: SemanticICONS;
    loading?: boolean;
    tooltip?: string;
}

export type TopBarMenuItemProps =
    & TopBarMenuItemOwnProps
    & MenuItemProps;

class TopBarMenuItem extends React.Component<TopBarMenuItemProps> {
  public render () {
    const { icon, loading, tooltip, ...menuItemProps } = this.props;
    const menuItem = (
      <Menu.Item disabled={this.props.loading} {...menuItemProps}>
        <React.Fragment>
          {this.props.loading && <Loader active={true} />}
          {!this.props.loading && <Icon name={this.props.icon} size="large" fitted />}
        </React.Fragment>
      </Menu.Item>
    );

    return this.props.tooltip
      ? <Popup trigger={menuItem} basic inverted content={this.props.tooltip} position="bottom center" on="hover" />
      : menuItem;
  }
}

export { TopBarMenuItem };
