import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal, ModalProps, Header, Divider, List, InputOnChangeData, Button } from 'semantic-ui-react';
import * as UsersApi from '../../../administration/users/api';
import { ClearableInput } from '../../../../components/common/ClearableInput';
import { defineMessages, useIntl } from 'react-intl';
import { commonMessages } from '../../../../constants';
import { User } from '../../../../models';
import { isApplicationOffline } from '../../../live-updates';
import { useSelector } from 'react-redux';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';
import { Stack, Typography } from '@mui/material';
import { UserPresenceCoin } from 'components/presences/UserPresenceCoin';

interface OwnProps {
  onCancel: () => void;
  onConfirm: (user: User) => void;
}

interface UsersGroup {
  letter: string;
  users: User[];
}

export type UserSelectionPromptProps =
  & OwnProps
  & ModalProps;

const m = defineMessages({
  title: { id: 'UserSelectionPrompt.title', defaultMessage: 'Who does this security key belongs to?' },
  subtitle: { id: 'UserSelectionPrompt.subtitle', defaultMessage: 'We need more information to authenticate you with this security key. Please specify the owner below.' },
  searchPlaceholder: { id: 'UserSelectionPrompt.searchPlaceholder', defaultMessage: 'Search' }
});

export const UserSelectionPrompt: React.FC<UserSelectionPromptProps> = (props) => {
  const { formatMessage } = useIntl();
  const { handleError } = useApiErrorHandler();
  const { onCancel, onConfirm, ...modalProps } = props;
  const [allUsers, setUsers] = useState<User[]>([]);
  const [displayedUsers, setDisplayedUsers] = useState<User[]>([]);
  const [selectedUser, selectUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const isOffline = useSelector(isApplicationOffline);

  useEffect(() => {
    if (!isOffline && props.open) {
      setLoading(true);

      UsersApi.getUsersWithAuthenticators()
        .then(users => {
          setUsers(users);
          setDisplayedUsers(users);
        })
        .catch(handleError)
        .finally(() => setLoading(false));
    }
  }, [handleError, formatMessage, isOffline, props.open]);

  const searchUsers = (searchTerm: string): User[] => {
    const normalizedSearchTerm = _.escapeRegExp(_.deburr(searchTerm)).toLocaleLowerCase().trim();
    const searchExpression = () => new RegExp(`(${normalizedSearchTerm})`, 'gi');
    const searchResults = allUsers.filter(x => searchExpression().test(x.displayName));

    return searchResults;
  };

  const clearSearch = () => {
    setSearchTerm('');
    setDisplayedUsers(allUsers);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const searchResults = searchUsers(data.value);
    setDisplayedUsers(searchResults);
    setSearchTerm(data.value || '');
  };

  const usersGrouppedByFirstLetter = _(displayedUsers)
    .groupBy(user => !_.isEmpty(user.displayName) ? user.displayName[0].toUpperCase() : '')
    .map((group, key): UsersGroup => ({ letter: key, users: group }))
    .orderBy(group => group.letter)
    .value();

  return (
    <Modal size="tiny" centered={false} {...modalProps}>
      <Modal.Header>
        <Header size="small" content={formatMessage(m.title)} subheader={formatMessage(m.subtitle)} />
        <ClearableInput
          size="small"
          fluid
          disabled={loading}
          onChange={handleSearchChange}
          onClear={clearSearch}
          placeholder={formatMessage(m.searchPlaceholder)}
          value={searchTerm}
        />
      </Modal.Header>
      <Modal.Content scrolling>
        {usersGrouppedByFirstLetter.map(group => (
          <div key={group.letter}>
            <Divider horizontal>{group.letter}</Divider>
            <List divided relaxed="very" selection>
              {_(group.users)
                .orderBy(x => x.displayName)
                .map(user => (
                  <List.Item key={user.id} active={selectedUser === user} onClick={() => selectUser(user)}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <UserPresenceCoin displayName={user.displayName} />
                      <Stack marginLeft={1}>
                        <Typography variant="body1" fontWeight={500}>{user.displayName}</Typography>
                      </Stack>
                    </div>
                  </List.Item>
                ))
                .value()
              }
            </List>
          </div>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button content={formatMessage(commonMessages.cancel)} onClick={props.onCancel} />
        <Button
          primary
          disabled={selectedUser == null}
          content={formatMessage(commonMessages.confirm)}
          onClick={() => selectedUser && props.onConfirm(selectedUser)}
        />
      </Modal.Actions>
    </Modal>
  );
};
