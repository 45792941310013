import * as React from 'react';
import { Icon } from 'semantic-ui-react';

import { SystemStatusIcon, SystemStatusIconType } from './SystemStatusIcon';

interface OwnProps {
    bordered?: boolean;
    onClick?: () => void;
}

export type SystemOfflineIconProps =
    & OwnProps;

class SystemOfflineIcon extends React.Component<SystemOfflineIconProps> {
  public render () {
    return (
      <SystemStatusIcon type={SystemStatusIconType.error} {...this.props}>
        <Icon.Group size="big">
          <Icon name="wifi" />
          <Icon name="x" corner />
        </Icon.Group>
      </SystemStatusIcon>
    );
  }
}

export { SystemOfflineIcon };
