import React from 'react';
import { useDispatch } from 'react-redux';
import * as LayoutActions from '../../../../store/layout/actions';
import { TopBarMenuItem } from '../TopBarMenuItem';
import styles from '../TopBarItemButton.module.css';

export const HistoryControl: React.FC = () => {
  const dispatch = useDispatch();
  const openHistoryDrawer = () => dispatch(LayoutActions.showHistoryDrawer());

  return (
    <TopBarMenuItem
      className={styles.Button}
      icon="history"
      onClick={openHistoryDrawer}
    />
  );
};