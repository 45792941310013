import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../../modules/account/selectors';
import { getCurrentDevice } from '../../../../modules/administration/devices/selectors';
import { getDeviceName } from '../../../../modules/administration/devices/types';

export const CurrentDeviceSummary: React.FC = () => {
  const currentUser = useSelector(getCurrentUser);
  const currentDevice = useSelector(getCurrentDevice);

  if (currentDevice == null) {
    return <></>;
  }

  return (
    <Stack marginLeft={1}>
      <Typography variant="body1" fontWeight={500}>{currentUser?.displayName}</Typography>
      <span><Icon name="tablet alternate" size="small" />{getDeviceName(currentDevice)}</span>
    </Stack>
  );
};
