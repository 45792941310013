import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Divider, DividerProps } from 'semantic-ui-react';

export type OrDividerProps =
    & DividerProps;

const m = defineMessages({
  or: { id: 'OrDivider.or', defaultMessage: 'Or' }
});

export const OrDivider = (props: OrDividerProps) => {
  const { formatMessage } = useIntl();
  const { ...dividerProps } = props;

  return (
    <Divider {...dividerProps}>
      <span style={{ textTransform: 'uppercase' }}>{formatMessage(m.or)}</span>
    </Divider>
  );
};
