import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Form, DropdownProps } from 'semantic-ui-react';
import { CostCentersDropdown, OperationsDropdown, ProductionUnitDropdown, allCellsValue } from '..';
import { WorkstationEdit } from '../../../models/WorkstationEdit';
import { ValidationResult } from '../../../../../../models';
import { nameof } from '../../../../../../utils';

interface OwnProps {
    workstation: WorkstationEdit;
    validationResult: ValidationResult;
    onUpdate: (fieldId: string, fieldValue: any) => void;
}

export type WorkstationFormProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
  costCenterLabel: { id: 'WorkstationForm.costCenterLabel', defaultMessage: 'Department' },
  costCenterPlaceholder: { id: 'WorkstationForm.costCenterPlaceholder', defaultMessage: 'Select a department' },
  operationsLabel: { id: 'WorkstationForm.operationsLabel', defaultMessage: 'Manufacturing operations' },
  operationsPlaceholder: { id: 'WorkstationForm.operationsPlaceholder', defaultMessage: 'Select the manufacturing operations' },
  productionUnitLabel: { id: 'WorkstationForm.productionUnitLabel', defaultMessage: 'Handles work orders from' },
  productionUnitPlaceholder: { id: 'WorkstationForm.productionUnitPlaceholder', defaultMessage: 'All cells' }
});

class WorkstationForm extends React.Component<WorkstationFormProps> {
  public render () {
    const { formatMessage } = this.props.intl;
    const { workstation, validationResult } = this.props;

    return (
      <Form error={!validationResult.isValid}>
        <Form.Field required error={validationResult.isFieldInvalid(nameof<WorkstationEdit>('costCenterId') as string)} style={{ marginBottom: '5px' }}>
          <label>{formatMessage(m.costCenterLabel)}</label>
          <CostCentersDropdown
            fluid
            selection
            placeholder={formatMessage(m.costCenterPlaceholder)}
            onChange={this.selectCostCenter}
            value={workstation.costCenterId}
          />
        </Form.Field>
        <Form.Field required error={validationResult.isFieldInvalid(nameof<WorkstationEdit>('operationIds') as string)}>
          <label>{formatMessage(m.operationsLabel)}</label>
          <OperationsDropdown
            costCenterId={workstation.costCenterId}
            useCode
            fluid
            selection
            multiple
            placeholder={formatMessage(m.operationsPlaceholder)}
            onChange={this.selectOperations}
            value={workstation.operationIds}
          />
        </Form.Field>
        <Form.Field>
          <label>{formatMessage(m.productionUnitLabel)}</label>
          <ProductionUnitDropdown
            fluid
            selection
            placeholder={formatMessage(m.productionUnitPlaceholder)}
            onChange={this.selectProductionUnit}
            value={workstation.productionUnitId || allCellsValue}
          />
        </Form.Field>
      </Form>
    );
  }

  private selectCostCenter = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const costCenterId = data.value !== '' ? data.value as number : undefined;
    this.props.onUpdate(nameof<WorkstationEdit>('costCenterId') as string, costCenterId);
  };

  private selectOperations = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const operations = data.value as number[];
    this.props.onUpdate(nameof<WorkstationEdit>('operationIds') as string, operations);
  };

  private selectProductionUnit = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const productionUnitId = data.value !== '' ? data.value as number : undefined;
    this.props.onUpdate(nameof<WorkstationEdit>('productionUnitId') as string, productionUnitId);
  };
}

const intlComponent = injectIntl(WorkstationForm);
export { intlComponent as WorkstationForm };
