import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as LayoutActions from '../../../store/layout/actions';
import * as PresencesActions from '../../../modules/administration/presences/actions';
import * as DevicesActions from '../../../modules/administration/devices/actions';
import { CostCenterOverviewCard } from './components/CostCenterOverviewCard/CostCenterOverviewCard';
import { getAllCostCenters } from '../../production';
import styles from './DashboardPage.module.css';

const m = defineMessages({
  title: { id: 'DashboardPage.title', defaultMessage: 'Dashboard' }
});

export const DashboardPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const costCenters = useSelector(getAllCostCenters);

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.title) }));
    dispatch(DevicesActions.loadDevices());
    dispatch(PresencesActions.loadUsersPresence());
  }, [dispatch, formatMessage]);

  return (
    <div className={styles.container}>
      {costCenters.map(costCenter => (
        <CostCenterOverviewCard
          key={costCenter.id}
          costCenter={costCenter}
          style={{ margin: '10px' }}
        />
      ))}
    </div>
  );
};
