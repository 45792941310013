import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Item } from '..';

import * as actions from './actions';
import * as api from './api';
import { SEARCH_ITEMS, SearchItemsAction } from './types';

function * handleSearchItems (action: SearchItemsAction) {
  try {
    const result: Item[] = yield call(api.searchForItems, action.payload.itemCode);
    yield put(actions.searchItemsSuccess(result));
  } catch (e) {
    yield put(actions.searchItemsFailure(e as Error));
  }
}

function * watchSearchItems () { yield takeLatest(SEARCH_ITEMS, handleSearchItems); }

function * searchSagas () {
  yield all([
    fork(watchSearchItems)
  ]);
}

export default searchSagas;
