import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const m = defineMessages({
  title: { id: 'SignOutButton.title', defaultMessage: 'Sign out' }
});

export const SignOutButton: React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={false}
      startIcon={<ExitToAppIcon />}
      {...props}
    >
      {formatMessage(m.title)}
    </Button>
  );
};
