import { Reducer } from 'redux';

import { CHANGE_LOT_FILTER, LotFilter, WorkstationActionTypes, WorkstationState, ProductionOrderFilter, CHANGE_PRODUCTION_ORDER_FILTER } from './types';

const initialWorkstationState: WorkstationState = {
  lotFilter: LotFilter.IncompleteLotsOnly,
  productionOrderFilter: ProductionOrderFilter.Ready
};

const workstationReducer: Reducer<WorkstationState, WorkstationActionTypes> = (state = initialWorkstationState, action): WorkstationState => {
  switch (action.type) {
  case CHANGE_LOT_FILTER: {
    return {
      ...state,
      lotFilter: action.payload
    };
  }

  case CHANGE_PRODUCTION_ORDER_FILTER: {
    return {
      ...state,
      productionOrderFilter: action.payload
    };
  }

  default:
    return state;
  }
};

export { workstationReducer };
