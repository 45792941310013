import * as React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Modal } from 'semantic-ui-react';
import { SpecialNotesWarning } from './SpecialNotesWarning';

interface OwnProps {
    notes: string;
}

export type SpecialNotesModalProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
  title: { id: 'SpecialNotesModal.title', defaultMessage: 'Special notes for the order' }
});

class SpecialNotesModal extends React.Component<SpecialNotesModalProps> {
  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <Modal closeIcon trigger={<SpecialNotesWarning style={{ cursor: 'pointer' }} />}>
        <Modal.Header>{formatMessage(m.title)}</Modal.Header>
        <Modal.Content>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {this.props.notes}
          </p>
        </Modal.Content>
      </Modal>
    );
  }
}

const intlComponent = injectIntl(SpecialNotesModal);
export { intlComponent as SpecialNotesModal };
