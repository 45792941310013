import React from 'react';
import { pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { PdfDocument } from '../PdfDocument';
import styles from './PdfViewer.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.min.js';

interface OwnProps {
    documentUrl: string;
    scale?: number;
    rotate?: number;

    onLoaded: (totalPages: number) => void;
    onPageChanged: (currentPage: number) => void;
}

export type PdfViewerProps =
    & OwnProps;

export const PdfViewer: React.FC<PdfViewerProps> = (props) => {
  return (
    <SizeMe>
      {({ size }) => (
        <div className={styles.container}>
          <PdfDocument
            documentUrl={props.documentUrl}
            width={size.width || undefined}
            scale={props.scale}
            rotate={props.rotate}
            onLoaded={props.onLoaded}
            onPageChanged={props.onPageChanged}
          />
        </div>
      )}
    </SizeMe>
  );
};
