import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';

export function configureTelemetry (plugin: ITelemetryPlugin, history: any) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '49c47ab1-15a2-4348-a681-347119207c53',
      extensions: [plugin],
      extensionConfig: {
        [plugin.identifier]: { history }
      },
      enableDebug: true
    }
  });

  appInsights.loadAppInsights();
}
