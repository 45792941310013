import { ProductionStatus as ProductionStatusGql } from 'gql/graphql';
import * as React from 'react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { Icon, IconProps } from 'semantic-ui-react';
import { ProductionStatus } from '../../lots/types';

interface LotStatusIconOwnProps {
    status: ProductionStatus | ProductionStatusGql;
}

export type LotStatusIconProps =
    & LotStatusIconOwnProps
    & IconProps
    & WrappedComponentProps;

const m = defineMessages({
  startedTooltip: { id: 'LotStatusIcon.startedTooltip', defaultMessage: 'Started' },
  stoppedTooltip: { id: 'LotStatusIcon.stoppedTooltip', defaultMessage: 'Not started' },
  completedTooltip: { id: 'LotStatusIcon.completedTooltip', defaultMessage: 'Completed' }
});

class LotStatusIcon extends React.Component<LotStatusIconProps> {
  public render () {
    return this.renderIcon();
  }

  private renderIcon () {
    const { formatMessage } = this.props.intl;
    const { ...iconProps } = this.props;

    switch (this.props.status) {
    case ProductionStatus.completed:
    case ProductionStatusGql.Completed:
      return <Icon title={formatMessage(m.completedTooltip)} name="check circle" color="green" {...iconProps} />;

    case ProductionStatus.started:
    case ProductionStatusGql.Started:
      return (
        <Icon.Group title={formatMessage(m.startedTooltip)} {...iconProps}>
          <Icon name="stopwatch" color="black" />
          <Icon name="play" corner="bottom right" color="green" />
        </Icon.Group>
      );

    case ProductionStatus.created:
    case ProductionStatusGql.Created:
      return (
        <Icon.Group title={formatMessage(m.stoppedTooltip)} {...iconProps}>
          <Icon name="stopwatch" color="black" />
          <Icon name="stop" corner="bottom right" color="red" />
        </Icon.Group>
      );

    default:
      return (
        <Icon name="question circle" {...iconProps} />
      );
    }
  }
}

const connectedComponent = injectIntl(LotStatusIcon);
export { connectedComponent as LotStatusIcon };

