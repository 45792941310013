import { AccountType } from './types';
import { MessageDescriptor } from 'react-intl';
import { userRoleMessages } from './messages';
import { commonMessages } from '../../../constants';
import { SemanticICONS } from 'semantic-ui-react';
import { User } from '../../../models';

export const roleMap = new Map<AccountType, MessageDescriptor>([
  [AccountType.Administrator, userRoleMessages.administrator],
  [AccountType.FactorySupervisor, userRoleMessages.factorySupervisor],
  [AccountType.FactoryWorker, userRoleMessages.factoryWorker]
]);

export const getRoleDescription = (user: User): MessageDescriptor => {
  return roleMap.get(user.role) || commonMessages.undefined;
};

interface IconDetails {
    name: SemanticICONS;
    tooltip: MessageDescriptor;
}

export const getUserIconDetails = (user: User): IconDetails => {
  const undefinedIcon: IconDetails = { name: 'question', tooltip: commonMessages.undefined };
  const userIconMap = new Map<AccountType, IconDetails>([
    [AccountType.FactoryWorker, { name: 'user', tooltip: userRoleMessages.factoryWorker } as IconDetails],
    [AccountType.FactorySupervisor, { name: 'eye', tooltip: userRoleMessages.factorySupervisor } as IconDetails],
    [AccountType.Administrator, { name: 'shield', tooltip: userRoleMessages.administrator } as IconDetails]
  ]);

  return userIconMap.get(user.role) || undefinedIcon;
};
