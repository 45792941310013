import React from 'react';
import { DevicesTableRowSkeleton } from './DevicesTableRowSkeleton';

export const LoadingDevicesTableContent: React.FC = () => {
  return (
    <>
      <DevicesTableRowSkeleton />
      <DevicesTableRowSkeleton />
      <DevicesTableRowSkeleton />
      <DevicesTableRowSkeleton />
      <DevicesTableRowSkeleton />
    </>
  );
};