import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { LanguageSwitcher } from './LanguageSwitcher';
import { SupportedLocales } from '../../../../configureI18n';
import * as LayoutActions from '../../../../store/layout/actions';
import * as AccountActions from '../../../../modules/account/actions';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
const m = defineMessages({
  title: { id: 'LanguageSettingsSection.title', defaultMessage: 'Language' }
});

export const LanguageSettingsSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const changeCurrentLocale = useCallback((locale: SupportedLocales) => {
    dayjs.locale(locale);
    dispatch(LayoutActions.setLocale(locale));
    dispatch(AccountActions.updateProfile({ preferredLocale: locale }));
  }, [dispatch]);

  return (
    <Box padding={2} gap={2}>
      <Typography variant='body2' fontWeight='700' color='GrayText' textTransform='uppercase'>
        {formatMessage(m.title)}
      </Typography>

      <LanguageSwitcher onChange={changeCurrentLocale} />
    </Box>
  );
};
