import * as React from 'react';
import { Segment, SegmentProps } from 'semantic-ui-react';

export type LoadingContainerProps = SegmentProps

class LoadingContainer extends React.Component<LoadingContainerProps> {
  public render () {
    return (
      <Segment basic={true} style={{ margin: 0, padding: 0, height: '100%' }}>
        {this.props.children}
      </Segment>
    );
  }
}

export { LoadingContainer };
