import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as LayoutActions from '../../../../store/layout/actions';
import { SalesOrdersTabs } from '../../prioritization/components/SalesOrdersTabs';
import { PlanningDataContainer } from '../components/PlanningDataContainer';
import { Container, Paper, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { PlanningCalendar } from '../components/PlanningCalendar';

const m = defineMessages({
  title: { id: 'OrdersSchedulePage.title', defaultMessage: 'Sales Orders Scheduling' }
});

export const OrdersSchedulePage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ productionDate?: string }>();
  const forProductionDate = dayjs(params.productionDate);

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.title) }));
  }, [dispatch, formatMessage]);

  const changeProductionDate = (date: Dayjs | null) => {
    if (date) {
      history.push(`/orders-scheduling/${dayjs(date).format('YYYY-MM-DD')}`);
    }
  };

  return (
    <>
      <SalesOrdersTabs />

      <Container>
        <Stack direction="row" sx={{ paddingTop: '20px' }}>
          <Paper sx={{ height: 'fit-content', position: 'sticky', top: '60px', marginTop: '44px' }}>
            <PlanningCalendar
              forProductionDate={forProductionDate}
              onChange={changeProductionDate}
            />
          </Paper>

          <PlanningDataContainer productionDate={forProductionDate.toDate()} />
        </Stack>
      </Container>
    </>
  );
};
