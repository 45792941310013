import React from 'react';
import _ from 'lodash';
import { Card, Header, CardProps, Statistic } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Workstation } from '../../../../workstations';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../../store';
import { getUsersPresenceByWorkstation } from '../../../../presences/selectors';
import { getLotById } from '../../../../../production';
import styles from './WorkstationOverview.module.css';
import { UsersPresenceList } from '../../../../../../components/presences/UsersPresenceList';

interface OwnProps {
    workstation: Workstation;
}

export type WorkstationOverviewProps =
    & CardProps
    & OwnProps;

const m = defineMessages({
  notApplicable: { id: 'WorkstationOverview.notApplicable', defaultMessage: '---' }
});

export const WorkstationOverview: React.FC<WorkstationOverviewProps> = (props) => {
  const { className, workstation, ...cardProps } = props;
  const { formatMessage } = useIntl();
  const usersPresences = useSelector((state: ApplicationState) => getUsersPresenceByWorkstation(state, workstation.id));
  const workstationPresence = _(usersPresences).sortBy(x => x.lastSeenOn).reverse().first();

  const currentLotId = workstationPresence?.currentLotId;
  const currentLot = useSelector((state: ApplicationState) => getLotById(state, currentLotId || 0));
  const currentProductionItemId = workstationPresence?.currentProductionItemId;
  const workstationDescription = currentLot?.name || currentProductionItemId || formatMessage(m.notApplicable);

  const isInactive = currentLot == null && currentProductionItemId == null;

  return (
    <Card
      className={`${styles.workstation} raised color-top ${className} ${isInactive ? styles.inactive : ''}`}
      color={isInactive ? 'grey' : 'green'}
      {...cardProps}
    >
      <Card.Content>
        <Card.Header textAlign="center">
          <Header
            content={workstation.name}
            subheader={workstation.description}
          />
        </Card.Header>
        <Card.Description textAlign="center">
          <Statistic size="mini">
            <Statistic.Value>
              {workstationDescription}
            </Statistic.Value>
          </Statistic>

          <UsersPresenceList
            presences={usersPresences}
            size={28}
            maximumCoins={4}
          />
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
