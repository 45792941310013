import { Button, Stack } from '@mui/material';
import React from 'react';
import { ErrorDetails } from '../components/ErrorDetails';
import { ReactComponent as NotFoundImage } from '../../../assets/images/not-found-error.svg';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ErrorLayout } from 'components/layout/ErrorLayout';

export const NotFoundErrorPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const navigateToPreviousPage = () => history.goBack();
  const navigateToHomePage = () => history.push('/');

  return (
    <ErrorLayout>
      <ErrorDetails
        image={<NotFoundImage />}
        title={formatMessage({ id: 'Resource not found' })}
        message={formatMessage({ id: 'The resource you are trying to access cannot be found in the system. It may have been deleted.' })}
        errorCode="404"
      />

      <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
        <Button variant="outlined" onClick={navigateToPreviousPage}>{formatMessage({ id: 'Previous page' })}</Button>
        <Button variant="contained" onClick={navigateToHomePage}>{formatMessage({ id: 'Home page' })}</Button>
      </Stack>
    </ErrorLayout>
  );
};