import { Stack, Typography, IconButton } from '@mui/material';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';

interface OwnProps {
  onClose: () => void;
}

export type HistoryDrawerHeaderProps = & OwnProps;

const m = defineMessages({
  title: { id: 'HistoryDrawerHeader.title', defaultMessage: 'Work History' }
});

export const WorkHistoryDrawerHeader: React.FC<HistoryDrawerHeaderProps> = (props) => {
  const { formatMessage } = useIntl();

  return(
    <Stack direction='row' justifyContent='space-between' alignItems='center' padding={1}>
      <Typography variant='h5' fontWeight='500'>{formatMessage(m.title)}</Typography>
      <IconButton onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};