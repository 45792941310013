import React from 'react';
import { Table, Icon, StrictTableRowProps } from 'semantic-ui-react';
import { Roadmap } from '../../../../../production/roadmaps/types';
import { defineMessages, useIntl } from 'react-intl';

interface OwnProps {
    roadmap: Roadmap;
    canDelete?: boolean;
    onRoadmapClick: (roadmap: Roadmap) => void;
}

export type RoadmapsTableRowProps =
    & StrictTableRowProps
    & OwnProps;

const m = defineMessages({
  numberOfFieldsLabel: { id: 'RoadmapsTableRow.numberOfFieldsLabel', defaultMessage: '{count, plural, =0 {No columns} one {{count} column} other {{count} columns}}' }
});

export const RoadmapsTableRow: React.FC<RoadmapsTableRowProps> = (props) => {
  const { formatMessage } = useIntl();
  const { roadmap, canDelete, onRoadmapClick, ...tableRowProps } = props;

  return (
    <Table.Row onClick={() => props.onRoadmapClick(props.roadmap)} {...tableRowProps}>
      <Table.Cell>{roadmap.name}</Table.Cell>
      <Table.Cell>{formatMessage(m.numberOfFieldsLabel, { count: roadmap.columns.length })}</Table.Cell>
      <Table.Cell collapsing textAlign="center">{roadmap.isDefault && <Icon size="large" color="green" name="check circle" /> }</Table.Cell>
    </Table.Row>
  );
};
