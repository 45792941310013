import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  add: { id: 'commonMessages.add', defaultMessage: 'Add' },
  apply: { id: 'commonMessages.apply', defaultMessage: 'Apply' },
  delete: { id: 'commonMessages.delete', defaultMessage: 'Delete' },
  cancel: { id: 'commonMessages.cancel', defaultMessage: 'Cancel' },
  confirm: { id: 'commonMessages.confirm', defaultMessage: 'Confirm' },
  clear: { id: 'commonMessages.clear', defaultMessage: 'Clear' },
  filter: { id: 'commonMessages.filter', defaultMessage: 'Filter' },
  modify: { id: 'commonMessages.modify', defaultMessage: 'Modify' },
  undefined: { id: 'commonMessages.undefined', defaultMessage: 'Undefined' },
  view: { id: 'commonMessages.view', defaultMessage: 'View' },
  dialogButtonYes: { id: 'commonMessages.dialogButtonYes', defaultMessage: 'Yes' },
  dialogButtonNo: { id: 'commonMessages.dialogButtonNo', defaultMessage: 'No' },
  dialogButtonCancel: { id: 'commonMessages.dialogButtonCancel', defaultMessage: 'Cancel' },
  yesDelete: { id: 'commonMessages.yesDelete', defaultMessage: 'Yes, delete' },
  noCancel: { id: 'commonMessages.noCancel', defaultMessage: 'No, cancel' }
});
