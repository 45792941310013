import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import { accountReducer, productionReducer } from '../modules';
import { AccountState } from '../modules/account';
import accountSagas from '../modules/account/sagas';
import { WorkstationsAdministrationState } from '../modules/administration';
import costCenterAdministrationSagas from '../modules/administration/cost-centers/sagas';
import { devicesReducer } from '../modules/administration/devices/reducer';
import devicesSagas from '../modules/administration/devices/sagas';
import { DevicesState } from '../modules/administration/devices/types';
import operationsSagas from '../modules/administration/operations/sagas';
import { usersPresenceReducer } from '../modules/administration/presences/reducer';
import presencesSagas from '../modules/administration/presences/sagas';
import { UsersPresenceState } from '../modules/administration/presences/types';
import administrativeRoadmapSagas from '../modules/administration/roadmaps/sagas';
import { WorkInstructionsState } from '../modules/administration/work-instructions';
import { workInstructionsReducer } from '../modules/administration/work-instructions/reducer';
import workInstructionsSagas from '../modules/administration/work-instructions/sagas';
import { workstationsReducer } from '../modules/administration/workstations';
import workstationSagas from '../modules/administration/workstations/sagas';
import { liveUpdatesReducer, LiveUpdatesState } from '../modules/live-updates';
import { liveUpdatesSagas } from '../modules/live-updates/sagas';
import { notificationsReducer } from '../modules/notifications/reducer';
import notificationsSagas from '../modules/notifications/sagas';
import { NotificationsState } from '../modules/notifications/types';
import { ProductionState } from '../modules/production';
import { collaborationReducer } from '../modules/production/collaboration/reducer';
import collaborationSagas from '../modules/production/collaboration/sagas';
import { CollaborationState } from '../modules/production/collaboration/types';
import lotPrioritizationSagas from '../modules/production/prioritization/sagas';
import roadmapSagas from '../modules/production/roadmaps/sagas';
import productionSagas from '../modules/production/sagas';
import lotExecutionSagas from '../modules/production/workstation/sagas';
import { productionScheduleReducer } from '../modules/schedule/reducer';
import productionScheduleSagas from '../modules/schedule/sagas';
import { ProductionScheduleState } from '../modules/schedule/types';
import { searchReducer, SearchState } from '../modules/search';
import searchSagas from '../modules/search/sagas';
import { systemReducer } from '../modules/system/reducer';
import systemSagas from '../modules/system/sagas';
import { SystemState } from '../modules/system/types';
import { LayoutState } from '../store/layout';
import { layoutReducer } from './layout';
import { layoutSagas } from './layout/sagas';

export interface ApplicationState {
    account: AccountState;
    collaboration: CollaborationState;
    devices: DevicesState;
    production: ProductionState;
    layout: LayoutState;
    liveUpdates: LiveUpdatesState;
    notifications: NotificationsState;
    presences: UsersPresenceState;
    productionSchedule: ProductionScheduleState;
    search: SearchState;
    system: SystemState;
    workInstructions: WorkInstructionsState;
    workstations: WorkstationsAdministrationState;
}

export const createRootReducer = (history: History) => combineReducers({
  // The router reducer must always be the top most reducer
  router: connectRouter(history),
  account: accountReducer,
  collaboration: collaborationReducer,
  devices: devicesReducer,
  production: productionReducer,
  layout: layoutReducer,
  liveUpdates: liveUpdatesReducer,
  notifications: notificationsReducer,
  presences: usersPresenceReducer,
  productionSchedule: productionScheduleReducer,
  search: searchReducer,
  system: systemReducer,
  workInstructions: workInstructionsReducer,
  workstations: workstationsReducer
});

export function * rootSaga () {
  yield all([
    fork(accountSagas),
    fork(administrativeRoadmapSagas),
    fork(collaborationSagas),
    fork(costCenterAdministrationSagas),
    fork(devicesSagas),
    fork(layoutSagas),
    fork(liveUpdatesSagas),
    fork(lotPrioritizationSagas),
    fork(notificationsSagas),
    fork(presencesSagas),
    fork(productionSagas),
    fork(productionScheduleSagas),
    fork(roadmapSagas),
    fork(workstationSagas),
    fork(lotExecutionSagas),
    fork(operationsSagas),
    fork(searchSagas),
    fork(systemSagas),
    fork(workInstructionsSagas)
  ]);
}
