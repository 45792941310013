import { defineMessages } from 'react-intl';

import { AccountType } from '../../modules/administration/users';
import { nameof, StringHelper, ValidationHelper } from '../../utils';
import { ValidationResult } from '../ValidationResult';

const m = defineMessages({
  emailRequired: { id: 'UserEdit.emailRequired', defaultMessage: 'The email address is required.' },
  emailInvalid: { id: 'UserEdit.emailInvalid', defaultMessage: 'The email provided is not a valid email address.' },
  passwordRequired: { id: 'UserEdit.passwordRequired', defaultMessage: 'The password is required.' },
  displayNameRequired: { id: 'UserEdit.displayNameRequired', defaultMessage: 'The user\'s full name is required.' }
});

export class UserEdit {
  public id: string | undefined
  public role: AccountType = AccountType.FactoryWorker
  public displayName: string | undefined
  public employeeNumber: string | undefined
  public username: string | undefined
  public password: string | undefined
  public isPasswordRequired = true

  public constructor (init?: Partial<UserEdit>) {
    Object.assign(this, init);
  }

  public validate (): ValidationResult {
    const result = new ValidationResult();

    if (!StringHelper.hasValue(this.displayName)) {
      result.isValid = false;
      result.errorFields.push(nameof<UserEdit>('displayName'));
      result.errorMessages.push(m.displayNameRequired);
    }

    if (!StringHelper.hasValue(this.username)) {
      result.isValid = false;
      result.errorFields.push(nameof<UserEdit>('username'));
      result.errorMessages.push(m.emailRequired);
    } else if (!ValidationHelper.isEmail(this.username)) {
      result.isValid = false;
      result.errorFields.push(nameof<UserEdit>('username'));
      result.errorMessages.push(m.emailInvalid);
    }

    if (this.isPasswordRequired && !StringHelper.hasValue(this.password)) {
      result.isValid = false;
      result.errorFields.push(nameof<UserEdit>('password'));
      result.errorMessages.push(m.passwordRequired);
    }

    return result;
  }
}
