import React from 'react';
import { ButtonProps, CircularProgress, Typography } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { StyledButton } from './WebAuthnLoginButton.styled';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  title: { id: 'WebAuthnLoginButton.title', defaultMessage: 'Sign in with a YubiKey' }
});

interface OwnProps {
  loading?: boolean;
}

export type WebAuthnLoginButtonProps =
  & ButtonProps
  & OwnProps;

export const WebAuthnLoginButton: React.FC<WebAuthnLoginButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const { loading, ...buttonProps } = props;

  return (
    <StyledButton {...buttonProps}>
      <KeyIcon className="logo" />
      <Typography>{formatMessage(m.title)}</Typography>

      {loading && <CircularProgress size={24} sx={{ position: 'absolute' }} />}
    </StyledButton>
  );
};