import moment from 'moment';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { defaultLocale, I18nMessages } from '../../configureI18n';
import { getCurrentLocale } from '../../store/layout';

interface OwnProps {
  messages: I18nMessages;
}

export type I18nProviderProps =
  & OwnProps;

export const I18nProvider: React.FC<I18nProviderProps> = (props) => {
  const currentLocale = useSelector(getCurrentLocale);
  const messagesForCurrentLocale = props.messages[currentLocale] ?? props.messages[defaultLocale];

  useEffect(() => {
    moment.locale(currentLocale);
    dayjs.locale(currentLocale);
  }, [currentLocale]);

  return (
    <IntlProvider locale={currentLocale} messages={messagesForCurrentLocale} defaultLocale={defaultLocale}>
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};