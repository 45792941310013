import { Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Device, getDeviceName } from 'modules/administration/devices/types';
import React from 'react';

export const DevicesTableRowSkeleton: React.FC<{ device?: Device }> = (props) => {
  return (
    <TableRow sx={{ height: '65px' }}>
      <TableCell>
        {props.device && <Typography sx={{ color: grey[400] }}>{getDeviceName(props.device)}</Typography>}
        {!props.device && <Skeleton sx={{ width: '100px' }} />}
      </TableCell>
      <TableCell><Skeleton sx={{ width: '300px' }} /></TableCell>
      <TableCell><Skeleton sx={{ width: '100px' }} /></TableCell>
    </TableRow>
  );
};