import { ValidationResult } from '../../../../models';
import { StringHelper, nameof } from '../../../../utils';
import { defineMessages } from 'react-intl';

const m = defineMessages({
  nameRequired: { id: 'WorkstationEdit.nameRequired', defaultMessage: 'Specify the name of the workstation' },
  costCenterRequired: { id: 'WorkstationEdit.costCenterRequired', defaultMessage: 'Specify the cost center where the workstation is located' },
  operationsRequired: { id: 'WorkstationEdit.operationsRequired', defaultMessage: 'Select the manufacturing operations that can be performed on the workstation' }
});

export class WorkstationEdit {
  public id: number | undefined
  public name: string | undefined
  public description: string | undefined
  public productionUnitId: number | undefined
  public costCenterId: number | undefined
  public operationIds: number[] = [];
    [key: string]: any;

    public constructor (init?: Partial<WorkstationEdit>) {
      Object.assign(this, init);
    }

    public validate (): ValidationResult {
      const result = new ValidationResult();

      if (!StringHelper.hasValue(this.name)) {
        result.isValid = false;
        result.errorFields.push(nameof<WorkstationEdit>('name') as string);
        result.errorMessages.push(m.nameRequired);
      }

      if (this.costCenterId == null) {
        result.isValid = false;
        result.errorFields.push(nameof<WorkstationEdit>('costCenterId') as string);
        result.errorMessages.push(m.costCenterRequired);
      }

      if (this.operationIds.length <= 0) {
        result.isValid = false;
        result.errorFields.push(nameof<WorkstationEdit>('operationIds') as string);
        result.errorMessages.push(m.operationsRequired);
      }

      return result;
    }
}
