import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Collapse, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LotManagementData, LotManagementItem, ProductionStatus } from '../../types';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import React from 'react';
import axios from 'axios';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { ProductionItemListItemText } from './ProductionItemListItemText';
import { LotManagementForm } from './OrdersTransferList';

export interface ItemGroupListItemProps {
  item: LotManagementItem;
}

export const ItemGroupListItem = (props: ItemGroupListItemProps) => {
  const { watch } = useFormContext<LotManagementForm>();
  const [isExpanded, setIsExpanded] = useState(false);
  const { onSettledErrorHandler } = useApiErrorHandler();
  const queryClient = useQueryClient();
  const selectedLotId = watch('lotId');

  const queryData = useQuery<LotManagementData>(['lotManagementData'], async () =>
    (await axios.get<LotManagementData>('/api/lots/LotManagementData')).data,
  { onSettled: onSettledErrorHandler });
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const addItemToLotMutation = useMutation(async (data: { lotId: number, productionItemId: number }) =>
    await axios.post(`/api/lots/${data.lotId}/items`, data)
  , {
    onSettled: onSettledErrorHandler,
    onSuccess: (data, variables) => {
      const queryData = queryClient.getQueryData<LotManagementData>(['lotManagementData']);
      if (queryData) {
        queryClient.setQueryData(['lotManagementData'], {
          ...queryData,
          productionItems: {
            ...queryData.productionItems,
            [variables.productionItemId]: { ...queryData.productionItems[variables.productionItemId], lotId: variables.lotId }
          }
        });
      }
    }
  });

  const getProductionItems = () =>
    queryData.data
      ? Object.values(queryData.data.productionItems).filter(p => p.lotId == null
      && p.itemId === props.item.id
      && p.productionStatus !== ProductionStatus.completed)
      : [];

  const handleAdd = (productionItemId: number) => {
    if (selectedLotId != undefined) {
      addItemToLotMutation.mutate({
        lotId: selectedLotId,
        productionItemId: productionItemId
      });
    }
  };

  return (
    <>
      <ListItemButton  onClick={handleClick}>
        <ListItemText primary={`${props.item.itemCode} - ${props.item.itemDescription} `} />
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Divider />
      <Collapse in={isExpanded} timeout="auto">
        <List component="div" disablePadding>
          {queryData.data && getProductionItems().map((value) => {
            const salesOrder = queryData.data.salesOrders[value.salesOrderId];
            return (
              <ListItem
                key={value.id}
                role="listitem"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <ProductionItemListItemText
                  productionItem={value}
                  salesOrder={salesOrder}
                />
                <Button
                  variant='contained'
                  sx={{ padding: '10px 13px 10px 13px', minWidth: '0px' }}
                  disabled={selectedLotId == null}
                  onClick={() => {handleAdd(value.id);}}>
                  <KeyboardDoubleArrowRightIcon />
                </Button>
              </ListItem>
            );})}
        </List>
      </Collapse>
    </>);
};