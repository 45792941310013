import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { ApplicationState } from '../../../../../store';
import { getOperations, Operation } from '../../../../production';

interface OperationsDropdownConnectedProps {
    operations: Operation[];
}

interface OperationsDropdownOwnProps {
    costCenterId: number | undefined;
    useCode?: boolean;
}

export type OperationsDropdownProps =
    & OperationsDropdownConnectedProps
    & OperationsDropdownOwnProps
    & DropdownProps
    & WrappedComponentProps;

const m = defineMessages({
});

class OperationsDropdown extends React.Component<OperationsDropdownProps> {
  public render () {
    const { intl, operations, costCenterId, ...dropdownProps } = this.props;
    const operationsOptions: DropdownItemProps[] = this.props.operations.map((x): DropdownItemProps => ({
      text: this.props.useCode ? x.code : x.name,
      description: this.props.useCode ? x.name : undefined,
      value: x.id
    }));

    return (
      <Dropdown
        options={operationsOptions}
        disabled={this.props.costCenterId == null || this.props.operations.length <= 0}
        {...dropdownProps}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OperationsDropdownOwnProps): OperationsDropdownConnectedProps => {
  return {
    operations: ownProps.costCenterId ? getOperations(state, ownProps.costCenterId) : []
  };
};

const mapDispatchToProps = () => {
  return {};
};

const intlComponent = injectIntl(OperationsDropdown);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as OperationsDropdown };
