import {
  LOAD_PRODUCTION_UNITS,
  LOAD_PRODUCTION_UNITS_FAILURE,
  LOAD_PRODUCTION_UNITS_SUCCESS,
  ProductionActionTypes,
  Unit
} from './types';

export function loadProductionUnits (): ProductionActionTypes {
  return {
    type: LOAD_PRODUCTION_UNITS
  };
}

export function loadProductionUnitsSuccess (units: Unit[]): ProductionActionTypes {
  return {
    type: LOAD_PRODUCTION_UNITS_SUCCESS,
    payload: units
  };
}

export function loadProductionUnitsFailure (error: Error): ProductionActionTypes {
  return {
    type: LOAD_PRODUCTION_UNITS_FAILURE,
    payload: { error }
  };
}
