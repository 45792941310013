import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { LOAD_USERS_PRESENCE, UserPresence } from './types';

function * handleLoadUsersPresence () {
  try {
    const presences: UserPresence[] = yield call(api.loadUsersPresence);
    yield put(actions.loadUsersPresenceSuccess(presences));
  } catch (e) {
    yield put(actions.loadUsersPresenceFailure(e as Error));
  }
}

function * watchLoadUsersPresence () { yield takeEvery(LOAD_USERS_PRESENCE, handleLoadUsersPresence); }

function * presencesSagas () {
  yield all([
    fork(watchLoadUsersPresence)
  ]);
}

export default presencesSagas;
