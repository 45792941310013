import { defineMessages } from 'react-intl';

import { nameof, StringHelper } from '../../utils';
import { ValidationResult } from '../ValidationResult';
import { ProductionSorting } from '../../modules';

const m = defineMessages({
  nameRequired: { id: 'CostCenterEdit.nameRequired', defaultMessage: 'The name is required.' }
});

export class CostCenterEdit {
  public id = 0
  public name: string | undefined
  public productionUnits: number[] = []
  public isDisabled: boolean | undefined
  public sortBy: ProductionSorting = ProductionSorting.byProductionSchedule

  public constructor (init?: Partial<CostCenterEdit>) {
    Object.assign(this, init);
  }

  public validate (): ValidationResult {
    const result = new ValidationResult();

    if (!StringHelper.hasValue(this.name)) {
      result.isValid = false;
      result.errorFields.push(nameof<CostCenterEdit>('name'));
      result.errorMessages.push(m.nameRequired);
    }

    return result;
  }
}
