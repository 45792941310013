import React from 'react';
import { List, ListProps, Ref, StrictListProps } from 'semantic-ui-react';
import { RoadmapField } from '../../../../../production/roadmaps/types';
import { DraggableRoadmapField } from './DraggableRoadmapField';
import { Droppable } from 'react-beautiful-dnd';

interface OwnProps {
    droppableId: string;
    fields: RoadmapField[];
}

export type RoadmapFieldsListProps =
    & OwnProps
    & StrictListProps;

export const RoadmapFieldsList: React.FC<RoadmapFieldsListProps> = (props) => {
  const { droppableId, fields, ...listProps } = props;

  return (
    <Droppable droppableId={props.droppableId}>
      {(provided) => (
        <Ref innerRef={provided.innerRef}>
          <List relaxed divided selection {...listProps} style={{ position: 'relative', marginBottom: '45px' }}>
            {props.fields.map((x, index) => <DraggableRoadmapField key={x.id} field={x} index={index} />)}
            {provided.placeholder}
          </List>
        </Ref>
      )}
    </Droppable>
  );
};
