import * as React from 'react';
import { Divider, Grid, Header } from 'semantic-ui-react';

interface PageContentHeaderOwnProps {
  leftHeader?: React.ReactNode;
  middleHeader?: React.ReactNode;
  rightHeader?: React.ReactNode;
}

export type PageContentHeaderProps =
  & PageContentHeaderOwnProps;

class PageContentHeader extends React.Component<PageContentHeaderProps> {
  public render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              {this.props.leftHeader &&
                <Header size="small">{this.props.leftHeader}</Header>
              }
            </Grid.Column>
            <Grid.Column textAlign="center">
              {this.props.middleHeader &&
                <Header size="small">{this.props.middleHeader}</Header>
              }
            </Grid.Column>
            <Grid.Column textAlign="right">
              {this.props.rightHeader &&
                <Header size="small">{this.props.rightHeader}</Header>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />
      </React.Fragment>
    );
  }
}

export { PageContentHeader };
