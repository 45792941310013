import React from 'react';
import _ from 'lodash';

import { UserPresence, UserPresenceStatus } from '../../../modules/administration/presences/types';
import { UserPresenceCoinGroup } from '../UserPresenceCoinGroup';
import styles from './UsersPresenceList.module.css';
import { UserPresenceCoin } from '../UserPresenceCoin';
import { PersonaPresence } from '../../../modules/account/types';

interface OwnProps {
    presences: UserPresence[];
    size?: number;
    style?: React.CSSProperties;
    maximumCoins?: number | undefined;
}

export type UsersPresenceListProps =
    & OwnProps;

export const UsersPresenceList: React.FC<UsersPresenceListProps> = (props) => {
  const getPersonaPresence = (userPresence: UserPresence): PersonaPresence =>
    userPresence.status === UserPresenceStatus.online || userPresence.status === UserPresenceStatus.offline
      ? PersonaPresence.Online
      : PersonaPresence.Offline;

  const maximumCoins = props.maximumCoins ? props.maximumCoins - 1 : undefined;
  const coins = maximumCoins ? _.take(props.presences, maximumCoins) : props.presences;
  const overflowingCoins = maximumCoins ? _.drop(props.presences, maximumCoins) : [];

  return (
    <div className={styles.container} style={props.style}>
      {overflowingCoins.length > 0 &&
                <UserPresenceCoinGroup presences={overflowingCoins} size={props.size} />
      }

      {coins.map(userPresence => (
        <UserPresenceCoin
          key={userPresence.id}
          displayName={userPresence.displayName}
          size={props.size}
          presence={getPersonaPresence(userPresence)}
          style={{ marginLeft: '5px' }}
        />
      ))}
    </div>
  );
};
