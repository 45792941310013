import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { WorkHistory } from './types';
import _, { Dictionary } from 'lodash';
dayjs.extend(utc);

export const removeDuplicateWorkHistoriesInARow = (workHistoryArray: WorkHistory[]): WorkHistory[] => {
  const result = [];
  for (let i = 0; i < workHistoryArray.length; i++) {
    const currentWorkHistory = workHistoryArray[i];
    const previousWorkHistory = workHistoryArray[i - 1];

    if (!areWorkHistoriesEqual(currentWorkHistory, previousWorkHistory)) {
      result.push(currentWorkHistory);
    }
  }
  return result;
};

const areWorkHistoriesEqual = (a?: WorkHistory, b?: WorkHistory) => {
  if (a == null || b == null) return false;

  return a.operation?.id === b.operation?.id
    && a.lot?.id === b.lot?.id
    && a.productionItem?.id === b.productionItem?.id;
};

export const groupWorkHistoryByDate = (workHistoryArray: WorkHistory[]): Dictionary<WorkHistory[]> => {
  return _.groupBy(workHistoryArray, workHistory => dayjs(workHistory.eventDate).format('MMMM D'));
};