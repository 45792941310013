import { Container, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCostCenterById } from '../../..';
import { ApplicationState } from '../../../../store';
import * as LayoutActions from '../../../../store/layout/actions';
import { loadUsersPresence } from '../../presences/actions';
import { getWorkstationsByCostCenter } from '../../workstations';
import { WorkstationOverview } from './components/WorkstationOverview';
import styles from './CostCenterOverviewPage.module.css';

interface RouteProps {
    costCenterId: string;
}

export const CostCenterOverviewPage: React.FC = () => {
  const dispatch = useDispatch();
  const routeProps = useParams<RouteProps>();

  const costCenterId = Number(routeProps.costCenterId);
  const costCenter = useSelector((state: ApplicationState) => getCostCenterById(state, costCenterId));
  const workstations = useSelector((state: ApplicationState) => getWorkstationsByCostCenter(state, costCenterId));

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: costCenter?.name || '' }));
  }, [dispatch, costCenter]);

  useEffect(() => {
    dispatch(loadUsersPresence());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Container>

        <Stack direction='row' gap={2}>
          {workstations.map(workstation => (
            <WorkstationOverview
              key={workstation.id}
              className={styles.tile}
              workstation={workstation}
            />
          ))}
        </Stack>
      </Container>
    </React.Fragment>
  );
};
