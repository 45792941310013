import 'moment/locale/fr';
import { defineMessages } from 'react-intl';

export const frenchLocale: SupportedLocales = 'fr';
export const englishLocale: SupportedLocales = 'en';
export type SupportedLocales = 'en' | 'fr';
export const allLocales: SupportedLocales[] = [frenchLocale, englishLocale];
export const defaultLocale: SupportedLocales = englishLocale;

// tslint:disable-next-line:interface-name
export interface I18nMessages {
    [locale: string]: {
        [id: string]: string
    };
}

export const localeNames = defineMessages({
  en: { id: 'localeNames.en', defaultMessage: 'English' },
  fr: { id: 'localeNames.fr', defaultMessage: 'Français' }
});

export const i18nMessages: I18nMessages = {
  en: require('./assets/messages/locales/en.json'),
  fr: require('./assets/messages/locales/fr.json')
};
