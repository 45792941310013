import { User } from '../../../models';

export const ADD_LOCAL_USER = 'COLLABORATION_ADD_LOCAL_USER';
export const ADD_LOCAL_USER_SUCCESS = 'COLLABORATION_ADD_LOCAL_USER_SUCCESS';
export const ADD_LOCAL_USER_FAILURE = 'COLLABORATION_ADD_LOCAL_USER_FAILURE';

export const REMOVE_LOCAL_USER = 'COLLABORATION_REMOVE_LOCAL_USER';

export interface AddLocalUserAction { type: typeof ADD_LOCAL_USER; payload: { user?: User }; }
export interface AddLocalUserSuccessAction { type: typeof ADD_LOCAL_USER_SUCCESS; payload: { user: User }; }
export interface AddLocalUserFailureAction { type: typeof ADD_LOCAL_USER_FAILURE; payload: { error: Error }; }
export interface RemoveLocalUserAction { type: typeof REMOVE_LOCAL_USER; payload: { userId: string }; }

export type CollaborationActionTypes =
    | AddLocalUserAction | AddLocalUserSuccessAction | AddLocalUserFailureAction
    | RemoveLocalUserAction;

export interface CollaborationState {
    localUsers: LocalCollaborativeUsersState;
}

export interface LocalCollaborativeUsersState {
    byId: { [userId: string]: User };
    allIds: string[];
    isLoading: boolean;
}

export enum CollaborativeUserPresence {
    online,
    offline
}
