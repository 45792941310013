import { Stack, StackProps, styled, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  title: string;
  image?: React.ReactNode;
  message?: string;
  errorCode?: string;
}

export type ErrorDetailsProps =
    & OwnProps;

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  alignItems: 'center',
  '& svg': {
    maxWidth: '100%',
    maxHeight: '250px',
    height: '100%',
    marginBottom: theme.spacing(5),
    flexGrow: 0,
  }
}));

export const ErrorDetails: React.FC<ErrorDetailsProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <StyledStack>
      {props.image}

      <Stack sx={{ flexGrow: 1 }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>{props.title}</Typography>
        <Typography variant="body1">
          {props.message}
        </Typography>

        {props.errorCode &&
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          {formatMessage({ id: 'ErrorDetails.errorCode', defaultMessage: 'Error code: {code}' }, { code: props.errorCode })}
        </Typography>
        }

        <Typography variant="body2">
          {formatMessage({ id: 'ErrorDetails.timestamp', defaultMessage: 'Timestamp: {value}' }, { value: new Date().toISOString() })}
        </Typography>
      </Stack>
    </StyledStack>
  );
};