import { MoreVert } from '@mui/icons-material';
import { ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

interface OwnProps {
  children?: React.ReactNode;
}

export type ActionMenuProps =
  & OwnProps;

export const ActionMenu: React.FC<ActionMenuProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenState = () => setIsOpen((previousValue) => !previousValue);

  const closeMenu = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpen(false);
    } else if (event.key === 'Escape') {
      setIsOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const previousOpenValue = useRef(isOpen);

  useEffect(() => {
    if (previousOpenValue.current === true && isOpen === false) {
      anchorRef.current!.focus();
    }

    previousOpenValue.current = isOpen;
  }, [isOpen]);

  return (
    <>
      <IconButton ref={anchorRef} onClick={toggleOpenState}>
        <MoreVert />
      </IconButton>

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ textAlign: 'left' }}>
              <ClickAwayListener onClickAway={closeMenu}>
                <MenuList
                  autoFocusItem={isOpen}
                  onKeyDown={handleListKeyDown}
                  onClick={closeMenu}
                >
                  {props.children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};