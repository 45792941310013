import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import { REGISTER_UI_PROGRESS_EVENT, RegisterUIProgressEventAction } from './types';

function* handleUpdateProgress(action: RegisterUIProgressEventAction) {
  try {
    yield call(api.updateProgress, action.payload);
    yield put(actions.registerUIProgressEventSuccess(action.payload));
  } catch (e) {
    yield put(actions.registerUIProgressEventFailure(e as Error));
  }
}

function* watchUpdateProgress() { yield takeEvery(REGISTER_UI_PROGRESS_EVENT, handleUpdateProgress); }

function* lotExecutionSagas() {
  yield all([
    fork(watchUpdateProgress)
  ]);
}

export default lotExecutionSagas;
