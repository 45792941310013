import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { RaisedTile, PageHeader } from '../../../../components';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getLotOverviewsForOperation } from '../selectors';
import { ProductionUnitSelectionList } from './components/ProductionUnitSelectionList';
import { getProductionUnits } from '../../../administration/cost-centers/selectors';
import { Unit } from '../..';
import { Grid } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';

import * as LayoutActions from '../../../../store/layout/actions';
import { getOperationById } from '../../selectors';

interface RouteProps {
    workstationId: string;
    operationId: string;
}

const m = defineMessages({
  pageTitle: { id: 'ProductionUnitSelectionPage.pageTitle', defaultMessage: 'Production units list' },
  title: { id: 'ProductionUnitSelectionPage.title', defaultMessage: 'Select a production unit' }
});

export const ProductionUnitSelectionPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { workstationId, operationId } = useParams<RouteProps>();
  const operation = useSelector((state: ApplicationState) => getOperationById(state, Number(operationId)));
  const lotOverviews = useSelector((state: ApplicationState) => getLotOverviewsForOperation(state, Number(operationId)));

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.pageTitle), subtitle: operation?.name }));
  }, [dispatch, formatMessage, operation]);

  const allProductionUnits = useSelector(getProductionUnits);
  const productionUnitsWithLots = _(lotOverviews)
    .map(overview => overview.lot.productionUnitId)
    .uniqBy(unitId => unitId)
    .map(unitId => allProductionUnits.find(unit => unit.id === unitId))
    .filter(unit => unit != null)
    .value() as Unit[];

  const navigateToLots = (productionUnit: Unit) => {
    history.push(`/workstations/${workstationId}/operations/${operationId}/production-units/${productionUnit.id}/lots`);
  };

  return (
    <RaisedTile>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column />
          <Grid.Column textAlign="center">
            <PageHeader title={formatMessage(m.title)} centered />
          </Grid.Column>
          <Grid.Column textAlign="right" />
        </Grid.Row>
      </Grid>

      <ProductionUnitSelectionList
        productionUnits={productionUnitsWithLots}
        onUnitClick={navigateToLots}
      />
    </RaisedTile>
  );
};
