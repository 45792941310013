import { WorkInstructionAssociation } from '../../administration/work-instructions';
import { SalesOrder } from '../../schedule/types';
import { Lot, ProductionStatus } from '../lots/types';
import { ManufacturedItem, ManufacturedItemOperation, ProductionItem, ProductionNote } from '../types';

export const REGISTER_UI_PROGRESS_EVENT = 'REGISTER_UI_PROGRESS_EVENT';
export const REGISTER_UI_PROGRESS_EVENT_SUCCESS = 'REGISTER_UI_PROGRESS_EVENT_SUCCESS';
export const REGISTER_UI_PROGRESS_EVENT_FAILURE = 'REGISTER_UI_PROGRESS_EVENT_FAILURE';
export const SALES_ORDER_UPDATED = 'SALES_ORDER_UPDATED';
export const LOT_UPDATED = 'LOT_UPDATED';
export const PRODUCTION_ITEM_UPDATED = 'PRODUCTION_ITEM_UPDATED';
export const MANUFACTURED_ITEM_UPDATED = 'MANUFACTURED_ITEM_UPDATED';
export const MANUFACTURED_ITEM_OPERATION_UPDATED = 'MANUFACTURED_ITEM_OPERATION_UPDATED';

export const CHANGE_LOT_FILTER = 'WORKSTATION_CHANGE_LOT_FILTER';
export const CHANGE_PRODUCTION_ORDER_FILTER = 'WORKSTATION_CHANGE_PRODUCTION_ORDER_FILTER';

export interface RegisterUIProgressEventAction { type: typeof REGISTER_UI_PROGRESS_EVENT; payload: RegisterUIProgressEventPayload; }
export interface RegisterUIProgressEventSuccessAction { type: typeof REGISTER_UI_PROGRESS_EVENT_SUCCESS; payload: RegisterUIProgressEventPayload; }
export interface RegisterUIProgressEventFailureAction { type: typeof REGISTER_UI_PROGRESS_EVENT_FAILURE; payload: { error: Error }; }

export interface SalesOrderUpdatedAction { type: typeof SALES_ORDER_UPDATED, payload: Pick<SalesOrder, 'id'> & Partial<SalesOrder> }
export interface LotUpdatedAction { type: typeof LOT_UPDATED, payload: Pick<Lot, 'id'> & Partial<Lot> }
export interface ProductionItemUpdatedAction { type: typeof PRODUCTION_ITEM_UPDATED, payload: Pick<ProductionItem, 'id'> & Partial<ProductionItem> }
export interface ManufacturedItemUpdatedAction { type: typeof MANUFACTURED_ITEM_UPDATED, payload: Pick<ManufacturedItem, 'id'> & Partial<ManufacturedItem> }
export interface ManufacturedItemOperationUpdatedAction { type: typeof MANUFACTURED_ITEM_OPERATION_UPDATED, payload: Pick<ManufacturedItemOperation, 'id'> & Partial<ManufacturedItemOperation> }
export interface ChangeLotFilterAction { type: typeof CHANGE_LOT_FILTER; payload: LotFilter; }
export interface ChangeProductionOrderFilterAction { type: typeof CHANGE_PRODUCTION_ORDER_FILTER; payload: ProductionOrderFilter; }

export type WorkstationActionTypes =
    | RegisterUIProgressEventAction | RegisterUIProgressEventSuccessAction | RegisterUIProgressEventFailureAction
    | ChangeLotFilterAction
    | ChangeProductionOrderFilterAction
    | ManufacturedItemOperationUpdatedAction
    | ManufacturedItemUpdatedAction
    | ProductionItemUpdatedAction
    | LotUpdatedAction
    | SalesOrderUpdatedAction;

export interface LoadProductionItemsRequestParams {
    lotId: number;
    operationId: number;
}

export enum LotFilter {
    IncompleteLotsOnly,
    All
}

export enum ProductionOrderFilter {
    Ready,
    Upcoming,
    Completed
}

export interface WorkstationState {
    lotFilter: LotFilter;
    productionOrderFilter: ProductionOrderFilter;
}


export enum WorkstationProductionProgressUIEventType {
    LotRoadmapScreenOpened = 0,
    LotRoadmapScreenClosed = 1,
}

export interface RegisterUIProgressEventPayload {
    eventType: WorkstationProductionProgressUIEventType;
    deviceId: number | undefined;
    workstationId: number | undefined;
    lotId: number | undefined;
    productionItemId: number | undefined;
    manufacturedItemOperationIds: number[];
    operationId: number;
    userIds?: string[];
}

export interface LotOverviewByOperation {
    lot: Lot;
    productionStatus: ProductionStatus;
}

export interface ProductionOrderOverview {
    type: 'production-item' | 'lot';
    lot: Lot | undefined;
    salesOrder: SalesOrder | undefined;
    productionItem: ProductionItem | undefined;
    productionStatus: ProductionStatus;
    roadmapItemsProductionStatus: ProductionStatus;
    isReadyForProduction: boolean;
    orderIndex: number;
    operationId: number;
}

export interface Overview {
    lot: Lot | undefined;
    salesOrder: SalesOrder | undefined;
    productionItem: ProductionItem;
    productionStatus: ProductionStatus;
}


// aggregation of manufactured item operation by item code
export interface RoadmapItem {
    [key: string]: any;
    itemId: number;
    itemCode: string;
    description: string;
    quantity: number;
    quantityManufactured: number;
    material: string;
    dimension: string;
    operationSequence: string;
    notes: string;
    productionStatus: ProductionStatus;
    manufacturedItemOperations: number[];
    operationId: number;
    sequenceNumber: number;
    workInstructions: WorkInstructionAssociation[];
    salesOrderId: number | undefined;
    productionNotes: ProductionNote[];
    isReadyForProduction: boolean;
    itemCover: string;
    referenceNumber: string | undefined;
}

