/* global PublicKeyCredential, AuthenticatorAssertionResponse */
import React, { useState } from 'react';
import { ButtonProps, Button, Icon } from 'semantic-ui-react';
import * as accountApi from '../../../../account/api';
import { encodeArrayBufferToString } from '../../../../../utils/WebAuthHelper';
import { User } from '../../../../../models';
import { defineMessages, useIntl } from 'react-intl';

interface OwnProps {
    onIdentified: (user: User) => void;
}

export type IdentifyKeyOwnerButtonProps =
    & OwnProps
    & ButtonProps;

const m = defineMessages({
  searchByKeyButton: { id: 'UsersConfigurationPage.searchByKeyButton', defaultMessage: 'Search by key' }
});

export const IdentifyKeyOwnerButton: React.FC<IdentifyKeyOwnerButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const { onIdentified, ...buttonProps } = props;
  const [isIdentifying, setIdentifying] = useState(false);
  const identifyKeyOwner = async () => {
    try {
      setIdentifying(true);

      const options = await accountApi.makeAllAssertionOptions();

      let hasNotReadableExceptionOccurred = false;
      let assertion: PublicKeyCredential | null = null;

      do {
        hasNotReadableExceptionOccurred = false;
        try {
          assertion = await navigator.credentials!.get({ publicKey: options }) as PublicKeyCredential;
        } catch (e) {
          if (e instanceof DOMException && e.name === 'NotReadableError') {
            // this error can happen when the user moves the security key instead of holding it still against the NFC reader
            hasNotReadableExceptionOccurred = true;
          } else {
            throw e;
          }
        }
      } while (hasNotReadableExceptionOccurred);

      if (!assertion) {
        throw new Error('Expected credentials of type: PublicKeyCredential');
      }

      const userHandle = (assertion.response as AuthenticatorAssertionResponse).userHandle;
      const data = {
        id: assertion.id,
        rawId: encodeArrayBufferToString(assertion.rawId),
        type: assertion.type,
        response: {
          authenticatorData: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).authenticatorData),
          signature: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).signature),
          clientDataJson: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).clientDataJSON),
          userHandle: userHandle && userHandle.byteLength > 0 ? encodeArrayBufferToString(userHandle) : undefined
        }
      };

      const result = await accountApi.validateAssertion(data as any);
      onIdentified(result);
    } finally {
      setIdentifying(false);
    }
  };

  return (
    <Button
      disabled={isIdentifying}
      loading={isIdentifying}
      onClick={identifyKeyOwner}
      {...buttonProps}
    >
      <Icon name="key" />{formatMessage(m.searchByKeyButton)}
    </Button>
  );
};
