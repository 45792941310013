import React, { useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { RoadmapSortEntry, SortDirection } from '.';
import { RoadmapField } from '../../../../../production/roadmaps/types';
import { RoadmapFieldsDropdown } from './RoadmapFieldsDropdown';
import { SortDirectionDropdown } from './SortDirectionDropdown';

interface OwnProps {
    onAdd: (entry: RoadmapSortEntry) => void;
}

export type CreateRoadmapFieldsSortEntryProps =
    & OwnProps;

export const CreateRoadmapFieldsSortEntry: React.FC<CreateRoadmapFieldsSortEntryProps> = (props) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ascending);

  const createSortEntryFromField = (field: RoadmapField) => {
    props.onAdd({
      field,
      fieldId: field.id,
      orderIndex: 0,
      sortDirection
    });

    setSortDirection(SortDirection.ascending);
  };

  return (
    <Table.Row>
      <Table.Cell />

      <Table.Cell>
        <RoadmapFieldsDropdown
          deburr
          selection
          search
          value={false}
          selectOnBlur={false}
          onFieldSelected={createSortEntryFromField}
        />
      </Table.Cell>

      {/* rgba(0,0,0,.4) */}

      <Table.Cell>
        <SortDirectionDropdown value={sortDirection} onChange={(e, data) => setSortDirection(data.value as SortDirection)} />
      </Table.Cell>

      <Table.Cell collapsing />
    </Table.Row>
  );
};
