import { Operation } from '../../production';
import {
  LoadAllAction,
  LoadAllFailureAction,
  LoadAllSuccessAction,
  OPERATIONS_LOAD_ALL,
  OPERATIONS_LOAD_ALL_FAILURE,
  OPERATIONS_LOAD_ALL_SUCCESS,
  OPERATIONS_UPDATE,
  OPERATIONS_UPDATE_FAILURE,
  OPERATIONS_UPDATE_SUCCESS,
  UpdateAction,
  UpdateFailureAction,
  UpdateSuccessAction
} from './types';

export function loadAll (): LoadAllAction {
  return {
    type: OPERATIONS_LOAD_ALL,
    payload: {}
  };
}

export function loadAllSuccess (operations: Operation[]): LoadAllSuccessAction {
  return {
    type: OPERATIONS_LOAD_ALL_SUCCESS,
    payload: operations
  };
}

export function loadAllFailure (error: Error): LoadAllFailureAction {
  return {
    type: OPERATIONS_LOAD_ALL_FAILURE,
    payload: { error }
  };
}

export function update (operation: Operation): UpdateAction {
  return {
    type: OPERATIONS_UPDATE,
    payload: operation
  };
}

export function updateSuccess (operation: Operation): UpdateSuccessAction {
  return {
    type: OPERATIONS_UPDATE_SUCCESS,
    payload: operation
  };
}

export function updateFailure (operationId: number, error: Error): UpdateFailureAction {
  return {
    type: OPERATIONS_UPDATE_FAILURE,
    payload: { operationId, error }
  };
}
