import React from 'react';
import { RoadmapField } from '../../../../../production/roadmaps/types';

interface OwnProps {
    field: RoadmapField;
}

export type RoadmapFieldDescriptionProps =
    & OwnProps;

export const RoadmapFieldDescription: React.FC<RoadmapFieldDescriptionProps> = (props) => {
  return (
    <>
      <div style={{ margin: 0, fontWeight: 'bold', lineHeight: '16px' }}>{props.field.name}</div>
      <div style={{ margin: 0 }}>{props.field.propertyName}</div>
    </>
  );
};
