import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Label, List, Table } from 'semantic-ui-react';
import { ApplicationState } from '../../../../store';
import { CoverType, getLotById } from '../../../production';
import { Lot } from '../../../production/lots/types';
import { ProductionScheduleEntities, SalesOrderStatus } from '../../types';
import { SalesOrderStatusIcon } from './SalesOrderStatusIcon';
import { SpecialNotesModal } from './SpecialNotesModal';

interface ProductionScheduleEntryProps {
    productionSchedule: ProductionScheduleEntities;
}

export const ProductionScheduleEntry = (props: ProductionScheduleEntryProps) => {

  const [showDetails, setShowDetails] = React.useState(false);

  const lot = useSelector<ApplicationState, Lot | undefined>(state => getLotById(state, Object.values(props.productionSchedule.productionItems)[0].id));
  const productTypes = Object.values(props.productionSchedule.productionItems).map(x => {
    const product = props.productionSchedule.items[x.itemId];
    const productType = product ? props.productionSchedule.productTypes[product.productTypeId] : undefined;

    return {
      quantity: x.quantity,
      type: productType
    };
  });

  const salesOrder = Object.values(props.productionSchedule.salesOrders)[0];


  if (Object.values(props.productionSchedule.productionItems).length <= 0) {
    return (null);
  }

  const referenceNumber = Object.values(props.productionSchedule.productionItems)[0].referenceNumber;
  const totalQuantity = Object.values(props.productionSchedule.productionItems).reduce((total, current) => total + current.quantity, 0);

  // TODO:
  // The Production Schedule page was disabled when Steps was forked.
  // Production notes are now imported into every production item.
  // The production notes concept should be reevaluated once re-design for the production schedule screen is done.

  // const hasNotes = salesOrder != null && salesOrder.productionNotes != null && salesOrder.productionNotes !== '';
  const hasNotes = false;

  const renderStyle = () => {
    const coverTypeMap = new Map<CoverType, string>([
      [CoverType.fabric, 'T'],
      [CoverType.leather, 'C']
    ]);

    const style = Object.values(props.productionSchedule.productionItems)[0].style;
    const coverType = Object.values(props.productionSchedule.productionItems)[0].itemCoverType;
    return `${style}${coverTypeMap.get(coverType) || 'A'}`;
  };

  return (
    <React.Fragment>
      <Table.Row verticalAlign="top" onClick={() => setShowDetails(!showDetails)} warning={hasNotes}>
        <Table.Cell collapsing style={{ paddingRight: 0 }}>
          <Icon size="large" fitted name={showDetails ? 'caret down' : 'caret right'} style={{ width: '14px' }} />
        </Table.Cell>
        <Table.Cell collapsing style={{ paddingRight: 0 }}>
          <SalesOrderStatusIcon
            circular
            fitted
            inverted
            status={salesOrder ? salesOrder.status : SalesOrderStatus.ordered}
          />
        </Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <p style={{ margin: 0, fontWeight: 'bold' }}>{referenceNumber}</p>
              <p style={{ margin: 0 }}>{renderStyle()}</p>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell textAlign="right">{totalQuantity}</Table.Cell>
        <Table.Cell>
          <p style={{ margin: 0 }}>{lot ? lot.name : ''}</p>
          <Label circular color="grey" size="mini">F</Label>
          <Label circular color="grey" size="mini">T</Label>
          <Label circular color="grey" size="mini">C</Label>
        </Table.Cell>
      </Table.Row>
      {showDetails &&
                    <React.Fragment>
                      <Table.Row>
                        <Table.Cell colSpan={1} />
                        <Table.Cell colSpan={5}>
                          {hasNotes &&
                            <SpecialNotesModal notes={''} />
                          }

                          <List horizontal>
                            {productTypes.map((x, index) => <List.Item key={index} content={`${x.quantity} ${x.type ? x.type.drawingSchematics : 'INCONNU'}`} />)}
                          </List>
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
      }
    </React.Fragment>
  );
};