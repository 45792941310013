import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Icon, IconProps } from 'semantic-ui-react';

import { Item, ItemType } from '../../production';

interface ItemIconOwnProps {
    item: Item;
}

export type ItemIconProps =
    & ItemIconOwnProps
    & IconProps
    & WrappedComponentProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const m = defineMessages({
});

class ItemIcon extends React.Component<ItemIconProps> {
  private readonly partIcon: IconProps = { name: 'setting', color: 'grey' };
  private readonly templateIcon: IconProps = { name: 'cube', color: 'teal' };
  private readonly coverIcon: IconProps = { name: 'cut', color: 'violet' };
  private readonly subassemblyIcon: IconProps = { name: 'cubes', color: 'blue' };
  private readonly assemblyIcon: IconProps = { name: 'box', color: 'black' };
  private readonly unknownIcon: IconProps = { name: 'question', color: 'red' };

  private readonly fileTypeMap = new Map<ItemType, IconProps>([
    [ItemType.part, this.partIcon],
    [ItemType.template, this.templateIcon],
    [ItemType.cover, this.coverIcon],
    [ItemType.subassembly, this.subassemblyIcon],
    [ItemType.assembly, this.assemblyIcon]
  ]);

  public render () {
    const defaultIconProps = this.fileTypeMap.get(this.props.item.itemType) || this.unknownIcon;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { intl, file, ...ownIconProps } = this.props;

    return (
      <Icon {...defaultIconProps} {...ownIconProps} />
    );
  }
}

const connectedComponent = injectIntl(ItemIcon);
export { connectedComponent as ItemIcon };