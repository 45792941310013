import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isAuthenticated } from '../selectors';
import { OrDivider } from './components';
import { MicrosoftAuthentication } from './components/MicrosoftAuthentication';
import { WebAuthnAuthentication } from './components/WebAuthnAuthentication';
import { Box, Container } from '@mui/material';
import { ApplicationHeader } from './components/ApplicationHeader';

export const LoginPage: React.FC = () => {
  const isUserAuthenticated = useSelector(isAuthenticated);

  if (isUserAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <ApplicationHeader />
      <Box sx={{ width: '100%', maxWidth: '365px', marginTop: '4rem' }}>
        <MicrosoftAuthentication />
        <OrDivider horizontal section />
        <WebAuthnAuthentication />
      </Box>
    </Container>
  );
};
