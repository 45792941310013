import { Subscription } from './types';
import { get } from '../../../utils/api';
const endpoint = '/api/license';

export async function getSubscription (): Promise<Subscription> {
  return get<Subscription>(`${endpoint}/subscription`);
}

export async function getIsLicenseValid (): Promise<boolean> {
  return get<boolean>(`${endpoint}/isValid`);
}