import { Reducer } from 'redux';

import {
  COST_CENTERS_LOAD_ALL,
  COST_CENTERS_LOAD_ALL_FAILURE,
  COST_CENTERS_LOAD_ALL_SUCCESS,
  CostCentersActionTypes
} from '../administration/cost-centers';
import {
  OPERATIONS_LOAD_ALL,
  OPERATIONS_LOAD_ALL_FAILURE,
  OPERATIONS_LOAD_ALL_SUCCESS,
  OperationsActionTypes
} from '../administration/operations';
import {
  LOAD_PRIORITIES_FAILURE,
  LOAD_PRIORITIES_SUCCESS,
  PrioritizationActionTypes,
  ProductionActionTypes
} from '../production';
import {
  InitializationStatus,
  INITIALIZE,
  INITIALIZE_FAILURE,
  INITIALIZE_SUCCESS,
  SET_LOADING_STATE,
  SystemActionTypes,
  SystemState
} from './types';
import { RoadmapActionTypes, LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST, LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS, LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE } from '../production/roadmaps/types';

const initialState: SystemState = {
  isInitialized: false,
  isInitializing: false,
  costCentersStatus: InitializationStatus.uninitialized,
  operationsStatus: InitializationStatus.uninitialized,
  roadmapStatus: InitializationStatus.uninitialized,
  prioritiesStatus: InitializationStatus.uninitialized
};

const reducer: Reducer<SystemState, SystemActionTypes | CostCentersActionTypes | OperationsActionTypes | ProductionActionTypes | PrioritizationActionTypes | RoadmapActionTypes> = (state = initialState, action) => {
  switch (action.type) {
  case INITIALIZE: {
    return {
      ...state,
      isInitializing: true
    };
  }

  case INITIALIZE_FAILURE:
  case INITIALIZE_SUCCESS: {
    return {
      ...state,
      isInitialized: true,
      isInitializing: false
    };
  }

  case SET_LOADING_STATE: {
    return {
      ...state,
      isInitializing: action.payload
    };
  }

  case COST_CENTERS_LOAD_ALL: {
    return {
      ...state,
      costCentersStatus: InitializationStatus.loading
    };
  }

  case COST_CENTERS_LOAD_ALL_SUCCESS: {
    return {
      ...state,
      costCentersStatus: InitializationStatus.initialized
    };
  }

  case COST_CENTERS_LOAD_ALL_FAILURE: {
    return {
      ...state,
      costCentersStatus: InitializationStatus.failed
    };
  }

  case OPERATIONS_LOAD_ALL: {
    return {
      ...state,
      operationsStatus: InitializationStatus.loading
    };
  }

  case OPERATIONS_LOAD_ALL_SUCCESS: {
    return {
      ...state,
      operationsStatus: InitializationStatus.initialized
    };
  }

  case OPERATIONS_LOAD_ALL_FAILURE: {
    return {
      ...state,
      operationsStatus: InitializationStatus.failed
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST: {
    return {
      ...state,
      roadmapStatus: InitializationStatus.loading
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS: {
    return {
      ...state,
      roadmapStatus: InitializationStatus.initialized
    };
  }

  case LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE: {
    return {
      ...state,
      roadmapStatus: InitializationStatus.failed
    };
  }

  case LOAD_PRIORITIES_SUCCESS: {
    return {
      ...state,
      prioritiesStatus: InitializationStatus.initialized
    };
  }

  case LOAD_PRIORITIES_FAILURE: {
    return {
      ...state,
      prioritiesStatus: InitializationStatus.failed
    };
  }

  default:
    return state;
  }
};

export { reducer as systemReducer };
