import React from 'react';
import { NoContentPlaceholder } from '../../../../../../components';
import { useIntl, defineMessages } from 'react-intl';

interface OwnProps {
    operationName: string;
}

export type NoLotsPlaceholderProps =
    & OwnProps;

const m = defineMessages({
  noLotsTitle: { id: 'LotSelectionPage.noLotsTitle', defaultMessage: 'All lots completed!' },
  noLotsSubtitle: { id: 'LotSelectionPage.noLotsSubtitle', defaultMessage: 'There are no more lots to process for the operation "{name}".' }
});

export const NoLotsPlaceholder: React.FC<NoLotsPlaceholderProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <NoContentPlaceholder
      icon="check circle"
      title={formatMessage(m.noLotsTitle)}
      subtitle={formatMessage(m.noLotsSubtitle, { name: props.operationName })}
    />
  );
};
