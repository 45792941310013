import { CostCentersState } from '../administration/cost-centers';
import { OperationsState } from '../administration/operations';
import { WorkInstructionAssociation } from '../administration/work-instructions';
import { SalesOrder } from '../schedule/types';
import { Lot, LotsState, ProductionStatus } from './lots/types';
import { RoadmapField, RoadmapsState } from './roadmaps/types';
import { WorkstationState } from './workstation/types';

export const LOAD_PRODUCTION_UNITS = 'PRODUCTION_LOAD_PRODUCTION_UNITS';
export const LOAD_PRODUCTION_UNITS_SUCCESS = 'PRODUCTION_LOAD_PRODUCTION_UNITS_SUCCESS';
export const LOAD_PRODUCTION_UNITS_FAILURE = 'PRODUCTION_LOAD_PRODUCTION_UNITS_FAILURE';

export const LOAD_WORKSTATION_WORK_INSTRUCTIONS = 'LOAD_WORKSTATION_WORK_INSTRUCTIONS';
export const LOAD_WORKSTATION_WORK_INSTRUCTIONS_SUCCESS = 'LOAD_WORKSTATION_WORK_INSTRUCTIONS_SUCCESS';
export const LOAD_WORKSTATION_WORK_INSTRUCTIONS_FAILURE = 'LOAD_WORKSTATION_WORK_INSTRUCTIONS_FAILURE';

export interface LoadProductionUnitsAction { type: typeof LOAD_PRODUCTION_UNITS; }
export interface LoadProductionUnitsSuccessAction { type: typeof LOAD_PRODUCTION_UNITS_SUCCESS; payload: Unit[]; }
export interface LoadProductionUnitsFailureAction { type: typeof LOAD_PRODUCTION_UNITS_FAILURE; payload: { error: Error }; }

export type ProductionActionTypes =
    | LoadProductionUnitsAction | LoadProductionUnitsSuccessAction | LoadProductionUnitsFailureAction;

export interface ProductionState {
    readonly lots: LotsState;
    readonly items: ItemsState;
    readonly productionItems: ProductionItemsState;
    readonly productionNotes: ProductionNotesState;
    readonly manufacturedItems: ManufacturedItemsState;
    readonly manufacturedItemOperations: ManufacturedItemOperationsState;
    readonly operations: OperationsState;
    readonly productCollections: ProductCollectionsState;
    readonly productTypes: ProductTypesState;
    readonly costCenters: CostCentersState;
    readonly roadmaps: RoadmapsState;
    readonly salesOrders: SalesOrdersState;
    readonly units: UnitsState;
    readonly workInstructions: WorkInstructionsState;
    readonly workstation: WorkstationState;
}

export interface ProductCollectionsState {
    byId: { [id: number]: ProductCollection };
    allIds: number[];
}

export interface ProductTypesState {
    byId: { [id: number]: ProductType };
    allIds: number[];
}

export interface SalesOrdersState {
    byId: { [id: number]: SalesOrder };
    allIds: number[];
}

export interface UnitsState {
    readonly byId: {
        [unitId: number]: Unit;
    };

    readonly allIds: number[];
    readonly loadingIds: number[];
    readonly isLoading: boolean;
}

export enum ProductionSorting {
    unspecified = 0,
    byProductionSchedule = 1,
    manually = 2,
}

export interface CostCenter {
    id: number;
    name: string;
    productionUnits: number[];
    isDisabled: boolean;
    sortBy: ProductionSorting;
}

export enum OperatingMode {
    unspecified,
    perLot,
    perProductionOrder,
}

export enum GroupingMode {
    none,
    byProductionUnit,
}

export interface Operation {
    id: number;
    name: string;
    code: string;
    costCenterId: number;
    operatingMode: OperatingMode;
    groupingMode: GroupingMode;
    roadmapId: number | undefined;
    isDisabled: boolean;
}


export interface Unit {
    id: number;
    name: string;
}

// a finished product or component definition
export interface Item {
    id: number;
    itemType: ItemType;
    itemCode: string;
    description: string;
    material: string;
    dimension: string;
    notes: string;
    productCollectionId: number;
    productTypeId: number;
}

export enum ItemType {
    unknown = 0,
    part = 1,
    template = 2,
    cover = 3,
    assembly = 4,
    subassembly = 5
}

export interface ItemsState {
    readonly byId: {
        [id: number]: Item;
    };

    readonly allIds: number[];
}

export interface ProductCollection {
    id: number;
    code: string;
}

export interface ProductType {
    id: number;
    code: string;
    drawingSchematics: string;
}

export enum CoverType {
    unknown = 0,
    fabric = 1,
    leather = 2,
    other = 3
}

// a finished product to manufacture as part of an order
export interface ProductionItem {
    id: number;
    itemId: number;
    itemCoverId: number | undefined;
    itemCoverType: CoverType;
    lotId: number;
    referenceNumber: string;
    style: string;
    quantity: number;
    manufacturedItems: number[];
    startOn: Date | string;
    monetaryValue: number;
    productionUnitId: number | undefined;
    salesOrderId: number;
    status: ProductionStatus;
}

export interface ProductionItemsState {
    readonly byId: {
        [id: number]: ProductionItem;
    };
    byLotId: {
        [lotId: number]: number[]
    };
    byOrderId: {
        [orderId: number]: number[]
    };
    allIds: number[];
}

export interface ProductionNote {
    id: number;
    forProductionItemId: number;
    forItemId: number;
    forOperationId: number;
    note: string;
}

export interface ProductionNotesState {
    readonly byId: { [id: number]: ProductionNote };
    readonly byProductionItem: { [productionItemId: number]: number[] };
    readonly allIds: number[];
}

// an item or component to manufacture for a production item
export interface ManufacturedItem {
    id: number;
    itemId: number;
    productionItemId: number;
    quantity: number;
    manufacturedItemOperations: number[];
}

export interface ManufacturedItemsState {
    readonly byId: {
        [id: number]: ManufacturedItem;
    };

    byProductionItemId: {
        [productionItemId: number]: number[];
    };
}

export interface ManufacturedItemOperation {
    id: number;
    operationId: number;
    manufacturedItemId: number;
    productionStatus: ProductionStatus;
    sequenceNumber: number;
    quantityManufactured: number;
    isReadyForProduction: boolean;
}

export interface ManufacturedItemOperationsState {
    readonly byId: {
        [id: number]: ManufacturedItemOperation;
    };

    byManufacturedItemId: {
        [manufacturedItemId: number]: number[]
    };
}

export interface ProductionEntities {
    lots: { [id: number]: Lot; };
    productionItems: { [id: number]: ProductionItem };
    productionNotes: { [id: number]: ProductionNote };
    manufacturedItems: { [id: number]: ManufacturedItem };
    manufacturedItemOperations: { [id: number]: ManufacturedItemOperation };
    items: { [id: number]: ProductionItem };
    workInstructions: { [id: number]: WorkInstructionAssociation };
    salesOrders: { [id: number]: SalesOrder };
}

export interface RoadmapEntities {
    items: Item[];
    lots: Lot[];
    productionItems: ProductionItem[];
    productionNotes: ProductionNote[];
    manufacturedItems: ManufacturedItem[];
    manufacturedItemOperations: ManufacturedItemOperation[];
    roadmapFields: RoadmapField[];
    workInstructions: WorkInstructionAssociation[];
    salesOrders: SalesOrder[];
}

export interface WorkInstructionsState {
    byId: { [id: number]: WorkInstructionAssociation };
    allIds: number[];
    isLoading: boolean;
}
