import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';

function * handleLocationChange () {
  try {
    yield put(actions.hideNavigationMenu());

    // Reset the page titles each time we change the location. We do this in order to make sure that we don't
    // display the title from the previous page, which can happen if we forget to call the setPageTitle on the
    // new page we want to display.
    yield put(actions.setPageTitle({ title: '' }));
  } catch (e) {
    console.error(e);
  }
}

function * watchLocationChange () { yield takeLatest('@@router/LOCATION_CHANGE', handleLocationChange); }

function * layoutSagas () {
  yield all([
    fork(watchLocationChange)
  ]);
}

export { layoutSagas };
