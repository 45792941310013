import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  title: { id: 'PdfError.title', defaultMessage: 'Failed to load the PDF document' },
  subtitle: { id: 'PdfError.subtitle', defaultMessage: 'Please try again later or contact your administrator if the problem persists.' }
});

export const PdfError = () => {
  const { formatMessage } = useIntl();

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header icon inverted>
        <Icon name="x" circular inverted color="red" />
        {formatMessage(m.title)}
        <Header.Subheader>{formatMessage(m.subtitle)}</Header.Subheader>
      </Header>
    </div>
  );
};
