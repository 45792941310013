export const SET_LOADING_STATE = 'SYSTEM_SET_LOADING_STATE';

export const INITIALIZE = 'SYSTEM_INITIALIZE';
export const INITIALIZE_SUCCESS = 'SYSTEM_INITIALIZE_SUCCESS';
export const INITIALIZE_FAILURE = 'SYSTEM_INITIALIZE_FAILURE';

export const LOAD_DATA = 'SYSTEM_LOAD_DATA';
export const LOAD_DATA_SUCCESS = 'SYSTEM_LOAD_DATA_SUCCESS';
export const LOAD_DATA_FAILURE = 'SYSTEM_LOAD_DATA_FAILURE';

export const PING_PUBLISH = 'SYSTEM_PING_PUBLISH';

export interface SetLoadingStateAction { type: typeof SET_LOADING_STATE; payload: boolean; }

export interface InitializeAction { type: typeof INITIALIZE; }
export interface InitializeSuccessAction { type: typeof INITIALIZE_SUCCESS; payload: { workstationId: number | undefined }; }
export interface InitializeFailureAction { type: typeof INITIALIZE_FAILURE; }

export interface LoadDataAction { type: typeof LOAD_DATA; }
export interface LoadDataSuccessAction { type: typeof LOAD_DATA_SUCCESS; }
export interface LoadDataFailureAction { type: typeof LOAD_DATA_FAILURE; }

export interface PingAction { type: typeof PING_PUBLISH; payload: string; meta: string; }

export type SystemActionTypes =
    | SetLoadingStateAction
    | InitializeAction | InitializeSuccessAction | InitializeFailureAction
    | LoadDataAction | LoadDataSuccessAction | LoadDataFailureAction
    | PingAction;

export interface SystemState {
    readonly isInitialized: boolean;
    readonly isInitializing: boolean;

    readonly costCentersStatus: InitializationStatus;
    readonly operationsStatus: InitializationStatus;
    readonly roadmapStatus: InitializationStatus;
    readonly prioritiesStatus: InitializationStatus;
}

export enum InitializationStatus {
    uninitialized,
    loading,
    initialized,
    failed
}
