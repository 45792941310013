import { getCurrentDevice } from 'modules/administration/devices/selectors';
import { getDeviceName } from 'modules/administration/devices/types';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, Image } from 'semantic-ui-react';
import logo from '../../../../../assets/images/logo.svg';

const m = defineMessages({
  title: { id: 'LoginPage.title', defaultMessage: 'Connect to the manufacturing system' }
});

export const ApplicationHeader: React.FC = () => {
  const { formatMessage } = useIntl();
  const currentDevice = useSelector(getCurrentDevice);

  return (
    <>
      <Image src={logo} centered={true} size="medium" />
      <Header as="h2" color="teal" textAlign="center">{formatMessage(m.title)}</Header>

      {currentDevice != null &&
        <h2 style={{ marginTop: 0 }}>{getDeviceName(currentDevice)}</h2>
      }
    </>
  );
};