import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Device } from 'modules/administration/devices/types';
import { DevicesTableRow } from './DevicesTableRow';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { NoResultsTableRow } from '../../../../../../components/common/NoResultsTableRow';
import { LoadingDevicesTableContent } from './LoadingDevicesTableContent';

const m = defineMessages({
  nameHeader: { id: 'DevicesTable.nameHeader', defaultMessage: 'Name' },
  workstationsHeader: { id: 'DevicesTable.workstationsHeader', defaultMessage: 'Workstations assigned' },
  statusHeader: { id: 'DevicesTable.statusHeader', defaultMessage: 'Configuration status' },
  noResultsTitle: { id: 'DevicesTable.noResultsTitle', defaultMessage: 'No devices found' },
  noResultsSubtitle: { id: 'DevicesTable.noResultsSubtitle', defaultMessage: 'There are no devices that match your search criterias.' }
});

interface DeviceTableHeader {
  id: keyof Device;
  name: MessageDescriptor;
}

const tableHeaders: DeviceTableHeader[] = [
  { id: 'name', name: m.nameHeader },
  { id: 'workstationIds', name: m.workstationsHeader },
  { id: 'configurationStatus', name: m.statusHeader }
];

export const DevicesTable: React.FC<{
  devices: Device[],
  isLoading?: boolean,
  loadingIds?: number[],
  onClick: (device: Device) => void;
}> = (props) => {
  const { formatMessage } = useIntl();

  const [sortBy, setSortBy] = useState<(keyof Device)>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const sortColumn = (columnId: keyof Device) => {
    if (sortBy !== columnId) {
      setSortBy(columnId);
      setSortDirection('asc');
    } else {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  const displayedDevices = useMemo<Device[]>(() => {
    return sortDirection === 'asc'
      ? _.sortBy(props.devices, [sortBy])
      : _.sortBy(props.devices, [sortBy]).reverse();
  }, [props.devices, sortBy, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(header => (
              <TableCell key={header.id} sortDirection={header.id === sortBy ? sortDirection : false} sx={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={header.id === sortBy}
                  direction={header.id === sortBy ? sortDirection : undefined}
                  onClick={() => sortColumn(header.id)}
                >
                  {formatMessage(header.name)}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!props.isLoading && displayedDevices.map(device => (
            <DevicesTableRow
              key={device.id}
              isLoading={props.loadingIds?.find(x => device.id === x) != null}
              device={device}
              hover
              onClick={() => props.onClick(device)}
              sx={{ cursor: 'pointer' }}
            />
          ))}

          {!props.isLoading && displayedDevices.length === 0 &&
            <NoResultsTableRow
              title={formatMessage(m.noResultsTitle)}
              subtitle={formatMessage(m.noResultsSubtitle)}
              colSpan={3}
            />
          }

          {props.isLoading && <LoadingDevicesTableContent />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
