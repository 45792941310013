import { CostCenterEdit } from '../../../models/cost-center/CostCenterEdit';
import { CostCenter, Operation, ProductionItem } from '../../production';
import { Workstation } from '../workstations';
import { Lot } from '../../production/lots/types';

export const COST_CENTERS_LOAD_ALL = 'COST_CENTERS_LOAD_ALL';
export const COST_CENTERS_LOAD_ALL_SUCCESS = 'COST_CENTERS_LOAD_ALL_SUCCESS';
export const COST_CENTERS_LOAD_ALL_FAILURE = 'COST_CENTERS_LOAD_ALL_FAILURE';

export const COST_CENTERS_CREATE = 'COST_CENTERS_CREATE';
export const COST_CENTERS_CREATE_SUCCESS = 'COST_CENTERS_CREATE_SUCCESS';
export const COST_CENTERS_CREATE_FAILURE = 'COST_CENTERS_CREATE_FAILURE';

export const COST_CENTERS_UPDATE = 'COST_CENTERS_UPDATE';
export const COST_CENTERS_UPDATE_SUCCESS = 'COST_CENTERS_UPDATE_SUCCESS';
export const COST_CENTERS_UPDATE_FAILURE = 'COST_CENTERS_UPDATE_FAILURE';

export const COST_CENTERS_LOAD_OVERVIEW = 'COST_CENTERS_LOAD_OVERVIEW';
export const COST_CENTERS_LOAD_OVERVIEW_SUCCESS = 'COST_CENTERS_LOAD_OVERVIEW_SUCCESS';
export const COST_CENTERS_LOAD_OVERVIEW_FAILURE = 'COST_CENTERS_LOAD_OVERVIEW_FAILURE';

export interface LoadAllAction { type: typeof COST_CENTERS_LOAD_ALL; payload: Record<string, never>; }
export interface LoadAllSuccessAction { type: typeof COST_CENTERS_LOAD_ALL_SUCCESS; payload: CostCenter[]; }
export interface LoadAllFailureAction { type: typeof COST_CENTERS_LOAD_ALL_FAILURE; payload: { error: Error }; }

export interface CreateAction { type: typeof COST_CENTERS_CREATE; payload: CostCenterEdit; }
export interface CreateSuccessAction { type: typeof COST_CENTERS_CREATE_SUCCESS; payload: CostCenter; }
export interface CreateFailureAction { type: typeof COST_CENTERS_CREATE_FAILURE; payload: { error: Error }; }

export interface UpdateAction { type: typeof COST_CENTERS_UPDATE; payload: CostCenterEdit; }
export interface UpdateSuccessAction { type: typeof COST_CENTERS_UPDATE_SUCCESS; payload: CostCenter; }
export interface UpdateFailureAction { type: typeof COST_CENTERS_UPDATE_FAILURE; payload: { error: Error }; }

export interface LoadOverviewAction { type: typeof COST_CENTERS_LOAD_OVERVIEW; payload: { costCenterId: number }; }
export interface LoadOverviewSuccessAction { type: typeof COST_CENTERS_LOAD_OVERVIEW_SUCCESS; payload: { costCenterId: number, overviews: CostCenterOverview[] }; }
export interface LoadOverviewFailureAction { type: typeof COST_CENTERS_LOAD_OVERVIEW_FAILURE; payload: { costCenterId: number, error: Error }; }

export type CostCentersActionTypes =
    | LoadAllAction | LoadAllSuccessAction | LoadAllFailureAction
    | CreateAction | CreateSuccessAction | CreateFailureAction
    | UpdateAction | UpdateSuccessAction | UpdateFailureAction
    | LoadOverviewAction | LoadOverviewSuccessAction | LoadOverviewFailureAction;

export interface CostCentersState {
    readonly isLoading: boolean;
    readonly byId: { [id: number]: CostCenter };
    readonly allIds: number[];
    readonly overviews: CostCenterOverviewsState;
}

export interface CostCenterOverviewsState {
    readonly byId: { [costCenterId: number]: CostCenterOverview[] };
    readonly loadingIds: number[];
}

export interface CostCenterOverview {
    costCenterId: number;
    workstation: Workstation;
    operation: Operation;
    lastActivityOn?: Date | string;
    currentLot: Lot | undefined;
    currentProductionItem: ProductionItem | undefined;
    users: CostCenterPresence[];
}

export interface CostCenterPresence {
    costCenterId: number;
    workstationId: number;
    operationId: number;
    lastActivityOn?: Date | string;
    userId: string;
    displayName: string;
}