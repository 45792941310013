import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonProps, Confirm } from 'semantic-ui-react';
import { commonMessages } from '../../constants/messages';

interface OwnProps {
    confirmationTitle: string;
    confirmationMessage: string;
    onClick: () => void;
}

export type SmallConfirmDeleteButtonProps =
    & OwnProps
    & ButtonProps
    & WrappedComponentProps;

interface SmallConfirmDeleteButtonState {
    isDeleteConfirmationShown: boolean;
}

class SmallConfirmDeleteButton extends React.Component<SmallConfirmDeleteButtonProps, SmallConfirmDeleteButtonState> {
  public constructor (props: SmallConfirmDeleteButtonProps) {
    super(props);

    this.state = {
      isDeleteConfirmationShown: false
    };
  }

  public render () {
    const { formatMessage } = this.props.intl;
    const { onClick, confirmationTitle, confirmationMessage, ...buttonProps } = this.props;

    return (
      <Confirm
        trigger={<Button negative={true} icon="trash" onClick={(e) => { e.stopPropagation(); this.setState({ isDeleteConfirmationShown: true }); }} {...buttonProps} />}
        open={this.state.isDeleteConfirmationShown}
        header={confirmationTitle}
        content={confirmationMessage}
        confirmButton={<Button negative={true} content={formatMessage(commonMessages.delete)} />}
        cancelButton={formatMessage(commonMessages.cancel)}
        onCancel={this.closeConfirmationModal}
        onConfirm={(e) => this.confirmDelete(e, onClick)}
      />
    );
  }

  private confirmDelete = (e: React.MouseEvent<HTMLAnchorElement>, onClick: () => void) => {
    onClick();
    this.closeConfirmationModal(e);
  };

  private closeConfirmationModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    this.setState({
      isDeleteConfirmationShown: false
    });
  };
}

const intlComponent = injectIntl(SmallConfirmDeleteButton);
export { intlComponent as SmallConfirmDeleteButton };
