import { isAuthenticatingWithSecurityKey, isWebAuthUserRequested } from 'modules/account/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebAuthnLoginButton } from '../WebAuthnLoginButton';
import * as AccountActions from '../../../actions';
import { UserSelectionPrompt } from '../UserSelectionPrompt';
import { User } from 'models';

export const WebAuthnAuthentication: React.FC = () => {
  const dispatch = useDispatch();

  const authenticate = () => dispatch(AccountActions.loginWebAuth());
  const cancelUserSelection = () => dispatch(AccountActions.webAuthUserRejected());
  const selectUser = (user: User) => dispatch(AccountActions.webAuthUserProvided(user));

  const isAuthenticating = useSelector(isAuthenticatingWithSecurityKey);
  const isUserSelectionRequired = useSelector(isWebAuthUserRequested);

  return (
    <>
      <WebAuthnLoginButton
        variant="contained"
        loading={isAuthenticating}
        disabled={isAuthenticating}
        sx={{ width: '100%' }}
        onClick={authenticate}
      />

      <UserSelectionPrompt
        open={isUserSelectionRequired}
        onCancel={cancelUserSelection}
        onClose={cancelUserSelection}
        onConfirm={selectUser}
      />
    </>
  );
};