import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Form } from 'semantic-ui-react';
import { CreateEntityModal } from '../../../../components/common/CreateEntityModal';
import { User } from '../../../../models';
import useApiErrorHandler, { successToastOptions } from '../../../../utils/ApiErrorHandler';
import * as api from '../api';
import { UserFormControls } from './components/UserFormControls';

const m = defineMessages({
  title: { id: 'CreateUserPage.title', defaultMessage: 'Create a user account' },
  creationSuccessMessage: { id: 'CreateUserPage.creationSuccessMessage', defaultMessage: 'The user {name} has been added to the system.' }
});

export const CreateUserPage = () => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const formProps = useForm<User>({ criteriaMode: 'all', mode: 'onChange' });

  const history = useHistory();
  const { handleError } = useApiErrorHandler();

  const [isCreating, setIsCreating] = useState(false);

  const navigateToUsersManagementPage = () => history.push('/administration/users');

  const submitChanges = (user: User) => {
    setIsCreating(true);

    api.create(user)
      .then(result => {
        toast.success(formatMessage(m.creationSuccessMessage, { name: result.displayName }), successToastOptions);
        queryClient.invalidateQueries(['users']);
        navigateToUsersManagementPage();
      })
      .catch(handleError)
      .finally(() => setIsCreating(false));
  };

  return (
    <CreateEntityModal
      open
      title={formatMessage(m.title)}
      loading={isCreating}
      onClose={navigateToUsersManagementPage}
      onSubmit={formProps.handleSubmit(submitChanges)}
    >
      <FormProvider {...formProps}>
        <Form loading={isCreating}>
          <UserFormControls />
        </Form>
      </FormProvider>
    </CreateEntityModal>
  );
};
