import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import { commonMessages } from 'constants/messages';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { MuiDeleteConfirmation } from './MuiDeleteConfirmation';

interface OwnProps {
  title: string;
  loading?: boolean;
  deleting?: boolean;
  deleteConfirmationMessage?: string;
  onCancel: () => void;
  onSubmit: () => void;
  onDelete?: () => void;
}

export type MuiUpdateEntityModalProps =
  & DialogProps
  & OwnProps;

export const MuiUpdateEntityModal: React.FC<MuiUpdateEntityModalProps> = (props) => {
  const { formatMessage } = useIntl();
  const { title, loading, deleting, deleteConfirmationMessage, onCancel, onSubmit, onDelete, ...dialogProps } = props;

  const [isDeleteConfirmationRequested, setIsDeleteConfirmationRequested] = useState(false);
  const showDeleteConfirmation = () => setIsDeleteConfirmationRequested(true);
  const hideDeleteConfirmation = () => setIsDeleteConfirmationRequested(false);

  const confirmDeletion = () => {
    if (onDelete != null) {
      onDelete();
    }

    hideDeleteConfirmation();
  };

  return (
    <Dialog {...dialogProps} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {props.children}
      </DialogContent>
      <Divider  />
      <DialogActions sx={{ padding: '16px 24px', display: 'flex', flexDirection: 'row' }}>
        {!isDeleteConfirmationRequested &&
            <>
              <Stack direction="row" spacing={1.5}>
                {onDelete &&
                  <Button variant="contained" color="error" onClick={showDeleteConfirmation}>
                    {formatMessage(commonMessages.delete)}
                  </Button>
                }
              </Stack>

              <Stack direction="row" spacing={1.5} sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                <Button variant="contained" color="secondary" onClick={onCancel}>{formatMessage(commonMessages.cancel)}</Button>
                <Button variant="contained" onClick={onSubmit}>{formatMessage(commonMessages.modify)}</Button>
              </Stack>
            </>
        }
        {isDeleteConfirmationRequested &&
            <MuiDeleteConfirmation
              confirmationMessage={deleteConfirmationMessage}
              onConfirm={confirmDeletion}
              onCancel={hideDeleteConfirmation}
            />
        }
      </DialogActions>
    </Dialog>
  );
};