import { ProductionItem, Item, Unit, ProductCollection, ProductType } from '../production';
import { Lot, ProductionStatus } from '../production/lots/types';

export const LOAD_PRODUCTION_SCHEDULE_REQUEST = 'PRODUCTION_SCHEDULE_LOAD_REQUEST';
export const LOAD_PRODUCTION_SCHEDULE_SUCCESS = 'PRODUCTION_SCHEDULE_LOAD_SUCCESS';
export const LOAD_PRODUCTION_SCHEDULE_FAILURE = 'PRODUCTION_SCHEDULE_LOAD_FAILURE';

export interface LoadRequestAction { type: typeof LOAD_PRODUCTION_SCHEDULE_REQUEST; payload: { forDate: Date }; }
export interface LoadSuccessAction { type: typeof LOAD_PRODUCTION_SCHEDULE_SUCCESS; payload: { forDate: Date, entities: ProductionScheduleEntities }; }
export interface LoadFailureAction { type: typeof LOAD_PRODUCTION_SCHEDULE_FAILURE; payload: { forDate: Date, error: Error }; }

export type ProductionScheduleActionTypes =
    | LoadRequestAction | LoadSuccessAction | LoadFailureAction;

export interface ProductionScheduleState {
    byDate: { [date: string]: number[] };
    allDates: string[];
    loading: string[];
}

export interface ProductionSchedule {
    productCollections: ProductCollection[];
    productTypes: ProductType[];
    productionItems: ProductionItem[];
    items: Item[];
    lots: Lot[];
    salesOrders: SalesOrder[];
    productionUnits: Unit[];
}

export interface ProductionScheduleEntities {
    productCollections: { [id: number]: ProductCollection };
    productTypes: { [id: number]: ProductType };
    productionItems: { [id: number]: ProductionItem };
    items: { [id: number]: Item };
    lots: { [id: number]: Lot; };
    salesOrders: { [id: number]: SalesOrder };
    productionUnits: { [id: number]: Unit };
}

export interface ProductionUnitSchedule {
    productionUnitId: number | undefined;
    productionUnit: Unit | undefined;
    productionOrders: ProductionItem[];
}

export interface SalesOrder {
    id: number;
    clientFullName: string;
    clientCompany: string;
    purchaseOrderNumber: string | undefined;
    salesOrderNumber: string;
    orderedOn: string;
    orderIndex: number | undefined;
    status: SalesOrderStatus;
    productionStatus: ProductionStatus;
}

export enum SalesOrderStatus {
    ordered = 0,
    cancelled = 1,
    completed = 2,
}
