import React, { CSSProperties, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { Header, InputOnChangeData } from 'semantic-ui-react';
import { ClearableInput } from '../ClearableInput';

interface OwnProps {
    totalResultsCount: number;
    totalResultsMessage?: MessageDescriptor;
    searchResultsCount?: number;
    searchResultsMessage?: MessageDescriptor;
    searchPlaceholder?: string;
    style?: CSSProperties;

    onChange: (searchTerm: string) => void;
    onClear: () => void;
}

export type SearchControlsProps =
    & OwnProps;

const m = defineMessages({
  totalResultsCount: { id: 'SearchControls.totalResultsCount', defaultMessage: '{count, plural, =0 {No results} one {{count} result} other {{count} results}}' },
  searchResultsCount: { id: 'SearchControls.searchResultsCount', defaultMessage: '{count, plural, =0 {None found} other {{count} found}}' },
  searchPlaceholder: { id: 'SearchControls.searchPlaceholder', defaultMessage: 'Search...' }
});

export const SearchControls: React.FC<SearchControlsProps> = (props) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSearchTerm(data.value);
    props.onChange(data.value);
  };

  const handleClear = () => {
    setSearchTerm('');
    props.onClear();
  };

  return (
    <div style={props.style}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1em' }}>
        <Header size="small" style={{ margin: 0, flexGrow: 1 }}>
          <Header.Content>{formatMessage(props.totalResultsMessage || m.totalResultsCount, { count: props.totalResultsCount })}</Header.Content>
          <Header.Subheader>
            {searchTerm !== '' && props.searchResultsCount != null
              ? <React.Fragment>{formatMessage(props.searchResultsMessage || m.searchResultsCount, { count: props.searchResultsCount })}</React.Fragment>
              : <React.Fragment>&nbsp;</React.Fragment>
            }
          </Header.Subheader>
        </Header>

        <div>
          {props.children}
        </div>
      </div>

      <ClearableInput
        fluid
        onChange={handleSearchChange}
        onClear={handleClear}
        placeholder={props.searchPlaceholder || formatMessage(m.searchPlaceholder)}
        value={searchTerm}
      />
    </div>
  );
};
