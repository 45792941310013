import * as React from 'react';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { PageHeader, RaisedTile } from '../../../../components';
import { ApplicationState } from '../../../../store';
import * as LayoutActions from '../../../../store/layout/actions';
import { getWorkstationCostCenter } from '../../../administration';
import { getCurrentDevice } from '../../../administration/devices/selectors';
import { Device } from '../../../administration/devices/types';
import { getOperationById, getUnitsByCostCenter } from '../../selectors';
import { CostCenter, Operation, Unit } from '../../types';

const m = defineMessages({
  title: { id: 'ProductionOrderSelectionPage.title', defaultMessage: 'Select a production line' },
  subtitle: { id: 'ProductionOrderSelectionPage.subtitle', defaultMessage: 'Operation: {name}' },
  automaticallyTitle: { id: 'ProductionOrderSelectionPage.automaticallyTitle', defaultMessage: 'Automatically' },
  automaticallySubtitle: { id: 'ProductionOrderSelectionPage.automaticallySubtitle', defaultMessage: 'By using your device\'s camera to scan the barcode.' },
  scanButton: { id: 'ProductionOrderSelectionPage.scanButton', defaultMessage: 'Scan the barcode' },
  manuallyTitle: { id: 'ProductionOrderSelectionPage.manuallyTitle', defaultMessage: 'Manually' },
  manuallySubtitle: { id: 'ProductionOrderSelectionPage.manuallySubtitle', defaultMessage: 'By selecting your production line.' },
  manualInputLink: { id: 'ProductionOrderSelectionPage.manualInputLink', defaultMessage: 'Defective or unreadable code?' }
});

const ProductionOrderSelectionPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { workstationId, operationId } = useParams<{ workstationId: string, operationId: string }>();

  const costCenter = useSelector<ApplicationState, CostCenter | undefined>(state => getWorkstationCostCenter(state, Number(workstationId)));
  const productionUnits = useSelector<ApplicationState, Unit[] | undefined>(state => costCenter ? getUnitsByCostCenter(state, costCenter.id) : undefined);
  const operation = useSelector<ApplicationState, Operation | undefined>(state => getOperationById(state, Number(operationId)));
  const [activeTab, setActiveTab] = useState(0);

  React.useEffect(() => {
    LayoutActions.setPageTitle({ title: formatMessage(m.title), subtitle: operation ? operation.name : '' });
  });

  const listProductionOrders = React.useCallback((unitId: number) => {
    history.push(`/workstations/${workstationId}/operations/${operationId}/production-orders/list/${unitId}`);
  }, []);

  if (productionUnits?.length === 1) {
    return (<Redirect push={false} to={`/workstations/${workstationId}/operations/${operationId}/production-orders/list/${productionUnits[0].id}`} />);
  }

  return (
    <RaisedTile>
      <Grid>
        <Grid.Column width={4}></Grid.Column>
        <Grid.Column width={8} textAlign="center"><PageHeader title={formatMessage(m.title)} centered></PageHeader></Grid.Column>
        <Grid.Column width={4} textAlign="right"></Grid.Column>
      </Grid>

      <Segment>
        <Grid columns={2} stackable textAlign="center">
          <Grid.Row verticalAlign="top">
            <Grid.Column>
              <Grid columns={3} padded style={{ justifyContent: 'center' }}>
                {productionUnits?.map(x => (
                  <Grid.Column key={x.id}>
                    <Button
                      primary
                      fluid
                      content={x.name}
                      style={{ height: '80px' }}
                      onClick={() => listProductionOrders(x.id)}
                    />
                  </Grid.Column>
                ))}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </RaisedTile>
  );
};

export { ProductionOrderSelectionPage };