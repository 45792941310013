import { Roadmap } from '../../production/roadmaps/types';

export const CREATE_ROADMAP_REQUEST = 'ROADMAP_CREATE_REQUEST';
export const CREATE_ROADMAP_SUCCESS = 'ROADMAP_CREATE_SUCCESS';
export const CREATE_ROADMAP_FAILURE = 'ROADMAP_CREATE_FAILURE';

export const UPDATE_ROADMAP_REQUEST = 'ROADMAP_UPDATE_REQUEST';
export const UPDATE_ROADMAP_SUCCESS = 'ROADMAP_UPDATE_SUCCESS';
export const UPDATE_ROADMAP_FAILURE = 'ROADMAP_UPDATE_FAILURE';

export const DELETE_ROADMAP_REQUEST = 'ROADMAP_DELETE_REQUEST';
export const DELETE_ROADMAP_SUCCESS = 'ROADMAP_DELETE_SUCCESS';
export const DELETE_ROADMAP_FAILURE = 'ROADMAP_DELETE_FAILURE';

export interface CreateRoadmapRequestAction { type: typeof CREATE_ROADMAP_REQUEST; payload: { roadmap: Roadmap }; }
export interface CreateRoadmapSuccessAction { type: typeof CREATE_ROADMAP_SUCCESS; payload: { roadmap: Roadmap }; }
export interface CreateRoadmapFailureAction { type: typeof CREATE_ROADMAP_FAILURE; payload: { error: Error }; }

export interface UpdateRoadmapRequestAction { type: typeof UPDATE_ROADMAP_REQUEST; payload: { roadmapId: number, roadmap: Roadmap }; }
export interface UpdateRoadmapSuccessAction { type: typeof UPDATE_ROADMAP_SUCCESS; payload: { roadmap: Roadmap }; }
export interface UpdateRoadmapFailureAction { type: typeof UPDATE_ROADMAP_FAILURE; payload: { error: Error }; }

export interface DeleteRoadmapRequestAction { type: typeof DELETE_ROADMAP_REQUEST; payload: { roadmapId: number }; }
export interface DeleteRoadmapSuccessAction { type: typeof DELETE_ROADMAP_SUCCESS; payload: { roadmapId: number }; }
export interface DeleteRoadmapFailureAction { type: typeof DELETE_ROADMAP_FAILURE; payload: { roadmapId: number, error: Error }; }

export type AdministrativeRoadmapActionTypes =
    | CreateRoadmapRequestAction | CreateRoadmapSuccessAction | CreateRoadmapFailureAction
    | UpdateRoadmapRequestAction | UpdateRoadmapSuccessAction | UpdateRoadmapFailureAction
    | DeleteRoadmapRequestAction | DeleteRoadmapSuccessAction | DeleteRoadmapFailureAction;
