import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Icon, List } from 'semantic-ui-react';

import { ApplicationState } from '../../../../store';
import { getCostCentersStatus, getLotPrioritiesStatus, getOperationsStatus, getRoadmapStatus } from '../../selectors';
import { InitializationStatus } from '../../types';

interface StoreProps {
    costCentersStatus: InitializationStatus;
    operationsStatus: InitializationStatus;
    roadmapStatus: InitializationStatus;
    lotPrioritiesStatus: InitializationStatus;
}

export type SystemLoadingProgressProps =
    & StoreProps
    & WrappedComponentProps;

const m = defineMessages({
  costCentersLabel: { id: 'SystemLoadingProgress.costCentersLabel', defaultMessage: 'Cost centers' },
  operationsLabel: { id: 'SystemLoadingProgress.operationsLabel', defaultMessage: 'Operations' },
  roadmapLabel: { id: 'SystemLoadingProgress.roadmapLabel', defaultMessage: 'Roadmap' },
  prioritiesLabel: { id: 'SystemLoadingProgress.prioritiesLabel', defaultMessage: 'Lot priorities' }
});

class SystemLoadingProgress extends React.Component<SystemLoadingProgressProps> {
  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <List divided relaxed>
        <List.Item>
          <List.Icon>{this.renderStatusIcon(this.props.costCentersStatus)}</List.Icon>
          <List.Content>{formatMessage(m.costCentersLabel)}</List.Content>
        </List.Item>

        <List.Item>
          <List.Icon>{this.renderStatusIcon(this.props.operationsStatus)}</List.Icon>
          <List.Content>{formatMessage(m.operationsLabel)}</List.Content>
        </List.Item>

        <List.Item>
          <List.Icon>{this.renderStatusIcon(this.props.roadmapStatus)}</List.Icon>
          <List.Content>{formatMessage(m.roadmapLabel)}</List.Content>
        </List.Item>

        <List.Item>
          <List.Icon>{this.renderStatusIcon(this.props.lotPrioritiesStatus)}</List.Icon>
          <List.Content>{formatMessage(m.prioritiesLabel)}</List.Content>
        </List.Item>
      </List>
    );
  }

  private renderStatusIcon = (status: InitializationStatus) => {
    switch (status) {
    case InitializationStatus.loading: {
      return (<Icon name="circle notch" loading />);
    }

    case InitializationStatus.failed: {
      return (<Icon name="x" color="red" />);
    }

    case InitializationStatus.initialized: {
      return (<Icon name="check" color="green" />);
    }

    default: {
      return (<Icon name="angle right" />);
    }
    }
  };
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    costCentersStatus: getCostCentersStatus(state),
    operationsStatus: getOperationsStatus(state),
    roadmapStatus: getRoadmapStatus(state),
    lotPrioritiesStatus: getLotPrioritiesStatus(state)
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

const intlComponent = injectIntl(SystemLoadingProgress);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as SystemLoadingProgress };