import { get, post, httpDelete } from '../../../utils/api';
import { Workstation } from './types';
import { WorkstationEdit } from './models/WorkstationEdit';

const endpoint = '/api/workstations';

export async function addWorkstation (workstation: WorkstationEdit): Promise<Workstation> {
  return post<Workstation>(`${endpoint}`, workstation);
}

export async function deleteWorkstation (workstationId: number): Promise<any> {
  return httpDelete<any>(`${endpoint}/${workstationId}`);
}

export async function loadAll (): Promise<Workstation[]> {
  return get<Workstation[]>(endpoint);
}

export async function updateWorkstation (workstationId: number, workstation: WorkstationEdit): Promise<Workstation> {
  return post<Workstation>(`${endpoint}/${workstationId}`, workstation);
}
