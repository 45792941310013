import React from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';

export const Tooltip: React.FC<PopupProps> = (props) => {
  return (
    <Popup
      basic
      inverted
      position="bottom center"
      mouseEnterDelay={500}
      {...props}
    />
  );
};