import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages, FormattedMessage } from 'react-intl';
import { WorkstationEdit } from '../../../models/WorkstationEdit';
import { TileDeleteTemplate } from '../../../../../../components';

interface OwnProps {
    workstation: WorkstationEdit;
    onCancel: () => void;
    onConfirm: () => void;

    className?: string;
    style?: React.CSSProperties;
}

export type DeleteWorkstationTileProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
  deleteConfirmation: { id: 'DeleteWorkstationTile.deleteConfirmation', defaultMessage: 'Are you sure you want to delete the workstation {name}?' }
});

// tslint:disable-next-line: variable-name
const DeleteWorkstationTile: React.StatelessComponent<DeleteWorkstationTileProps> = (props: DeleteWorkstationTileProps) => {
  const workstationName = <strong>{props.workstation.name || ''}</strong>;

  return (
    <TileDeleteTemplate
      message={<FormattedMessage {...m.deleteConfirmation} values={{ name: workstationName }} />}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      className={props.className}
      style={props.style}
    />
  );
};

const intlComponent = injectIntl(DeleteWorkstationTile);
export { intlComponent as DeleteWorkstationTile };
