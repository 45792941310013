import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { isAuthenticated as isAuthenticatedSelector } from 'modules/account/selectors';
import { useLicense } from '../hooks/useLicense';
import { InvalidLicenseModal } from './InvalidLicenseModal';

export const LicenseValidator: React.FC = () => {
  const isAuthenticated = useSelector<ApplicationState, boolean>(state => isAuthenticatedSelector(state));
  const { isValid, subscription } = useLicense();

  const isOpen = isAuthenticated && subscription != undefined && !isValid;

  return (
    <InvalidLicenseModal open={isOpen} subscriptionStatus={subscription?.status} />
  );
};
