import {
  INITIALIZE,
  INITIALIZE_FAILURE,
  INITIALIZE_SUCCESS,
  InitializeAction,
  InitializeFailureAction,
  InitializeSuccessAction,
  LoadDataAction,
  LOAD_DATA,
  LoadDataSuccessAction,
  LOAD_DATA_SUCCESS,
  LoadDataFailureAction,
  LOAD_DATA_FAILURE,
  PingAction,
  PING_PUBLISH
} from './types';

export function initialize (): InitializeAction {
  return { type: INITIALIZE };
}

export function initializeSuccess (workstationId?: number): InitializeSuccessAction {
  return {
    type: INITIALIZE_SUCCESS,
    payload: { workstationId }
  };
}

export function initializeFailure (): InitializeFailureAction {
  return { type: INITIALIZE_FAILURE };
}

export function loadData (): LoadDataAction {
  return { type: LOAD_DATA };
}

export function loadDataSuccess (): LoadDataSuccessAction {
  return { type: LOAD_DATA_SUCCESS };
}

export function loadDataFailure (): LoadDataFailureAction {
  return { type: LOAD_DATA_FAILURE };
}

export function ping (pingedOn: Date): PingAction {
  return {
    type: PING_PUBLISH,
    payload: pingedOn.toISOString(),
    meta: 'Ping'
  };
}
