import { SupportedLocales } from '../../configureI18n';
import { User } from '../../models/account';

export const LOGIN_WEBAUTH = 'LOGIN_WEBAUTH';
export const LOGIN_WEBAUTH_SUCCESS = 'LOGIN_WEBAUTH_SUCCESS';
export const LOGIN_WEBAUTH_FAILURE = 'LOGIN_WEBAUTH_FAILURE';

export const LOGOUT = 'ACCOUNT_LOGOUT';

export const LOAD_PROFILE = 'ACCOUNT_LOAD_PROFILE';
export const LOAD_PROFILE_SUCCESS = 'ACCOUNT_LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_FAILURE = 'ACCOUNT_LOAD_PROFILE_FAILURE';

export const UPDATE_PROFILE = 'ACCOUNT_UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'ACCOUNT_UPDATE_PROFILE_SUCCESS';
export const UPdATE_PROFILE_FAILURE = 'ACCOUNT_UPDATE_PROFILE_FAILURE';

export const WEBAUTH_USER_REQUIRED = 'ACCOUNT_WEBAUTH_USER_REQUIRED';
export const WEBAUTH_USER_PROVIDED = 'ACCOUNT_WEBAUTH_USER_PROVIDED';
export const WEBAUTH_USER_REJECTED = 'ACCOUNT_WEBAUTH_USER_REJECTED';

export interface LoginWebAuthAction { type: typeof LOGIN_WEBAUTH; payload: { user?: User }; }
export interface LoginWebAuthSuccessAction { type: typeof LOGIN_WEBAUTH_SUCCESS; payload: User; }
export interface LoginWebAuthFailureAction { type: typeof LOGIN_WEBAUTH_FAILURE; payload: { error: Error }; }

export interface WebAuthUserRequiredAction { type: typeof WEBAUTH_USER_REQUIRED; payload: { isCollaborativeUser?: boolean }; }
export interface WebAuthUserProvidedAction { type: typeof WEBAUTH_USER_PROVIDED; payload: { user: User, isCollaborativeUser?: boolean }; }
export interface WebAuthUserRejectedAction { type: typeof WEBAUTH_USER_REJECTED; payload: Record<string, never>; }

export interface LogoutAction { type: typeof LOGOUT; payload: Record<string, never>; }

export interface LoadProfileAction { type: typeof LOAD_PROFILE; payload: Record<string, never>; }
export interface LoadProfileSuccessAction { type: typeof LOAD_PROFILE_SUCCESS; payload: User; }
export interface LoadProfileFailureAction { type: typeof LOAD_PROFILE_FAILURE; payload: { error: Error }; }

export interface UpdateProfileAction { type: typeof UPDATE_PROFILE; payload: { profile: UserProfile }}
export interface UpdateProfileSuccessAction { type: typeof UPDATE_PROFILE_SUCCESS; payload: { user: User }}
export interface UpdateProfileFailureAction { type: typeof UPdATE_PROFILE_FAILURE; payload: { error: Error }}

export type AccountActionTypes =
    | LoginWebAuthAction | LoginWebAuthSuccessAction | LoginWebAuthFailureAction
    | LogoutAction
    | LoadProfileAction | LoadProfileSuccessAction | LoadProfileFailureAction
    | UpdateProfileAction | UpdateProfileSuccessAction | UpdateProfileFailureAction
    | WebAuthUserRequiredAction | WebAuthUserProvidedAction | WebAuthUserRejectedAction;

export interface AccountState {
    user: User | undefined;
    isAuthenticating: boolean;
    isProfileLoaded: boolean;
    isAuthenticatingWithWebAuth: boolean;
    hasFailedAuthentication: boolean;
    hasWebAuthAuthenticationFailed: boolean;
    isAuthenticated: boolean;
    isWebAuthUserRequested: boolean;
}

export interface AuthenticationInfo {
    email: string;
    password: string;
}

export interface AuthenticatorAssertionRawResponseResponse {
    authenticatorData: string;
    signature: string;
    clientDataJson: string;
    userHandle?: string;
}

export enum PersonaPresence {
    Offline = 0,
    Online = 1
}

export interface AuthenticatorAssertionRawResponse {
    id: string;
    rawId: string;
    type: PublicKeyCredentialType;
    response: AuthenticatorAssertionRawResponseResponse;
}

export interface UserProfile {
    preferredLocale: SupportedLocales;
}
