import _ from 'lodash';
import { Reducer } from 'redux';

import {
  WORK_INSTRUCTIONS_CREATE_SUCCESS,
  WORK_INSTRUCTIONS_LOAD_ALL,
  WORK_INSTRUCTIONS_LOAD_ALL_FAILURE,
  WORK_INSTRUCTIONS_LOAD_ALL_SUCCESS,
  WorkInstructionsActionTypes,
  WorkInstructionsState
} from './types';

const initialState: WorkInstructionsState = {
  byId: {},
  allIds: [],
  isLoading: false
};

const workInstructionsReducer: Reducer<WorkInstructionsState, WorkInstructionsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
  case WORK_INSTRUCTIONS_CREATE_SUCCESS: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: action.payload
      },
      allIds: _.union(state.allIds, [action.payload.id])
    };
  }

  default:
    return state;
  }
};

export { workInstructionsReducer };
