import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon, Progress } from 'semantic-ui-react';
import { InternalLink } from '../../../../../../components';
import { BulkImportJobProgress } from '../../../models';

interface OwnProps {
    job: BulkImportJobProgress;
    onViewDetails: () => void;
}

export type RunningJobProgressProps =
    & OwnProps;

const m = defineMessages({
  viewDetailsLink: { id: 'RunningJobProgress.viewDetailsLink', defaultMessage: 'More details' },
  warningsCount: { id: 'RunningJobProgress.warningsCount', defaultMessage: '{count, plural, =0 {No warnings} one {{count} warning} other {{count} warnings}}' }
});

export const RunningJobProgress: React.FC<RunningJobProgressProps> = (props) => {
  const { formatMessage } = useIntl();
  const hasErrors = props.job.errors.length > 0;

  return (
    <>
      <Progress
        active
        total={props.job.associationsCount}
        value={props.job.associationsProcessed}
        progress="percent"
        precision={1}
        color="blue"
        style={{ marginBottom: '5px' }}
      />

      <InternalLink onClick={props.onViewDetails}>
        {hasErrors && <><Icon name="warning sign" color="yellow" /> {formatMessage(m.warningsCount, { count: props.job.errors.length })}</>}
        {!hasErrors && <><Icon name="info circle" color="blue" />{formatMessage(m.viewDetailsLink)}</>}
      </InternalLink>
    </>
  );
};
