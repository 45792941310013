import { Box, Divider, Drawer, DrawerProps } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSettingsDrawerVisible } from '../../../store/layout';
import { SettingsDrawerHeader } from './SettingsDrawerHeader';
import * as LayoutActions from '../../../store/layout/actions';
import { CurrentUserSection } from './CurrentUserSection';
import { LanguageSettingsSection } from './LanguageSettingsSection';

export const SettingsDrawer: React.FC<DrawerProps> = (props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isSettingsDrawerVisible);
  const closeDrawer = () => dispatch(LayoutActions.hideSettingsDrawer());

  return (
    <Drawer open={isOpen} onClose={closeDrawer} {...props}>
      <Box sx={{ width: '400px' }}>
        <SettingsDrawerHeader onClose={closeDrawer} />
        <Divider />

        <CurrentUserSection />
        <Divider />

        <LanguageSettingsSection />
      </Box>
    </Drawer>
  );
};
