import React from 'react';
import { Button, Header, ButtonProps } from 'semantic-ui-react';

import { Operation } from '../../../../types';
import styles from './OperationButton.module.css';

interface OwnProps {
    operation: Operation;
}

export type OperationButtonProps =
    & ButtonProps
    & OwnProps;

export const OperationButton: React.FC<OperationButtonProps> = (props) => {
  const { operation, ...buttonProps } = props;

  return (
    <Button
      className={styles.operationButton}
      primary
      fluid
      {...buttonProps}
    >
      <Header as="h2">
        {operation.code}
        <Header.Subheader>{operation.name}</Header.Subheader>
      </Header>
    </Button>
  );
};
