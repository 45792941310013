import React from 'react';
import { RoadmapColumn, RoadmapField } from '../../../../../production/roadmaps/types';
import { RoadmapFieldsSelection } from '../RoadmapFieldsSelection';

interface OwnProps {
    roadmapId: number;
    selectedColumns: RoadmapColumn[];
    onChange: (columns: RoadmapColumn[]) => void;
}

export type RoadmapColumnsSelectionProps =
    & OwnProps;

export const RoadmapColumnsSelection: React.FC<RoadmapColumnsSelectionProps> = (props) => {
  const selectField = (field: RoadmapField, orderIndex: number) => {
    const newSelectedColumn: RoadmapColumn = {
      roadmapId: props.roadmapId,
      fieldId: field.id,
      field,
      orderIndex: props.selectedColumns.length + 1
    };

    const selectedColumnsExceptNewOne = props.selectedColumns.filter(x => x.fieldId !== field.id);
    const updatedColumns = [...selectedColumnsExceptNewOne.slice(0, orderIndex), newSelectedColumn, ...selectedColumnsExceptNewOne.slice(orderIndex)];
    updatedColumns.forEach((column, index) => column.orderIndex = index);

    props.onChange(updatedColumns);
  };

  const deselectField = (field: RoadmapField) => {
    const selectedColumnsExceptNewOne = props.selectedColumns.filter(x => x.fieldId !== field.id);
    selectedColumnsExceptNewOne.forEach((column, index) => column.orderIndex = index);

    props.onChange(selectedColumnsExceptNewOne);
  };

  return (
    <RoadmapFieldsSelection
      selectedFields={props.selectedColumns.map(column => column.field) || []}
      onSelect={selectField}
      onDeselect={deselectField}
    />
  );
};
