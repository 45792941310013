import {
  LOAD_PRIORITIES_FAILURE,
  LOAD_PRIORITIES_SUCCESS,
  PrioritizationActionTypes,
  PRIORITIZE_SALES_ORDER_SUCCESS,
  UNPRIORITIZE_SALES_ORDER_SUCCESS,
  SAVE_PRIORITIES_REQUEST,
  SAVE_PRIORITIES_SUCCESS,
  SAVE_PRIORITIES_FAILURE
} from './types';

export function loadPrioritiesSuccess (salesOrderIds: number[]): PrioritizationActionTypes {
  return {
    type: LOAD_PRIORITIES_SUCCESS,
    payload: { salesOrderIds }
  };
}

export function loadPrioritiesFailure (error: Error): PrioritizationActionTypes {
  return {
    type: LOAD_PRIORITIES_FAILURE,
    payload: error
  };
}

export function savePrioritiesSuccess (orderedSalesOrdersIds: number[]): PrioritizationActionTypes {
  return {
    type: SAVE_PRIORITIES_SUCCESS,
    payload: { orderedSalesOrdersIds: orderedSalesOrdersIds }
  };
}

export function prioritizeSuccess (salesOrderId: number): PrioritizationActionTypes {
  return {
    type: PRIORITIZE_SALES_ORDER_SUCCESS,
    payload: { salesOrderId }
  };
}

export function unprioritizeSuccess (salesOrderId: number): PrioritizationActionTypes {
  return {
    type: UNPRIORITIZE_SALES_ORDER_SUCCESS,
    payload: { salesOrderId }
  };
}