import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Icon, IconProps } from 'semantic-ui-react';

import { SalesOrderStatus } from '../../types';

interface SalesOrderStatusIconOwnProps {
    status: SalesOrderStatus;
}

export type SalesOrderStatusIconProps =
    & SalesOrderStatusIconOwnProps
    & IconProps
    & WrappedComponentProps;

const m = defineMessages({
  producingTooltip: { id: 'SalesOrderStatusIcon.producing', defaultMessage: 'Producing' },
  completedTooltip: { id: 'SalesOrderStatusIcon.stoppedTooltip', defaultMessage: 'Production complete' },
  loadedTooltip: { id: 'SalesOrderStatusIcon.completedTooltip', defaultMessage: 'Loaded' },
  shippedTooltip: { id: 'SalesOrderStatusIcon.shippedTooltip', defaultMessage: 'Shipped' },
  billedTooltip: { id: 'SalesOrderStatusIcon.billedTooltip', defaultMessage: 'Billed' }
});

class SalesOrderStatusIcon extends React.Component<SalesOrderStatusIconProps> {
  public render () {
    return this.renderIcon();
  }

  private renderIcon () {
    const { formatMessage } = this.props.intl;
    const { status, ...iconProps } = this.props;

    switch (status) {
    case SalesOrderStatus.ordered:
      return <Icon title={formatMessage(m.producingTooltip)} name="box" color="blue" {...iconProps} />;

    case SalesOrderStatus.completed:
      return <Icon title={formatMessage(m.completedTooltip)} name="clipboard check" color="teal" {...iconProps} />;

    default:
      return (
        <Icon name="question circle" color="red" {...iconProps} />
      );
    }
  }
}

const connectedComponent = injectIntl(SalesOrderStatusIcon);
export { connectedComponent as SalesOrderStatusIcon };
