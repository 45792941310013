import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Header, Icon, Table } from 'semantic-ui-react';
import { LoadingContainer, LoadingDimmer } from '../../../../../../components';
import * as api from '../../../api';
import { BulkImportJobProgress } from '../../../models';
import { CompletedBulkImportRow } from './CompletedBulkImportRow';

interface OwnProps {
    onViewDetails: (job: BulkImportJobProgress) => void;
}

export type CompletedBulkImportsProps =
    & OwnProps;

const m = defineMessages({
  title: { id: 'CompletedBulkImports.title', defaultMessage: 'Completed bulk imports' },
  startedOnHeader: { id: 'CompletedBulkImports.startedOnHeader', defaultMessage: 'Started' },
  durationHeader: { id: 'CompletedBulkImports.durationHeader', defaultMessage: 'Duration' },
  warningsHeader: { id: 'CompletedBulkImports.warningsHeader', defaultMessage: 'Warnings' }
});

export const CompletedBulkImports: React.FC<CompletedBulkImportsProps> = (props) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [completedJobs, setCompletedJobs] = useState<BulkImportJobProgress[]>([]);

  useEffect(() => {
    setIsLoading(true);

    api.loadCompletedJobs()
      .then(result => setCompletedJobs(result))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Header content={formatMessage(m.title)} />
      <LoadingContainer>
        <LoadingDimmer active={isLoading} />
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing />
              <Table.HeaderCell>{formatMessage(m.startedOnHeader)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(m.durationHeader)}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage(m.warningsHeader)}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {completedJobs.map(job => <CompletedBulkImportRow key={job.jobId} job={job} onClick={() => props.onViewDetails(job)} />)}
          </Table.Body>
        </Table>
      </LoadingContainer>
    </>
  );
};
