import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import * as accountApi from '../../account/api';
import * as accountActions from '../../account/actions';
import * as actions from './actions';
import { ADD_LOCAL_USER, AddLocalUserAction } from './types';
import { encodeArrayBufferToString } from '../../../utils/WebAuthHelper';
import { User } from '../../../models';
import { getCurrentUser } from '../../account/selectors';
import { WEBAUTH_USER_PROVIDED, WebAuthUserProvidedAction } from '../../account';

function * handleAddUser (action: AddLocalUserAction) {
  try {
    const options = (yield call(accountApi.makeAssertionOptions, action.payload.user)) as PublicKeyCredentialRequestOptions;

    if (options.allowCredentials == null || options.allowCredentials.length <= 0) {
      // We didn't receive any credentials to challenge from the server. On Chrome for Android,
      // this will result in an error. As such, we need to ask for the user and get its credentials
      // before we can keep authenticating with webauth.
      yield put(accountActions.webAuthUserRequired(true));
      return;
    }

    let hasNotReadableExceptionOccurred = false;
    let assertion: PublicKeyCredential | null = null;
    do {
      hasNotReadableExceptionOccurred = false;
      try {
        assertion = (yield call(() => navigator.credentials!.get({ publicKey: options }))) as PublicKeyCredential;
      } catch (e) {
        if (e instanceof DOMException && e.name === 'NotReadableError') {
          // this error can happen when the user moves the security key instead of holding it still against the NFC reader
          hasNotReadableExceptionOccurred = true;
        } else if (e instanceof DOMException && e.name === 'InvalidStateError') {
          yield put(accountActions.webAuthUserRequired());
          return;
        } else {
          throw e;
        }
      }
    } while (hasNotReadableExceptionOccurred);

    if (!assertion) {
      throw new Error('Expected credentials of type: PublicKeyCredential');
    }

    const userHandle = (assertion.response as AuthenticatorAssertionResponse).userHandle;
    const data = {
      id: assertion.id,
      rawId: encodeArrayBufferToString(assertion.rawId),
      type: assertion.type,
      response: {
        authenticatorData: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).authenticatorData),
        signature: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).signature),
        clientDataJson: encodeArrayBufferToString((assertion.response as AuthenticatorAssertionResponse).clientDataJSON),
        userHandle: userHandle && userHandle.byteLength > 0 ? encodeArrayBufferToString(userHandle) : undefined
      }
    };

    const result = (yield call(accountApi.validateAssertion, data as any)) as User;
    const currentUser = (yield select(getCurrentUser)) as User | undefined;
    if (currentUser != null && currentUser.id !== result.id) {
      yield put(actions.addLocalUserSuccess(result));
    } else {
      yield put(actions.addLocalUserFailure(new Error('The collaborative user is the same as the current user connected on the workstation.')));
    }
  } catch (e) {
    yield put(actions.addLocalUserFailure(e as Error));
  }
}

function * handleWebauthUserProvided (action: WebAuthUserProvidedAction) {
  if (action.payload.isCollaborativeUser) {
    yield put(actions.addLocalUser(action.payload.user));
  }
}

function * watchAddUser () { yield takeEvery(ADD_LOCAL_USER, handleAddUser); }
function * watchWebauthUserProvided () { yield takeEvery(WEBAUTH_USER_PROVIDED, handleWebauthUserProvided); }

function * collaborationSagas () {
  yield all([
    fork(watchAddUser),
    fork(watchWebauthUserProvided)
  ]);
}

export default collaborationSagas;
