import * as React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';

import { isSystemInitialized } from '../../modules/system/selectors';
import { ApplicationState } from '../../store';
import { Loader } from 'semantic-ui-react';

import * as AccountActions from '../../modules/account/actions';
import { isProfileLoaded } from '../../modules/account/selectors';

interface DispatchProps {
    accountActions: typeof AccountActions;
}

interface StoreProps {
    isInitialized: boolean;
    isProfileLoaded: boolean;
}

export type SplashScreenProps =
    & StoreProps
    & DispatchProps
    & RouteComponentProps<void>
    & WrappedComponentProps;

const m = defineMessages({
  loadingMessage: { id: 'SplashScreen.loadingMessage', defaultMessage: 'Loading...' }
});

class SplashScreen extends React.Component<SplashScreenProps> {
  public componentDidMount () {
    this.props.accountActions.loadProfile();
  }

  public render () {
    // This ensures that the sagas that determines whether the current device is a workstation or an administrative computer
    // has been run. If we don't make this check, we can have a weird case where a workstation will go inside an administrative
    // route due to being authenticated, but not having a device type yet.
    const hasLoadedDeviceType = this.props.isInitialized;

    return (
      <React.Fragment>
        {hasLoadedDeviceType && this.props.isProfileLoaded ? this.props.children : this.renderLoading()}
      </React.Fragment>
    );
  }

  private renderLoading = () => {
    const { formatMessage } = this.props.intl;

    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Loader active inline size="large" content={formatMessage(m.loadingMessage)} />
      </div>
    );
  };
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    isInitialized: isSystemInitialized(state),
    isProfileLoaded: isProfileLoaded(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
};

const intlComponent = injectIntl(SplashScreen);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
const routedComponent = withRouter(connectedComponent);
export { routedComponent as SplashScreen };
