import React, { useState } from 'react';
import { Accordion, SemanticICONS, Icon, Menu } from 'semantic-ui-react';

export interface NavigationMenuSectionProps {
    icon?: SemanticICONS;
    title: string;
    isExpandedByDefault?: boolean;
}

export const NavigationMenuSection: React.FunctionComponent<NavigationMenuSectionProps> = (props) => {
  const [isActive, setActive] = useState(props.isExpandedByDefault != null ? props.isExpandedByDefault : true);

  return (
    <React.Fragment>
      <Accordion.Title active={isActive} onClick={() => setActive(!isActive)}>
        <Menu.Item header>
          <span>
            {props.icon && <Icon name={props.icon} />}
            {props.title}
          </span>
          <Icon name="dropdown" />
        </Menu.Item>
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        {props.children}
      </Accordion.Content>
    </React.Fragment>
  );
};
