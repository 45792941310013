import React from 'react';
import { Table, TableRowProps, Checkbox } from 'semantic-ui-react';
import { Operation, getCostCenterById } from '../../../../production';
import { useIntl } from 'react-intl';
import { getOperatingModeDescription } from '../../messages';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { getTemplateById } from '../../../../production/roadmaps/selectors';

interface OwnProps {
    operation: Operation;
}

export type OperationsTableRowProps =
    & TableRowProps
    & OwnProps;

export const OperationsTableRow: React.FC<OperationsTableRowProps> = (props) => {
  const { operation, ...tableRowProps } = props;
  const { formatMessage } = useIntl();
  const costCenter = useSelector((state: ApplicationState) => getCostCenterById(state, operation.costCenterId));
  const roadmap = useSelector((state: ApplicationState) => getTemplateById(state, operation.roadmapId || -1));

  return (
    <Table.Row {...tableRowProps}>
      <Table.Cell>{operation.code}</Table.Cell>
      <Table.Cell>{operation.name}</Table.Cell>
      <Table.Cell>{costCenter?.name || ''}</Table.Cell>
      <Table.Cell>{formatMessage(getOperatingModeDescription(operation.operatingMode))}</Table.Cell>
      <Table.Cell>{roadmap?.name || ''}</Table.Cell>
    </Table.Row>
  );
};
