import React from 'react';
import { ButtonProps, CircularProgress, Typography } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import { ReactComponent as MicrosoftLogo } from './MicrosoftLogo.svg';
import { StyledButton } from './MicrosoftLoginButton.styled';

type MicrosoftLoginButtonProps = ButtonProps & {
  loading?: boolean;
}

const m = defineMessages({
  title: { id: 'MicrosoftLoginButton.title', defaultMessage: 'Sign in with Microsoft' },
});

export const MicrosoftLoginButton: React.FC<MicrosoftLoginButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const { loading, ...buttonProps } = props;

  return (
    <StyledButton {...buttonProps}>
      <MicrosoftLogo className="logo" />
      <Typography>{formatMessage(m.title)}</Typography>

      {loading && <CircularProgress size={24} sx={{ position: 'absolute' }} />}
    </StyledButton>
  );
};