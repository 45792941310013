import _ from 'lodash';
import { combineReducers, Reducer } from 'redux';

import {
  HIDE_TOAST,
  NotificationsActions,
  NotificationsState,
  PIN_TOAST,
  SHOW_TOAST,
  ToastsState,
  UNPIN_TOAST
} from './types';

const initialToastsState: ToastsState = {
  byId: {},
  allIds: []
};

const toastsReducer: Reducer<ToastsState, NotificationsActions> = (state = initialToastsState, action: NotificationsActions) => {
  switch (action.type) {
  case SHOW_TOAST: {
    return {
      ...state,
      byId: _.merge({}, state.byId, { [action.payload.id]: action.payload }),
      allIds: _.union(state.allIds, [action.payload.id])
    };
  }

  case HIDE_TOAST: {
    return {
      ...state,
      allIds: state.allIds.filter(x => x !== action.payload.id)
    };
  }

  case PIN_TOAST: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: {
          ...state.byId[action.payload.id],
          isPinned: true
        }
      }
    };
  }

  case UNPIN_TOAST: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: {
          ...state.byId[action.payload.id],
          isPinned: false
        }
      }
    };
  }

  default:
    return state;
  }
};

const notificationsReducer = combineReducers<NotificationsState>({
  toasts: toastsReducer
});

export { notificationsReducer };
