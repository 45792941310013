import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { logout } from '../modules/account/actions';
import { ApiError } from './api';
import { BadRequestError } from './BadRequestError';
import { ModelState } from './ModelState';

const m = defineMessages({
  forbidden: { id: 'useApiErrorHandler.forbiddenErrorMessage', defaultMessage: 'Your current role does not allow you to perform this action.' },
  defaultErrorMessage: { id: 'useApiErrorHandler.genericErrorMessage', defaultMessage: 'An unexpected error occurred while communicating with the server. Please try again or contact your administrator if the problem persists.' }
});

const isDevelopment = process.env.NODE_ENV == null || process.env.NODE_ENV === 'development';

export enum ErrorHandlingStrategy {
AlertNonFieldSpecificErrorsOnly = 0,
AlertAllErrors = 1,
}

export const successToastOptions: ToastOptions = { position: 'top-center', autoClose: 2000 };
export const defaultToastOptions: ToastOptions = { position: 'top-center', autoClose: 5000 };

const useApiErrorHandler = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const showGenericServerError = useCallback(() => {
    toast.error(formatMessage(m.defaultErrorMessage), defaultToastOptions);
  }, [formatMessage]);

  const errorsToToastMessage = (errors: string[]): ToastContent => {
    if (errors.length === 0) {
      return undefined;
    }

    // eslint-disable-next-line react/no-children-prop
    const listItemElements = errors.map((error) => React.createElement('li', { children: error }));

    // eslint-disable-next-line react/no-children-prop
    return React.createElement('ul', { children: listItemElements });
  };

  const handleBadRequestError = useCallback(
    (error: BadRequestError, errorHandlingStrategy: ErrorHandlingStrategy) => {
      const errors =
        errorHandlingStrategy === ErrorHandlingStrategy.AlertAllErrors
          ? error.modelState.getAllErrorMessages(formatMessage)
          : error.modelState.getNonFieldSpecificErrorMessages(formatMessage);
      if (errors) {
        toast.error(errorsToToastMessage(errors), defaultToastOptions);
      } else {
        showGenericServerError();
      }

      return error.modelState;
    },
    [formatMessage, showGenericServerError]
  );

  const handleApiError = useCallback(
    (error: ApiError) => {
      if (error.statusCode === 403) {
        toast.error(formatMessage(m.forbidden), defaultToastOptions);
      } else if (error.statusCode === 401) {
        dispatch(logout());
      } else {
        showGenericServerError();
      }
    },
    [formatMessage, showGenericServerError]
  );

  const handleError = useCallback(
    (
      error: unknown,
      errorHandlingStrategy: ErrorHandlingStrategy = ErrorHandlingStrategy.AlertAllErrors
    ): Promise<ModelState | undefined> => {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_CANCELED') {
          return Promise.resolve(undefined);
        }
        if (error.response?.status === 403) {
          toast.error(formatMessage(m.forbidden), defaultToastOptions);
        } else if (error.response?.status === 401) {
          dispatch(logout());
        } else {
          let errorMessage: string | undefined = undefined;
          if (error.response?.data && error.response?.data.UserMessage) {
            errorMessage = (isDevelopment ? error.response?.data.UserMessage + ' DEBUG: ' + error.response?.data.DebugMessage : error.response?.data.UserMessage);
          }
          else if (error && error.response?.data.title) {
            errorMessage = error.response?.data.title;
          }

          if (errorMessage) {
            toast.error(errorMessage);
          }
          else {
            showGenericServerError();
          }
        }
      } else if (error instanceof ApiError) {
        handleApiError(error);
      } else if (error instanceof DomainError) {
        toast.error(error.message);
      } else if (error instanceof BadRequestError) {
        return Promise.resolve(handleBadRequestError(error, errorHandlingStrategy));
      } else {
        showGenericServerError();
      }
      return Promise.resolve(undefined);
    },
    [handleApiError, handleBadRequestError, showGenericServerError]
  );

  const onSettledErrorHandler = useCallback((data: any, error: unknown) => {
    if (error) {
      handleError(error);
    }
  }, [handleError]);

  return { handleError, onSettledErrorHandler};
};

export class DomainError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export default useApiErrorHandler;
