import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TileEditTemplate } from '../../../../../../components';
import { DetectClickOutside } from '../../../../../../components/common';
import { ValidationResult } from '../../../../../../models';
import { WorkstationEdit } from '../../../models/WorkstationEdit';
import { WorkstationForm } from './WorkstationForm';
import { workstationIcon } from './WorkstationTile';

interface OwnProps {
    workstation: WorkstationEdit | undefined;
    closeIcon?: React.ReactElement | null;
    style?: React.CSSProperties;
    className?: string;

    onClose: (workstation: WorkstationEdit) => void;
}

interface StateProps {
    workstation: WorkstationEdit;
    validationResult: ValidationResult;
}

export type EditWorkstationTileProps =
    & OwnProps
    & WrappedComponentProps;

class EditWorkstationTile extends React.Component<EditWorkstationTileProps, StateProps> {
  public constructor (props: EditWorkstationTileProps) {
    super(props);

    this.state = {
      workstation: new WorkstationEdit(props.workstation),
      validationResult: ValidationResult.empty()
    };
  }

  public render () {
    return (
      <DetectClickOutside onClickOutside={this.updateWorkstation}>
        <TileEditTemplate
          tileIcon={workstationIcon}
          description={this.state.workstation.description}
          canEditDescription={true}
          name={this.state.workstation.name || ''}
          validationResult={this.state.validationResult}
          closeIcon={this.props.closeIcon}
          onUpdate={this.changeWorkstation}
          onClose={this.updateWorkstation}
          className={this.props.className}
          style={this.props.style}
        >
          <WorkstationForm
            workstation={this.state.workstation}
            validationResult={this.state.validationResult}
            onUpdate={this.changeWorkstation}
          />
        </TileEditTemplate>
      </DetectClickOutside>
    );
  }

  private updateWorkstation = () => {
    const validationResult = this.state.workstation.validate();
    this.setState({ validationResult });

    if (validationResult.isValid) {
      this.props.onClose(this.state.workstation);
    }
  };

  private changeWorkstation = (fieldId: string, fieldValue: any) => {
    this.setState((current) => ({
      ...current,
      workstation: new WorkstationEdit({
        ...current.workstation,
        [fieldId]: fieldValue
      })
    }));
  };
}

const intlComponent = injectIntl(EditWorkstationTile);
export { intlComponent as EditWorkstationTile };
