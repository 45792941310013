import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Container, Stack } from '@mui/material';
import { SearchControls } from 'components/common/SearchControls';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { graphql, useFragment } from 'gql';
import { useGraphQL } from 'gql/useGraphQL';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as LayoutActions from '../../../../store/layout/actions';
import {
  OrderList_QueryFragment,
  OrdersTable,
} from '../components/OrdersTable';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

const m = defineMessages({
  totalResultsMessage:{
    id: 'ProductionHistoryPage.TotalOrders',
    defaultMessage:
      '{count, plural, =0 {No order found} one {{count} order} other {{count} orders}}',
  },
  searchResultsMessage:{
    id: 'ProductionHistoryPage.TotalOrdersFound',
    defaultMessage:
      '{count, plural, =0 {No order found} one {{count} order found} other {{count} orders found}}',
  }
});

const SalesOrdersTotalCountQuery = graphql(`
  query SalesOrdersTotalCountQuery {
    salesOrdersTotalCount
  }
`);

const ProductionHistoryPageQuery = graphql(`
  query ProductionHistoryPageQuery($filter: SalesOrderGraphQLTypeFilterInput) {
    ...OrderList_QueryFragment
  }
`);

export const ProductionHistoryPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');

  const filter = {
    or: [
      { salesOrderNumber: { contains: searchTerm } },
      { purchaseOrderNumber: { contains: searchTerm } },
      { customerFullName: { contains: searchTerm } },
      { customerCompanyName: { contains: searchTerm } },
    ] as any,
  };

  const longDateFormat = dayjs().localeData().longDateFormat('L');
  const searchTermAsDate = dayjs(searchTerm, longDateFormat);

  if (searchTermAsDate.isValid()) {
    const createdOnFilter = {
      and: [
        { orderedOn: { gte: searchTermAsDate.startOf('d') } },
        { orderedOn: { lte: searchTermAsDate.endOf('d') } },
      ],
    };

    filter.or.push(createdOnFilter);
  }

  const { data: salesOrdersTotalCountData } = useGraphQL(
    SalesOrdersTotalCountQuery
  );

  const salesOrdersTotalCount =
    salesOrdersTotalCountData?.salesOrdersTotalCount;

  const { data: salesOrdersData } = useGraphQL(ProductionHistoryPageQuery, {
    filter: searchTerm ? filter : null,
  });

  const query = useFragment(OrderList_QueryFragment, salesOrdersData);

  useEffect(() => {
    dispatch(
      LayoutActions.setPageTitle({
        title: formatMessage({
          id: 'ProductionHistoryPage.Title',
          defaultMessage: 'Production history',
        }),
      })
    );
  }, [dispatch]);

  return (
    <Container sx={{ paddingTop: '20px', paddingBottom: '50px' }}>
      <Stack gap={2}>
        <SearchControls
          totalResultsCount={salesOrdersTotalCount ?? 0}
          totalResultsMessage={m.totalResultsMessage}
          searchResultsMessage={m.searchResultsMessage}
          searchResultsCount={
            (searchTerm && query?.salesOrders?.totalCount) || 0
          }
          onChange={debounce(setSearchTerm, 500)}
          onClear={() => setSearchTerm('')}
        />
        {salesOrdersData && (
          <OrdersTable
            query={salesOrdersData}
            density="comfortable"
            disableColumnMenu
            disableColumnFilter
            disableColumnReorder
            disableRowSelectionOnClick
            ignoreDiacritics
            height="70vh"
            hideFooter
            slots={{
              detailPanelExpandIcon: () => <KeyboardArrowDownIcon />,
              detailPanelCollapseIcon: () => <KeyboardArrowUpIcon />,
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit',
              },
            }}
          />
        )}
      </Stack>
    </Container>
  );
};
