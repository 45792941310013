import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container } from 'semantic-ui-react';
import { UsersTable, UsersTableQueryFragment } from './components/UsersTable';
import { defineMessages, useIntl } from 'react-intl';
import * as layoutActions from '../../../../store/layout/actions';
import { useHistory } from 'react-router';
import { SearchControls } from '../../../../components/common/SearchControls';
import { graphql, useFragment } from 'gql';
import request from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { IdentifyKeyOwnerButton } from './components/IdentifyKeyOwnerButton';

const m = defineMessages({
  title: { id: 'UsersConfigurationPage.title', defaultMessage: 'Configure Users' },
  subtitle: { id: 'UsersConfigurationPage.subtitle', defaultMessage: 'View and configure users.' },
  totalUsersCount: { id: 'UsersConfigurationPage.totalUsersCount', defaultMessage: '{count, plural, =0 {No users} one {{count} user} other {{count} users}}' },
  addUserButton: { id: 'UsersConfigurationPage.addUserButton', defaultMessage: 'Add a user' }
});

const ManageUsersPageQuery = graphql(`
  query ManageUsersPageQuery {
    ...UsersTableQueryFragment
  }
`);

export const ManageUsersPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { data } = useQuery(['users'], async () => request('/graphql', ManageUsersPageQuery));

  const allUsers = useFragment(UsersTableQueryFragment, data);

  useEffect(() => {
    dispatch(layoutActions.setPageTitle({ title: formatMessage(m.title), subtitle: formatMessage(m.subtitle) }));
  }, [dispatch, formatMessage]);

  const navigateToUserModificationPage = (userId: string) => history.push(`/administration/users/${userId}`);
  const navigateToUserCreationPage = () => history.push('/administration/users/new');

  const totalUsersCount = allUsers?.users.length ?? 0;

  return (
    <Container>
      <SearchControls
        totalResultsCount={totalUsersCount}
        totalResultsMessage={m.totalUsersCount}
        onChange={setSearchTerm}
        onClear={() => setSearchTerm('')}
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <IdentifyKeyOwnerButton onIdentified={owner => setSearchTerm(owner.username)} />
        <Button primary content={formatMessage(m.addUserButton)} onClick={navigateToUserCreationPage} />
      </SearchControls>

      {data &&
        <UsersTable
          users={data}
          searchTerm={searchTerm}
          onClick={navigateToUserModificationPage}
        />
      }
    </Container>
  );
};
