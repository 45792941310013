import React from 'react';
import { useDispatch } from 'react-redux';
import * as LayoutActions from '../../../../store/layout/actions';
import styles from '../TopBarItemButton.module.css';
import { TopBarMenuItem } from '../TopBarMenuItem';

export const SearchControl: React.FC = () => {
  const dispatch = useDispatch();
  const showSearchModal = () => dispatch(LayoutActions.showSearchModal());

  return (
    <TopBarMenuItem
      className={styles.Button}
      icon="search"
      onClick={showSearchModal}
    />
  );
};