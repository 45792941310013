import { Operation } from 'modules/production';
import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { DropdownItemProps, Dropdown } from 'semantic-ui-react';
import { ProductionOrderRoadmapHeaderProps } from './ProductionOrderRoadmapHeader';
import { ProductionStatus } from 'modules/production/lots/types';

const m = defineMessages({
  operationTitle: { id: 'ProductionOrderRoadmapHeader.operationTitle', defaultMessage: 'Operation: ' },
});

export const ProductionOrderRoadmapMiddleHeader: React.FC<ProductionOrderRoadmapHeaderProps> = (props) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const getOptions = (): DropdownItemProps[] => {
    const completedOrStartedOperations = props.manufacturedOperationItems
      .filter(operation =>
        operation.productionStatus == ProductionStatus.completed
        || operation.productionStatus == ProductionStatus.started
        || operation.isReadyForProduction == true);
    const availableOperations: Operation[] = [];

    //Needs this check when clicking the reload button because the ApplicationState gets reset
    if (props.operations[0] === undefined) {
      return [];
    }

    if (completedOrStartedOperations.length == 0) {
      availableOperations.push(props.operations[0]);
    }
    else {
      const currentOperation = Math.max(...completedOrStartedOperations.map(operation => operation.operationId)) + 1;
      const allOperationIds = [...completedOrStartedOperations.map(operation => operation.operationId), currentOperation];
      availableOperations.push(...props.operations.filter(operation => allOperationIds.includes(operation.id)));
    }

    if (availableOperations.map(operation => operation.id).includes(props.operation?.id as number) == false) {
      availableOperations.push(props.operation as Operation);
    }

    return availableOperations.map((operation) => {
      const workstationId = props.workstations.find(workstation => workstation.operationIds.includes(operation.id))?.id;
      if (props.lot == undefined) {
        return {
          key: `/workstations/${workstationId}/operations/${operation.id}/production-orders/${props.productionOrder?.id}/roadmap`,
          value: `/workstations/${workstationId}/operations/${operation.id}/production-orders/${props.productionOrder?.id}/roadmap`,
          text: operation?.name || ''
        } as DropdownItemProps;
      }
      else {
        return {
          key: `/workstations/${workstationId}/operations/${operation.id}/lots/${props.lot.id}/roadmap`,
          value: `/workstations/${workstationId}/operations/${operation.id}/lots/${props.lot.id}/roadmap`,
          text: operation?.name || ''
        } as DropdownItemProps;
      }
    });
  };

  const options = useMemo(() => {
    return getOptions();
  }, [props.manufacturedOperationItems]);

  return (
    <>
      {formatMessage(m.operationTitle)}
      <Dropdown
        inline
        value={history.location.pathname}
        onChange={(_, data) => history.push(data.value as string)}
        options={options}
      />
    </>
  );
};
