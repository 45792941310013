import { Stack, Typography } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, ButtonProps, Icon, SemanticICONS } from 'semantic-ui-react';
import { ApplicationState } from '../../../../../store';
import { SalesOrder } from '../../../../schedule';
import { Lot, ProductionStatus } from '../../../lots/types';
import { getItemById, getOperationById } from '../../../selectors';
import { Item, Operation, ProductionItem } from '../../../types';

interface ProductionOrderButtonOwnProps {
    lot: Lot | undefined;
    salesOrder?: SalesOrder;
    productionItem: ProductionItem | undefined;
    productionStatus: ProductionStatus;
    operationId?: number;
}

export type ProductionOrderButtonProps =
    & ButtonProps
    & ProductionOrderButtonOwnProps;

const m = defineMessages({
  lotTitle: { id: 'ProductionOrderButton.lotTitle', defaultMessage: 'Lot: {lotName}' },
  productionOrderTitle: { id: 'ProductionOrderButton.productionOrderTitle', defaultMessage: 'Order #{salesOrderNumber}' }
});

export const ProductionOrderButton = (props: ProductionOrderButtonProps) => {
  const { formatMessage } = useIntl();
  const { lot, productionItem, salesOrder, productionStatus, operationId, ...buttonProps } = props;
  const item = useSelector<ApplicationState, Item | undefined>(state => productionItem ? getItemById(state, productionItem.itemId) : undefined);
  const operation = useSelector<ApplicationState, Operation | undefined>(state => getOperationById(state, operationId ?? 0));

  const getIcon = (): SemanticICONS => {
    switch (productionStatus) {
    case ProductionStatus.completed: {
      return 'check circle';
    }

    case ProductionStatus.started: {
      return 'play circle';
    }

    default:
      return 'stop circle';
    }
  };

  return (
    <Button icon labelPosition="right" primary size="huge" fluid {...buttonProps}>
      <Icon size="large" name={getIcon()} />
      <Stack direction='row' width='100%' justifyContent='space-between' paddingRight={7}>

        {item &&
          <Typography variant='h5' sx={{flex: 1, textAlign: 'left'}}>
            {item?.description}
          </Typography>
        }

        {lot &&
          <Typography variant='h5' sx={{flex: 1, textAlign: 'left'}}>
            {formatMessage(m.lotTitle, { lotName: lot?.name })}
          </Typography>
        }

        {operation && (
          <Typography variant='h5' sx={{textAlign: 'right'}}>
            {operation.name}
          </Typography>
        )}

        {salesOrder &&
          <Stack sx={{ flex: 1 }}>
            <Typography variant='h5' >
              {formatMessage(m.productionOrderTitle, { salesOrderNumber: salesOrder.salesOrderNumber })}
            </Typography>
            <Typography variant='h5'>{salesOrder.clientFullName}</Typography>
            <Typography variant='h5'>{salesOrder.clientCompany}</Typography>
          </Stack>
        }

      </Stack>
    </Button>
  );
};