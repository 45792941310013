import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { RaisedTile, PageHeader, LoadingDimmer, NoContentPlaceholder } from '../../../../components';
import { defineMessages, useIntl } from 'react-intl';
import { WorkInstructionTable } from './components/WorkInstructionTable';
import { Button } from 'semantic-ui-react';
import { WorkInstructionTableControls } from './components/WorkInstructionTableControls';
import { WorkInstruction, WorkInstructionEdit } from '../types';
import * as api from '../api';
import { AddWorkInstructionModal } from './components';
import { PdfModal } from '../../../production/work-instructions/views/components/PdfModal';
import { PaginatedResults, emptyPaginatedResults } from '../../../../utils/PaginatedResults';
import useApiErrorHandler from '../../../../utils/ApiErrorHandler';

const m = defineMessages({
  title: { id: 'WorkInstructionsPage.title', defaultMessage: 'Configure the work instructions' },
  subtitle: { id: 'WorkInstructionsPage.subtitle', defaultMessage: 'Configure the work instructions that will be displayed for specific production orders, items and operations.' },
  addButton: { id: 'WorkInstructionsPage.addButton', defaultMessage: 'Add manually' },
  failedToLoadWorkInstructionsTitle: { id: 'WorkInstructionsPage.failedToLoadWorkInstructionsTitle', defaultMessage: 'Failed to load the work instructions' },
  failedToLoadWorkInstructionsMessage: { id: 'WorkInstructionsPage.failedToLoadWorkInstructionsMessage', defaultMessage: 'An error occurred while loading the work instructions. Please try again later or contact your administrator if the problem persists.' },
  failedToAddWorkInstructionTitle: { id: 'WorkInstructionPage.failedToAddWorkInstructionTitle', defaultMessage: 'Failed to add the work instruction' },
  failedToAddWorkInstructionMessage: { id: 'WorkInstructionPage.failedToAddWorkInstructionMessage', defaultMessage: 'An error occurred while adding the work instruction. Please try again later or contact your administrator if the problem persists.' },
  emptyContentTitle: { id: 'WorkInstructionsPage.emptyContentTitle', defaultMessage: 'There are no work instructions configured.' },
  emptyContentMessage: { id: 'WorkInstructionsPage.emptyContentMessage', defaultMessage: 'There are no work instructions currently configured. You can add a single PDF work instruction through a manual import or add multiple ones through the CSV Import functionnality.' },
  importResultTitle: { id: 'WorkInstructionsPage.importResultTitle', defaultMessage: 'Some work instructions could not be imported.' }
});

export const ManageInstructionsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleError } = useApiErrorHandler();
  const [paginatedInstructions, setPaginatedInstructions] = useState<PaginatedResults<WorkInstruction>>(emptyPaginatedResults());
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddModalVisible, setAddModalVisibility] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const performSearch = (searchTerm: string) => {
    setIsLoading(true);

    if (searchTerm !== '') {
      api.search(searchTerm)
        .then(instructions => setPaginatedInstructions(instructions))
        .finally(() => setIsLoading(false));
    } else {
      loadInstructions();
    }
  };

  const openAddModal = () => setAddModalVisibility(true);
  const closeAddModal = () => setAddModalVisibility(false);

  const loadInstructions = useCallback(() => {
    setIsLoading(true);

    api.loadAll()
      .then(instructions => {
        setPaginatedInstructions(instructions);
        setTotalResultsCount(instructions.totalResults);
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    loadInstructions();
  }, [loadInstructions]);

  const addSingleInstruction = (workInstruction: WorkInstructionEdit) => {
    setIsAdding(true);
    closeAddModal();

    api.create(workInstruction)
      .then(() => loadInstructions())
      .catch(handleError)
      .finally(() => setIsAdding(false));
  };

  const deleteInstruction = (instruction: WorkInstruction) => {
    api.deleteAssociation(instruction.id)
      .then(() => {
        setPaginatedInstructions({
          ...paginatedInstructions,
          totalResults: paginatedInstructions.totalResults - 1,
          results: paginatedInstructions.results.filter(result => result !== instruction)
        });
      })
      .catch(handleError);
  };

  const previewInstruction = (instruction: WorkInstruction) => {
    setPreviewUrl(instruction.documentUrl);
    setIsPreviewOpen(true);
  };

  return (
    <RaisedTile>
      <PageHeader icon="clipboard list" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
      <LoadingDimmer active={false} />

      <WorkInstructionTableControls
        onSearch={_.debounce(performSearch, 800)}
        totalResults={totalResultsCount}
        searchResults={paginatedInstructions.totalResults}
      >
        <AddWorkInstructionModal
          open={isAddModalVisible}
          closeOnDimmerClick={false}
          trigger={<Button disabled={isAdding} loading={isAdding} primary content={formatMessage(m.addButton)} onClick={openAddModal} />}
          onAdd={addSingleInstruction}
          onClose={closeAddModal}
          onCancel={closeAddModal}
        />
      </WorkInstructionTableControls>

      <WorkInstructionTable
        loading={isLoading}
        data={paginatedInstructions.results}
        onDelete={deleteInstruction}
        onPreview={previewInstruction}
      />

      <PdfModal
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        title="Test"
        url={previewUrl}
      />

      {!isLoading && paginatedInstructions.results.length <= 0 &&
                <NoContentPlaceholder
                  icon="file pdf outline"
                  title={formatMessage(m.emptyContentTitle)}
                  subtitle={formatMessage(m.emptyContentMessage)}
                />
      }
    </RaisedTile>
  );
};
