import { Grid } from '@mui/material';
import { HugeButton } from './HugeButton';

interface DigitButtonProps {
  digit: number;
  onClick: (digit: number) => void;
}

export const DigitButton = ({ digit, onClick }: DigitButtonProps) => {
  return (
    <Grid item xs={4}>
      <HugeButton onClick={() => onClick(digit)} variant="outlined">
        {digit}
      </HugeButton>
    </Grid>
  );
};
