import { ListItemText, Paper } from '@mui/material';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro';
import { DataTable } from 'components/common/DataTable';
import { FragmentType, graphql, useFragment } from 'gql';
import { AccountType } from 'gql/graphql';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  searchTerm?: string;
  onClick: (userId: string) => void;
  users: FragmentType<typeof UsersTableQueryFragment>
}

export type UsersTableProps =
    & OwnProps;

export const UsersTableQueryFragment = graphql(`
  fragment UsersTableQueryFragment on Query {
    users {
      id
      accountType
      displayName
      userName
      employeeNumber
      storedCredentials {
        regDate
      }
    }
  }
`);

export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const { formatMessage } = useIntl();

  const searchFilters: GridFilterModel = useMemo(() => ({
    items: [
      { id: 1, field: 'displayName', operator: 'contains', value: props.searchTerm },
      { id: 2, field: 'userName', operator: 'contains', value: props.searchTerm },
      { id: 3, field: 'employeeNumber', operator: 'contains', value: props.searchTerm },
    ],
    logicOperator: GridLogicOperator.Or,
  }), [props.searchTerm]);

  const data = useFragment(UsersTableQueryFragment, props.users);
  const userRoles = {
    [AccountType.Administrator]: formatMessage({ id: 'Administrator' }),
    [AccountType.FactorySupervisor]: formatMessage({ id: 'Supervisor' }),
    [AccountType.FactoryWorker]: formatMessage({ id: 'Worker' }),
  };

  return (
    <Paper>
      <DataTable
        rows={data.users}
        filterModel={searchFilters}
        onRowClick={(params) => props.onClick(params.row.id)}
        disableColumnMenu
        disableColumnFilter
        disableColumnReorder
        ignoreDiacritics
        getRowId={(row) => row.id ?? 0}
        columns={[
          {
            field: 'displayName',
            headerName: formatMessage({ id: 'User' }),
            flex: 1,
            renderCell: ({ row, value }) => (
              <ListItemText primary={value} secondary={userRoles[row.accountType] ?? formatMessage({ id: 'Unknown' })} />
            ),
          },
          {
            field: 'userName',
            flex: 1,
            headerName: formatMessage({ id: 'Email' })
          },
          {
            field: 'employeeNumber',
            flex: 1,
            headerName: formatMessage({ id: 'Employee number' })
          },
          {
            field: 'hasRegisteredAuthenticator',
            type: 'boolean',
            headerName: formatMessage({ id: 'Security key' }),
            valueGetter: (data) => data.row.storedCredentials.length > 0,
            renderCell: ({ row, value }) => (
              value ? formatMessage({ id: 'Enabled' }) : null
            )
          }
        ]}
        sx={{
          '.MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          }
        }}
      />
    </Paper>
  );
};
