import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import styles from './SpecialNotesWarning.module.css';

interface OwnProps {
    style?: React.CSSProperties;
    onClick?: () => void;
}

export type SpecialNotesWarningProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
  title: { id: 'SpecialNotesWarning.title', defaultMessage: 'This order has special notes' },
  subtitle: { id: 'SpecialNotesWarning.subtitle', defaultMessage: 'View the applicable notes' }
});

class SpecialNotesWarning extends React.Component<SpecialNotesWarningProps> {
  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <div className={styles.container} onClick={this.props.onClick} style={this.props.style}>
        <Icon name="exclamation circle" color="yellow" size="large" />
        <div>
          <strong className={styles.title}>{formatMessage(m.title)}</strong>
          <a className={styles.subtitle}>{formatMessage(m.subtitle)}</a>
        </div>
      </div>
    );
  }
}

const intlComponent = injectIntl(SpecialNotesWarning);
export { intlComponent as SpecialNotesWarning };