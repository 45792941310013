import { Container, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PrioritizableSalesRow } from './PrioritizableSalesRow';
import { SalesOrdersTable } from './SalesOrdersTable';
import { EmptySalesOrdersTableContent } from './EmptySalesOrdersTableContent';
import { LoadingSalesOrdersTableContent } from './LoadingSalesOrdersTableContent';
import { SalesOrder } from '../../../schedule';
import { Lot } from '../../lots/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { LotsTable } from './LotsTable';
import { PrioritizableLotRow } from './PrioritizableLotRow';
import { EmptyLotsTableContent } from './EmptyLotsTableContent';
import moment from 'moment';


interface SalesOrdersContainerProps {
  productionDate: Date;
}

const m = defineMessages({
  salesOrdersTitle: { id: 'PlanningDataContainer.salesOrdersTitle', defaultMessage: 'Sales Orders' },
  lotsTitle: { id: 'PlanningDataContainer.lotsTitle', defaultMessage: 'Lots' },
});

export interface PlanningData {
  salesOrders: SalesOrder[],
  lots: Lot[]
}

export const PlanningDataContainer: React.FC<SalesOrdersContainerProps> = (props) => {
  const { formatMessage } = useIntl();

  const planningDataQuery = useQuery<PlanningData>([`planning-data-${moment(props.productionDate).startOf('day').toISOString()}`], async () => {
    const response = await axios.get<PlanningData>(`/api/planning/${moment(props.productionDate).startOf('day').toISOString()}`);
    return response.data;
  });

  return (
    <Container>
      <Stack gap={2}>
        <Typography variant='h5'>{formatMessage(m.salesOrdersTitle)}</Typography>
        <SalesOrdersTable>
          {planningDataQuery.isSuccess && planningDataQuery.data?.salesOrders.sort((a, b) => a.id - b.id).map((salesOrder) => <PrioritizableSalesRow key={salesOrder.id} salesOrder={salesOrder} />)}
          {planningDataQuery.isSuccess
            && planningDataQuery.data?.salesOrders.length === 0
            && <EmptySalesOrdersTableContent />}
          {planningDataQuery.isFetching && <LoadingSalesOrdersTableContent />}
        </SalesOrdersTable>

        <Typography variant='h5'>{formatMessage(m.lotsTitle)}</Typography>
        <LotsTable>
          {planningDataQuery.isSuccess && planningDataQuery.data?.lots.sort((a, b) => a.id - b.id).map((lot) => <PrioritizableLotRow key={lot.id} lot={lot} />)}
          {planningDataQuery.isSuccess
            && planningDataQuery.data?.lots.length === 0
            && <EmptyLotsTableContent />}
          {planningDataQuery.isFetching && <LoadingSalesOrdersTableContent />}
        </LotsTable>
      </Stack>
    </Container>
  );
};
