import React from 'react';
import moment from 'moment';
import { Header, Icon, List, Modal, ModalProps } from 'semantic-ui-react';
import { BulkImportJobProgress } from '../../../models';
import { defineMessages, useIntl } from 'react-intl';
import { JobWarningListItem } from './JobWarningListItem';
import { NoWarningsListItem } from './NoWarningsListItem';

interface OwnProps {
    job: BulkImportJobProgress | undefined;
}

export type JobDetailsModalProps =
    & ModalProps
    & OwnProps;

const m = defineMessages({
  title: { id: 'JobDetailsModal.title', defaultMessage: 'Job #{jobId}' },
  currentlyRunning: { id: 'JobDetailsModal.currentlyRunning', defaultMessage: 'Currently running' },
  startedOnHeader: { id: 'JobDetailsModal.startedOnHeader', defaultMessage: 'Started on' },
  completedOnHeader: { id: 'JobDetailsModal.completedOnHeader', defaultMessage: 'Completed on' },
  documentsCountHeader: { id: 'JobDetailsModal.documentsCountHeader', defaultMessage: 'Documents count' },
  documentsProcessedHeader: { id: 'JobDetailsModal.documentsProcessedHeader', defaultMessage: 'Documents processed' },
  associationsCountHeader: { id: 'JobDetailsModal.associationsCountHeader', defaultMessage: 'Associations count' },
  associationsProcessedHeader: { id: 'JobDetailsModal.associationsProcessedHeader', defaultMessage: 'Associations processed' },
  warningsHeader: { id: 'JobDetailsModal.warningsHeader', defaultMessage: 'Warnings' }
});

export const JobDetailsModal: React.FC<JobDetailsModalProps> = (props) => {
  const { formatMessage } = useIntl();
  const { job, ...modalProps } = props;

  if (job == null) {
    return <></>;
  }

  const startedOn = moment.utc(job.startedOn).local();
  const completedOn = job.completedOn != null ? moment.utc(job.completedOn).local() : undefined;

  return (
    <Modal {...modalProps}>
      <Modal.Header>
        <Header>
          {formatMessage(m.title, { jobId: job.jobId })}
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        <List divided relaxed="very">
          <List.Item>
            <List.Header>{formatMessage(m.startedOnHeader)}</List.Header>
            {startedOn.format('LLL')}
          </List.Item>

          <List.Item>
            <List.Header>{formatMessage(m.completedOnHeader)}</List.Header>
            {completedOn != null && completedOn.format('LLL')}
            {completedOn == null && formatMessage(m.currentlyRunning)}
          </List.Item>

          <List.Item>
            <List.Header>{formatMessage(m.documentsCountHeader)}</List.Header>
            {job.documentsCount}
          </List.Item>

          <List.Item>
            <List.Header>{formatMessage(m.documentsProcessedHeader)}</List.Header>
            {job.documentsProcessed}
          </List.Item>

          <List.Item>
            <List.Header>{formatMessage(m.associationsCountHeader)}</List.Header>
            {job.associationsCount}
          </List.Item>

          <List.Item>
            <List.Header>{formatMessage(m.associationsProcessedHeader)}</List.Header>
            {job.associationsProcessed}
          </List.Item>

          <List.Item>
            <List.Content>
              <List.Header>{formatMessage(m.warningsHeader)}</List.Header>
              <List.List className="ui relaxed">
                {props.job?.errors.map((error, index) => <JobWarningListItem key={index} item={error} />)}
                {props.job?.errors.length === 0 && <NoWarningsListItem />}
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Modal.Content>
    </Modal>
  );
};
