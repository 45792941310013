import React from 'react';
import { Box, Card, Divider, List, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import { LotManagementData, ProductionStatus } from '../../types';
import { ItemGroupListItem } from './ItemGroupListItem';
import axios from 'axios';

const m = defineMessages({
  itemsTitle: { id: 'ItemsList.itemsTitle', defaultMessage: 'Items' },
  itemsSubtitle: { id: 'ItemsList.itemsSubtitle', defaultMessage: 'Select one or more items to group into a lot' }
});

export const ItemsList: React.FC = () => {
  const { onSettledErrorHandler } = useApiErrorHandler();
  const { formatMessage } = useIntl();

  const queryData = useQuery<LotManagementData>(['lotManagementData'], async () =>
    (await axios.get<LotManagementData>('/api/lots/LotManagementData')).data,
  { onSettled: onSettledErrorHandler });

  const getItemsWithPendingOrders = useCallback(() => queryData.data ?
    _.uniq(Object.values(queryData.data.productionItems)
      .filter(p => p.lotId == null && p.productionStatus !== ProductionStatus.completed)
      .map(p => p.itemId))
      .map(itemId => queryData.data.items[itemId]) : [], [queryData]);

  return (
    <Box>
      <Typography variant="h5">{formatMessage(m.itemsTitle)}</Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {formatMessage(m.itemsSubtitle)}
      </Typography>

      <Card sx={{
        width: '400px',
        bgcolor: 'background.paper',
        overflow: 'auto',
      }}>
        <List
          dense
          component="div"
          role="list"
          sx={{ height: '600px', overflow: 'auto', padding: 0 }}
        >
          {queryData && getItemsWithPendingOrders().map((item) => (
            <Box key={item.id}>
              <ItemGroupListItem item={item} />
              <Divider />
            </Box>
          ))}
        </List>
      </Card>
    </Box>
  );
};