import { defineMessages, MessageDescriptor } from 'react-intl';

import { OperatingMode, GroupingMode } from '../../production';

export const operatingModeMessages = defineMessages({
  unspecified: { id: 'operatingModeMessages.unspecified', defaultMessage: 'Unspecified' },
  perLot: { id: 'operatingModeMessages.perLot', defaultMessage: 'Per lot' },
  perProductionOrder: { id: 'operatingModeMessages.perProductionOrder', defaultMessage: 'Per production order' }
});

export const getOperatingModeDescription = (operatingMode: OperatingMode): MessageDescriptor => {
  const operatingModeMap: Map<OperatingMode, MessageDescriptor> = new Map([
    [OperatingMode.perLot, operatingModeMessages.perLot],
    [OperatingMode.perProductionOrder, operatingModeMessages.perProductionOrder]
  ]);

  return operatingModeMap.get(operatingMode) || operatingModeMessages.unspecified;
};

export const groupingModeMessages = defineMessages({
  none: { id: 'groupingModeMessages.none', defaultMessage: 'By default' },
  byProductionUnit: { id: 'groupingModeMessages.byProductionUnit', defaultMessage: 'By production unit' }
});

export const getGroupingModeDescription = (groupingMode: GroupingMode): MessageDescriptor => {
  const groupingModeMap: Map<GroupingMode, MessageDescriptor> = new Map([
    [GroupingMode.none, groupingModeMessages.none],
    [GroupingMode.byProductionUnit, groupingModeMessages.byProductionUnit]
  ]);

  return groupingModeMap.get(groupingMode) || groupingModeMessages.none;
};
