import * as React from 'react';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { UserPresenceCoin } from '../../../../components/presences/UserPresenceCoin/UserPresenceCoin';
import { Dropdown } from 'semantic-ui-react';
import { User } from '../../../../models';

interface OwnProps {
    user: User;
    onDisconnect: () => void;
}

export type LocalCollaborativeUserProps =
    & OwnProps
    & WrappedComponentProps;

const m = defineMessages({
  disconnect: { id: 'LocalCollaborativeUser.disconnect', defaultMessage: 'Sign out' }
});

class LocalCollaborativeUser extends React.Component<LocalCollaborativeUserProps> {
  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <Dropdown
        trigger={<UserPresenceCoin displayName={this.props.user.displayName} />}
        icon={null}
        item
      >
        <Dropdown.Menu>
          <Dropdown.Item icon="sign out" text={formatMessage(m.disconnect)} onClick={this.props.onDisconnect} />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const intlComponent = injectIntl(LocalCollaborativeUser);
export { intlComponent as LocalCollaborativeUser };
