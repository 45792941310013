import { Button, ButtonProps, styled } from '@mui/material';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  fontFamily: 'Segoe UI, sans-serif',
  fontSize: '15px',
  fontWeight: 600,
  height: '41px',
  padding: '0 12px',
  display: 'inline-flex',
  alignItems: 'center',
  textTransform: 'none',
  backgroundColor: '#00b5ad',
  border: 'solid 1px #00b5ad',
  borderRadius: 0,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderColor: '#8c8c8c',
    color: '#5e5e5e'
  },
  '&:focus': {
    outline: 'none',
  },
  '.logo': {
    marginRight: '12px',
  }
}));