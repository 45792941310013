import { Container, Stack } from '@mui/material';
import React from 'react';

export const ErrorLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <Container maxWidth="sm" sx={{ height: '100vh' }}>
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh', padding: 4 }}>
        {props.children}
      </Stack>
    </Container>
  );
};