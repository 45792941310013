import AddBoxIcon from '@mui/icons-material/AddBox';
import { Button } from '@mui/material';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AddOrUpdateLotDrawer } from './AddOrUpdateLotDrawer';
import React from 'react';

const m = defineMessages({
  title: { id: 'LotCreationButton.title', defaultMessage: 'Add Lot' },
});


export const LotCreationButton = () => {

  const { formatMessage } = useIntl();

  const [isLotDrawerOpen, setIsLotDrawerOpen] = useState(false);

  return (
    <>
      <Button sx={{ marginLeft: 2, marginBottom: 2, marginTop: 2, }} variant='contained' onClick={() => setIsLotDrawerOpen(true)}>
        {formatMessage(m.title)}
      </Button>

      <AddOrUpdateLotDrawer lot={undefined} onClose={() => setIsLotDrawerOpen(false)} isOpen={isLotDrawerOpen} />
    </>
  );
};