import React from 'react';
import { RoadmapField } from '../../../types';
import { TableCell } from '@mui/material';

interface OwnProps {
    roadmapField: RoadmapField;
}

export type RoadmapFieldTableHeaderProps =
    & OwnProps;

export const RoadmapFieldTableHeader: React.FC<RoadmapFieldTableHeaderProps> = (props) => {
  const { roadmapField } = props;

  return (
    <TableCell>
      {roadmapField.name}
    </TableCell>
  );
};
