/* global RequestInit */
import moment from 'moment';

const multiPartConfiguration = (): RequestInit => ({
  credentials: 'same-origin',
  headers: {
    // For multipart configurations, the content type needs to remain blank, as it will be auto generated by the browser.
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    'Accept-Language': moment.locale()
  }
});

const baseConfiguration = (): RequestInit => ({
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    'Accept-Language': moment.locale()
  }
});

const isDevelopment = process.env.NODE_ENV == null || process.env.NODE_ENV === 'development';

export async function get<TResponse> (requestUrl: string): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(
    requestUrl,
    {
      ...baseConfiguration(),
      method: 'GET'
    }
  );

  return result;
}

export async function post<TResponse> (requestUrl: string, body: any | undefined): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(
    requestUrl,
    {
      ...baseConfiguration(),
      method: 'POST',
      body: body != null ? JSON.stringify(body) : undefined
    });

  return result;
}

export async function postMultiPart<TResponse> (requestUrl: string, body: any): Promise<TResponse> {
  const bodyFormData = Object.keys(body).reduce((formData, key) => {
    const value = body[key];
    const isFileArray = value instanceof Array && value.every(x => x instanceof File);

    if (isFileArray) {
      // We can't pass directly file arrays to the form data. Somehow, we have to append them
      // individually to the same key.
      value.forEach((x:File) => formData.append(key, x));
    } else {
      // We need to make some slight adjustments to propagate the value to the backend.
      // 1. Dates should be sent as ISO strings
      // 2. Null values should be sent as empty strings
      const formattedValue = value instanceof Date ? value.toISOString() : value || '';
      formData.append(key, formattedValue);
    }

    return formData;
  }, new FormData());

  const result = await fetchRequest<TResponse>(
    requestUrl,
    {
      ...multiPartConfiguration(),
      method: 'POST',
      body: bodyFormData
    });

  return result;
}

export async function httpDelete<TResponse> (requestUrl: string): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(
    requestUrl,
    {
      ...baseConfiguration(),
      method: 'DELETE'
    }
  );

  return result;
}

export async function put<TResponse> (requestUrl: string, body: any | undefined): Promise<TResponse> {
  const result = await fetchRequest<TResponse>(
    requestUrl,
    {
      ...baseConfiguration(),
      method: 'PUT',
      body: body != null ? JSON.stringify(body) : undefined
    });

  return result;
}

export async function fetchRequest<TResponse> (requestUrl: string, requestConfiguration: RequestInit): Promise<TResponse> {
  const response = await fetch(requestUrl, requestConfiguration);

  if (response.status >= 200 && response.status < 300) {
    return response.json() as Promise<TResponse>;
  }

  let errorMessage = `${response.status} ${response.statusText}`;
  try {
    const error = await response.json();

    if (error && error.UserMessage) {
      errorMessage = (isDevelopment ? error.UserMessage + ' DEBUG: ' + error.DebugMessage : error.UserMessage);
    }
    else if (error && error.title) {
      errorMessage = error.title;
    }
  } catch {
    // response did not contain a json serialized error
  }

  return Promise.reject(new ApiError(response.status, errorMessage));
}

export class ApiError extends Error {
  public readonly statusCode: number;
  constructor (statusCode: number, message?: string | undefined) {
    super(message);
    this.statusCode = statusCode;
  }
}
