import _ from 'lodash';
import { ProductionScheduleActionTypes, ProductionScheduleState, LOAD_PRODUCTION_SCHEDULE_REQUEST, LOAD_PRODUCTION_SCHEDULE_SUCCESS, LOAD_PRODUCTION_SCHEDULE_FAILURE } from './types';
import { Reducer } from 'redux';

const initialState: ProductionScheduleState = {
  byDate: {},
  allDates: [],
  loading: []
};

const productionScheduleReducer: Reducer<ProductionScheduleState, ProductionScheduleActionTypes> = (state = initialState, action): ProductionScheduleState => {
  switch (action.type) {
  case LOAD_PRODUCTION_SCHEDULE_REQUEST: {
    const forDate = action.payload.forDate.toISOString();

    return {
      ...state,
      loading: _.union(state.loading, [forDate])
    };
  }

  case LOAD_PRODUCTION_SCHEDULE_SUCCESS: {
    const forDate = action.payload.forDate.toISOString();
    const productionItemIds = Object.keys(action.payload.entities.productionItems).map(x => Number(x));

    return {
      ...state,
      byDate: {
        ...state.byDate,
        [forDate]: productionItemIds
      },
      loading: state.loading.filter(x => x !== forDate)
    };
  }

  case LOAD_PRODUCTION_SCHEDULE_FAILURE: {
    const forDate = action.payload.forDate.toISOString();

    return {
      ...state,
      loading: state.loading.filter(x => x !== forDate)
    };
  }

  default:
    return state;
  }
};

export { productionScheduleReducer };
