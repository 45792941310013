import _ from 'lodash';
import { Reducer } from 'redux';

import {
  COST_CENTERS_CREATE_SUCCESS,
  COST_CENTERS_LOAD_ALL,
  COST_CENTERS_LOAD_ALL_FAILURE,
  COST_CENTERS_LOAD_ALL_SUCCESS,
  COST_CENTERS_UPDATE_SUCCESS,
  CostCentersActionTypes,
  CostCentersState,
  COST_CENTERS_LOAD_OVERVIEW,
  COST_CENTERS_LOAD_OVERVIEW_FAILURE,
  COST_CENTERS_LOAD_OVERVIEW_SUCCESS
} from './types';

const initialState: CostCentersState = {
  isLoading: false,
  byId: {},
  allIds: [],
  overviews: {
    byId: {},
    loadingIds: []
  }
};

const costCentersReducer: Reducer<CostCentersState, CostCentersActionTypes> = (state = initialState, action) => {
  switch (action.type) {
  case COST_CENTERS_LOAD_ALL: {
    return {
      ...state,
      isLoading: true
    };
  }

  case COST_CENTERS_LOAD_ALL_SUCCESS: {
    const costCenterIds = action.payload.map(x => x.id);
    const costCenters = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));

    return {
      ...state,
      isLoading: false,
      byId: costCenters,
      allIds: costCenterIds
    };
  }

  case COST_CENTERS_LOAD_ALL_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  case COST_CENTERS_CREATE_SUCCESS: {
    const costCenter = action.payload;
    return {
      ...state,
      byId: {
        ...state.byId,
        [costCenter.id]: costCenter
      },
      allIds: _.union(state.allIds, [costCenter.id])
    };
  }

  case COST_CENTERS_UPDATE_SUCCESS: {
    const costCenter = action.payload;
    return {
      ...state,
      byId: {
        ...state.byId,
        [costCenter.id]: costCenter
      },
      allIds: _.union(state.allIds, [costCenter.id])
    };
  }

  case COST_CENTERS_LOAD_OVERVIEW: {
    return {
      ...state,
      overviews: {
        ...state.overviews,
        loadingIds: _.union(state.overviews.loadingIds, [action.payload.costCenterId])
      }
    };
  }

  case COST_CENTERS_LOAD_OVERVIEW_SUCCESS: {
    return {
      ...state,
      overviews: {
        ...state.overviews,
        byId: {
          ...state.overviews.byId,
          [action.payload.costCenterId]: action.payload.overviews
        },
        loadingIds: state.overviews.loadingIds.filter(x => x !== action.payload.costCenterId)
      }
    };
  }

  case COST_CENTERS_LOAD_OVERVIEW_FAILURE: {
    return {
      ...state,
      overviews: {
        ...state.overviews,
        loadingIds: state.overviews.loadingIds.filter(x => x !== action.payload.costCenterId)
      }
    };
  }

  default:
    return state;
  }
};

export { costCentersReducer };
