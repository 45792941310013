import { graphql } from 'gql';
import { useGraphQL } from 'gql/useGraphQL';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Search, SearchProps, SearchResultProps } from 'semantic-ui-react';

const SalesOrderSearchControlQuery = graphql(`
  query SalesOrderSearchControlQuery(
    $filter: SalesOrderGraphQLTypeFilterInput
  ) {
    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {
      totalCount
      items {
        id
        salesOrderNumber
        customerFullName
        customerCompanyName
      }
    }
  }
`);

interface SalesOrderSearchControlProps extends SearchProps {
  onSelected: (salesOrderId: number) => void;
  onCleared: () => void;
}

export const SalesOrderSearchControl = ({
  onSelected,
  onCleared,
  ...searchProps
}: SalesOrderSearchControlProps) => {
  const { formatMessage } = useIntl();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const filter = {
    or: [
      { salesOrderNumber: { contains: searchTerm } },
      { customerFullName: { contains: searchTerm } },
      { customerCompanyName: { contains: searchTerm } },
    ] as any,
  };

  const { data: salesOrdersData, isFetching } = useGraphQL(
    SalesOrderSearchControlQuery,
    {
      filter: searchTerm ? filter : null,
    }
  );

  const formattedResults: SearchResultProps[] = (
    salesOrdersData?.salesOrders?.items || []
  ).map((x) => ({
    id: x.id ?? '',
    title: x.salesOrderNumber ?? '',
    description: [x.customerCompanyName, x.customerFullName]
      .filter(Boolean)
      .join(', '),
    salesOrder: x,
  }));

  const handleSearchChange = (
    _event: React.MouseEvent<HTMLElement>,
    data: SearchProps
  ) => {
    if (data.value != null && data.value !== '') {
      setSearchTerm(data.value);
    } else {
      onCleared();
    }
  };

  const renderSearchResult = (searchResult: SearchResultProps) => {
    return (
      <div>
        <strong>{searchResult.title}</strong>
        <span style={{ marginLeft: '5px' }}>{searchResult.description}</span>
      </div>
    );
  };

  return (
    <Search
      fluid={true}
      loading={isFetching}
      onSearchChange={debounce(handleSearchChange, 500)}
      onResultSelect={(_, data) => onSelected(data.result.salesOrder.id)}
      results={formattedResults ?? []}
      resultRenderer={renderSearchResult}
      noResultsMessage={formatMessage({
        id: 'SalesOrderSearchControl.NoSalesOrderFound',
        defaultMessage: 'No sales order found',
      })}
      {...searchProps}
    />
  );
};
