import React, { useState } from 'react';
import { Header, Button, InputOnChangeData } from 'semantic-ui-react';
import { ClearableInput } from '../../../../../components';
import { defineMessages, useIntl } from 'react-intl';

const m = defineMessages({
  allCount: { id: 'WorkInstructionTableControls.allCount', defaultMessage: '{total} work instructions' },
  searchCount: { id: 'WorkInstructionTableControls.searchCount', defaultMessage: '{total} results' },
  searchPlaceholder: { id: 'WorkInstructionTableControls.searchPlaceholder', defaultMessage: 'Search' }
});

export interface WorkInstructionTableControlsProps {
    totalResults: number;
    searchResults: number;
    onSearch: (searchTerm: string) => void;
}

export const WorkInstructionTableControls: React.FC<WorkInstructionTableControlsProps> = (props) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const clearSearch = () => {
    setSearchTerm('');
    props.onSearch('');
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSearchTerm(data.value);
    props.onSearch(data.value);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1em' }}>
        <Header size="small" style={{ margin: 0, flexGrow: 1 }}>
          <Header.Content>{formatMessage(m.allCount, { total: props.totalResults })}</Header.Content>
          <Header.Subheader>
            {searchTerm !== ''
              ? <React.Fragment>{formatMessage(m.searchCount, { total: props.searchResults })}</React.Fragment>
              : <React.Fragment>&nbsp;</React.Fragment>
            }
          </Header.Subheader>
        </Header>
        {props.children}
      </div>

      <ClearableInput
        fluid
        placeholder={formatMessage(m.searchPlaceholder)}
        value={searchTerm}
        onChange={handleSearch}
        onClear={clearSearch}
      />
    </>
  );
};
