import { Stack, Typography } from '@mui/material';
import { PageHeader, RaisedTile } from 'components';
import sortBy from 'lodash/sortBy';
import { getSalesOrders } from 'modules/production/selectors';
import { SalesOrderButton } from 'modules/production/workstation/views/components/SalesOrderButton';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export const SalesOrderSearchPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const salesOrderNumberSearch = searchParams.get('salesOrderNumber');
  const allSalesOrder = useSelector(getSalesOrders);

  if (!salesOrderNumberSearch) {
    return null;
  }

  const matchingSalesOrder = salesOrderNumberSearch
    ? allSalesOrder.filter((salesOrder) =>
      salesOrder.salesOrderNumber.includes(salesOrderNumberSearch)
    )
    : [];

  const sortedSalesOrder = sortBy(
    matchingSalesOrder,
    (salesOrder) => salesOrder.salesOrderNumber
  );

  if (sortedSalesOrder.length === 1) {
    history.replace(`/sales-order/${sortedSalesOrder[0].id}/production-orders/list`);
  }

  return (
    <RaisedTile>
      <Stack gap={3}>
        <PageHeader
          title={formatMessage(
            {
              id: 'SalesOrderSearchPage.Title',
              defaultMessage: 'Search result for {search}',
            },
            { search: salesOrderNumberSearch ?? '' }
          )}
          centered
        />

        {sortedSalesOrder.length ? (
          <Stack gap={2}>
            {sortedSalesOrder.map((salesSorder) => (
              <SalesOrderButton key={salesSorder.id} salesOrder={salesSorder} />
            ))}
          </Stack>
        ) : (
          <Typography variant="h5" alignSelf="center">
            {formatMessage({
              id: 'SalesOrderSearchPage.NoSalesOrderFound',
              defaultMessage: 'No sales order found',
            })}
          </Typography>
        )}
      </Stack>
    </RaisedTile>
  );
};
