import { LoadingButton } from '@mui/lab';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import useApiErrorHandler from '../../../../../utils/ApiErrorHandler';
import {
  Lot,
  LotManagementData,
  LotManagementLot,
  LotManagementProductionItem,
} from '../../types';
import { DeleteLotButton } from './DeleteLotButton';

export interface AddOrUpdateLotDrawerProps {
  lot?: LotManagementLot;
  productionItems?: LotManagementProductionItem[];
  onClose: () => void;
  isOpen: boolean;
}

const m = defineMessages({
  titleAdd: { id: 'AddOrUpdateLotDrawer.title.add', defaultMessage: 'Add Lot' },
  titleUpdate: {
    id: 'AddOrUpdateLotDrawer.title.edit',
    defaultMessage: 'Update Lot',
  },
  add: { id: 'AddOrUpdateLotDrawer.add', defaultMessage: 'Add' },
  update: { id: 'AddOrUpdateLotDrawer.update', defaultMessage: 'Update' },
  lotName: {
    id: 'AddOrUpdateLotDrawer.lotName',
    defaultMessage: 'Lot identifier',
  },
});

interface LotForm {
  id: number | undefined;
  name: string;
}

export const AddOrUpdateLotDrawer = (props: AddOrUpdateLotDrawerProps) => {
  const { handleSubmit, control } = useForm<LotForm>({
    defaultValues: { id: props.lot?.id },
  });
  const queryClient = useQueryClient();
  const { onSettledErrorHandler } = useApiErrorHandler();

  const createLotMutation = useMutation(
    (data: LotForm) => axios.post<Lot>('/api/lots', data),
    {
      onSettled: onSettledErrorHandler,
      onSuccess: (response, variables) => {
        const queryData = queryClient.getQueryData<LotManagementData>([
          'lotManagementData',
        ]);
        if (queryData) {
          queryClient.setQueryData(['lotManagementData'], {
            ...queryData,
            lots: {
              ...queryData.lots,
              [response.data.id]: {
                id: response.data.id,
                name: variables.name,
              },
            },
          });
        }

        props.onClose();
      },
    }
  );

  const updateLotMutation = useMutation(
    (data: LotForm) => {
      return axios.post<Lot>(`/api/lots/${data.id}`, data);
    },
    {
      onSettled: onSettledErrorHandler,
      onSuccess: (response, variables) => {
        const queryData = queryClient.getQueryData<LotManagementData>([
          'lotManagementData',
        ]);
        if (queryData && variables.id) {
          queryClient.setQueryData(['lotManagementData'], {
            ...queryData,
            lots: {
              ...queryData.lots,
              [variables.id]: { name: variables.name },
            },
          });
        }

        props.onClose();
      },
    }
  );

  const { formatMessage } = useIntl();

  return (
    <Drawer anchor="right" open={props.isOpen} onClose={() => props.onClose()}>
      <form
        onSubmit={handleSubmit((data) =>
          props.lot
            ? updateLotMutation.mutate(data)
            : createLotMutation.mutate(data)
        )}
      >
        <List sx={{ width: '400px' }}>
          <ListItem>
            <Typography variant="h4">
              {props.lot
                ? formatMessage(m.titleUpdate)
                : formatMessage(m.titleAdd)}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem>
            <Controller
              name="name"
              rules={{
                required: true,
              }}
              defaultValue={props.lot?.name}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  error={fieldState.error != null}
                  placeholder={formatMessage(m.lotName)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </ListItem>

          <Divider />
          <ListItem>
            <Stack
              flex={1}
              direction="row"
              gap={2}
              justifyContent="space-between"
            >
              {props.lot && props.productionItems && (
                <DeleteLotButton
                  lot={props.lot}
                  productionItems={props.productionItems}
                />
              )}
              <Stack flex={1} direction="row" justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={
                    createLotMutation.isLoading || updateLotMutation.isLoading
                  }
                >
                  {props.lot ? formatMessage(m.update) : formatMessage(m.add)}
                </LoadingButton>
              </Stack>
            </Stack>
          </ListItem>
        </List>
      </form>
    </Drawer>
  );
};
