import * as React from 'react';
import { defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Header, Icon, Table } from 'semantic-ui-react';

import { PageHeader } from '../../../../components/layout';
import { commonMessages } from '../../../../constants';
import { ValidationResult } from '../../../../models';
import { CostCenterEdit } from '../../../../models/cost-center/CostCenterEdit';
import { ApplicationState } from '../../../../store';
import { CostCenter, getAllCostCenters, getProductionSortingDescription, Unit } from '../../../production';
import * as CostCenterActions from '../actions';
import { AddOrUpdateCostCenterModal } from './components/AddOrUpdateCostCenterModal';
import { RaisedTile } from '../../../../components';

interface StateProps {
    isConfirmDialogOpen: boolean;
    isAddCostCenterDialogOpen: boolean;
    editingCostCenter?: CostCenter;
}

interface DispatchProps {
    costCenterActions: typeof CostCenterActions;
}

interface StoreProps {
    costCenters: CostCenter[];
    productionUnitsById: { [unitId: number]: Unit };
}

export type CostCentersConfigurationPageProps =
    & StoreProps
    & DispatchProps
    & WrappedComponentProps;

const m = defineMessages({
  title: { id: 'CostCentersConfigurationPage.title', defaultMessage: 'Configure Cost Centers' },
  subtitle: { id: 'CostCentersConfigurationPage.subtitle', defaultMessage: 'View and configure cost centers.' },
  costCenterHeader: { id: 'CostCentersConfigurationPage.costCenter', defaultMessage: 'Cost Center' },
  productionUnitsHeader: { id: 'CostCentersConfigurationPage.productionUnits', defaultMessage: 'Production Units' },
  productionSortingHeader: { id: 'CostCentersConfigurationPage.productionSortingHeader', defaultMessage: 'Production sorting' },
  confirmRemoveMessage: { id: 'CostCentersConfigurationPage.confirmRemoveMessage', defaultMessage: 'Are you sure you want to deactivate this cost center?' },
  addCostCenter: { id: 'CostCentersConfigurationPage.addCostCenterButton', defaultMessage: 'Add Cost Center' }
});

class CostCentersConfigurationPage extends React.Component<CostCentersConfigurationPageProps, StateProps> {
  constructor (props: CostCentersConfigurationPageProps) {
    super(props);

    this.state = {
      isConfirmDialogOpen: false,
      isAddCostCenterDialogOpen: false
    };
  }

  public componentDidMount () {
    this.props.costCenterActions.loadAll();
  }

  public render () {
    const { formatMessage } = this.props.intl;

    return (
      <RaisedTile>
        <>
          <PageHeader icon="factory" title={formatMessage(m.title)} subtitle={formatMessage(m.subtitle)} />
          <Table compact={true} celled={true} striped={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{formatMessage(m.costCenterHeader)}</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.productionUnitsHeader)}</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.productionSortingHeader)}</Table.HeaderCell>
                <Table.HeaderCell collapsing={true} />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.props.costCenters.map(costCenter => {
                return (
                  <Table.Row key={costCenter.id}>
                    <Table.Cell>
                      <div>
                        <Header as="h4" image>
                          <Icon name="factory" />
                          <Header.Content>
                            {costCenter.name}
                          </Header.Content>
                        </Header>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {this.renderProductionUnits(costCenter.productionUnits)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatMessage(getProductionSortingDescription(costCenter.sortBy))}
                    </Table.Cell>
                    <Table.Cell>
                      <Button fluid={true} onClick={() => this.setState({ editingCostCenter: costCenter, isAddCostCenterDialogOpen: true })}>{formatMessage(commonMessages.modify)}</Button>
                    </Table.Cell>
                  </Table.Row>);
              })}
            </Table.Body>

            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell width="3">
                  <AddOrUpdateCostCenterModal
                    costCenter={this.state.editingCostCenter}
                    open={this.state.isAddCostCenterDialogOpen}
                    trigger={<Button fluid primary content={formatMessage(m.addCostCenter)} onClick={() => this.setState({ editingCostCenter: undefined, isAddCostCenterDialogOpen: true })} />}
                    size="tiny"
                    onClose={() => this.setState({ isAddCostCenterDialogOpen: false })}
                    onCancel={() => this.setState({ isAddCostCenterDialogOpen: false })}
                    onConfirm={this.addCostCenter}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </RaisedTile >
    );
  }

  private renderProductionUnits = (productionUnitIds: number[]) => {
    return productionUnitIds
      .map(id => {
        const productionUnit = this.props.productionUnitsById[id];
        return productionUnit ? productionUnit.name : id;
      })
      .sort()
      .join(', ');
  };

  private addCostCenter = (costCenter: CostCenterEdit): ValidationResult => {
    const validationResult = costCenter.validate();

    if (validationResult.isValid) {
      if (costCenter.id === 0) {
        this.props.costCenterActions.create(costCenter);
      } else {
        this.props.costCenterActions.update(costCenter);
      }
      this.setState({ isAddCostCenterDialogOpen: false });
    }

    return validationResult;
  };
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
  return {
    costCenters: getAllCostCenters(state),
    productionUnitsById: state.production.units.byId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    costCenterActions: bindActionCreators(CostCenterActions, dispatch)
  };
};

const intlComponent = injectIntl(CostCentersConfigurationPage);
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(intlComponent);
export { connectedComponent as CostCentersConfigurationPage };
