import { Operation } from '../../production';

export const OPERATIONS_LOAD_ALL = 'OPERATIONS_LOAD_ALL';
export const OPERATIONS_LOAD_ALL_SUCCESS = 'OPERATIONS_LOAD_ALL_SUCCESS';
export const OPERATIONS_LOAD_ALL_FAILURE = 'OPERATIONS_LOAD_ALL_FAILURE';

export const OPERATIONS_UPDATE = 'OPERATIONS_UPDATE';
export const OPERATIONS_UPDATE_SUCCESS = 'OPERATIONS_UPDATE_SUCCESS';
export const OPERATIONS_UPDATE_FAILURE = 'OPERATIONS_UPDATE_FAILURE';

export interface LoadAllAction { type: typeof OPERATIONS_LOAD_ALL; payload: Record<string, never>; }
export interface LoadAllSuccessAction { type: typeof OPERATIONS_LOAD_ALL_SUCCESS; payload: Operation[]; }
export interface LoadAllFailureAction { type: typeof OPERATIONS_LOAD_ALL_FAILURE; payload: { error: Error }; }

export interface UpdateAction { type: typeof OPERATIONS_UPDATE; payload: Operation; }
export interface UpdateSuccessAction { type: typeof OPERATIONS_UPDATE_SUCCESS; payload: Operation; }
export interface UpdateFailureAction { type: typeof OPERATIONS_UPDATE_FAILURE; payload: { operationId: number, error: Error }; }

export type OperationsActionTypes =
    | LoadAllAction | LoadAllSuccessAction | LoadAllFailureAction
    | UpdateAction | UpdateSuccessAction | UpdateFailureAction;

export interface OperationsState {
    readonly isLoading: boolean;
    readonly byId: { [id: number]: Operation };
    readonly allIds: number[];
    readonly loadingIds: number[];
}
