import {
  LoadUsersPresence,
  LOAD_USERS_PRESENCE,
  LoadUsersPresenceSuccess,
  LOAD_USERS_PRESENCE_SUCCESS,
  UserPresence,
  LOAD_USERS_PRESENCE_FAILURE
} from './types';

export function loadUsersPresence (): LoadUsersPresence {
  return {
    type: LOAD_USERS_PRESENCE,
    payload: {}
  };
}

export function loadUsersPresenceSuccess (presences: UserPresence[]): LoadUsersPresenceSuccess {
  return {
    type: LOAD_USERS_PRESENCE_SUCCESS,
    payload: presences
  };
}

export function loadUsersPresenceFailure (error: Error) {
  return {
    type: LOAD_USERS_PRESENCE_FAILURE,
    payload: { error }
  };
}
