import React from 'react';
import { PageContentHeader } from '../../../../../../components';
import { ManufacturedItemOperation, Operation, ProductionItem } from '../../../../types';
import { useIntl, defineMessages } from 'react-intl';
import { Lot } from '../../../../lots/types';
import { Workstation } from 'modules/administration';
import { ProductionOrderRoadmapMiddleHeader } from './ProductionOrderRoadmapMiddleHeader';

interface OwnProps {
  lot: Lot | undefined;
  operation: Operation | undefined;
  operations: Operation[];
  workstations: Workstation[];
  manufacturedOperationItems: ManufacturedItemOperation[];
  productionOrder: ProductionItem | undefined;
}

export type ProductionOrderRoadmapHeaderProps = & OwnProps;

const m = defineMessages({
  lotTitle: { id: 'ProductionOrderRoadmapHeader.lotTitle', defaultMessage: '{name}' },
  productionOrderTitle: { id: 'ProductionOrderRoadmapHeader.productionOrderTitle', defaultMessage: 'Production order: {number}' }
});

export const ProductionOrderRoadmapHeader: React.FC<ProductionOrderRoadmapHeaderProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <PageContentHeader
      leftHeader={formatMessage(m.lotTitle, { name: props.lot?.name || '' })}
      middleHeader={<ProductionOrderRoadmapMiddleHeader {...props} />}
      rightHeader={formatMessage(m.productionOrderTitle, { number: props.productionOrder?.id || '' })}
    />
  );
};
