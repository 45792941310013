import { get } from '../../../utils/api';
import { WorkInstructionAssociation } from '../../administration/work-instructions';
import { Lot } from '../lots/types';
import { Item, ManufacturedItem, ManufacturedItemOperation, ProductionEntities, ProductionItem, ProductionNote, RoadmapEntities } from '../types';
import { Roadmap, RoadmapField } from './types';

const roadmapEndpoint = '/api/roadmap';

export async function loadRoadmapItemsForOperation (operationId: number, lotId?: number, productionItemId?: number): Promise<ProductionEntities> {
  let endpointUrl = `${roadmapEndpoint}/for-operation/${operationId}`;

  if (productionItemId != null) {
    endpointUrl += `?productionItemId=${productionItemId}`;
  } else if (lotId != null) {
    endpointUrl += `?lotId=${lotId}`;
  }

  const roadmap = await get<RoadmapEntities>(endpointUrl);
  const result: ProductionEntities = {
    items: Object.assign({}, ...roadmap.items.map((x: Item) => ({ [x.id]: x }))),
    lots: Object.assign({}, ...roadmap.lots.map((x: Lot) => ({ [x.id]: x }))),
    productionItems: Object.assign({}, ...roadmap.productionItems.map((x: ProductionItem) => ({ [x.id]: x }))),
    productionNotes: Object.assign({}, ...roadmap.productionNotes.map((x: ProductionNote) => ({ [x.id]: x }))),
    manufacturedItems: Object.assign({}, ...roadmap.manufacturedItems.map((x: ManufacturedItem) => ({ [x.id]: x }))),
    manufacturedItemOperations: Object.assign({}, ...roadmap.manufacturedItemOperations.map((x: ManufacturedItemOperation) => ({ [x.id]: x }))),
    workInstructions: Object.assign({}, ...roadmap.workInstructions.map((x: WorkInstructionAssociation) => ({ [x.id]: x }))),
    salesOrders: Object.assign({}, ...roadmap.salesOrders.map(salesOrder => ({ [salesOrder.id]: salesOrder }))),
  };

  return result;
}

export async function loadRoadmapItemsForDevice (deviceId: number, salesOrderId?: number, lotId?: number): Promise<ProductionEntities> {
  const endpointUrl = `${roadmapEndpoint}/for-device/${deviceId}?salesOrderId=${salesOrderId != null ? salesOrderId : ''}&lotId=${lotId != null ? lotId : ''}`;

  const roadmap = await get<RoadmapEntities>(endpointUrl);
  const result: ProductionEntities = {
    items: Object.assign({}, ...roadmap.items.map((x: Item) => ({ [x.id]: x }))),
    lots: Object.assign({}, ...roadmap.lots.map((x: Lot) => ({ [x.id]: x }))),
    productionItems: Object.assign({}, ...roadmap.productionItems.map((x: ProductionItem) => ({ [x.id]: x }))),
    productionNotes: Object.assign({}, ...roadmap.productionNotes.map((x: ProductionNote) => ({ [x.id]: x }))),
    manufacturedItems: Object.assign({}, ...roadmap.manufacturedItems.map((x: ManufacturedItem) => ({ [x.id]: x }))),
    manufacturedItemOperations: Object.assign({}, ...roadmap.manufacturedItemOperations.map((x: ManufacturedItemOperation) => ({ [x.id]: x }))),
    workInstructions: Object.assign({}, ...roadmap.workInstructions.map((x: WorkInstructionAssociation) => ({ [x.id]: x }))),
    salesOrders: Object.assign({}, ...roadmap.salesOrders.map(x => ({ [x.id]: x })))
  };

  return result;
}

export async function loadRoadmapTemplates (): Promise<Roadmap[]> {
  return await get<Roadmap[]>(roadmapEndpoint);
}

export async function loadRoadmapFields (): Promise<RoadmapField[]> {
  return await get<RoadmapField[]>(`${roadmapEndpoint}/fields`);
}

export async function loadDeviceRoadmapFields (deviceId: number): Promise<RoadmapField[]> {
  const fields = await get<RoadmapField[]>(`${roadmapEndpoint}/for-device/${deviceId}/fields`);
  return fields;
}
