import { AddLocalUserAction, ADD_LOCAL_USER, AddLocalUserFailureAction, ADD_LOCAL_USER_FAILURE, AddLocalUserSuccessAction, ADD_LOCAL_USER_SUCCESS, RemoveLocalUserAction, REMOVE_LOCAL_USER } from './types';
import { User } from '../../../models';

export function addLocalUser (user?: User): AddLocalUserAction {
  return {
    type: ADD_LOCAL_USER,
    payload: { user }
  };
}

export function addLocalUserSuccess (user: User): AddLocalUserSuccessAction {
  return {
    type: ADD_LOCAL_USER_SUCCESS,
    payload: { user }
  };
}

export function addLocalUserFailure (error: Error): AddLocalUserFailureAction {
  return {
    type: ADD_LOCAL_USER_FAILURE,
    payload: { error }
  };
}

export function removeLocalUser (userId: string): RemoveLocalUserAction {
  return {
    type: REMOVE_LOCAL_USER,
    payload: { userId }
  };
}
