import * as React from 'react';
import { Card, DropdownItemProps, Icon, Dropdown } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import styles from './TileHeader.module.css';

export interface TileHeaderStrictProps {
    title?: string;
    subtitle?: string | React.ReactElement;
    icon?: SemanticICONS;
    contextMenu?: DropdownItemProps[] | null;
}

export type TileHeaderProps =
    & TileHeaderStrictProps;

class TileHeader extends React.Component<TileHeaderProps> {
  public render () {
    return (
      <Card.Content className={styles.header}>
        <div className={styles.container}>
          {this.props.icon && <Icon className={styles.icon} name={this.props.icon} size="large" />}
          <div className={styles.text}>
            {this.props.title && <p className={styles.title} title={this.props.title}>{this.props.title}</p>}
            {this.props.subtitle && <span className={`${styles.subtitle} meta`}>{this.props.subtitle}</span>}
          </div>
          {this.renderContextMenu()}
        </div>
      </Card.Content>
    );
  }

  private renderContextMenu = () => {
    if (this.props.contextMenu == null || this.props.contextMenu.length <= 0) {
      return (null);
    }

    const menuIcon = <Icon name="ellipsis vertical" link size="large" />;

    return (
      <Dropdown icon={null} pointing="top right" trigger={menuIcon}>
        <Dropdown.Menu>
          {this.props.contextMenu.map((x, index) => <Dropdown.Item key={index} {...x} />)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

export { TileHeader };
