import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { SalesOrder } from '../../schedule';

const defaultRequestConfiguration: AxiosRequestConfig = {
  baseURL: '/api/prioritization',
  headers: {
    'Accept-Language': moment.locale()
  }
};

const getPrioritizedSalesOrders = async () => {
  const response = await axios.get<SalesOrder[]>('prioritized-sales-orders', defaultRequestConfiguration);
  return response.data;
};

const savePriorities = async (orderedSalesOrderIds: number[]) => {
  await axios.post('/', { orderedSalesOrderIds }, defaultRequestConfiguration);
};

const appendFirst = async (salesOrderIdsToAdd: number[]) => {
  await axios.post('/append-first', salesOrderIdsToAdd, defaultRequestConfiguration);
};

const appendLast = async (salesOrderIdsToAdd: number[]) => {
  await axios.post('/append-last', salesOrderIdsToAdd, defaultRequestConfiguration);
};

const PrioritizationApi = {
  getPrioritizedSalesOrders,
  savePriorities,
  appendFirst,
  appendLast,
};

export default PrioritizationApi;