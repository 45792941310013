import { Container, Paper, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import dayjs from 'dayjs';
import { ProductionItemForPrioritization } from 'modules/production/lots/types';
import { SalesOrder } from 'modules/schedule';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProductionItemsTable } from '../ProductionItemsTable';

interface OwnProps {
  salesOrder: SalesOrder;
}

export type SalesOrderSummaryProps =
  & OwnProps;

export const SalesOrderSummary: React.FC<SalesOrderSummaryProps> = (props) => {
  const { formatMessage } = useIntl();

  const { data, isFetching } = useQuery<ProductionItemForPrioritization[]>(
    [`sales-order-${props.salesOrder.id}/production-items`],
    async () => (await axios.get<ProductionItemForPrioritization[]>(`/api/planning/sales-orders/${props.salesOrder.id}/production-items`)).data,
  );

  return (
    <Container sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h6">
          {formatMessage({ id: 'SalesOrderSummary.title', defaultMessage: 'Order #{number}' }, { number: props.salesOrder.salesOrderNumber })}
        </Typography>

        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 1 }}>
          <Stack>
            <Typography variant="body2" color="textSecondary">{formatMessage({ id: 'Client information' })}</Typography>
            <Typography variant="body1">{props.salesOrder.clientFullName}</Typography>
            <Typography variant="body1">{props.salesOrder.clientCompany}</Typography>
          </Stack>

          <Stack>
            <Typography variant="body2" color="textSecondary" align="right">{formatMessage({ id: 'Order information' })}</Typography>
            {props.salesOrder.purchaseOrderNumber != null &&
              <Typography variant="body1" align="right">
                {formatMessage({ id: 'SalesOrderSummary.poNumber', defaultMessage: 'PO #{purchaseOrderNumber}' }, { purchaseOrderNumber: props.salesOrder.purchaseOrderNumber })}
              </Typography>
            }
            <Typography variant="body1" align="right">
              {formatMessage({ id: 'SalesOrderSummary.orderedOn', defaultMessage: 'Ordered on {date}' }, { date: dayjs(props.salesOrder.orderedOn).format('ll') })}
            </Typography>
          </Stack>
        </Stack>

        <ProductionItemsTable
          loading={isFetching}
          productionItems={data ?? []}
          emptyTitle={formatMessage({ id: 'Empty order' })}
          emptySubtitle={formatMessage({ id: 'There are no products assigned to this order.' })}
        />
      </Paper>
    </Container>
  );
};