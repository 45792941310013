import { Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RoadmapItem } from '../../../../workstation/types';
import { TechnicalInstructionsButton } from '../../../../workstation/views/components/TechnicalInstructionsButton';
import { RoadmapField } from '../../../types';
interface OwnProps {
    operationId: number;
    roadmapItem: RoadmapItem;
    roadmapFields: RoadmapField[];
    productionOrderId?: number;
    isSelected: boolean;
    onUpdateQuantityClicked: () => void;
}

const m = defineMessages({
  update: { id: 'RoadmapExecutionTableRow.defaultButtonText', defaultMessage: 'Update' }
});

export type RoadmapExecutionTableRowProps =
    & OwnProps;

export const RoadmapExecutionTableRow: React.FC<RoadmapExecutionTableRowProps> = (props) => {
  const {
    operationId,
    roadmapItem,
    productionOrderId,
    ...tableRowProps
  } = props;

  const { formatMessage } = useIntl();
  const hasWorkInstructions = roadmapItem.workInstructions != null && roadmapItem.workInstructions.length > 0;
  const hasProductionNotes = roadmapItem.productionNotes != null && roadmapItem.productionNotes.length > 0;

  const renderRoadmapField = (field: RoadmapField) => {
    if (field.propertyName === 'quantity') {
      return (
        <>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Typography variant='body1'>
              {`${roadmapItem.quantityManufactured} / ${roadmapItem.quantity}`}
            </Typography>
            <Button variant='contained' onClick={props.onUpdateQuantityClicked}>{formatMessage(m.update)}</Button>
          </Stack>
        </>);
    }
    else {
      return roadmapItem[field.propertyName];
    }
  };

  return (
    <TableRow {...tableRowProps}>

      {props.roadmapFields
        .map((field, index) => (
          <TableCell key={index}>
            {renderRoadmapField(field)}
          </TableCell>
        ))}

      <TableCell>
        <TechnicalInstructionsButton
          hasWorkInstructions={hasWorkInstructions}
          hasProductionNotes={hasProductionNotes}
          itemId={roadmapItem.itemId}
          operationId={operationId}
          productionItemId={productionOrderId}
          itemCode={roadmapItem.itemCode}
          productionNotes={roadmapItem.productionNotes}
          salesOrderId={roadmapItem.salesOrderId}
        />
      </TableCell>
    </TableRow>
  );
};
