import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { LinearProgress, Stack } from '@mui/material';
import { Container } from '@mui/system';
import {
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { SearchControls } from 'components/common/SearchControls';
import { SplitButton, SplitButtonOption } from 'components/common/SplitButton';
import dayjs from 'dayjs';
import { graphql } from 'gql';
import { useGraphQLMutation } from 'gql/useGraphQL';
import _ from 'lodash';
import PrioritizationApi from 'modules/production/prioritization/api';
import { SalesOrdersTabs } from 'modules/production/prioritization/components/SalesOrdersTabs';
import { SalesOrder } from 'modules/schedule';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import useApiErrorHandler from 'utils/ApiErrorHandler';
import * as LayoutActions from '../../../../store/layout/actions';
import { SalesOrderSummary } from '../../scheduling/components/SalesOrderSummary/SalesOrderSummary';
import { UnplanifiedOrdersTable } from '../components/UnplannedOrders/UnplannedOrdersTable';

const m = defineMessages({
  pageTitle: {
    id: 'UnplanifiedOrdersPage.pageTitle',
    defaultMessage: 'Unplanned Orders',
  },
  lotsTitle: {
    id: 'UnplanifiedOrdersPage.lotsTitle',
    defaultMessage: 'Unplanned Lots',
  },
  totalUnplannedOrders: {
    id: 'UnplannedOrdersPage.totalUnplannedOrders',
    defaultMessage:
      '{count, plural, =0 {No unplanned orders} one {{count} unplanned order} other {{count} unplanned orders}}',
  },
});

const completeSalesOrderGqlMutation = graphql(`
  mutation CompleteSalesOrderMutation($input: CompleteSalesOrderInput!) {
    completeSalesOrder(input: $input) {
      int
    }
  }
`);

export const UnplannedOrdersPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { onSettledErrorHandler } = useApiErrorHandler();
  const [searchTerm, setSearchTerm] = useState('');
  const gridApiRef = useGridApiRef();
  const selectedRowsRef = useRef<number[]>([]);
  const queryClient = useQueryClient();

  const { data: unprioritizedOrders, isFetching: isLoading } = useQuery<
    SalesOrder[]
  >(
    ['planning-unprioritized-sales-orders'],
    async () =>
      (await axios.get<SalesOrder[]>('/api/planning/unprioritized-orders'))
        .data,
    {
      refetchOnWindowFocus: false,
      onSettled: onSettledErrorHandler,
    }
  );

  const searchFilters: GridFilterModel = useMemo(
    () => ({
      items: [
        {
          id: 1,
          field: 'orderedOn',
          operator: 'is',
          value: dayjs(searchTerm).isValid()
            ? dayjs(searchTerm).format('YYYY-MM-DD')
            : undefined,
        },
        { id: 2, field: 'salesOrderNumber', operator: '=', value: searchTerm },
        { id: 3, field: 'client', operator: 'contains', value: searchTerm },
        { id: 4, field: 'company', operator: 'contains', value: searchTerm },
      ],
      logicOperator: GridLogicOperator.Or,
    }),
    [searchTerm]
  );

  const handleRowsSelectionChange = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    selectedRowsRef.current = rowSelectionModel as number[];
  };

  useEffect(() => {
    dispatch(LayoutActions.setPageTitle({ title: formatMessage(m.pageTitle) }));
  }, []);

  const prioritizeButtonOptions: SplitButtonOption[] = [
    {
      label: formatMessage({ id: 'Prioritize first' }),
      onClick: () =>
        selectedRowsRef.current.length > 0 &&
        prioritizeFirstMutation.mutate(selectedRowsRef.current),
    },
    {
      label: formatMessage({ id: 'Prioritize last' }),
      onClick: () =>
        selectedRowsRef.current.length > 0 &&
        prioritizeLastMutation.mutate(selectedRowsRef.current),
    },
  ];

  const handlePrioritizeOrderFirst = (salesOrderId: number) =>
    prioritizeFirstMutation.mutate([salesOrderId]);

  const handlePrioritizeOrderLast = (salesOrderId: number) =>
    prioritizeLastMutation.mutate([salesOrderId]);

  const handleCompleteSalesOrder = (salesOrderId: number) => {
    completeSalesOrderMutation.mutate({ input: { salesOrderId } });
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries(['planning-unprioritized-dates']);
    queryClient.invalidateQueries(['planning-unprioritized-sales-orders']);
  };

  const prioritizeFirstMutation = useMutation(PrioritizationApi.appendFirst, {
    onSuccess: () => invalidateQueries(),
    onSettled: onSettledErrorHandler,
  });

  const prioritizeLastMutation = useMutation(PrioritizationApi.appendLast, {
    onSuccess: () => invalidateQueries(),
    onSettled: onSettledErrorHandler,
  });

  const completeSalesOrderMutation = useGraphQLMutation(
    completeSalesOrderGqlMutation,
    {
      onSuccess: () => invalidateQueries(),
      onSettled: onSettledErrorHandler,
    }
  );

  return (
    <>
      <SalesOrdersTabs />

      {isLoading && <LinearProgress />}

      <Container sx={{ paddingTop: '20px', paddingBottom: '50px' }}>
        <Stack gap={2}>
          {!isLoading && (
            <>
              <SearchControls
                totalResultsCount={unprioritizedOrders?.length ?? 0}
                totalResultsMessage={m.totalUnplannedOrders}
                onChange={_.debounce(setSearchTerm, 500)}
                onClear={() => setSearchTerm('')}
              >
                <SplitButton
                  variant="contained"
                  disabled={
                    prioritizeFirstMutation.isLoading ||
                    prioritizeLastMutation.isLoading
                  }
                  loading={
                    prioritizeFirstMutation.isLoading ||
                    prioritizeLastMutation.isLoading
                  }
                  options={prioritizeButtonOptions}
                />
              </SearchControls>

              <UnplanifiedOrdersTable
                density="comfortable"
                apiRef={gridApiRef}
                salesOrders={unprioritizedOrders ?? []}
                filterModel={searchFilters}
                disableColumnMenu
                disableColumnFilter
                disableColumnReorder
                ignoreDiacritics
                getRowId={(row) => row.id}
                onRowSelectionModelChange={handleRowsSelectionChange}
                getDetailPanelContent={({ row }) => (
                  <SalesOrderSummary salesOrder={row} />
                )}
                slots={{
                  detailPanelExpandIcon: () => <KeyboardArrowDownIcon />,
                  detailPanelCollapseIcon: () => <KeyboardArrowUpIcon />,
                }}
                onPrioritizeFirst={handlePrioritizeOrderFirst}
                onPrioritizeLast={handlePrioritizeOrderLast}
                onComplete={handleCompleteSalesOrder}
                height="70vh"
              />
            </>
          )}
        </Stack>
      </Container>
    </>
  );
};
