import { CostCenterEdit } from '../../../models/cost-center/CostCenterEdit';
import { CostCenter } from '../../production';
import {
  COST_CENTERS_CREATE,
  COST_CENTERS_CREATE_FAILURE,
  COST_CENTERS_CREATE_SUCCESS,
  COST_CENTERS_LOAD_ALL,
  COST_CENTERS_LOAD_ALL_FAILURE,
  COST_CENTERS_LOAD_ALL_SUCCESS,
  COST_CENTERS_UPDATE,
  COST_CENTERS_UPDATE_FAILURE,
  COST_CENTERS_UPDATE_SUCCESS,
  CreateAction,
  CreateFailureAction,
  CreateSuccessAction,
  LoadAllAction,
  LoadAllFailureAction,
  LoadAllSuccessAction,
  UpdateAction,
  UpdateFailureAction,
  UpdateSuccessAction,
  LoadOverviewAction,
  COST_CENTERS_LOAD_OVERVIEW,
  CostCenterOverview,
  LoadOverviewSuccessAction,
  COST_CENTERS_LOAD_OVERVIEW_SUCCESS,
  LoadOverviewFailureAction,
  COST_CENTERS_LOAD_OVERVIEW_FAILURE
} from './types';

export function loadAll (): LoadAllAction {
  return {
    type: COST_CENTERS_LOAD_ALL,
    payload: {}
  };
}

export function loadAllSuccess (costCenters: CostCenter[]): LoadAllSuccessAction {
  return {
    type: COST_CENTERS_LOAD_ALL_SUCCESS,
    payload: costCenters
  };
}

export function loadAllFailure (error: Error): LoadAllFailureAction {
  return {
    type: COST_CENTERS_LOAD_ALL_FAILURE,
    payload: { error }
  };
}

export function create (costCenter: CostCenterEdit): CreateAction {
  return {
    type: COST_CENTERS_CREATE,
    payload: costCenter
  };
}

export function createSuccess (costCenter: CostCenter): CreateSuccessAction {
  return {
    type: COST_CENTERS_CREATE_SUCCESS,
    payload: costCenter
  };
}

export function createFailure (error: Error): CreateFailureAction {
  return {
    type: COST_CENTERS_CREATE_FAILURE,
    payload: { error }
  };
}

export function update (costCenter: CostCenterEdit): UpdateAction {
  return {
    type: COST_CENTERS_UPDATE,
    payload: costCenter
  };
}

export function updateSuccess (costCenter: CostCenter): UpdateSuccessAction {
  return {
    type: COST_CENTERS_UPDATE_SUCCESS,
    payload: costCenter
  };
}

export function updateFailure (error: Error): UpdateFailureAction {
  return {
    type: COST_CENTERS_UPDATE_FAILURE,
    payload: { error }
  };
}

export function loadOverview (costCenterId: number): LoadOverviewAction {
  return {
    type: COST_CENTERS_LOAD_OVERVIEW,
    payload: { costCenterId }
  };
}

export function loadOverviewSuccess (costCenterId: number, overviews: CostCenterOverview[]): LoadOverviewSuccessAction {
  return {
    type: COST_CENTERS_LOAD_OVERVIEW_SUCCESS,
    payload: { costCenterId, overviews }
  };
}

export function loadOverviewFailure (costCenterId: number, error: Error): LoadOverviewFailureAction {
  return {
    type: COST_CENTERS_LOAD_OVERVIEW_FAILURE,
    payload: { costCenterId, error }
  };
}
