import { LOAD_PRODUCTION_SCHEDULE_REQUEST, LoadRequestAction, ProductionScheduleEntities } from './types';
import { takeEvery, put, all, fork, call } from 'redux-saga/effects';
import * as actions from './actions';
import * as api from './api';

function * handleLoad (action: LoadRequestAction) {
  try {
    const results: ProductionScheduleEntities = yield call(api.load, action.payload.forDate);
    yield put(actions.loadSuccess(action.payload.forDate, results));
  } catch (ex) {
    yield put(actions.loadFailure(action.payload.forDate, ex as Error));
  }
}

function * watchLoad () { yield takeEvery(LOAD_PRODUCTION_SCHEDULE_REQUEST, handleLoad); }

function * productionScheduleSagas () {
  yield all([
    fork(watchLoad)
  ]);
}

export default productionScheduleSagas;
