import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { AccountType } from '../../..';
import { FormSection } from '../../../../../../components/common/FormSection';
import { InformationBubble } from '../../../../../../components/common/InformationBubble';
import { User } from '../../../../../../models';
import { UserRoleDropdown } from '../UserRoleDropdown';

const m = defineMessages({
  generalSectionTitle: { id: 'ProfilePropertiesSection.generalSectionTitle', defaultMessage: 'Profile properties' },
  generalSectionSubtitle: { id: 'ProfilePropertiesSection.generalSectionSubtitle', defaultMessage: 'Configure the default properties for this user account.' },
  displayNameLabel: { id: 'ProfilePropertiesSection.displayNameLabel', defaultMessage: 'Name' },
  displayNamePlaceholder: { id: 'ProfilePropertiesSection.displayNamePlaceholder', defaultMessage: 'Full name' },
  roleLabel: { id: 'ProfilePropertiesSection.roleLabel', defaultMessage: 'Role' },
  employeeNumberLabel: { id: 'ProfilePropertiesSection.employeeNumberLabel', defaultMessage: 'Employee number' },
  employeeNumberPlaceholder: { id: 'ProfilePropertiesSection.empoyeeNumberPlaceholder', defaultMessage: 'Optional' },
  employeeNumberTooltip: { id: 'ProfilePropertiesSection.employeeNumberTooltip', defaultMessage: 'If you have a supported time tracking software (e.g. Kelio), you can specify an employee identifier to retrieve accurate clock-ins and clock-outs from that system.' }
});

export const ProfilePropertiesSection = () => {
  const { control, formState: { errors } } = useFormContext<User>();
  const { formatMessage } = useIntl();

  return (
    <FormSection title={formatMessage(m.generalSectionTitle)} subtitle={formatMessage(m.generalSectionSubtitle)} isOpenByDefault={true}>
      <Controller
        name="displayName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Form.Input
            required
            error={errors.displayName != null}
            label={formatMessage(m.displayNameLabel)}
            placeholder={formatMessage(m.displayNamePlaceholder)}
            value={field.value || ''}
            onChange={(e, data) => field.onChange(data.value)}
          />
        )}
      />

      <Form.Group widths={2}>
        <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Field required error={errors.role != null}>
              <label>{formatMessage(m.roleLabel)}</label>
              <UserRoleDropdown
                fluid
                selection
                value={field.value}
                onChange={(e, data) => field.onChange(data.value as AccountType)}
              />
            </Form.Field>
          )}
        />

        <Controller
          name="employeeNumber"
          control={control}
          render={({ field }) => (
            <Form.Field error={errors.employeeNumber != null}>
              <label>
                {formatMessage(m.employeeNumberLabel)}
                <InformationBubble content={formatMessage(m.employeeNumberTooltip)} />
              </label>
              <Form.Input
                placeholder={formatMessage(m.employeeNumberPlaceholder)}
                value={field.value || ''}
                onChange={(e, data) => field.onChange(data.value)}
              />
            </Form.Field>
          )}
        />
      </Form.Group>
    </FormSection>
  );
};
