import { ApplicationState } from '../../store';
import { WorkInstructionAssociation } from '../administration/work-instructions';
import { SalesOrder } from '../schedule/types';
import { Lot } from './lots/types';
import { CostCenter, GroupingMode, Item, OperatingMode, Operation, ProductCollection, ProductionItem, ProductionNote, ProductType, Unit } from './types';


// cost centers
export const getAllCostCenters = (state: ApplicationState): CostCenter[] => state.production.costCenters.allIds.map(x => state.production.costCenters.byId[x]);
export const getCostCenterById = (state: ApplicationState, id: number): CostCenter | undefined => state.production.costCenters.byId[id];

// operations
export const getOperationById = (state: ApplicationState, id: number): Operation | undefined => state.production.operations.byId[id];
export const getOperations = (state: ApplicationState, costCenterId: number): Operation[] => {
  return getAllOperations(state).filter(x => x.costCenterId === costCenterId);
};
export const getAllOperations = (state: ApplicationState): Operation[] => state.production.operations.allIds.map(x => state.production.operations.byId[x]);
export const getAllOperatingModes = (state: ApplicationState): OperatingMode[] => {
  return [
    OperatingMode.perLot,
    OperatingMode.perProductionOrder
  ];
};

export const getAllGroupingModes = (state: ApplicationState): GroupingMode[] => {
  return [
    GroupingMode.none,
    GroupingMode.byProductionUnit
  ];
};

// lots
export const getLotById = (state: ApplicationState, lotId: number): Lot | undefined => state.production.lots.byId[lotId];
export const getAllLots = (state: ApplicationState): Lot[] => state.production.lots.allIds.map(x => getLotById(state, x)).filter(x => x != null) as Lot[];

// production orders
export const getProductionOrders = (state: ApplicationState, unitId: number): ProductionItem[] => {
  const lotIds = state.production.lots.byUnitId[unitId] || [];
  const lots = lotIds.map(x => getLotById(state, x)).filter(x => x != null) as Lot[];
  const productionItems = lots.flatMap(x => x.productionItems.map(y => getProductionItemById(state, y))).filter(x => x != null) as ProductionItem[];

  return productionItems;
};

export const getProductionItemById = (state: ApplicationState, productionOrderId: number): ProductionItem | undefined => state.production.productionItems.byId[productionOrderId];
export const getAllProductionItems = (state: ApplicationState) =>
  state.production.productionItems.allIds
    .map((x) => getProductionItemById(state, x))
    .filter((x): x is ProductionItem => x != null);

// production notes
export const getProductionNoteById = (state: ApplicationState, id: number): ProductionNote | undefined => state.production.productionNotes.byId[id];
export const getProductionNotesByProductionOrder = (state: ApplicationState, productionItemId: number): ProductionNote[] => {
  const productionNoteIds = state.production.productionNotes.byProductionItem[productionItemId] || [];
  const result = productionNoteIds.map(x => getProductionNoteById(state, x)).filter(x => x != null);
  return result as ProductionNote[];
};

// production units
export const getAllUnits = (state: ApplicationState): Unit[] => state.production.units.allIds.map(p =>  state.production.units.byId[p]);
export const getUnitById = (state: ApplicationState, unitId: number): Unit | undefined => state.production.units.byId[unitId];
export const getUnitsByCostCenter = (state: ApplicationState, costCenterId: number) => {
  const costCenter = getCostCenterById(state, costCenterId);
  if (costCenter != null) {
    return costCenter.productionUnits.map(x => state.production.units.byId[x]).filter(x => x != null);
  }

  return [];
};

// work instructions
export const getWorkInstructions = (
  state: ApplicationState,
  itemId: number,
  operationId: number,
  productionItemId: number,
  salesOrderId: number | undefined
): WorkInstructionAssociation[] => {
  const allWorkInstructionAssociations =
    state.production.workInstructions.allIds.map(
      (id) => state.production.workInstructions.byId[id]
    );

  const workInstructionAssociation = allWorkInstructionAssociations.filter(
    (x) =>
      (x.forItemId == null || x.forItemId === itemId) &&
      (x.forOperationId == null || x.forOperationId === operationId) &&
      (x.forProductionItemId == null ||
        x.forProductionItemId === productionItemId) &&
      (x.forSalesOrderId == null || x.forSalesOrderId === salesOrderId)
  );
  return workInstructionAssociation;
};

export const getProductCollectionById = (state: ApplicationState, id: number): ProductCollection | undefined => state.production.productCollections.byId[id];
export const getProductTypeById = (state: ApplicationState, id: number): ProductType | undefined => state.production.productTypes.byId[id];

export const areWorkInstructionsLoading = (state: ApplicationState): boolean => state.production.workInstructions.isLoading;

export const isUnitLoading = (state: ApplicationState, unitId: number): boolean => state.production.units.loadingIds.find(x => x === unitId) != null;
export const areUnitsLoading = (state: ApplicationState): boolean => state.production.units.isLoading;
export const isRoadmapLoading = (state: ApplicationState): boolean => state.production.lots.isRoadmapLoading;

export const getItemById = (state: ApplicationState, id: number): Item | undefined => state.production.items.byId[id];
export const getSalesOrderById = (state: ApplicationState, id: number): SalesOrder | undefined => state.production.salesOrders.byId[id];
export const getSalesOrders = (state: ApplicationState): SalesOrder[] => state.production.salesOrders.allIds.map(id => state.production.salesOrders.byId[id]);