import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { DigitInput } from 'components/DigitInput';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { isSearchModalVisible } from 'store/layout';
import * as LayoutActions from 'store/layout/actions';

export const SearchModal = (props: Omit<DialogProps, 'open' | 'onClose'>) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const isOpen = useSelector(isSearchModalVisible);
  const [search, setSearch] = useState('');
  const closeModal = () => dispatch(LayoutActions.hideSearchModal());

  const handleSearch = () => {
    closeModal();
    history.push(`/sales-order/search?salesOrderNumber=${search}`);
    setSearch('');
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} {...props}>
      <DialogTitle>
        {formatMessage({
          id: 'Production.SearchModal.Title',
          defaultMessage: 'Search by order number',
        })}
      </DialogTitle>
      <DialogContent>
        <Stack flex={1} maxWidth={400} gap={2}>
          <Stack gap={2}>
            <TextField
              fullWidth
              variant="standard"
              inputProps={{ readOnly: true }}
              value={search}
            />
            <DigitInput value={search} onChange={setSearch} />
          </Stack>
          <Button primary onClick={handleSearch}>
            {formatMessage({
              id: 'Production.SearchModal.SearchButton',
              defaultMessage: 'Search',
            })}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
