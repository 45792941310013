import { RegisterUIProgressEventPayload } from '../production/workstation/types';

export const SIGNALR_UPDATE_PROGRESS = 'UpdateProgress';
export const SIGNALR_SALES_ORDERS_PRIORITIES_UPDATED = 'SalesOrdersPrioritiesUpdated';
export const SIGNALR_PRIORITIZE_LOT = 'PrioritizeLot';
export const SIGNALR_UNPRIORITIZE_LOT = 'UnprioritizeLot';
export const SIGNALR_DISCONNECT = 'Disconnect';
export const SIGNALR_DISCONNECTED = 'ConnectionClosed';
export const SIGNALR_RECONNECTING = 'ConnectionReestablishing';
export const SIGNALR_RECONNECTED = 'ConnectionReestablished';

export interface SignalRUpdateProgressEvent { event: typeof SIGNALR_UPDATE_PROGRESS; payload: RegisterUIProgressEventPayload; }
export interface SignalRPrioritizeLotEvent { event: typeof SIGNALR_PRIORITIZE_LOT; payload: { lotId: number; orderIndex: number; }; }
export interface SignalRUnprioritizeLotEvent { event: typeof SIGNALR_UNPRIORITIZE_LOT; payload: { lotId: number; }; }
export interface SignalRDisconnect { event: typeof SIGNALR_DISCONNECT; }
export interface SignalRDisconnected { event: typeof SIGNALR_DISCONNECTED; }
export interface SignalRReconnecting { event: typeof SIGNALR_RECONNECTING; }
export interface SignalRReconnected { event: typeof SIGNALR_RECONNECTED; }

export type SignalREvents =
    | SignalRUpdateProgressEvent
    | SignalRPrioritizeLotEvent
    | SignalRUnprioritizeLotEvent
    | SignalRDisconnect
    | SignalRDisconnected
    | SignalRReconnecting
    | SignalRReconnected;

export const SET_CONNECTION_STATUS_ONLINE = 'LIVE_UPDATES_SET_CONNECTION_STATUS_ONLINE';
export const SET_CONNECTION_STATUS_OFFLINE = 'LIVE_UPDATES_SET_CONNECTION_STATUS_OFFLINE';
export const TURN_ON_LIVE_UPDATES = '@@dev/TURN_ON_LIVE_UPDATES';
export const TURN_OFF_LIVE_UPDATES = '@@dev/TURN_OFF_LIVE_UPDATES';

export interface SetConnectionStatusOnlineAction { type: typeof SET_CONNECTION_STATUS_ONLINE; }
export interface SetConnectionStatusOfflineAction { type: typeof SET_CONNECTION_STATUS_OFFLINE; }
export interface TurnOnLiveUpdatesAction { type: typeof TURN_ON_LIVE_UPDATES; }
export interface TurnOffLiveUpdatesAction { type: typeof TURN_OFF_LIVE_UPDATES; }

export type LiveUpdatesActions =
    | SetConnectionStatusOnlineAction
    | SetConnectionStatusOfflineAction
    | TurnOnLiveUpdatesAction
    | TurnOffLiveUpdatesAction;

export enum ConnectionStatus {
    online,
    offline,
}

export interface LiveUpdatesState {
    connectionStatus: ConnectionStatus;
    areLiveUpdatesForcedOff: boolean;
}

export interface UserLocation {
    deviceId: number | undefined;
    costCenterId: number | undefined;
    workstationId: number | undefined;
    operationId: number | undefined;
    lotId: number | undefined;
    productionItemId: number | undefined;
    locationUrl: string;
}
