import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAllTemplates, areTemplatesLoading } from '../../../../production/roadmaps/selectors';



export type RoadmapsDropdownProps =
    & DropdownProps;

const m = defineMessages({
  defaultRoadmap: { id: 'RoadmapsDropdown.defaultRoadmap', defaultMessage: 'Use the default roadmap' }
});

export const defaultRoadmapValue = -1;

export const RoadmapsDropdown: React.FC<RoadmapsDropdownProps> = (props) => {
  const { ...dropdownProps } = props;

  const { formatMessage } = useIntl();
  const [dropdownItems, setDropdownItems] = useState<DropdownItemProps[]>([]);
  const roadmaps = useSelector(getAllTemplates);
  const loading = useSelector(areTemplatesLoading);

  useEffect(() => {
    const defaultRoadmapOption: DropdownItemProps = ({
      key: defaultRoadmapValue,
      text: formatMessage(m.defaultRoadmap),
      value: defaultRoadmapValue
    });

    const items = roadmaps.map((x): DropdownItemProps => ({
      key: x.id,
      text: x.name,
      value: x.id
    }));

    setDropdownItems([defaultRoadmapOption, ...items]);
  }, [roadmaps]);

  return (
    <Dropdown
      options={dropdownItems}
      loading={loading}
      {...dropdownProps}
    />
  );
};
