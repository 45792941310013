import { Roadmap } from '../../production/roadmaps/types';
import { get, post, httpDelete } from '../../../utils/api';

const roadmapEndpoint = '/api/roadmap';

export async function loadRoadmapById (roadmapId: number): Promise<Roadmap> {
  return await get<Roadmap>(`${roadmapEndpoint}/${roadmapId}`);
}

export async function createRoadmap (roadmap: Roadmap): Promise<Roadmap> {
  return await post<Roadmap>(roadmapEndpoint, roadmap);
}

export async function updateRoadmap (roadmapId: number, roadmap: Roadmap): Promise<Roadmap> {
  return await post<Roadmap>(`${roadmapEndpoint}/${roadmapId}`, roadmap);
}

export async function deleteRoadmap (roadmapId: number): Promise<void> {
  return await httpDelete<void>(`${roadmapEndpoint}/${roadmapId}`);
}
