import { ApplicationState } from '../../store/index';

export const getCurrentUser = (state: ApplicationState) => state.account.user;
export const getCurrentUserInitials = (state: ApplicationState) => getInitials(state.account.user?.displayName);
export const getCurrentUserToken = (state: ApplicationState) => state.account.user != null ? state.account.isAuthenticated : undefined;
export const isAuthenticated = (state: ApplicationState): boolean => state.account.user != null && state.account.isAuthenticated;
export const isAuthenticating = (state: ApplicationState): boolean => state.account.isAuthenticating;
export const isAuthenticatingWithSecurityKey = (state: ApplicationState) => state.account.isAuthenticatingWithWebAuth;
export const hasFailedAuthentication = (state: ApplicationState) => state.account.hasFailedAuthentication;
export const isProfileLoaded = (state: ApplicationState) => state.account.isProfileLoaded;
export const isWebAuthUserRequested = (state: ApplicationState) => state.account.isWebAuthUserRequested;

export const getInitials = (value: string | undefined, maxNumberOfInitials = 2) => (value ?? '').trim().split(' ').map(p => p.charAt(0).toUpperCase()).slice(0, maxNumberOfInitials).reduce((a, b) => a + b, '');
