import { RoadmapSortEntry } from '../../administration/roadmaps/views/components/RoadmapFieldsSort';
import { ProductionEntities } from '../types';

export const LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST = 'LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST';
export const LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS = 'LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS';
export const LOAD_ROADMAP_ITEMS_FOR_OPERATION_FAILURE = 'LOAD_ROADMAP_ITEMS_FOR_OPERATION_FAILURE';

export const LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST = 'LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST';
export const LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS = 'LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS';
export const LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE = 'LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE';

export const LOAD_ALL_ROADMAP_TEMPLATES_REQUEST = 'LOAD_ALL_ROADMAP_TEMPLATES_REQUEST';
export const LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS = 'LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS';
export const LOAD_ALL_ROADMAP_TEMPLATES_FAILURE = 'LOAD_ALL_ROADMAP_TEMPLATES_FAILURE';

export const LOAD_ALL_ROADMAP_FIELDS_REQUEST = 'LOAD_ALL_ROADMAP_FIELDS_REQUEST';
export const LOAD_ALL_ROADMAP_FIELDS_SUCCESS = 'LOAD_ALL_ROADMAP_FIELDS_SUCCESS';
export const LOAD_ALL_ROADMAP_FIELDS_FAILURE = 'LOAD_ALL_ROADMAP_FIELDS_FAILURE';

export interface LoadRoadmapItemsForOperationRequestAction { type: typeof LOAD_ROADMAP_ITEMS_FOR_OPERATION_REQUEST; payload: { operationId: number, lotId?: number, productionItemId?: number }; }
export interface LoadRoadmapItemsForOperationSuccessAction { type: typeof LOAD_ROADMAP_ITEMS_FOR_OPERATION_SUCCESS; payload: { entities: ProductionEntities }; }
export interface LoadRoadmapItemsForOperationFailureAction { type: typeof LOAD_ROADMAP_ITEMS_FOR_OPERATION_FAILURE; payload: { error: Error }; }

export interface LoadRoadmapItemsForDeviceRequestAction { type: typeof LOAD_ROADMAP_ITEMS_FOR_DEVICE_REQUEST; payload: { deviceId: number, salesOrderId?: number, lotId?: number }; }
export interface LoadRoadmapItemsForDeviceSuccessAction { type: typeof LOAD_ROADMAP_ITEMS_FOR_DEVICE_SUCCESS; payload: { entities: ProductionEntities }; }
export interface LoadRoadmapItemsForDeviceFailureAction { type: typeof LOAD_ROADMAP_ITEMS_FOR_DEVICE_FAILURE; payload: { error: Error }; }

export interface LoadAllRoadmapTemplatesRequestAction { type: typeof LOAD_ALL_ROADMAP_TEMPLATES_REQUEST; }
export interface LoadAllRoadmapTemplatesSuccessAction { type: typeof LOAD_ALL_ROADMAP_TEMPLATES_SUCCESS; payload: { templates: Roadmap[] }; }
export interface LoadAllRoadmapTemplatesFailureAction { type: typeof LOAD_ALL_ROADMAP_TEMPLATES_FAILURE; payload: { error: Error }; }

export interface LoadAllRoadmapFieldsRequestAction { type: typeof LOAD_ALL_ROADMAP_FIELDS_REQUEST; }
export interface LoadAllRoadmapFieldsSuccessAction { type: typeof LOAD_ALL_ROADMAP_FIELDS_SUCCESS; payload: { fields: RoadmapField[] }; }
export interface LoadAllRoadmapFieldsFailureAction { type: typeof LOAD_ALL_ROADMAP_FIELDS_FAILURE; payload: { error: Error }; }

export type RoadmapActionTypes =
    | LoadRoadmapItemsForOperationRequestAction | LoadRoadmapItemsForOperationSuccessAction | LoadRoadmapItemsForOperationFailureAction
    | LoadRoadmapItemsForDeviceRequestAction | LoadRoadmapItemsForDeviceSuccessAction | LoadRoadmapItemsForDeviceFailureAction
    | LoadAllRoadmapTemplatesRequestAction | LoadAllRoadmapTemplatesSuccessAction | LoadAllRoadmapTemplatesFailureAction
    | LoadAllRoadmapFieldsRequestAction | LoadAllRoadmapFieldsSuccessAction | LoadAllRoadmapFieldsFailureAction;

export interface RoadmapsState {
    templates: RoadmapTemplatesState;
    fields: RoadmapFieldsState;
}

export interface RoadmapTemplatesState {
    byId: {
        [id: number]: Roadmap;
    },
    allIds: number[];
    isLoading: boolean;
}

export interface RoadmapFieldsState {
    byId: { [id: number]: RoadmapField };
    allIds: number[];
    isLoading: boolean;
}

export interface Roadmap {
    id: number;
    isDefault: boolean;
    columns: RoadmapColumn[];
    sortings: RoadmapSortEntry[];
    name: string;
}

export interface RoadmapColumn {
    roadmapId: number;
    fieldId: number;
    field: RoadmapField;
    orderIndex: number;
}

export interface RoadmapField {
    id: number;
    propertyName: string;
    name: string;
}

export interface UpdateItemOperationCompletedQuantity {
    manufacturedItemOperationId: number;
    quantity: number;
}

