import React from 'react';
import { List, StrictListItemProps } from 'semantic-ui-react';
import { DragDropIcon } from '../../../../../../components';
import { RoadmapField } from '../../../../../production/roadmaps/types';
import { DraggableProvided } from 'react-beautiful-dnd';

interface OwnProps {
    field: RoadmapField;
    draggable?: DraggableProvided;
}

export type RoadmapFieldListItemProps =
    & OwnProps
    & StrictListItemProps;

export const RoadmapFieldListItem: React.FC<RoadmapFieldListItemProps> = (props) => {
  const { field, draggable, ...listItemProps } = props;

  const canBeDragAndDropped = props.draggable != null;
  const draggableProps = props.draggable != null ? props.draggable.draggableProps : {};
  const dragHandleProps = props.draggable != null ? props.draggable.dragHandleProps : {};

  return (
    <List.Item {...draggableProps} {...dragHandleProps} {...listItemProps}>
      {canBeDragAndDropped &&
                <List.Icon>
                  <DragDropIcon size="large" />
                </List.Icon>
      }
      <List.Content>
        <List.Header>{props.field.name}</List.Header>
        <List.Description>{props.field.propertyName}</List.Description>
      </List.Content>
    </List.Item>
  );
};
