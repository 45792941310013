import _ from 'lodash';
import { Reducer, combineReducers } from 'redux';
import { WorkstationsAdministrationState, WorkstationsState, Workstation, ADD_WORKSTATION_SUCCESS, WorkstationsActionTypes, DELETE_WORKSTATION_FAILURE, DELETE_WORKSTATION_SUCCESS, LOAD_ALL_WORKSTATIONS_SUCCESS, UPDATE_WORKSTATION_SUCCESS, DELETE_WORKSTATION } from './types';

const initialState: WorkstationsAdministrationState = {
  workstations: {
    byId: {},
    byCostCenterId: {},
    allIds: []
  }
};

const workstationsReducer: Reducer<WorkstationsState, WorkstationsActionTypes> = (state = initialState.workstations, action) => {
  switch (action.type) {
  case ADD_WORKSTATION_SUCCESS: {
    return {
      ...state,
      byId: _.merge({}, state.byId, { [action.payload.workstation.id]: action.payload.workstation }),
      allIds: _.union([action.payload.workstation.id], state.allIds)
    };
  }

  case UPDATE_WORKSTATION_SUCCESS: {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.workstation.id]: action.payload.workstation
      }
    };
  }

  case DELETE_WORKSTATION: {
    return {
      ...state,
      allIds: state.allIds.filter(x => x !== action.payload.workstationId)
    };
  }

  case DELETE_WORKSTATION_FAILURE: {
    return {
      ...state,
      allIds: _.union([action.payload.workstationId], state.allIds)
    };
  }

  case LOAD_ALL_WORKSTATIONS_SUCCESS: {
    const byId = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));

    const byCostCenterId = Object.assign({}, ..._.chain(action.payload)
      .groupBy(x => x.costCenterId)
      .map((workstations, costCenterId) => ({ [costCenterId]: workstations.map(x => x.id) }))
      .value());

    return {
      ...state,
      byId,
      byCostCenterId,
      allIds: action.payload.map(x => x.id)
    };
  }

  default:
    return state;
  }
};

const reducers = combineReducers<WorkstationsAdministrationState>({
  workstations: workstationsReducer
});

export { reducers as workstationsReducer };
