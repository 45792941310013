/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query ManageUsersPageQuery {\n    ...UsersTableQueryFragment\n  }\n": types.ManageUsersPageQueryDocument,
    "\n  fragment UsersTableQueryFragment on Query {\n    users {\n      id\n      accountType\n      displayName\n      userName\n      employeeNumber\n      storedCredentials {\n        regDate\n      }\n    }\n  }\n": types.UsersTableQueryFragmentFragmentDoc,
    "\n  query SalesOrderSearchControlQuery(\n    $filter: SalesOrderGraphQLTypeFilterInput\n  ) {\n    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {\n      totalCount\n      items {\n        id\n        salesOrderNumber\n        customerFullName\n        customerCompanyName\n      }\n    }\n  }\n": types.SalesOrderSearchControlQueryDocument,
    "\n  mutation DeleteLotMutation($input: DeleteLotInput!) {\n    deleteLot(input: $input) {\n      int\n      errors {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.DeleteLotMutationDocument,
    "\n  fragment OrderList_QueryFragment on Query {\n    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {\n      totalCount\n      items {\n        id\n        salesOrderNumber\n        purchaseOrderNumber\n        orderedOn\n        customerFullName\n        customerCompanyName\n        productionStatus\n      }\n    }\n  }\n": types.OrderList_QueryFragmentFragmentDoc,
    "\n  query ProductionHistoryProductionItemsQuery($salesOrderId: Int!) {\n    productionItems(where: { orderId: { eq: $salesOrderId } }) {\n      id\n      itemDescription\n      quantity\n      currentOperationId\n    }\n    operations {\n      id\n      name\n    }\n  }\n": types.ProductionHistoryProductionItemsQueryDocument,
    "\n  query SalesOrdersTotalCountQuery {\n    salesOrdersTotalCount\n  }\n": types.SalesOrdersTotalCountQueryDocument,
    "\n  query ProductionHistoryPageQuery($filter: SalesOrderGraphQLTypeFilterInput) {\n    ...OrderList_QueryFragment\n  }\n": types.ProductionHistoryPageQueryDocument,
    "\n  mutation CompleteSalesOrderMutation($input: CompleteSalesOrderInput!) {\n    completeSalesOrder(input: $input) {\n      int\n    }\n  }\n": types.CompleteSalesOrderMutationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ManageUsersPageQuery {\n    ...UsersTableQueryFragment\n  }\n"): (typeof documents)["\n  query ManageUsersPageQuery {\n    ...UsersTableQueryFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UsersTableQueryFragment on Query {\n    users {\n      id\n      accountType\n      displayName\n      userName\n      employeeNumber\n      storedCredentials {\n        regDate\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment UsersTableQueryFragment on Query {\n    users {\n      id\n      accountType\n      displayName\n      userName\n      employeeNumber\n      storedCredentials {\n        regDate\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SalesOrderSearchControlQuery(\n    $filter: SalesOrderGraphQLTypeFilterInput\n  ) {\n    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {\n      totalCount\n      items {\n        id\n        salesOrderNumber\n        customerFullName\n        customerCompanyName\n      }\n    }\n  }\n"): (typeof documents)["\n  query SalesOrderSearchControlQuery(\n    $filter: SalesOrderGraphQLTypeFilterInput\n  ) {\n    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {\n      totalCount\n      items {\n        id\n        salesOrderNumber\n        customerFullName\n        customerCompanyName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteLotMutation($input: DeleteLotInput!) {\n    deleteLot(input: $input) {\n      int\n      errors {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteLotMutation($input: DeleteLotInput!) {\n    deleteLot(input: $input) {\n      int\n      errors {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrderList_QueryFragment on Query {\n    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {\n      totalCount\n      items {\n        id\n        salesOrderNumber\n        purchaseOrderNumber\n        orderedOn\n        customerFullName\n        customerCompanyName\n        productionStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OrderList_QueryFragment on Query {\n    salesOrders(take: 200, where: $filter, order: [{ orderedOn: DESC }]) {\n      totalCount\n      items {\n        id\n        salesOrderNumber\n        purchaseOrderNumber\n        orderedOn\n        customerFullName\n        customerCompanyName\n        productionStatus\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductionHistoryProductionItemsQuery($salesOrderId: Int!) {\n    productionItems(where: { orderId: { eq: $salesOrderId } }) {\n      id\n      itemDescription\n      quantity\n      currentOperationId\n    }\n    operations {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query ProductionHistoryProductionItemsQuery($salesOrderId: Int!) {\n    productionItems(where: { orderId: { eq: $salesOrderId } }) {\n      id\n      itemDescription\n      quantity\n      currentOperationId\n    }\n    operations {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SalesOrdersTotalCountQuery {\n    salesOrdersTotalCount\n  }\n"): (typeof documents)["\n  query SalesOrdersTotalCountQuery {\n    salesOrdersTotalCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductionHistoryPageQuery($filter: SalesOrderGraphQLTypeFilterInput) {\n    ...OrderList_QueryFragment\n  }\n"): (typeof documents)["\n  query ProductionHistoryPageQuery($filter: SalesOrderGraphQLTypeFilterInput) {\n    ...OrderList_QueryFragment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CompleteSalesOrderMutation($input: CompleteSalesOrderInput!) {\n    completeSalesOrder(input: $input) {\n      int\n    }\n  }\n"): (typeof documents)["\n  mutation CompleteSalesOrderMutation($input: CompleteSalesOrderInput!) {\n    completeSalesOrder(input: $input) {\n      int\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;